import { useState } from "react";

import Image from "next/image";

import styles from "styles/containers/cart-item.module.scss";

import { Currency, CatalogHelpers } from "shared/tools";

import { Button, Drawer } from "shared/components";
import {
  Minus,
  NoImage,
  PenCircle,
  Plus,
  TrashCircle,
} from "shared/components/Icons";

import { OrderStorage } from "storage/OrderStorage";
import { DataLayer } from "shared/tools/DataLayer";
import { OMath } from "shared/tools/OMath";

const Item = ({ product, onEditProduct, onRemoveProduct, cartItemIndex }) => {
  const [openRemoveProduct, setOpenRemoveProduct] = useState(false);

  const selectedSize = (product.sizes || []).find((s) => s.selected);

  let selectedOptions = [];
  if (product.type !== "pizza") {
    selectedOptions = (product.items || [])
      .map((q) => q.items.filter((o) => o.selected))
      .flat();
  }

  const selectedDough = (product.doughs || []).find((e) => e.selected);

  let selectedFlavors = [];
  if (product.type === "pizza") {
    selectedFlavors = (product.items || []).filter((e) => e.selected);
  }

  const selectedEdge = (product.edges || []).find((e) => e.selected);

  const selectedExtras = (product.extra_ingredients || []).filter(
    (e) => e.selected
  );

  const calculateItemPrice = (item) => {
    const props = ["items", "sizes", "extra_ingredients", "doughs", "edges"];

    let total =
      Math.round((item.price || 0) * 100) *
      (item.selected !== false && item.quantity > 0 ? 1 : 0);
    for (const key of props) {
      if (item[key] && item[key].length > 0) {
        for (const subItem of item[key]) {
          total += calculateItemPrice(subItem) * 100;
        }
      }
    }

    return +OMath.div(total, 100).toFixed(2);
  };

  const decreaseQuantity = () => {
    if (product.quantity === 1) return;

    const productDataLayer = {
      event: "remove_from_cart",
      ecommerce: {
        currency: "BRL",
        value: calculateItemPrice(product),
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            quantity: 1,
            price: calculateItemPrice(product),
          },
        ],
      },
    };

    DataLayer.push({ ecommerce: null });
    DataLayer.push(productDataLayer);

    product.quantity = product.quantity - 1;
    OrderStorage.updateCartItem(cartItemIndex, product);
  };

  const increaseQuantity = () => {
    const productDataLayer = {
      event: "add_to_cart",
      ecommerce: {
        currency: "BRL",
        value: DataLayer.mountPrice(product),
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            quantity: 1,
            price: calculateItemPrice(product),
          },
        ],
      },
    };

    DataLayer.push({ ecommerce: null });
    DataLayer.push(productDataLayer);

    product.quantity = product.quantity + 1;
    OrderStorage.updateCartItem(cartItemIndex, product);
  };

  const getQuestionsOverview = () => {
    const getQuestion = (questionName, options) => {
      return (
        <div className={styles.question}>
          <p>{questionName}</p>
          <span>{options.map((option) => option).join(", ")}</span>
        </div>
      );
    };

    const extractSelectOptionsText = (q) => {
      const selectedOptions = q.items.filter(
        (option) => option.selected === true
      );
      const options = selectedOptions.map(
        (option) => `${option.quantity}x ${option.name}`
      );
      if (selectedOptions?.length === 0) return [];

      return options;
    };

    return product.items.map((question) => {
      const selectedOptions = question.items.filter(
        (option) => option.selected === true
      );
      const optionTexts = extractSelectOptionsText(question);
      return (
        <>
          {getQuestion(question.name, optionTexts)}
          {selectedOptions.map((sOption) => {
            return sOption.items?.map((subQuestion) => {
              const subOptionTexts = extractSelectOptionsText(subQuestion);
              return getQuestion(subQuestion.name, subOptionTexts);
            });
          })}
        </>
      );
    });
  };

  const getDoughOverview = () => {
    return (
      <div className={styles.extra}>
        <div className="d-flex align-center flex-1">
          <span className={styles.name}>{selectedDough.name}</span>
        </div>
      </div>
    );
  };

  const getFlavorsOverview = () => {
    if (selectedFlavors.length === 1) {
      return (
        <div
          className={styles.extra}
          key={`flavor-overview-${selectedFlavors[0].id}`}
        >
          <div className="d-flex align-center flex-1">
            <span className={styles.name}>{selectedFlavors[0].name}</span>
          </div>
        </div>
      );
    }

    const halves = selectedFlavors.reduce(
      (total, flavor) => total + flavor.quantity,
      0
    );
    return selectedFlavors.map((flavor) => (
      <div className={styles.extra} key={`flavor-overview-${flavor.id}`}>
        <div className="d-flex align-center flex-1">
          <span className={styles.quantity}>
            {flavor.quantity} / {halves} &nbsp;
          </span>
          <span className={styles.name}>{flavor.name}</span>
        </div>
      </div>
    ));
  };

  const getEdgeOverview = () => {
    if (!selectedEdge) {
      return <p>Não quero, obrigado</p>;
    }

    return (
      <div className={styles.extra}>
        <div className="d-flex align-center flex-1">
          <span className={styles.name}>{selectedEdge.name}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.item}>
        <div className={styles.picture}>
          {product.image ? (
            <Image
              src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${product.image}`}
              alt=""
              layout="fill"
              sizes="100vw"
            />
          ) : (
            <div className={styles.noImage}>
              <NoImage />
            </div>
          )}
        </div>
        <div className={styles.details}>
          <div className="d-flex align-center">
            <p className={styles.qty}>{product.quantity}x</p>
            <p className={styles.itemName}>{product?.name}</p>
          </div>

          {selectedSize && (
            <div className={styles.size}>
              <p>Tamanho:&nbsp;</p>
              <span className={styles.name}>{selectedSize.name}</span>
              {/* <p className={styles.price}>
                {Currency.formatCurrency(selectedSize.price || product.price)}
              </p> */}
            </div>
          )}

          {selectedOptions.length > 0 && (
            <div className={styles.questions}>{getQuestionsOverview()}</div>
          )}

          {selectedDough && (
            <div className={styles.extras}>
              <p>Massa</p>
              {getDoughOverview()}
            </div>
          )}

          {selectedFlavors.length > 0 && (
            <div className={styles.extras}>
              <p>Sabores</p>
              {getFlavorsOverview()}
            </div>
          )}

          {selectedEdge && (
            <div className={styles.extras}>
              <p>Borda</p>
              {getEdgeOverview()}
            </div>
          )}

          {selectedExtras.length > 0 && (
            <div className={styles.extras}>
              <p>Adicionais</p>
              {(selectedExtras || []).map((item, index) => (
                <div className={styles.extra} key={index}>
                  <div className="d-flex align-center flex-1">
                    <span className={styles.quantity}>
                      {item.quantity}x&nbsp;
                    </span>
                    <span className={styles.name}>{item.name}</span>
                  </div>
                  <span className={styles.extraPrice}>
                    {Currency.formatCurrency(
                      item.sizes[0]?.price || item.price || 0
                    )}
                  </span>
                </div>
              ))}
            </div>
          )}

          {product.observation && (
            <p className={styles.observation}>Obs.: {product.observation}</p>
          )}

          <div className={styles.total}>
            <p className={styles.value}>
              {Currency.formatCurrency(
                CatalogHelpers.calculateSelectedItemPrice(product)
              )}
            </p>
            <div className="d-flex align-center w-100 justify-end">
              <div className={styles.quantitySpinner}>
                <div
                  className={`${styles.minus} ${
                    product.quantity === 1 ? styles.disabled : styles.enabled
                  }`}
                  onClick={() => decreaseQuantity(product)}
                >
                  <Minus />
                </div>

                <p className={styles.quantity}>{product.quantity}</p>

                <div
                  className={styles.plus}
                  onClick={() => increaseQuantity(product)}
                >
                  <Plus />
                </div>
              </div>

              <div
                onClick={() => onEditProduct && onEditProduct(product)}
                className={styles.edit}
              >
                <PenCircle />
              </div>
              <div
                onClick={() => setOpenRemoveProduct(true)}
                className={styles.remove}
              >
                <TrashCircle />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        open={openRemoveProduct}
        onClose={(status) => setOpenRemoveProduct(status)}
        alreadyDrawer
        direction="bottom"
      >
        <div className={styles.removeProduct}>
          <p>Excluir</p>
          <span>Deseja excluir este item do seu carrinho?</span>

          <Button
            design="primary"
            className="mb-10"
            onClick={() => onRemoveProduct && onRemoveProduct()}
          >
            Sim
          </Button>
          <Button
            design="secondary"
            onClick={() => setOpenRemoveProduct(false)}
          >
            Cancelar
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default function CartItem({
  order,
  onEditProduct,
  onRemoveProduct,
  onClose,
}) {
  return (
    <div className={styles.ecleticaItems}>
      {(order.cart.catalog.items || []).map((item, index) => (
        <Item
          key={`cart-item-${Math.random()}`}
          product={item}
          onEditProduct={(product) => onEditProduct(product, index)}
          onRemoveProduct={(product) =>
            onRemoveProduct && onRemoveProduct(product, index)
          }
          cartItemIndex={index}
        />
      ))}

      <div className={styles.addItems}>
        <Button onClick={() => onClose && onClose()}>
          Adicionar mais itens
        </Button>
      </div>
    </div>
  );
}
