import "styles/layout/globals.scss";
import "styles/layout/drawer.scss";

import { useEffect } from "react";

import Head from "next/head";

import { OlgaFoodSession } from "shared/tools/OlgaFoodSession";
import { BugsnagStart } from "shared/tools/BugsnagStart";

import Layout from "shared/layouts/Layout";

import { Brand } from "@/shared/core/Brand";
import { Holding } from "@/shared/core/Holding";
import { Store } from "@/shared/core/Store";
import { Table } from "@/shared/core/Table";

import { DefaultSeo } from "next-seo";

import { BenefitStorage } from "@/storage/BenefitStorage";
import { OrderStorage } from "@/storage/OrderStorage";

import { Toaster } from "react-hot-toast";

import smoothscroll from "smoothscroll-polyfill";
import { Flows } from "@/shared/core/Enum";

if (typeof window != "undefined") {
  smoothscroll.polyfill();
}

const ErrorBoundary = BugsnagStart();

function App({ Component, pageProps }) {
  const showNotFoundPage = pageProps?.statusCode === 404;

  useEffect(() => {
    const selectedBenefit = BenefitStorage.getSelectedBenefit();
    if (selectedBenefit && !selectedBenefit?.type) {
      BenefitStorage.setSelectedBenefit(null);
    }

    if (pageProps && !showNotFoundPage) {
      const { environment, brandAlias, configurations } = pageProps;
      if (environment.type == "HOLDING") {
        if (!brandAlias) {
          Brand.alias = null;
          Store.alias = null;
          Holding.install(environment.alias).then(() => {
            Holding.alias = environment.alias;
          });
        } else {
          Brand.install(brandAlias).then(() => {
            Brand.alias = brandAlias;

            if (OrderStorage.flow === Flows.OPEN_TABLE && Table.id) {
              const { brandAlias: tableBrandAlias, storeAlias } = Table;
              if (storeAlias && tableBrandAlias === brandAlias) {
                Store.alias = storeAlias;
              }
            }
          });
        }
      } else if (environment.type == "BRAND") {
        Holding.alias = null;
        Brand.install(environment.alias).then(() => {
          Brand.alias = environment.alias;

          if (OrderStorage.flow === Flows.OPEN_TABLE && Table.id) {
            const { brandAlias: tableBrandAlias, storeAlias } = Table;
            if (storeAlias && tableBrandAlias === environment.alias) {
              Store.alias = storeAlias;
            }
          }

          //Checar se há alguma unidade selecionada, e não não exista, selecionar a marca
          if (Store.alias && !Store.active) {
            Store.alias = null;
          }
        });
      }
      OlgaFoodSession.validate();
      getPhoneFromUrl();
      // checkToken();
    }
  }, [Component, pageProps, showNotFoundPage]);

  // const checkToken = async () => {
  // 	const accessToken = localStorage.getItem('accessToken');
  // 	if (accessToken) {
  // 		try {
  // 			const jwtBody = JWT.decode(accessToken);
  // 			const daysToExpire = dayjs(jwtBody.exp * 1000).diff(dayjs(), 'days');

  // 			if (daysToExpire <= 0) {
  // 				throw new Error('Token expirado');
  // 			}

  // 			if (daysToExpire <= 4) {
  // 				const response = await OAuthAPI.refresh();
  // 				const { access_token: newAccessToken } = response.getData({
  // 					access_token: '',
  // 				});
  // 				if (!newAccessToken) {
  // 					throw new Error('Não autenticado');
  // 				}

  // 				localStorage.setItem('accessToken', newAccessToken);
  // 			}
  // 		} catch (err) {
  // 			console.error(err);

  // 			localStorage.removeItem('accessToken');
  // 			localStorage.setItem('address', '[]');
  // 			CustomerStorage.setCustomer(null);
  // 			OrderStorage.setOrder(OrderStorage.initOrder());
  // 			UnitStorage.setUnit(null);
  // 		}
  // 	}
  // };

  const getPhoneFromUrl = async () => {
    const queryString = window.location.search;
    let url = new URL(window.location.href);

    const searchParams = new URLSearchParams(queryString);
    const queryPhone = searchParams.get("q");
    if (queryPhone) {
      sessionStorage.setItem("customerPhone", queryPhone);
      window.history.replaceState({}, "", `${url.origin}${url.pathname}`);
    }
  };

  if (showNotFoundPage) return <Component {...pageProps} />;

  const environment = pageProps.environment;

  const configurations = pageProps.configurations;
  const host = pageProps?.req?.host;

  const brandName = configurations?.name;
  const brandAvatar = configurations?.avatar;
  const description = configurations?.metadata?.description;
  const url = host ? `https://${host}` : "";

  return (
    <ErrorBoundary>
      <Toaster />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Head>
      <DefaultSeo
        title={brandName || environment?.name}
        description={description || ""}
        canonical={url}
        openGraph={{
          type: "website",
          locale: "pt_BR",
          url,
          title: brandName || environment?.name,
          siteName: brandName || environment?.name,
          description: description || "",
          images: [
            {
              url: brandAvatar
                ? `${process.env.NEXT_PUBLIC_CDN_ASSETS}/${brandAvatar}`
                : "",
              width: 1200,
              height: 630,
              alt: brandName,
              type: "image/png",
            },
          ],
        }}
        twitter={{
          site: url,
          cardType: "summary_large_image",
        }}
        additionalLinkTags={[
          {
            rel: "icon",
            href:
              `${process.env.NEXT_PUBLIC_CDN_ASSETS}/${brandAvatar}` ||
              Holding.avatar ||
              null,
          },
          {
            rel: "apple-touch-icon",
            href:
              `${process.env.NEXT_PUBLIC_CDN_ASSETS}/${brandAvatar}` ||
              Holding.avatar ||
              null,
            sizes: "76x76",
          },
        ]}
      />

      <Layout
        environment={pageProps.environment}
        isClub={pageProps.isClub}
        req={pageProps.req}
        configurations={pageProps.configurations}
      >
        <Component {...pageProps} />
      </Layout>
    </ErrorBoundary>
  );
}

export default App;
