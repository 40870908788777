import { useState } from "react";
import classNames from "classnames/bind";

import { Radio } from "shared/components";
import { ChevronDown } from "shared/components/Icons";
import { Currency, Benefit } from "shared/tools";
import { Customer } from "shared/core/Customer";
import styles from "styles/containers/coupon.module.scss";

const Coupon = ({ coupon, selected, onClick, hasRadio, allowSelectCoupon }) => {
  const cx = classNames.bind(styles);

  const [collapsed, setCollapsed] = useState(false);

  const collapseRules = (e) => {
    e.stopPropagation();
    setCollapsed(!collapsed);
  };

  const disabled =
    (!Benefit.canBeUsed(coupon) || !Customer.phone) && !allowSelectCoupon;
  const rules = Benefit.getRules(coupon);

  return (
    <div
      className={cx({ coupon: true, disabled, selected })}
      onClick={(e) => !disabled && onClick()}
    >
      <div className="d-flex align-center justify-between">
        {hasRadio && (
          <Radio value={1} checked={+selected} readOnly className="mr-15" />
        )}
        <div className={styles.couponDetails}>
          <p className={styles.name}>{Benefit.getTitle(coupon)}</p>
          <span className={styles.code}>{coupon.code}</span>
        </div>
        {rules.length > 0 && (
          <div
            role="button"
            tabIndex="0"
            className={collapsed ? styles.seeRulesShow : styles.seeRulesHide}
            onClick={collapseRules}
          >
            {collapsed ? "Ocultar" : "Ver regras"}
            <ChevronDown color="#828282" />
          </div>
        )}
      </div>

      <div className={styles.requirements}>
        {coupon.min_order
          ? `Pedido mínimo ${Currency.formatCurrency(coupon.min_order)}`
          : "Não há pedido mínimo"}
      </div>

      {rules.length > 0 && (
        <ul
          className={`${styles.rules} ${
            collapsed ? styles.show : styles.hide
          } `}
        >
          <li className={styles.title}>Regras:</li>
          {rules.map((rule, index) => (
            <li key={`rule-${index}`}>{rule}</li>
          ))}
        </ul>
      )}
      {(disabled || allowSelectCoupon) && Benefit.notAvailableText(coupon) && (
        <div className={styles.notAvailable}>
          {Benefit.notAvailableText(coupon)}
        </div>
      )}
    </div>
  );
};

export default Coupon;
