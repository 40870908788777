import styles from "@/styles/containers/checkout-receive-on.module.scss";

import { Button, Checkbox, Drawer } from "@/shared/components";
import { Blocked, GiftLight, UserLight } from "@/shared/components/Icons";

import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import Benefits from "../Benefits/Benefits";
import PaymentMethodsReceiveOn from "../Payment/PaymentMethodsReceiveOn";
import { Currency } from "@/shared/tools";
import { SimpleInput } from "@/shared/components/SimpleInput";
const css = classNames.bind(styles);

const ApplyOptions = {
  APPLY: "APPLY",
  NO_APPLY: "NO_APPLY",
};

const Steps = {
  NAME: 1,
  APPLY_BENEFIT: 2,
  ENTER_PHONE: 3,
  BENEFIT_APPLIED: 4,
};

export default function CheckoutReceiveOn({ open, onClose }) {
  const [step, setStep] = useState(Steps.NAME);
  const [applyBenefit, setApplyBenefit] = useState(null);
  const [openBenefits, setOpenBenefits] = useState(false);
  const [checkedSMS, setCheckedSMS] = useState(null);
  const [openPayment, setOpenPayment] = useState(false);

  const prevStep = () => {
    switch (step) {
      case Steps.NAME:
        return onClose();
      case Steps.APPLY_BENEFIT:
        return setStep(Steps.NAME);
      case Steps.ENTER_PHONE:
      case Steps.BENEFIT_APPLIED:
        return setStep(Steps.APPLY_BENEFIT);
    }
  };

  const getTitle = () => {
    const titles = {
      [Steps.NAME]: "Identificação",
      [Steps.APPLY_BENEFIT]: "Aplicar benefício",
      [Steps.ENTER_PHONE]: "Identificação",
      [Steps.BENEFIT_APPLIED]: "Sucesso",
    };

    return titles[step];
  };

  useEffect(() => {
    if (open) {
      setApplyBenefit(null);
      setStep(Steps.NAME);
    }
  }, [open]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={getTitle()}
      showBackButton
      backButton={prevStep}
    >
      {step === Steps.NAME && (
        <div className={styles.nameContainer}>
          <div className={styles.icon}>
            <UserLight />
          </div>
          <p className={styles.title}>Como podemos te chamar?</p>
          <span className={styles.subtitle}>
            Antes de prosseguirmos para o pagamento, precisamos de alguns dados
            básicos sobre você.
          </span>

          <div className={styles.hairline} />

          <SimpleInput floatLabel="Insira seu nome" />

          <div className={styles.footer}>
            <Button
              design="primary"
              onClick={() => setStep(Steps.APPLY_BENEFIT)}
            >
              Próximo
            </Button>
          </div>
        </div>
      )}

      {step === Steps.APPLY_BENEFIT && (
        <>
          <div className={styles.applyBenefitOptions}>
            <p className={styles.title}>
              Você gostaria de aplicar um <span>benefício</span>?
            </p>

            <div className={styles.options}>
              <div
                className={css({
                  option: true,
                  selected: applyBenefit === ApplyOptions.APPLY,
                })}
                onClick={() => setApplyBenefit(ApplyOptions.APPLY)}
              >
                <div className={styles.icon}>
                  <GiftLight />
                </div>
                <p className={styles.name}>Aplicar benefício</p>
              </div>
              <div
                className={css({
                  option: true,
                  selected: applyBenefit === ApplyOptions.NO_APPLY,
                })}
                onClick={() => setApplyBenefit(ApplyOptions.NO_APPLY)}
              >
                <div className={styles.icon}>
                  <Blocked />
                </div>
                <p className={styles.name}>Não, obrigado</p>
              </div>
            </div>
          </div>

          {applyBenefit && (
            <div className={styles.footer}>
              <Button
                design="primary"
                onClick={() =>
                  applyBenefit === ApplyOptions.APPLY
                    ? setOpenBenefits(true)
                    : setStep(Steps.ENTER_PHONE)
                }
              >
                Próximo
              </Button>
            </div>
          )}
        </>
      )}

      {step === Steps.ENTER_PHONE && (
        <div className={styles.enterPhoneContainer}>
          <div className={styles.icon}>
            <GiftLight />
          </div>
          <p className={styles.title}>
            Informe seu telefone e receba <span>benefícios</span>.
          </p>
          <span className={styles.subtitle}>
            Antes de prosseguirmos para o pagamento, precisamos de alguns dados
            básicos sobre você.
          </span>

          <div className={styles.hairline} />

          <SimpleInput
            placeholder="(DDD) + Número"
            type="tel"
            className="mb-15"
          />
          <Checkbox onChange={(e) => setCheckedSMS(e)} checked={checkedSMS}>
            Aceito receber e-mails e SMS&apos;s promocionais
          </Checkbox>

          <div className={styles.footer}>
            <Button
              design="primary"
              onClick={() => setStep(Steps.BENEFIT_APPLIED)}
              className="mb-20"
            >
              Próximo
            </Button>

            <div className="d-flex justify-center mb-10">
              <Button onClick={() => setOpenPayment(true)}>Pular</Button>
            </div>
          </div>
        </div>
      )}

      {step === Steps.BENEFIT_APPLIED && (
        <div className={styles.benefitApplied}>
          <p className={styles.label}>
            Ao finalizar esta compra, você irá ganhar este benefício.
          </p>
          <div className={styles.benefit}>
            <div className={styles.name}>
              Cupom de {Currency.formatCurrency(20)}
            </div>
            <div className="d-flex align-center justify-between">
              <div className={styles.pin}>20OFF - 9z3</div>
            </div>
          </div>

          <div className={styles.footer}>
            <Button design="primary" onClick={() => setOpenPayment(true)}>
              Avançar
            </Button>
          </div>
        </div>
      )}

      <Benefits
        open={openBenefits}
        onClose={() => setOpenBenefits(false)}
        withoutTransition
        withoutFade
        showJumpButton
        onJump={() => {
          setOpenBenefits(false);
        }}
      />

      <Drawer open={openPayment}>
        <PaymentMethodsReceiveOn
          onPaymentSuccess={() => {
            setOpenPayment(false);
            onClose();
          }}
          onClose={() => {
            setOpenPayment(false);
          }}
        />
      </Drawer>
    </Drawer>
  );
}
