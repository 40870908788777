import dayjs from "dayjs";
import JWT from "jsonwebtoken";
import { OAuthAPI } from "api/OAuthAPI";
import { OrderStorage } from "storage/OrderStorage";
import { UnitStorage } from "storage/UnitStorage";
import { Store } from "shared/core/Store";
import { BenefitStorage } from "storage/BenefitStorage";
import { Customer } from "shared/core/Customer";
import { PaymentStorage } from "storage/PaymentStorage";
import { OlgaCustomer } from "shared/core/OlgaCustomer";

export class OlgaFoodSession {

    static hasSession() {
        return localStorage.getItem("accessToken") ? true : false;
    }

    static set session(session) {
        return localStorage.setItem('accessToken', session);
    }

    static async validate() {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            try {
                const jwtBody = JWT.decode(accessToken);
                const daysToExpire = dayjs(jwtBody.exp * 1000).diff(dayjs(), "days");

                if (daysToExpire <= 0) {
                    throw new Error("Token expirado");
                }

                if (daysToExpire <= 4) {
                    await OlgaFoodSession.refresh();
                }
            } catch (err) {
                console.error(err);
                OlgaFoodSession.resetSession();
            }
        }
    }

    static async refresh() {
        const response = await OAuthAPI.refresh();
        const { access_token: newAccessToken } = response.getData({ access_token: "" });
        if (!newAccessToken) {
            throw new Error("Não autenticado");
        }
        localStorage.setItem("accessToken", newAccessToken);
    }

    static resetSession() {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("olgaAccessToken");
        localStorage.setItem("address", "[]");
        Customer.setCustomer(null);
        OlgaCustomer.install();
        OrderStorage.setOrder(OrderStorage.initOrder());
        UnitStorage.setUnit(null);
        BenefitStorage.setSelectedBenefit(null);
        PaymentStorage.setCard([]);
        Store.alias = null;
    }
}