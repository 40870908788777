import { useEffect, useState } from "react";

import styles from "styles/containers/payment-methods.module.scss";

import classNames from "classnames/bind";

import {
  CheckCircle,
  CreditCard,
  EllipsisVertical,
  TrashCircle,
} from "shared/components/Icons";
import { Payment } from "shared/core/Payment";

export const CardTypes = {
  CREDIT: "Crédito",
  DEBIT: "Débito",
  VOUCHER: "Voucher",
};

const cx = classNames.bind(styles);

export const Card = ({ card, onSelectCard, removeCard, selectedPix }) => {
  const [visibleTrash, setVisibleTrash] = useState(false);
  const [isLastSelected, setIsLastSelected] = useState(
    card.id === Payment.selectedCard
  );

  useEffect(() => {
    if (selectedPix) setIsLastSelected(null);
  }, [selectedPix]);

  return (
    <div
      className={cx({
        paymentCard: true,
        selected: isLastSelected,
      })}
      key={card.id}
    >
      <div className={styles.details} onClick={() => onSelectCard(card.id)}>
        <div className={styles.icon}>
          <CreditCard color="#3d734a" />
        </div>
        <div className="d-flex flex-col flex-1">
          <p className={styles.alias}>
            {card.name} - {CardTypes[card.type]}
          </p>
          <p className={styles.number}>{card.number}</p>
        </div>

        <div className={styles.selected}>
          {isLastSelected && (
            <div className={styles.check}>
              <CheckCircle />
            </div>
          )}
          <div
            className={styles.remove}
            onClick={(event) => {
              event.stopPropagation();
              removeCard(card.gatewayReference);
            }}
          >
            <TrashCircle />
          </div>
        </div>
      </div>
    </div>
  );
};
