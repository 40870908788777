import { Method, PaymentMethod } from "@olga-food/schemas/lib/classes/schemas/order/payment/Payment";
import { PaymentStorage } from "storage/PaymentStorage";

const payment_key = 'of-payment';

export const PaymentEvents = {
    PAYMENT_CHANGED: 'PAYMENT_CHANGED'
}

export const PaymentOrigin = {
    OFFLINE: 'OFFLINE',
    ONLINE: 'ONLINE'
}

export const OfflinePaymentMethods = {
    CREDIT: PaymentMethod.CREDIT | PaymentMethod.FISICO,
    DEBIT: PaymentMethod.DEBIT | PaymentMethod.FISICO,
    MEAL_VOUCHER: PaymentMethod.MEAL | PaymentMethod.VOUCHER | PaymentMethod.FISICO,
    FOOD_VOUCHER: PaymentMethod.FOOD | PaymentMethod.VOUCHER | PaymentMethod.FISICO,
    MONEY: PaymentMethod.MONEY | PaymentMethod.FISICO,
}

const CARDS_FORMATS = {
    [Method.AMERICAN_EXPRESS.name]: /^3[47][0-9]{13}$/,
};

export class Payment {

    static set configurations(data) {
        localStorage.setItem(payment_key, JSON.stringify(data));

        window.dispatchEvent(
            new CustomEvent(PaymentEvents.PAYMENT_CHANGED, { detail: data })
        );
    }

    static get configurations() {
        try {
            const payment = localStorage.getItem(payment_key);
            return payment ? JSON.parse(payment) : {};
        } catch (err) {
            console.log(err);
            return {};
        }
    }

    static set selectedCard(cardId) {
        const configs = Payment.configurations;
        configs.selectedCard = cardId;
        configs.selectedPayment = null;
        Payment.configurations = configs;
    }

    static get selectedCard() {
        return Payment.configurations?.selectedCard;
    }

    static set selectedPayment(payment) {
        const configs = Payment.configurations;
        configs.selectedCard = null;
        configs.selectedPayment = payment;
        Payment.configurations = configs;
    }

    static get selectedPayment() {
        return Payment.configurations?.selectedPayment;
    }

    static set currentOrigin(value) {
        const configs = Payment.configurations;
        configs.origin = value;
        Payment.configurations = configs;
    }

    static get currentOrigin() {
        return Payment.configurations?.origin
    }

    static set offlinePayment(value) {
        const configs = Payment.configurations;
        configs.selectedPayment = null;
        configs.offlinePayment = value;
        Payment.configurations = configs;
    }

    static get offlinePayment() {
        return Payment.configurations?.offlinePayment
    }

    static get onlinePayment() {
        const payments = PaymentStorage.getCard();
        const cardSelected = payments.find((item) => item.id == Payment.selectedCard);

        const cardBrand = cardSelected.name;
        const aliasByBrand = Payment.getAliasByName(cardBrand);

        const onlinePayment = {
            alias: aliasByBrand,
            gatewayReference: cardSelected?.gatewayReference
        }

        if (PaymentMethod[cardSelected.type] == PaymentMethod.VOUCHER) {
            onlinePayment.type = PaymentMethod.ONLINE | PaymentMethod.VOUCHER | PaymentMethod.MEAL
        } else {
            onlinePayment.type = PaymentMethod.ONLINE | PaymentMethod[cardSelected.type]
        }

        return onlinePayment
    }


    static getPaymentMethodText(method) {
        const isType = (haystack, needle) => (haystack & needle) == needle;
        if (isType(OfflinePaymentMethods.CREDIT, method)) {
            return 'Crédito';
        } else if (isType(OfflinePaymentMethods.DEBIT, method)) {
            return 'Débito';
        } else if (isType(PaymentMethod.MEAL | PaymentMethod.VOUCHER | PaymentMethod.FISICO, method)) {
            return 'Refeição';
        } else if (isType(OfflinePaymentMethods.FOOD_VOUCHER|  PaymentMethod.VOUCHER | PaymentMethod.FISICO, method)) {
            return 'Alimentação';
        } else if (isType(OfflinePaymentMethods.MONEY, method)) {
            return 'Dinheiro';
        } else {
            return null;
        }
    }

    static getAliasByName(name) {
        const needle = name.toLocaleLowerCase();
        const method = Object.values(Method).find((m) => m.name.toLocaleLowerCase() == needle);
        return method?.alias;
    }

    static isAmexFromCardNumber(number) {
        const isAmex = CARDS_FORMATS[Method.AMERICAN_EXPRESS.name].test(String(number).replace(/[^0-9]/g, ""));
        return isAmex;
    }
}