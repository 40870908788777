import { useEffect, useState } from "react";

import Image from "next/image";

import { BrazilStates } from "shared/tools/BrazilStates";

import classNames from "classnames/bind";

import styles from "styles/containers/address-list.module.scss";

import { CustomerAPI } from "api/CustomerAPI";
import { BucketsAPI } from "api/BucketAPI";
import { AddressStorage } from "storage/AddressStorage";

import { Button, Drawer, Loading } from "shared/components";

import AddAddress from "shared/containers/Address/AddAddress";

import {
  CheckCircle,
  EllipsisVertical,
  MapMarker,
  PenCircle,
  TrashCircle,
} from "shared/components/Icons";
import { Address } from "shared/core/Address";
import { Environment } from "shared/core/Environment";
import { Customer } from "shared/core/Customer";
import { OlgaFoodSession } from "shared/tools/OlgaFoodSession";
import { OrderStorage } from "storage/OrderStorage";

const deliveryTypes = {
  DEFAULT: 1,
  SCHEDULED: 2,
};

export const AddressCard = ({
  address,
  nearUnit,
  unitIndex,
  onSelectAddress,
  onRemoveAddress,
  disabledRemove,
  onOpenDeliveryType,
}) => {
  const cx = classNames.bind(styles);

  const [deleteIndex, setDeleteIndex] = useState(false);
  const unitNear = nearUnit?.addressId === address.id;
  const unitIsOpen = nearUnit?.unit?.isOpen;

  const selectedDeliveryType = localStorage.getItem("selected-delivery-type");

  return (
    <div
      className={cx({
        addressCard: true,
        selected: address.isLastSelected,
        disabled: !unitNear && !unitIsOpen,
      })}
      key={address.id}
    >
      <div className="d-flex align-center w-100">
        <div
          className={styles.details}
          onClick={() => !!unitNear && onSelectAddress(unitIndex, address)}
        >
          <div className="d-flex align-center">
            <div className={styles.icon}>
              <MapMarker />
            </div>
            <div className="d-flex flex-col flex-1">
              <div className="d-flex align-center">
                <div className="d-flex flex-col flex-1">
                  <p className={styles.alias}>{address.alias}</p>
                  <p className={styles.address}>
                    {Address.generateAddressString(address)}
                  </p>
                </div>
                <div className={styles.selected}>
                  {address.isLastSelected && (
                    <div className={styles.check}>
                      <CheckCircle />
                    </div>
                  )}
                  {!disabledRemove && (
                    <div
                      className={cx({ ellipsis: true, rotate: deleteIndex })}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteIndex(!deleteIndex);
                      }}
                    >
                      <EllipsisVertical />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {address.isLastSelected && (
            <>
              {selectedDeliveryType == deliveryTypes.DEFAULT && (
                <div className={styles.deliveryType}>
                  <p className={styles.name}>Entrega padrão</p>
                  <div className="d-flex align-center w-100 justify-between mt-10">
                    <div className={styles.deliveryEstimate}>20-30 min</div>
                    <div
                      className={styles.edit}
                      onClick={() => onOpenDeliveryType()}
                    >
                      <PenCircle color="#3D734A" />
                    </div>
                  </div>
                </div>
              )}

              {selectedDeliveryType == deliveryTypes.SCHEDULED && (
                <div className={styles.deliveryType}>
                  <p className={styles.name}>Entrega agendada</p>
                  <div className="d-flex align-center w-100 justify-between mt-10">
                    <div className={styles.deliveryEstimate}>
                      06/06/23 | 19:00 - 20:00
                    </div>
                    <div
                      className={styles.edit}
                      onClick={() => onOpenDeliveryType()}
                    >
                      <PenCircle color="#3D734A" />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {/* {unitNear ? (
            <div className={styles.unit}>
              <div className={styles.icon}>
                <Image
                  src="/store-green.png"
                  className={styles.store}
                  layout="fill"
                  alt=""
                />
              </div>
              <p className={styles.unitName}>
                {nearUnit.unit?.name} {!unitIsOpen && "(Fechado)"}
              </p>
            </div>
          ) : (
            <div className={styles.unit}>
              <div className={styles.icon}>
                <Image
                  src="/store-red.png"
                  className={styles.store}
                  layout="fill"
                  alt=""
                />
              </div>
              <p className={styles.unitName}>Fora da região de entrega</p>
            </div>
          )} */}
      </div>

      {!disabledRemove && (
        <div
          className={cx({
            editOrRemove: true,
            visible: deleteIndex,
          })}
        >
          <div className={styles.remove} onClick={() => onRemoveAddress()}>
            <TrashCircle />
          </div>
        </div>
      )}
    </div>
  );
};
