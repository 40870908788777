import { useState } from "react";

import styles from "@/styles/containers/payment-methods-receive-on.module.scss";

import {
  CreditCard,
  CreditCardGreen,
  Gift,
  PixLight,
  PixLightGreen,
} from "@/shared/components/Icons";

import { Currency } from "@/shared/tools";

import { Button, Radio } from "@/shared/components";

import CardPayment from "./CardPayment";

import classNames from "classnames/bind";
const css = classNames.bind(styles);

const PaymentTypes = {
  PIX: "PIX",
  CARD: "CARD",
};

export default function PaymentMethodsReceiveOn({ onPaymentSuccess, onClose }) {
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [openCardPayment, setOpenCardPayment] = useState(false);

  return (
    <>
      <div className={styles.title}>Qual é a forma de pagamento?</div>

      <div className={styles.totalsContainer}>
        <ul>
          <li>
            <p>Valor total inicial</p>
            <span>{Currency.formatCurrency(133)}</span>
          </li>
          <li>
            <p className={styles.benefit}>Benefício aplicado</p>
            <span className={styles.benefit}>
              {Currency.formatCurrency(133)}
            </span>
          </li>
          <li>
            <p>Total</p>
            <span className={styles.total}>{Currency.formatCurrency(118)}</span>
          </li>
        </ul>

        <div className={styles.gift}>
          <div className={styles.icon}>
            <Gift />
          </div>
          <p>
            Cupom de <strong>{Currency.formatCurrency(15)}</strong> aplicado
          </p>
        </div>
      </div>

      <div className={styles.options}>
        <p className={styles.label}>Selecione uma das opções abaixo</p>

        <div
          className={css({
            option: true,
            selected: selectedPayment === PaymentTypes.PIX,
          })}
          onClick={() => setSelectedPayment(PaymentTypes.PIX)}
        >
          <div className="d-flex align-center">
            <div className={styles.icon}>
              {selectedPayment === PaymentTypes.PIX ? (
                <PixLightGreen />
              ) : (
                <PixLight />
              )}
            </div>
            <p>Pix</p>
          </div>
          <Radio
            readOnly
            family="option"
            value={PaymentTypes.PIX}
            checked={selectedPayment}
          />
        </div>
        <div
          className={css({
            option: true,
            selected: selectedPayment === PaymentTypes.CARD,
          })}
          onClick={() => setSelectedPayment(PaymentTypes.CARD)}
        >
          <div className="d-flex align-center">
            <div className={styles.icon}>
              {selectedPayment === PaymentTypes.CARD ? (
                <CreditCardGreen />
              ) : (
                <CreditCard />
              )}
            </div>
            <p>Cartão</p>
          </div>
          <Radio
            readOnly
            family="option"
            value={PaymentTypes.CARD}
            checked={selectedPayment}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          design="primary"
          className="mb-15"
          disabled={!selectedPayment}
          onClick={() => setOpenCardPayment(true)}
        >
          Confirmar
        </Button>
        <Button design="secondary" onClick={onClose}>
          Voltar
        </Button>
      </div>

      <CardPayment
        open={openCardPayment}
        onPaymentSuccess={onPaymentSuccess}
        onClose={() => {
          setOpenCardPayment(false);
          onClose();
        }}
      />
    </>
  );
}
