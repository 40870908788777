import { Request } from "@konv/http";

export class OpenTableAPI {
  static async request({
    endpoint = "/",
    method = "GET",
    headers = {},
    query = {},
    data = {},
  }) {
    return await Request.request({
      endpoint: endpoint,
      cors: true,
      method: method || "GET",
      headers: headers || {},
      service: "orders",
      ignoreVersion: true,
      params: query || {},
      data: data || {},
    });
  }

  static async requestAuthenticated({
    endpoint = "/",
    method = "GET",
    query,
    data,
  }) {
    return await OpenTableAPI.request({
      endpoint,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      method,
      query,
      data,
    });
  }

  static async getTableByCode(code) {
    return await OpenTableAPI.requestAuthenticated({
      endpoint: `/environments/tables/qrcode/${code}`,
      method: "GET",
    });
  }

  static async addTableItems(environmentId, tableId, items) {
    return await OpenTableAPI.requestAuthenticated({
      endpoint: `/environments/${environmentId}/tables/${tableId}`,
      method: "POST",
      data: items || []
    });
  }

  static async getTable(environmentId, tableId) {
    const tableResponse = await OpenTableAPI.requestAuthenticated({
      endpoint: `/environments/${environmentId}/tables/${tableId}`,
      method: "GET"
    });

    return tableResponse.getData({});
  }

  static async getTableItems(environmentId, tableId) {
    const tableItemsResponse = await OpenTableAPI.requestAuthenticated({
      endpoint: `/environments/${environmentId}/tables/${tableId}/items`,
      method: "GET"
    });

    return tableItemsResponse.getData([]);
  }

  static async getTableStatus(environmentId, tableId) {
    const tableStatusResponse = await OpenTableAPI.requestAuthenticated({
      endpoint: `/environments/${environmentId}/tables/${tableId}/status`,
      method: "GET"
    });

    const { status } = tableStatusResponse.getData({});

    return status;
  }
  
  static async preFinishTable(environmentId, tableId) {
    return await OpenTableAPI.requestAuthenticated({
      endpoint: `/environments/${environmentId}/tables/${tableId}/prefinish`,
      method: "POST",
    });
  }

  static async callWaiter(environmentId, tableId) {
    return await OpenTableAPI.requestAuthenticated({
      endpoint: `/environments/${environmentId}/tables/${tableId}/waiter`,
      method: "POST",
    });
  }

}
