import { Request } from "@konv/http";

export class EnvironmentAPI {
	static async request({
		endpoint = "/",
		method = "GET",
		headers,
		query,
		data,
	}) {
		return await Request.request({
			endpoint: endpoint,
			method: method,
			headers: headers,
			service: "environment",
			ignoreVersion: true,
			params: query,
			data: data,
		});
	}

	static async getS3Configurations(alias) {
		try {
			const url = `${process.env.NEXT_PUBLIC_CDN_ENVIRONMENT_CONFIGURATIONS}/${alias}.json?t=${Date.now()}`;
			for (let i = 0; i < 3; i++) {
				console.log('Realizando a tentativa', i + 1);
				try {
					return await fetch(url).then((r) => r === null ? r : r.json());
				} catch (err) {
					console.log(err)
				}
				await new Promise(resolve => setTimeout(resolve, 1000))
			}
			return null;
		} catch (err) {
			console.log(err);
			return {};
		}
	}
}
