import { Radio } from "shared/components";

import styles from "styles/containers/take-out.module.scss";

import { MapMarker } from "shared/components/Icons";

import { DeliveryType } from "@olga-food/schemas/lib/classes/schemas/order/delivery/Delivery";

import { OrderStorage } from "storage/OrderStorage";
import { useEffect, useRef, useState } from "react";
import { StoreLight } from "@/shared/components/Icons";

import Image from "next/image";

import classNames from "classnames/bind";
let css = classNames.bind(styles);

export default function UnitLocation({
  id,
  name,
  delivery,
  selected,
  onSelect,
  active,
}) {
  const [deliveryType, setDeliveryType] = useState(
    OrderStorage.getDelivery()?.type
  );
  const notInitialRender = useRef(false);

  useEffect(() => {
    if (notInitialRender.current) {
      setDeliveryType(delivery.type);
    } else {
      notInitialRender.current = true;
    }
  }, [delivery.type, onSelect]);

  const isLocal = [DeliveryType.GET_ON_PLACE, DeliveryType.INDOOR].includes(
    delivery.type
  );
  const isSelected = selected === id;

  const getDeliveryTime = () => {
    if (isLocal) {
      return `${delivery.deliveryStrip.minDesk} - ${delivery.deliveryStrip.maxDesk} min`;
    }
    return `${delivery.deliveryStrip.minDelivery} - ${delivery.deliveryStrip.maxDelivery} min`;
  };

  return (
    <div
      className={css({
        unit: true,
        closed: !active,
        selected: isSelected,
      })}
      onClick={onSelect}
    >
      <div className="d-flex flex-col w-100">
        {/* <div className={styles.picture}>
          <Image src="/unitImage.png" alt="" layout="fill" />
        </div> */}
        <div className={styles.details}>
          <div className="d-flex align-center w-100 mr-15">
            <div className={styles.icon}>
              <StoreLight />
            </div>
            <div className="d-flex flex-col">
              <div className="d-flex align-center">
                <p className={styles.name}>{name}</p>
                {!active ? (
                  <span className={styles.status}>(Fechado)</span>
                ) : null}
              </div>
              <span className={styles.address}>
                {delivery.address.address}, {delivery.address.number} -{" "}
                {delivery.address.region}
              </span>
              {delivery.deliveryStrip && active ? (
                <div className={styles.delivery}>
                  <div className="d-flex align-center">
                    {delivery.distance && delivery.distance > 0 && (
                      <span>{delivery.distance.toFixed(2)} km / </span>
                    )}
                    {delivery.deliveryStrip && <span>{getDeliveryTime()}</span>}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {active ? (
            <Radio readOnly name="radio" value={id} checked={selected} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
