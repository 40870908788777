import React from "react";
import styles from "styles/containers/cashback-balance.module.scss";
import { List, ListB } from "shared/components/Icons";
import { Currency } from "shared/tools/Currency";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

class CashbackBalance extends React.Component {
    render() {
        const { balance = 0, goToExtract, showViewExtract } = this.props;

        return (
            <div
                className={cx({
                    container: true,
                    disabled: balance == 0,
                    "justify-between": showViewExtract,
                    "justify-center": !showViewExtract,
                })}
            >
                <div
                    className={cx({
                        "d-flex flex-col": true,
                        "align-start": showViewExtract,
                        "align-center": !showViewExtract,
                    })}
                >
                    <span className={styles.text}>Saldo de cashback</span>
                    <p className={styles.value}>
                        {Currency.formatCurrency(balance || 0)}
                    </p>
                </div>
                {showViewExtract && (
                    <div
                        className={styles.goToExtract}
                        role="button"
                        tabIndex="0"
                        onClick={goToExtract}
                    >
                        {balance == 0 ? <ListB /> : <List />}
                    </div>
                )}
            </div>
        );
    }
}

export default CashbackBalance;
