import { useEffect, useState } from "react";
import Image from "next/image";
import { ChevronRight, CreditCardB } from "shared/components/Icons";
import { CardTypes } from "shared/containers/Payment/Card";
import { Payment, PaymentEvents, PaymentOrigin } from "shared/core/Payment";
import useEventListener from "shared/hooks/useEventListener";
import { PaymentStorage } from "storage/PaymentStorage";
import { Method } from "@olga-food/schemas/lib/classes/schemas/order/payment/Payment";

import styles from "styles/containers/cart.module.scss";
import { Currency } from "shared/tools";

export const PaymentSection = ({ onClick }) => {
  const [paymentOrigin, setPaymentOrigin] = useState(Payment.currentOrigin);
  const [offlinePayment, setOfflinePayment] = useState(Payment.offlinePayment);
  const [cardSelected, setCardSelected] = useState(null);
  const [selectedPix, setSelectedPix] = useState(null);

  useEventListener(PaymentEvents.PAYMENT_CHANGED, () => {
    findSelectedCard();
  });

  const findSelectedCard = () => {
    const selectedCard = Payment.selectedCard;
    const pixSelected = Payment.selectedPayment === "PIX";
    if (selectedCard) {
      const cardSelected = PaymentStorage.getCard().filter(
        (item) => item.id == selectedCard
      );
      setCardSelected(cardSelected[0]);
      setSelectedPix(false);
    }

    if (pixSelected) {
      setSelectedPix(true);
      setCardSelected(false);
    }
    setPaymentOrigin(Payment.currentOrigin);
    setOfflinePayment(Payment.offlinePayment);
  };

  useEffect(() => {
    findSelectedCard();
  }, []);

  //Evento vindo do PaymentStorage
  useEventListener("paymentChange", () => {
    findSelectedCard();
  });

  const getSubtextLine = (method) => {
    if (method?.alias == "DINHEIRO") {
      const change_for = Currency.formatCurrency(method?.change_for || 0);
      return `${Method[method?.alias]?.name} `;
    }
    return `${Payment.getPaymentMethodText(method?.type)} - ${
      Method[method?.alias]?.name
    }`;
  };

  return (
    <div className={styles.paymentContainer} onClick={onClick}>
      <p className={styles.title}>Forma de pagamento</p>
      <div className={styles.selectPayment}>
        {!cardSelected && !selectedPix && !offlinePayment?.type && (
          <div className="d-flex align-center justify-between w-100">
            <div className={styles.selectPaymentMethod}>
              Selecione uma forma de pagamento
            </div>
            <ChevronRight />
          </div>
        )}
        {paymentOrigin == PaymentOrigin.OFFLINE && offlinePayment?.type && (
          <>
            <div className="d-flex align-center">
              <div className={styles.icon}>
                <CreditCardB />
              </div>

              <div className="d-flex flex-col">
                <div className={styles.paymentName}>{"Pagar na entrega"}</div>
                <div className={styles.cardNumber}>
                  {getSubtextLine(offlinePayment)}
                </div>
              </div>
            </div>
            <ChevronRight />
          </>
        )}
        {paymentOrigin == PaymentOrigin.ONLINE  && cardSelected && (
          <>
            <div className="d-flex align-center">
              <div className={styles.icon}>
                <CreditCardB />
              </div>
              <div className="d-flex flex-col">
                <div className={styles.paymentName}>
                  {CardTypes[cardSelected.type]}
                </div>
                <div className={styles.cardNumber}>
                  {cardSelected.number} {cardSelected.name}
                </div>
              </div>
            </div>
            <ChevronRight />
          </>
        )}
        {selectedPix && !cardSelected && paymentOrigin == PaymentOrigin.ONLINE && (
          <>
            <div className="d-flex align-center">
              <div className={styles.pix}>
                <Image src="/pix.png" alt="" layout="fill" sizes="100vw" />
              </div>
              <div className={styles.paymentName}>PIX</div>
            </div>
            <ChevronRight />
          </>
        )}
      </div>
    </div>
  );
};
