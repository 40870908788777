import { DeliveryType } from "@olga-food/schemas/lib/classes/schemas/order/delivery/Delivery";
import Image from "next/image";
import { CalendarAlt, ChevronRight } from "shared/components/Icons";
import { Brand } from "shared/core/Brand";
import { Store } from "shared/core/Store";
import styles from "styles/containers/cart.module.scss";

const deliveryTypes = {
  DEFAULT: 1,
  SCHEDULED: 2,
};

export const CartDelivery = ({ order, onClick }) => {
  const { delivery } = order;

  if (!delivery) {
    return null;
  }

  const delivery_time = Store.delivery_time;
  const { address } = delivery;

  const isLocal = [DeliveryType.GET_ON_PLACE, DeliveryType.INDOOR].includes(
    delivery?.type
  );

  const selectedDeliveryType = localStorage.getItem("selected-delivery-type");

  return (
    <div className={styles.deliveryContainer} onClick={onClick}>
      {(delivery_time || delivery.deliveryStrip) &&
        (isLocal ? (
          <div className={styles.deliveryForecast}>
            <div className="d-flex align-center justify-between">
              <p>{`${delivery_time.minDesk} - ${delivery_time.maxDesk} min`}</p>
            </div>
          </div>
        ) : (
          delivery.deliveryStrip && (
            <>
              <div className={styles.deliveryForecast}>
                <p>{`${delivery.deliveryStrip.minDelivery} - ${delivery.deliveryStrip.maxDelivery} min`}</p>
              </div>

              {/* {selectedDeliveryType == deliveryTypes.DEFAULT && (
                <>
                  <div className="d-flex flex-col">
                    <p className={styles.deliveryType}>Entrega padrão</p>
                    <p>{`${delivery_time.minDesk} - ${delivery_time.maxDesk} min`}</p>
                  </div>
                </>
              )} */}

              {/* {selectedDeliveryType == deliveryTypes.DEFAULT && (
                <>
                  <div className="d-flex flex-col">
                    <p className={styles.deliveryType}>Entrega agendada</p>
                    <p>06/06/23 - 19:00 - 20:00</p>
                  </div>
                </>
              )} */}

              {/* <div className="d-flex align-start">
                <div className={styles.appointmentRequired}>
                  <CalendarAlt color="rgba(238, 64, 35, 0.74)" />
                  <p>Agendamento necessário</p>
                </div>
              </div> */}
            </>
          )
        ))}

      <div className={styles.address}>
        <div className="d-flex align-center">
          <div className={styles.icon}>
            {isLocal ? (
              <Image
                src="/get-on-place-map.png"
                className={styles.mapAddress}
                alt=""
                layout="fill"
                sizes="100vw"
              />
            ) : (
              <Image
                src="/deliver-on-address-map.png"
                className={styles.mapAddress}
                alt=""
                layout="fill"
                sizes="100vw"
              />
            )}
          </div>
          {!address && (
            <div className="d-flex align-center justify-between w-100">
              <div className={styles.selectAddress}>
                Selecione um endereço de entrega
              </div>
              <ChevronRight />
            </div>
          )}
          {address && (
            <div className="d-flex flex-col flex-1">
              <div className={styles.deliveryIn}>
                {isLocal ? (
                  <>
                    <div className="d-flex align-center justify-between">
                      <span>Retirar em</span>
                      <>
                        {order.delivery?.type === DeliveryType.GET_ON_PLACE ? (
                          <p className={styles.withdrawalType}>Para levar</p>
                        ) : (
                          <p className={styles.withdrawalType}>
                            Comer no local
                          </p>
                        )}
                      </>
                    </div>
                    <p className={styles.address}>{Brand.name}</p>
                    {Store.name && (
                      <p className={styles.unitName}>{Store.name}</p>
                    )}
                  </>
                ) : (
                  <>
                    <span>Receber em</span>
                    {address?.alias && (
                      <p className={styles.address}>{address.alias}</p>
                    )}
                  </>
                )}
                <p className={styles.referencePoint}>{address?.observation}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
