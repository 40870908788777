import { useState, useEffect, useRef } from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import styles from "styles/containers/payment-methods.module.scss";
import { UnitStorage } from "storage/UnitStorage";

import { Button, Drawer, Input, Tabs } from "shared/components";
import { CreditCard, Voucher } from "shared/components/Icons";

import OnlinePayments from "shared/containers/Payment/OnlinePayments";
import { OfflinePayments } from "shared/containers/Payment/OfflinePayments";

import { PaymentHubAPI } from "api/PaymentHub";
import { PaymentStorage } from "storage/PaymentStorage";

import useEventListener from "shared/hooks/useEventListener";
import { Customer } from "shared/core/Customer";
import { Message } from "shared/components";
import { Store } from "shared/core/Store";

import classNames from "classnames/bind";
import { Payment } from "shared/core/Payment";
import { OrderStorage } from "storage/OrderStorage";
import { DataLayer } from "shared/tools/DataLayer";

const cx = classNames.bind(styles);

const Tab = {
  ONLINE: "Pagamento online",
  OFFLINE: "Pagamento na entrega",
};

const Steps = {
  PAYMENTS_LIST: 1,
  ADD_PAYMENT_METHODS: 2,
  ADD_PAYMENT_INFOS: 3,
};

const paymentMethods = {
  CREDIT: "Crédito",
  // DEBIT: "Débito",
  VOUCHER: "Voucher",
};

const formatHolderName = (holderName) => {
  const newHolderName = holderName.toUpperCase().replace(/[^A-Z\s]/g, "");
  return newHolderName;
};

export default function PaymentMethods({
  open,
  onlyOnline,
  onClose,
  alreadyDrawer,
}) {
  const formRef = useRef();

  const [onlinePaymentCheck, setonlinePaymentCheck] = useState(false);
  const [offlinePaymentCheck, setOfflinePaymentCheck] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    let paymentConfig = Store.getSwitchs();
    if ((Object.keys(paymentConfig) || []).length === 0) {
      paymentConfig = {
        online_payment: true,
        payment_on_delivery: false,
      };
    }

    setonlinePaymentCheck(paymentConfig.online_payment || false);
    setOfflinePaymentCheck(paymentConfig.payment_on_delivery || false);

    updatePaymentAccount();

    setPaymentMethod(Store.payment_methods);
  }, []);

  useEffect(() => {
    let tabs = [];

    if (onlinePaymentCheck) {
      tabs.push("Pagamento online");
      setActiveTab(Tab.ONLINE);
    }
    if (offlinePaymentCheck) {
      tabs.push("Pagamento na entrega");
      if (!onlinePaymentCheck) {
        setActiveTab(Tab.OFFLINE);
      }
    }
    setTabs(tabs);
  }, [onlinePaymentCheck, offlinePaymentCheck]);

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(Steps.PAYMENTS_LIST);
  const [activeTab, setActiveTab] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [payments, setPayments] = useState([]);

  const pixSelected = Payment.selectedPayment === "PIX";

  const updatePaymentAccount = async () => {
    const customer = Customer.getCustomer();
    if (customer) {
      const { id, name, email, phone, extras } = customer;

      const accountResponse = await PaymentHubAPI.PagarMeCreateAccount(id, {
        name,
        email,
        phone,
        document: extras.doc,
      });

      if (accountResponse.getErrors([]).length === 0) {
        const cardsResponse = await PaymentHubAPI.PagarMeGetCards(id);
        if (cardsResponse.getErrors([]).length === 0) {
          PaymentStorage.loadCards(cardsResponse.getData([]));
        }
      } else {
        console.log(accountResponse.getErrors());
      }
    }
  };

  const callToPagarMeCreateCard = async (customerReferenceId, payload) => {
    return await PaymentHubAPI.PagarMeCreateCard(customerReferenceId, payload);
  };

  const loadCardsIntoStorage = async (
    cardResponseData,
    customerReferenceId
  ) => {
    const newCard = cardResponseData;
    const cardsResponse = await PaymentHubAPI.PagarMeGetCards(
      customerReferenceId
    );
    if (cardsResponse.getErrors([]).length === 0) {
      const cards = cardsResponse.getData([]).map((card) => ({
        ...card,
        isLastSelected: newCard.id === card.id,
      }));
      PaymentStorage.loadCards(cards);
      setLoading(false);
    }
    window.dispatchEvent(new CustomEvent("CARD_ADD"));
  };

  const save = async (data, actions) => {
    const currentStore = Store.alias;
    setLoading(true);

    if (!currentStore) {
      return setErrorMessage(
        "Selecione um endereço de entrega ou retirada antes registrar o cartão"
      );
    }
    const { number, cvv, exp, name, cpfCnpj } = data;
    const customerReferenceId = Customer.id;

    if (Payment.isAmexFromCardNumber(number) && cvv.length !== 4) {
      actions.setFieldError("cvv", "CVV inválido");
      setLoading(false);
      return;
    }

    const types = {
      [paymentMethods.CREDIT]: "credit",
      [paymentMethods.DEBIT]: "debit",
      [paymentMethods.VOUCHER]: "voucher",
    };

    const type = types[paymentMethod];

    const payload = {
      type,
      number: number.replace(/\s/g, ""),
      holderName: name,
      holderDocument: cpfCnpj.replace(/[^\d]+/g, ""),
      expirationMonth: parseInt(exp.split("/")[0]),
      expirationYear: parseInt(exp.split("/")[1]),
      cvv,
    };

    const cardResponse = await callToPagarMeCreateCard(
      customerReferenceId,
      payload
    );
    if (cardResponse.getErrors([]).length === 0) {
      await loadCardsIntoStorage(cardResponse.getData(), customerReferenceId);
    } else {
      await updatePaymentAccount();

      const retryCardRespone = await callToPagarMeCreateCard(
        customerReferenceId,
        payload
      );

      if (retryCardRespone.getErrors([]).length === 0) {
        await loadCardsIntoStorage(
          retryCardRespone.getData(),
          customerReferenceId
        );

        const order = OrderStorage.getOrder();

        const paymentType = {
          // debit: "Débito",
          credit: "Crédito",
          voucher: "Voucher",
        };

        const productDataLayer = {
          event: "add_payment_info",
          ecommerce: {
            currency: "BRL",
            value: order.total,
            items: DataLayer.getOrderItems(order.cart?.catalog?.items) || [],
            payment_type: paymentType[type],
          },
        };

        DataLayer.push({ ecommerce: null });
        DataLayer.push(productDataLayer);
      } else {
        setErrorMessage(
          "Não foi possível registrar este cartão. Verifique se há um erro de digitação"
        );
      }
      setLoading(false);
    }
  };

  const onTabSelected = (tab) => {
    setActiveTab(tab);
  };

  useEventListener("CARD_ADD", () => setStep(Steps.PAYMENTS_LIST));

  const geTitle = () => {
    const titles = {
      1: "Formas de pagamento",
      2: "Adicionar cartão",
      3: paymentMethod,
    };

    return titles[step];
  };

  const renderPaymentMethods = () => {
    const selectPaymentMethod = (method) => {
      setPaymentMethod(method);
      setStep(Steps.ADD_PAYMENT_INFOS);
    };

    return (
      <>
        <div
          className={styles.paymentMethod}
          onClick={() => selectPaymentMethod(paymentMethods.CREDIT)}
        >
          <div className={styles.icon}>
            <CreditCard />
          </div>
          <p className={styles.name}>Crédito</p>
        </div>

        {/* 
                ###### Débito não funciona na Pagar.me devido a bloqueio de bancos quando usado sem senha #####
                <div
                
                    className={styles.paymentMethod}
                    onClick={() => selectPaymentMethod(paymentMethods.DEBIT)}
                >
                    <div className={styles.icon}>
                        <CreditCard />
                    </div>
                    <p className={styles.name}>Débito</p>
                </div> */}

        <div
          className={styles.paymentMethod}
          onClick={() => selectPaymentMethod(paymentMethods.VOUCHER)}
        >
          <div className={styles.icon}>
            <Voucher />
          </div>
          <p className={styles.name}>Voucher</p>
        </div>
      </>
    );
  };

  const renderPaymentInfos = () => {
    const initialValuesFullAddress = {
      number: "",
      exp: "",
      cvv: "",
      name: "",
      cpfCnpj: "",
    };

    const validationSchemaFullAddress = Yup.object({
      number: Yup.string().required("Campo obrigatório"),
      exp: Yup.string().required("Campo obrigatório"),
      cvv: Yup.string().required("Campo obrigatório").min(3, "CVV inválido"),
      name: Yup.string().required("Campo obrigatório"),
      cpfCnpj: Yup.string().required("Campo obrigatório"),
    });

    const validateCvv = (event) => {
      const cvv =
        event.target.name === "cvv"
          ? event.target.value
          : formRef.current.values.cvv;
      const number =
        event.target.name === "number"
          ? event.target.value
          : formRef.current.values.number;

      if (cvv.length > 3) {
        if (!Payment.isAmexFromCardNumber(number)) {
          formRef.current.setFieldValue("cvv", String(cvv).substring(0, 3));
        }
      }
    };

    return (
      <Formik
        innerRef={formRef}
        initialValues={initialValuesFullAddress}
        validationSchema={validationSchemaFullAddress}
        onSubmit={(values, actions) => save(values, actions)}
      >
        {(props) => (
          <>
            <Form
              autoComplete="off"
              onChange={(e) => {
                if (["cvv", "number"].includes(e.target.name)) {
                  validateCvv(e);
                }
              }}
            >
              <Input
                design="two"
                className="mb-20"
                name="number"
                label="Número do cartão"
                mask="9999 9999 9999 9999"
                type="tel"
                placeholder="0000 0000 0000 0000"
              />

              <div className="d-flex mb-20">
                <Input
                  design="two"
                  className="w-100"
                  name="exp"
                  label="Data de validade"
                  mask="99/9999"
                  type="tel"
                  placeholder="MM/AAAA"
                />
                <Input
                  design="two"
                  className="ml-10 w-100"
                  name="cvv"
                  label="CVV"
                  mask="9999"
                  type="tel"
                />
              </div>

              <Input
                design="two"
                className="mb-20"
                name="name"
                label="Nome do titular"
                onChange={(e) => {
                  props.handleChange(e);
                  const name = formatHolderName(e.target.value);
                  props.setFieldValue("name", name);
                }}
              />
              <Input
                design="two"
                className="mb-20"
                name="cpfCnpj"
                mask="999.999.999-99"
                label="CPF do titular"
                type="tel"
              />

              <div className={styles.footer}>
                <Button
                  design="primary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Salvar
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={() => {
        setStep(Steps.PAYMENTS_LIST);
        setActiveTab(onlinePaymentCheck ? Tab.ONLINE : Tab.OFFLINE);
        setErrorMessage(null);
        onClose();
      }}
      alreadyDrawer={alreadyDrawer}
      title={geTitle()}
      showBackButton={step !== Steps.PAYMENTS_LIST}
      showCloseButton={step === Steps.PAYMENTS_LIST}
      backButton={() => (step > 1 ? setStep(step - 1) : onClose(false))}
    >
      <>
        {step === Steps.PAYMENTS_LIST && (
          <div className={styles.paymentsList}>
            {tabs.length >= 1 && (
              <div className="mb-30">
                <Tabs
                  tabs={tabs}
                  onTabSelected={onTabSelected}
                  activeTab={activeTab}
                />
              </div>
            )}

            <div
              className={cx({
                scrollContainer: true,
                withTabs: tabs.length > 1,
              })}
            >
              {activeTab === Tab.ONLINE && (
                <OnlinePayments
                  onClose={() => {
                    onClose(false);
                    setActiveTab(onlinePaymentCheck ? Tab.ONLINE : Tab.OFFLINE);
                  }}
                  addNewPayment={() => setStep(Steps.ADD_PAYMENT_METHODS)}
                />
              )}

              {activeTab === Tab.OFFLINE && (
                <OfflinePayments
                  onClose={() => {
                    onClose(false);
                    setActiveTab(onlinePaymentCheck ? Tab.ONLINE : Tab.OFFLINE);
                  }}
                />
              )}
            </div>
          </div>
        )}

        {step === Steps.ADD_PAYMENT_METHODS && renderPaymentMethods()}
        {step === Steps.ADD_PAYMENT_INFOS && renderPaymentInfos()}

        {errorMessage && (
          <Message type="error" text={errorMessage} className="mt-15" />
        )}
      </>
    </Drawer>
  );
}
