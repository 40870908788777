import { Fragment } from "react";
import { OptionItem } from "./OptionItem";
import styles from "styles/containers/product-details.module.scss";

export const PizzaDoughStep = ({ doughs, onSelectDough }) => {
    return (
        <>
            <div className={styles.sectionHeader}>
                <div className="d-flex flex-col">
                    <p className={styles.sectionTitle}>Escolha a massa</p>
                </div>
                <span className={styles.requiredField}>Obrigatório</span>
            </div>

            <div className={styles.optionItems}>
                {doughs.map((dough) => (
                    <Fragment key={`dough-${dough.id}`}>
                        <OptionItem
                            option={dough}
                            singleOption={true}
                            onClick={onSelectDough}
                        />
                    </Fragment>
                ))}
            </div>
        </>
    );
}