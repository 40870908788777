import React, { Fragment, useEffect, useState } from "react";

import Image from "next/image";

import styles from "styles/containers/payment-methods.module.scss";

import { PaymentStorage } from "../../../storage/PaymentStorage";
import { PaymentHubAPI } from "./../../../api/PaymentHub";
import { Customer } from "shared/core/Customer";
import { Card } from "./Card";
import { CheckCircle, EmptyCards } from "shared/components/Icons";
import { Payment, PaymentOrigin } from "shared/core/Payment";

import classNames from "classnames/bind";
import { Button, Loading } from "shared/components";
import { Brand } from "shared/core/Brand";
import { Holding } from "shared/core/Holding";
import { Store } from "shared/core/Store";
const css = classNames.bind(styles);

export default function OnlinePayments({ onClose, addNewPayment }) {
  const [cards, setCards] = useState(null);
  const [selectedPix, setSelectedPix] = useState(
    Payment.selectedPayment === "PIX"
  );
  const [loading, setLoading] = useState(false);

  /* Liberação temporária do Pix somente para o pizza-for-fun. Solicitado em: 27/07 */
  const showPix = (Brand.alias === 'pizza-for-fun907' || Holding.alias === 'pizza-for-fun') && Store.alias == 'tatuape913';

  useEffect(() => {
    loadCreditCards();
  }, []);

  const removeCard = async (cardGatewayReference) => {
    const customerReferenceId = Customer.id;
    const card = cards.find((c) => c.gatewayReference == cardGatewayReference);

    if (card.id == Payment.selectedCard) {
      Payment.selectedCard = null;
    }

    await PaymentHubAPI.PagarMeRemoveCard(
      customerReferenceId,
      cardGatewayReference
    );
    loadCreditCards();
  };

  const loadCreditCards = async () => {
    setLoading(true);
    const res = PaymentStorage.parseCards(
      (await PaymentHubAPI.PagarMeGetCards(Customer.id)).getData([])
    );

    const cards = (res || []).map((item) => {
      return {
        id: item.id,
        gatewayReference: item.gatewayReference,
        name: item.brand || item.name,
        number: item.firstSixDigits || item.number,
        type: item.type,
      };
    });
    PaymentStorage.setCard(cards);
    setCards(cards);
    setLoading(false);
  };

  const onSelectCard = (cardId) => {
    Payment.currentOrigin = PaymentOrigin.ONLINE;
    Payment.selectedCard = cardId;
    onClose && onClose();
  };

  const selectPix = () => {
    Payment.currentOrigin = PaymentOrigin.ONLINE;
    Payment.selectedPayment = "PIX";
    onClose && onClose();
  };

  return (
    <div className={styles.onlinePayments}>

      {showPix && (
        <>
          <div className={styles.pixContainer}>
            <div
              className={css({
                pix: true,
                selected: selectedPix,
              })}
              onClick={() => setSelectedPix(true)}
            >
              <div className="d-flex align-center">
                <div className={styles.icon}>
                  <Image src="/pix.png" alt="" layout="fill" sizes="100vw" />
                </div>
                <p className={styles.alias}>PIX</p>
              </div>
              {selectedPix && <CheckCircle />}
            </div>
          </div>
        </>
      )}


      {loading && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}

      {cards?.length === 0 && !loading && (
        <div className={styles.empty}>
          <EmptyCards />
          <p>Você ainda não adicionou nenhuma forma de pagamento.</p>
        </div>
      )}

      {cards?.length > 0 && !loading && (
        <>
          {/* <div className={styles.hairline} /> */}

          <div className={styles.cards}>
            {cards.map((card, _i) => {
              return (
                <Fragment key={_i}>
                  <Card
                    card={card}
                    onSelectCard={(cardId) => onSelectCard(cardId)}
                    removeCard={(cardGatewayReference) =>
                      removeCard(cardGatewayReference)
                    }
                    selectedPix={selectedPix}
                  />
                </Fragment>
              );
            })}
          </div>
        </>
      )}

      <div className={styles.footer}>
        {!selectedPix && (
          <Button
            design="primary"
            className="w-100"
            onClick={() => addNewPayment()}
          >
            Adicionar novo cartão
          </Button>
        )}

        {selectedPix && (
          <Button
            design="primary"
            className="w-100"
            onClick={() => selectPix()}
          >
            Confirmar
          </Button>
        )}
      </div>
    </div>
  );
}
