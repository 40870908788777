import classNames from "classnames/bind";
import styles from "styles/components/input.module.scss";
import { EyeClosed, EyeOpen } from "../Icons";
import { useState } from "react";

export const CommonInput = ({
    type,
    placeholder = " ",
    onBlur,
    onFocus,
    onChange,
    autoFocus,
    label,
    mask,
    floatLabel,
    autoComplete,
    maxLength,
    className,
    search,
    design = "one",
    isInvalid,
    defaultValue,
    value,
    ...props
}) => {
    const cx = classNames.bind(styles);
    const [inputType, setInputType] = useState(type);
    const { name } = props;
    const id = props.id || name;

    const additionalProps = {};
    if (onChange) {
        additionalProps.onChange = onChange;
    }

    return (
        <div
            className={cx({
                inputContainer: true,
                [className]: className,
            })}
            spellCheck="false"
        >
            {label && (
                <label className={styles.label} htmlFor={name}>
                    {label}
                </label>
            )}
            <div className="relative">
                <input
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    type={inputType}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoFocus={autoFocus}
                    maxLength={maxLength}
                    defaultValue={defaultValue}
                    value={value}
                    autoComplete="off"
                    className={cx({
                        invalid: isInvalid,
                        [`design-${design}`]: true,
                    })}
                    {...additionalProps}
                />
                {floatLabel && (
                    <label className={`${styles.float} ${isInvalid ? styles.invalid : ""}`}>
                        {floatLabel}
                    </label>
                )}
                {type === "password" && (
                    <span
                        className={styles.eye}
                        onClick={() =>
                            setInputType(inputType === "password" ? "text" : "password")
                        }
                    >
                        {inputType === "password" ? <EyeClosed /> : <EyeOpen />}
                    </span>
                )}
            </div>
        </div>
    );
};
