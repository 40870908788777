import { DeliveryType } from "@olga-food/schemas/lib/classes/schemas/order/delivery/Delivery";
import { Currency } from "shared/tools";
import styles from "styles/containers/cart.module.scss";

export const CartTotals = ({ order, selectedBenefit }) => {
    const { delivery, cart } = order;

    return (
      <div className={styles.totalContainer}>
        <p className={styles.title}>Valores</p>

        <div className={styles.total}>
          <div className="d-flex align-center justify-between">
            <p>Subtotal</p>
            <span className={styles.value}>
               {Currency.formatCurrency(cart?.total || 0)} 
            </span>
          </div>

          {delivery?.type === DeliveryType.DELIVER_ON_ADDRESS && (
            <div className="d-flex align-center justify-between">
              <p>Taxa de entrega</p>
              <span className={styles.value}>
                {selectedBenefit && selectedBenefit.type === "FREE_DELIVERY"
                  ? "Grátis"
                  : Currency.formatCurrency(delivery.total || 0)}
              </span>
            </div>
          )}

          {
            selectedBenefit &&
            selectedBenefit.type &&
            order.gift &&
            !["CASHBACK_ACCUMULATE", "FREE_DELIVERY"].includes(selectedBenefit.type) && (
              <div className="d-flex align-center justify-between">
                <p className={styles.gift}>Benefícios</p>
                <div className="d-flex align-center">
                  <span className={styles.giftCode}>
                    {selectedBenefit.code && (
                      <span>({selectedBenefit.code})</span>
                    ) ||
                    (!selectedBenefit?.amount)
                ? Currency.formatCurrency(+(
                    (order.cart.total || 0) +
                    (order.delivery?.total || 0)
                  ).toFixed(2))
                : Currency.formatCurrency((order?.total - selectedBenefit?.amount > 0) 
                ? order?.total - selectedBenefit?.amount
                : delivery?.total) &&
                    (<span>(Cashback)</span>)}


                  </span>
                  <span className={styles.giftValue}>
                    {Currency.formatCurrency(order.gift?.total || (selectedBenefit?.amount > order?.total ? order?.total : selectedBenefit?.amount) || 0)}
                  </span>
                </div>
              </div>
            )}

          <div className={styles.totalValue}>
            <p>Total</p>
            <span className={styles.value}>
            {(!selectedBenefit?.amount)
                ? Currency.formatCurrency(order?.total)
                : Currency.formatCurrency((order?.gift?.total + selectedBenefit?.amount >= 0) 
                ? order?.total
                : delivery?.total)
            }
            </span>
          </div>
        </div>
      </div>
    );
  }