import Decimal from "decimal.js";

export class OMath {
  static sum(...args) {
    return args
      .reduce((t, i) => t.add(new Decimal(i)), new Decimal(0))
      .toNumber();
  }

  static sub(...args) {
    const [first] = args.splice(0, 1);
    return args
      .reduce((t, i) => t.sub(new Decimal(i)), new Decimal(first))
      .toNumber();
  }

  static times(...args) {
    return args
      .reduce((t, i) => t.mul(new Decimal(i)), new Decimal(1))
      .toNumber();
  }

  static div(a, b) {
    return new Decimal(a).div(new Decimal(b)).toNumber();
  }
}
