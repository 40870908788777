import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import Image from "next/image";

import styles from "styles/containers/brands.module.scss";

import { NoImage } from "shared/components/Icons";
import { Brand } from "@/shared/core/Brand";
import { OrderStorage } from "@/storage/OrderStorage";
import { Flows } from "@/shared/core/Enum";

export default function Brands({ holding, onSelectBrand, onlyBrands }) {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const activeBrands = holding.childs.filter((b) => !!b.active);

    setBrands(
      activeBrands.map((brand) => ({
        name: brand.name,
        alias: brand.alias,
        avatar: brand.avatar,
        address: { city: "" },
        benefits: false,
      }))
    );
  }, [holding.childs]);

  return (
    <div className={styles.brands}>
      {(brands || []).map((brand) => (
        <BrandCard
          key={brand.alias}
          brand={brand}
          onSelectBrand={onSelectBrand}
          onlyBrands={onlyBrands}
        />
      ))}
    </div>
  );
}

const BrandCard = ({ brand, onSelectBrand, onlyBrands }) => {
  const router = useRouter();

  const goToCatalogBrand = () => {
    Brand.alias = brand.alias;
    OrderStorage.setFlow(Flows.DELIVERY);
    router.push(`/${brand.alias}/cardapio`);
  };

  return (
    <div
      className={styles.brand}
      onClick={() => (onlyBrands ? goToCatalogBrand() : onSelectBrand(brand))}
    >
      <div className={styles.picture}>
        {brand.avatar ? (
          <Image
            src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${brand.avatar}`}
            alt={brand.name}
            layout="fill"
          />
        ) : (
          <div className={styles.noImage}>
            <NoImage />
          </div>
        )}
      </div>
      <p className={styles.brandName}>{brand.name}</p>
    </div>
  );
};
