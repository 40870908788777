import { CustomerAPI } from "api/CustomerAPI";
import { useEffect, useState } from "react";

import { Button, Drawer, Modal } from "shared/components";
import { CommonInput } from "shared/components/SimpleForm/CommonInput";

import { DataLayer } from "shared/tools/DataLayer";

import styles from "styles/containers/login-or-register.module.scss";

export const DeleteModal = ({ visible, onConfirm, onClose }) => {
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);

  const onDeleteAccount = async ({ password }) => {
    const deleteResponse = await CustomerAPI.deleteCustomer(password);
    if (deleteResponse.getCode(500) != 200) {
      setError(deleteResponse.getErrors()[0]);
    } else {
      onConfirm && onConfirm();
    }
  };

  useEffect(() => {
    if (visible) {
      DataLayer.push({
        event: "page_view",
        page_title: "Exclusão de conta",
      });
    }
  }, [visible]);

  return (
    <>
      <Drawer
        open={visible}
        onClose={(status) => onClose(status)}
        direction="bottom-center"
        alreadyDrawer
      >
        <div className={styles.logout}>
          <p className={styles.alertTitle}>
            Você realmente deseja excluir sua conta?
          </p>
          <CommonInput
            floatLabel={"Digite sua senha"}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            design="primary"
            className="mt-20 mb-10"
            disabled={!password}
            onClick={() => onDeleteAccount({ password })}
          >
            Excluir conta
          </Button>
          <Button
            design="secondary"
            className="mb-10"
            onClick={() => onClose(false)}
          >
            Cancelar
          </Button>
        </div>
        <Modal open={error} alreadyModal onClose={() => setError(false)}>
          <p className={styles.errorMessage}>{error}</p>
          <Button
            design="primary"
            className="w-100 mt-30"
            onClick={() => setError(false)}
          >
            <span className="d-flex align-center">Ok</span>
          </Button>
        </Modal>
      </Drawer>
    </>
  );
};
