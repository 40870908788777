import { Fragment } from "react";
import { OptionItem } from "./OptionItem";

import { Table } from "../../../core/Table";

import styles from "styles/containers/product-details.module.scss";

const getTextMinMaxOptions = (question) => {
    const min = question?.min;
    const max = question?.max;

    const optionPlural = max > 1 ? 'opções' : 'opção';

    if (min == 0 && max >= 1)
        return `Escolha até ${max} ${optionPlural}`;

    if (min === max)
        return `Escolha ${max} ${optionPlural}`;

    if (min >= 1 && !max)
        return `Escolha no mínimo ${min} ${optionPlural}`;

    if (min >= 1 && min < max)
        return `Escolha entre ${min} e ${max} ${optionPlural}`;
};

const checkIsDisabledOption = (question, option) => {
    const min = question?.min;
    const max = question?.max;

    const selectedOptions = question.items.reduce((t, o) => t + o.quantity, 0);
    if (max && max <= selectedOptions) {
        return true;
    }

    // const isSeletedOption = selectedOptions.some(
    //     (selectedOption) =>
    //         selectedOption.selected === true && selectedOption.id === option.id
    // );

    // if (isSeletedOption) {
    //     return false;
    // } else {
    //     if (min === 1 && max === 1 && selectedOptions.length > 0) {
    //         return true;
    //     }

    //     if (!min && max >= 1 && selectedOptions.length === max) {
    //         return true;
    //     }

    //     if (min >= 1 && min <= max && selectedOptions.length === max) {
    //         return true;
    //     }

    //     return false;
    // }
};

const formatInputExtra = (question) => {
    switch (true) {
        case question.min === 1 && question.max === 1:
            return true;
        default:
            return false;
    }
};

export const QuestionItem = ({ question, onChange, questionIndex }) => {

    const handleOptionClick = (option, optionIndex) => {
        const sectionHeader = document.querySelectorAll(
            `.${styles.sectionHeader}`
        );

        question.items[optionIndex] = option;
        
        if (question.max === 1 && question.min === 1) {
            question.items.forEach((o) => (o.selected = false));
            question.items[optionIndex].selected = !question.items[optionIndex].selected;
            question.items[optionIndex].quantity = +question.items[optionIndex].selected;
        } else {
            question.items.forEach((o) => {
                if (question.mode == 'quantity') {
                    o.selected = o.quantity > 0;
                    return;
                }

                if (option.id == o.id) {
                    o.selected = !o.selected;
                    o.quantity = +o.selected;
                }
            });
        }

        const max = question?.max;
        const selectedOptions = question.items.reduce((t, o) => t + o.quantity, 0);
        if (max && max <= selectedOptions && sectionHeader[questionIndex + 1]) {
          sectionHeader[questionIndex + 1].scrollIntoView({
            behavior: "smooth",
          });
        }

        onChange(question);
    };

    const externalId = Table.externalId;
    const isDigitalMenu = externalId === Table.isDigitalMenu;

    return (
        <>
            <div className={styles.sectionHeader}>
                <div className="d-flex flex-col">
                    <p className={styles.sectionTitle}>{question.name}</p>
                    {(question?.min > 0 || question?.max > 0) && (
                        <span className={styles.minQuantity}>
                            {getTextMinMaxOptions(question)}
                        </span>
                    )}
                </div>
                {question?.min > 0 && !isDigitalMenu && (
                    <span className={styles.requiredField}>Obrigatório</span>
                )}
            </div>

            <div className={styles.optionItems}>
                {(question.items || []).map((option, optionIndex) => (
                    <Fragment key={optionIndex}>
                        <OptionItem
                            option={option}
                            singleOption={formatInputExtra(question)}
                            sameOptions={question.mode == 'quantity'}
                            onClick={(newOption) => {
                                handleOptionClick(newOption, optionIndex)
                            }}
                            disabled={checkIsDisabledOption(question, option)}
                            ecleticaMenu
                        />
                    </Fragment>
                ))}
            </div>
        </>
    );
};