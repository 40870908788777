import React from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { Button, Input, VerificationCode } from "shared/components";

import { Phone, CPF } from "shared/tools";

import { ClubAPI } from "api/ClubAPI";

import styles from "styles/containers/benefits.module.scss";

const Steps = {
  SEND_CODE: 1,
  CONFIRM_CODE: 2,
  ADJUST_CPF: 3,
};

export default class AdjustCPF extends React.Component {
  formRef = null;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      step: Steps.SEND_CODE,
      resendTimer: 0,
      codeNumber: "",
      errorCode: false,
      cpf: "",
      errorCpf: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.resendTimer > 0 &&
      prevState.resendTimer !== this.state.resendTimer
    ) {
      const timer = setTimeout(() => {
        this.setResendTimer(this.state.resendTimer - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }

  setResendTimer(value) {
    this.setState({ resendTimer: value });
  }

  setLoading(value) {
    this.setState({ loading: value });
  }

  setCodeNumber(code) {
    this.setState({ codeNumber: code });
  }

  async onSubmit(values, actions) {
    const { finishCpfUpdate, cpfIsValid } = this.props;
    const { codeNumber } = this.state;

    this.setLoading(true);
    const updateFieldReq = await ClubAPI.updateField(
      "doc",
      values.cpf,
      codeNumber
    );
    const updatedField = updateFieldReq.getData({});

    if (updatedField?.doc) {
      cpfIsValid && cpfIsValid(true);
      finishCpfUpdate && finishCpfUpdate(values.cpf);
    } else {
      await this.formRef.setFieldError(
        "cpf",
        "Não foi possível atualizar o CPF.*"
      );
    }

    this.setLoading(false);
  }

  render() {
    const { customerPhone } = this.props;
    const { step, resendTimer, codeNumber, loading, errorCpf } = this.state;

    const setCpf = (value) => {
      this.setState({ cpf: value });
    };

    const initialValues = {
      cpf: "",
    };

    const onSendCode = async () => {
      this.setLoading(true);
      await ClubAPI.requestVerificationCode("doc");
      this.setLoading(false);
      this.setState({ step: Steps.CONFIRM_CODE });
      this.setResendTimer(60);
    };

    const onConfirmCode = async () => {
      const { codeNumber } = this.state;

      this.setLoading(true);
      const validateCodeReq = await ClubAPI.validateVerificationCode(
        "doc",
        codeNumber
      );
      this.setLoading(false);

      const validCode = validateCodeReq.getData({});
      if (validCode?.success) {
        this.setState({ step: Steps.ADJUST_CPF });
      } else {
        this.setState({ errorCode: true });
      }
    };

    const onChange = (str) => {
      const formattedCpf = String(str).replace(/[^0-9]/g, "");
      const isValidCpf = CPF.validate(formattedCpf);
      setCpf(formattedCpf);
      this.formRef.setFieldValue("cpf", formattedCpf);
      this.setState({ errorCpf: !isValidCpf });
    };

    const validationSchema = Yup.object({
      cpf: Yup.lazy(() => {
        if (errorCpf)
          return Yup.string()
            .required("Campo obrigatório.*")
            .length(0, "CPF inválido.*");
        else return Yup.string().required("Campo obrigatório.*");
      }),
    });

    const getTitle = () => {
      const titles = {
        [Steps.SEND_CODE]: "Ajuste seu CPF",
        [Steps.CONFIRM_CODE]: "Insira o código",
        [Steps.ADJUST_CPF]: "Informe o seu CPF",
      };

      return titles[step];
    };

    return (
      <>
        <div className="d-flex flex-col align-start mb-10">
          <p className={styles.title}>{getTitle()}</p>
        </div>

        {step !== Steps.ADJUST_CPF && (
          <div className="card">
            <p className={styles.subtitle}>
              Aparentemente seu CPF pode estar cadastrado de forma incorreta em
              nossa base. Confirme sua identidade através do código de validação
              enviado via SMS, para o número:&nbsp;
              <strong>{Phone.partialFormat(customerPhone)}</strong>
            </p>
          </div>
        )}

        {step === Steps.SEND_CODE && (
          <>
            <Button
              design="primary"
              onClick={onSendCode}
              className="w-100 mt-30"
              loading={loading}
            >
              Enviar código
            </Button>
          </>
        )}
        {step === Steps.CONFIRM_CODE && (
          <>
            <div className="card mt-20 mb-5">
              <VerificationCode onChange={(code) => this.setCodeNumber(code)} />
            </div>

            <Button
              design="primary"
              onClick={onConfirmCode}
              className="w-100 mt-30"
              disabled={codeNumber.length < 6}
              loading={loading}
            >
              Continuar
            </Button>

            <Button
              design="underline"
              onClick={onSendCode}
              className={`w-100 mt-25 ${resendTimer > 0 ? "loading" : ""}`}
              disabled={resendTimer > 0}
              loading={loading}
            >
              Enviar novamente{" "}
              {resendTimer > 0 ? `(em ${resendTimer} segundos)` : ""}
            </Button>
          </>
        )}
        {step === Steps.ADJUST_CPF && (
          <>
            <Formik
              innerRef={(ref) => (this.formRef = ref)}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(e) => this.onSubmit(e)}
            >
              {(props) => (
                <>
                  <Form>
                    <Input
                      name="cpf"
                      label="CPF"
                      onChange={(e) => {
                        props.handleChange(e);
                        onChange(e.target.value);
                      }}
                      mask="999.999.999-99"
                      type="tel"
                    />

                    <Button
                      type="submit"
                      design="primary"
                      className="mt-30 w-100"
                      loading={loading}
                    >
                      Confirmar CPF
                    </Button>
                  </Form>
                </>
              )}
            </Formik>
          </>
        )}
      </>
    );
  }
}
