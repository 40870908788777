import { BaseStorage } from "./BaseStorage";

export class AddressStorage {
  static storage = new BaseStorage({ key: "address" });

  static load(addresses) {
    AddressStorage.storage.setData(addresses);
  }

  static add(address) {
    const addresses = AddressStorage.storage.getData() || [];

    if (address.isLastSelected) {
      for (let addressIndex in addresses) {
        addresses[addressIndex].isLastSelected = false;
      }
    }

    addresses.push(address);

    AddressStorage.storage.setData(addresses);
  }

  static updateByIndex(addressIndex, newAddress) {
    const addresses = AddressStorage.storage.getData() || [];

    if (newAddress.isLastSelected) {
      for (let addrIndex in addresses) {
        addresses[addrIndex].isLastSelected = false;
      }
    }

    const address = addresses[addressIndex];
    if (address) {
      addresses[addressIndex] = newAddress;
      AddressStorage.storage.setData(addresses);
    }
  }

  static deleteByIndex(addressIndex) {
    const addresses = AddressStorage.storage.getData() || [];

    const address = addresses[addressIndex];
    if (address) {
      addresses.splice(addressIndex, 1);
      AddressStorage.storage.setData(addresses);
    }
  }

  static findAll() {
    const addresses = AddressStorage.storage.getData() || [];
    return addresses;
  }

  static findSelected() {
    const addresses = AddressStorage.storage.getData() || [];
    return addresses.find((address) => address.isLastSelected === true);
  }
}
