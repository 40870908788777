import { useRef, useState } from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { Button, Drawer, Input } from "@/shared/components";
import { CheckCircle2, UserLight } from "@/shared/components/Icons";

import styles from "@/styles/containers/open-command.module.scss";

export default function OpenTable({ open, onClose, onCommandOpened }) {
  const [openCreatedCommand, setOpenCreatedCommand] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const formRef = useRef();

  const formatPhone = (phone) => {
    let phoneNumber = phone.replace(/\D/g, "");
    phoneNumber = phoneNumber.replace(/^0/, "");

    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.replace(
        /^(\d\d)(\d{5})(\d{4}).*/,
        "($1) $2-$3"
      );
    } else if (phoneNumber.length > 7 && phoneNumber.length <= 10) {
      phoneNumber = phoneNumber.replace(
        /^(\d\d)(\d{4})(\d{0,4}).*/,
        "($1) $2-$3"
      );
    } else if (phoneNumber.length > 2 && phoneNumber.length <= 7) {
      phoneNumber = phoneNumber.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else if (phone.trim() !== "") {
      phoneNumber = phoneNumber.replace(/^(\d*)/, "($1");
    }

    return phoneNumber;
  };

  const initialValues = {
    name: "",
    phoneNumber: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Campo obrigatório.*"),
    phoneNumber: Yup.string()
      .required("Campo obrigatório.*")
      .matches(/^\(\d{2}\) \d{5}\-\d{4}/, "Telefone inválido"),
  });

  return (
    <Drawer open={open} onClose={onClose} showBackButton title="Abrir mesa">
      <div className={styles.openCommand}>
        <div className={styles.icon}>
          <UserLight />
        </div>
        <p className={styles.title}>Precisamos identificar a sua mesa</p>
        <span className={styles.subtitle}>
          Insira o seu nome e o seu número de celular.
        </span>

        <div className={styles.hairline} />

        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => setOpenCreatedCommand(true)}
        >
          {(props) => (
            <Form
              onChange={(e) => {
                if (e.target.name === "phoneNumber") {
                  let t = e.target.value;
                  const formattedPhone = formatPhone(t);
                  formRef.current.setFieldValue("phoneNumber", formattedPhone);
                  setPhoneNumber(formattedPhone);
                }
              }}
            >
              <Input className="mb-20" name="name" floatLabel="Seu nome" />
              <Input
                name="phoneNumber"
                floatLabel="Número de celular"
                placeholder="(DDD) + Número"
                type="tel"
              />

              <div className={styles.footer}>
                <Button type="submit" design="primary" className="w-100">
                  Concluir
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <Drawer
        open={openCreatedCommand}
        alreadyDrawer
        onClose={() => setOpenCreatedCommand()}
        direction="bottom-center"
      >
        <div className="d-flex justify-center mb-20">
          <CheckCircle2 />
        </div>
        <p className={styles.alertTitle}>Mesa aberta com sucesso!</p>
        <Button
          design="primary"
          className="w-100"
          onClick={() => {
            setOpenCreatedCommand(false);
            onCommandOpened();
            onClose();
          }}
        >
          Voltar para cardápio
        </Button>
      </Drawer>
    </Drawer>
  );
}
