import Image from "next/image";
import { PenCircle } from "shared/components/Icons";
import { OlgaImageLoader } from "shared/tools";

import styles from "styles/containers/product-details.module.scss";

const StepsName = {
    SIZES: "Tamanho",
    DOUGHS: "Massa",
    FLAVORS: "Sabores",
    EDGES: "Borda",
    EXTRAS: "Adicionais",
    OVERVIEW: "Resumo",
};

const GoToStep = ({ onClick }) => {
    return (
        <div className={styles.goToStep} onClick={onClick} >
            <PenCircle />
        </div>
    )
}

const getFlavorById = (product, flavorId) => {
    return product.items.find(f => f.id == flavorId);
}

export const PizzaOverviewStep = ({ product, selectedFlavors, steps, onActiveStep }) => {

    const selected_dough = product.doughs.find((dough) => dough.selected === true);
    const selected_edge = product.edges.find((edge) => edge.selected === true);

    const getFlavorsOverview = () => {

        if (selectedFlavors.length === 1) {
            const mainFlavor = getFlavorById(product, selectedFlavors[0]);
            return <p>{mainFlavor?.name}</p>;
        }

        const halves = selectedFlavors.length;

        const parts = selectedFlavors.reduce((parts, flavorId) => {
            if (!parts[`${flavorId}`]) {
                const flavor = getFlavorById(product, flavorId);
                parts[`${flavorId}`] = { quantity: 0, name: flavor.name };
            }
            parts[`${flavorId}`].quantity++;
            return parts;
        }, {});

        return Object.values(parts).map((flavor) => (
            <p key={`flavor-overview-${flavor.id}`}>
                {flavor.quantity}<span>/</span>{halves} {flavor.name}
            </p>
        ));
    };

    const getExtrasOverview = () => {
        const selectedAddons = product.extra_ingredients
            .filter((extra) => extra.selected === true)
            .map((item) => `${item.quantity}x ${item.name}`);

        if (selectedAddons.length === 0) {
            return <p>Não quero, obrigado</p>;
        }

        return <p>{selectedAddons.map((addon) => addon).join(", ")}</p>;
    };

    return (
        <div className={styles.overviewContainer}>
            <div className={styles.overviewHeader}>
                {product.image && (
                    <div className={styles.productImage}>
                        <Image
                            src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${product.image}`}
                            alt={product.name}
                            placeholder="blur"
                            blurDataURL={OlgaImageLoader.BLURRED_IMG}
                            loader={OlgaImageLoader.load}
                            layout="fill"
                            sizes="100vw"
                        />
                    </div>
                )}
                <p className={styles.productName}>{product.name}</p>
            </div>

            <div className={styles.overviewContent}>
                <div className={styles.stepContent}>
                    <div className="d-flex flex-col">
                        <p className={styles.stepName}>Tamanho</p>
                        <div className={styles.stepChoices}>
                            <p>{product.sizes[0]?.name}</p>
                        </div>
                    </div>

                    {steps.includes(StepsName.SIZES) && (
                        <GoToStep onClick={() => onActiveStep(StepsName.SIZES)} />
                    )}
                </div>

                {steps.includes(StepsName.DOUGHS) && (
                    <div className={styles.stepContent}>
                        <div className="d-flex flex-col">
                            <p className={styles.stepName}>Massa</p>
                            <div className={styles.stepChoices}>
                                <p>{selected_dough?.name}</p>
                            </div>
                        </div>

                        <GoToStep onClick={() => onActiveStep(StepsName.DOUGHS)} />
                    </div>
                )}

                <div className={styles.stepContent}>
                    <div className="d-flex flex-col">
                        <p className={styles.stepName}>Sabores</p>
                        <div className={styles.stepChoices}>{getFlavorsOverview()}</div>
                    </div>
                    <GoToStep onClick={() => onActiveStep(StepsName.FLAVORS)} />
                </div>

                {steps.includes(StepsName.EDGES) && (
                    <div className={styles.stepContent}>
                        <div className="d-flex flex-col">
                            <p className={styles.stepName}>Borda</p>
                            <div className={styles.stepChoices}>
                                <p>{selected_edge?.name || "Não quero, obrigado"}</p>
                            </div>
                        </div>
                        <GoToStep onClick={() => onActiveStep(StepsName.EDGES)} />
                    </div>
                )}

                {steps.includes(StepsName.EXTRAS) && (
                    <div className={styles.stepContent}>
                        <div className="d-flex flex-col">
                            <p className={styles.stepName}>Adicionais</p>
                            <div className={styles.stepChoices}>{getExtrasOverview()}</div>
                        </div>
                        <GoToStep onClick={() => onActiveStep(StepsName.EXTRAS)} />
                    </div>
                )}
            </div>
        </div>
    );
};