import styles from "styles/layout/layout.module.scss";

import dynamic from "next/dynamic";

import ScrollToTop from "shared/hooks/useScrollToTop";

import { useRouter } from "next/router";

import { isDesktop, isMobile } from "react-device-detect";

import Header from "./Header";
import MenuMobile from "./MenuMobile";

import classNames from "classnames/bind";
import { Brand } from "../core/Brand";
import { Store } from "../core/Store";
const css = classNames.bind(styles);

function Layout({ environment, isClub, children }) {
  const router = useRouter();

  const isMonoBrandClub = isClub && environment.type == "BRAND";

  const isHome = router.pathname === "/";
  const isQrCode = router.pathname.startsWith("/qrcode");

  const showHeader = !isHome && !isQrCode && (Brand.alias && Store.alias) && isDesktop;
  const showMenu = !isHome && !isQrCode && (Brand.alias && Store.alias) && isMobile;

  return (
    <div className={styles.layoutContainer} id="layout">
      {showHeader && <Header />}

      <div
        className={css({
          content: !isMonoBrandClub && !isClub,
          clubContent: isMonoBrandClub || (isClub && router.pathname !== "/"),
        })}
      >
        {children}
      </div>

      {showMenu && <MenuMobile />}

      <ScrollToTop />
    </div>
  );
}

export default dynamic(() => Promise.resolve(Layout), {
  ssr: false,
});
