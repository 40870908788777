import { AddressStorage } from "storage/AddressStorage";

const customer_key = "of-customer";

export const CustomerEvents = {
  CUSTOMER_CHANGED: "CUSTOMER_CHANGED",
};

export class Customer {
  static setCustomer(data) {
    const unparsedData = JSON.stringify(data);
    localStorage.setItem(customer_key, unparsedData);
    if (window) {
      window.dispatchEvent(
        new CustomEvent(CustomerEvents.CUSTOMER_CHANGED, { detail: data })
      );
    }
  }

  static getCustomer() {
    try {
      const customer = localStorage.getItem(customer_key);
      return customer ? JSON.parse(customer) : null;
    } catch (err) {
      return null;
    }
  }

  static get id() {
    const customer = Customer.getCustomer();
    return customer?.id || null;
  }

  static get name() {
    const customer = Customer.getCustomer();
    if (customer?.extras?.surname) {
      return `${customer.extras.surname}`;
    } else if (customer?.name) {
      return String(customer.name).split(" ")[0];
    } else {
      return "";
    }
  }

  static get phone() {
    const customer = Customer.getCustomer();
    return customer ? customer.phone : sessionStorage.getItem("customerPhone");
  }

  static getGreetings() {
    const hour = new Date().getHours();
    const name = Customer.name;

    if (name) return `Olá, ${name}`;
    return "Boas-vindas!";

    // if (hour < 12) {
    //     return "Bom dia" + final;
    // } else if (hour < 18) {
    //     return "Boa tarde" + final;
    // } else {
    //     return "Boa noite" + final;
    // }
  }

  static getCustomerAddress() {
    return AddressStorage.findSelected() || null;
  }

  static getCustomerAddresses() {
    return AddressStorage.findAll();
  }

  static onChange(eventHandler) {
    window.addEventListener(CustomerEvents.CUSTOMER_CHANGED, eventHandler);
  }

  static offChange(eventHandler) {
    window.removeEventListener(CustomerEvents.CUSTOMER_CHANGED, eventHandler);
  }
}
