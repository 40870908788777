const unitKey = "of-payments-cards";

export class PaymentStorage {

  static parseCards(cards) {
    return cards.map((card) => ({
      id: card.id,
      type: card.type.toUpperCase(),
      gatewayReference: card.gatewayReference,
      name: card.brand,
      number: `${card.firstSixDigits}******${card.lastFourDigits}`,
      isLastSelected: card.isLastSelected !== "undefined" ? card.isLastSelected : false,
    }));
  }

  static loadCards(cards) {
    const parsedCards = PaymentStorage.parseCards(cards);
    PaymentStorage.setCard(parsedCards);
  }

  static getDisplayName() {
    const cardData = PaymentStorage.getCustomer();
    if (cardData?.extras?.surname) return `${cardData.extras.surname}`;
    else if (cardData?.name) return String(cardData.name).split(" ")[0];
    else return "";
  }

  static attachOnCardChanged(eventHandler) {
    window.addEventListener("paymentChange", eventHandler);
  }

  static detachOnCardChanged(eventHandler) {
    window.removeEventListener("paymentChange", eventHandler);
  }

  static getCard() {
    try {
      const cardRaw = localStorage.getItem(unitKey);
      return JSON.parse(cardRaw);
    } catch (err) {
      return [];
    }
  }

  static setCard(data) {
    const unparsedData = JSON.stringify(data);
    localStorage.setItem(unitKey, unparsedData);
    if (window) {
      window.dispatchEvent(
        new CustomEvent("paymentChange", { detail: data })
      );
    }
  }

  static removeCard(cardReference) {
    const cards = PaymentStorage.getCard();
    const newCards = cards.filter((item) => item.gatewayReference !== cardReference);
    PaymentStorage.setCard(newCards);
    return newCards;
  }

  static unselectAllCards() {
    const cards = PaymentStorage.getCard();

    if (cards?.length > 0) {
      PaymentStorage.setCard(
        cards.map(c => {
          c.isLastSelected = false;
          return c
        })
      );
    }
  }
}
