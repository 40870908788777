export class Currency {
  /**
   * Formata números para o padrão pt-BR
   * @params {number} value Valor a ser formatado
   * @returns {string} valor formatado
   * */

  static formatCurrency(value) {
    const newValue = value || 0;
    const money = newValue.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
    return money;
  }
}
