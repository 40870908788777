import dayjs from "dayjs";

import { BucketsAPI } from "api/BucketAPI";

class Day {
  weeknum = null;
  open = null;
  close = null;
  active = false;

  static fromObject(obj) {
    let d = new Day();

    Object.keys(obj || {}).forEach((key) => {
      let value = obj[key];
      switch (key) {
        case "open":
        case "close":
        case "active":
        case "weeknum":
          d[key] = value;
          break;
      }
    });

    return d;
  }
}

export class WorkingTime {
  days = [];
  _workingTime;
  _todayIndex;
  _open;
  _close;
  _nextDay;

  constructor({ workingTime }) {
    this._workingTime = workingTime;
    this._todayIndex = (new Date().getDay() + 6) % 7;
    this._open = workingTime?.[this._todayIndex]?.open || [];
    this._close = workingTime?.[this._todayIndex]?.close || [];
    this._nextDay = workingTime?.[this._todayIndex]?.nextDay || false;
  }

  static fromObject(obj) {
    let w = new WorkingTime();

    Object.keys(obj || {}).forEach((key) => {
      let value = obj[key];
      switch (key) {
        case "days":
          w.days = value.map((day) => Day.fromObject(day));
          break;
      }
    });

    return w;
  }

  getOpening() {
    if (Array.isArray(this._open) && this._open.length === 2) {
      const firstOpenTime = +String(this._open[0]);
      const secondOpenTime = +String(this._open[1]);
      const nowTime = +String(new Date().toLocaleTimeString())
        .slice(0, 5)
        .replace(":", "");

      // compara o horário atual com as aberturas pra saber qual retornar
      if (nowTime <= firstOpenTime) return this._open[0];
      if (nowTime > firstOpenTime && nowTime <= secondOpenTime)
        return this._open[1];

      return this._open[1];
    }

    return this._open[0];
  }

  getClosing() {
    if (Array.isArray(this._close) && this._close.length === 2) {
      const firstCloseTime = +String(this._close[0]);
      const nowTime = +String(new Date().toLocaleTimeString())
        .slice(0, 5)
        .replace(":", "");

      // compara o horário atual com os fechamentos pra saber qual retornar
      if (nowTime < firstCloseTime) return this._close[0];

      return this._close[1];
    }

    return this._close?.[0];
  }

  getWorkingTimeMessage(timeOrDay, type = 0) {
    const plural = timeOrDay > 1 ? "s" : "";
    let formattedTimeOrDay = "";

    if (!timeOrDay) {
      return "Fechado";
    }

    let time = `${String(timeOrDay).slice(0, 2)}h${String(timeOrDay).slice(2)}`;

    if (typeof timeOrDay !== "number") {
      formattedTimeOrDay = time;
    } else {
      formattedTimeOrDay = type !== 3 ? time : timeOrDay;
    }

    const message = {
      0: `fecha às ${formattedTimeOrDay}`,
      1: `abre às ${formattedTimeOrDay}`,
      2: `abre amanhã às ${formattedTimeOrDay}`,
      3: `abre em ${formattedTimeOrDay} dia${plural}`,
    };

    return message[type];
  }

  getDaysUntilOpen(days, startFrom = 0) {
    let toStart = false;
    let dayActiveFound = false;
    let dayActive = null;
    let daysUntilOpen = 0;

    for (
      let i = toStart ? i : startFrom;
      i < days?.length && !dayActiveFound;
      i++
    ) {
      if (days[i]?.active) {
        dayActive = days[i];
        daysUntilOpen =
          i < startFrom ? 7 - (startFrom - i) + 1 : i - startFrom + 1;
        dayActiveFound = true;
      }

      if (!dayActiveFound && i === days?.length - 1) {
        i = -1;
        toStart = true;
      }

      if (toStart && i === startFrom) break;
    }

    return [dayActive, daysUntilOpen];
  }

  async handle() {
    let message = "";
    const currentOpenTime = this.getOpening();
    const currentCloseTime = this.getClosing();

    const currentDate = dayjs();
    const isOpen = BucketsAPI.isOpen(
      currentDate.day(),
      currentDate.format("HHmm"),
      this._workingTime
    );

    if (this._workingTime) {
      const TODAY_MESSAGE = 1;
      const TOMORROW_MESSAGE = 2;
      const ANOTHER_DAY_MESSAGE = 3;

      const today = this._workingTime?.[this._todayIndex];
      const tomorrow = this._workingTime?.[this._todayIndex + 1];

      const date = new Date();
      const minutes =
        `${date.getMinutes()}`.length === 1
          ? `0${date.getMinutes()}`
          : date.getMinutes();
      const now = +`${date.getHours()}${minutes}`;

      const [dayActive, daysUntilOpen] = this.getDaysUntilOpen(
        this._workingTime,
        this._todayIndex + 1
      );

      // HOJE - ABERTO
      if (today?.active && isOpen) {
        message = this.getWorkingTimeMessage(currentCloseTime, 0);
      } else {
        // FECHADO

        if (tomorrow?.active) {
          // AMANHÃ
          message = this.getWorkingTimeMessage(
            tomorrow?.open[0],
            TOMORROW_MESSAGE
          );
        }
        if (today?.active && now < +currentOpenTime) {
          // HOJE
          message = this.getWorkingTimeMessage(currentOpenTime, TODAY_MESSAGE);
        } else if (dayActive && daysUntilOpen !== 0 && !tomorrow.active) {
          // OUTROS DIAS

          message = this.getWorkingTimeMessage(
            daysUntilOpen,
            ANOTHER_DAY_MESSAGE
          );
        }
      }
    }

    return message;
  }

  isOpen(date = null) {
    const todayDate = date || this.getBRLNowTime();
    const weekDay = todayDate.getDay();
    const nowTime = `${String(todayDate.getHours()).padStart(2, `0`)}${String(
      todayDate.getMinutes()
    ).padStart(2, `0`)}`;

    const horaries = this.days;
    const yesterdayNum = weekDay == 0 ? 6 : weekDay - 1;
    const tomorrowNum = (weekDay + 1) % 7;

    const stringTime2Int = (time) => {
      return +time.replace(":", "");
    };

    if (!horaries) {
      return false;
    }

    const today = horaries.find((d) => d.active && d.weeknum == weekDay);
    const yesterday = horaries.find(
      (d) => d.active && d.weeknum == yesterdayNum
    );

    if (!today) {
      return false;
    }

    if (yesterday && yesterday.active) {
      const yesterdayPeriods = yesterday.open.map((openTime, openIndex) => {
        return {
          active: yesterday.active,
          open: openTime,
          close: yesterday.close[openIndex],
          nextDay:
            stringTime2Int(openTime) >=
            stringTime2Int(yesterday.close[openIndex]),
        };
      });
      const yesterdayNextDay = yesterdayPeriods.filter((d) => d.nextDay);

      if (yesterdayNextDay.length > 0) {
        today.open.push("0000");
        today.close.push(yesterdayNextDay[0].close);
      }
    }

    for (let periodIndex = 0; periodIndex < today.open.length; periodIndex++) {
      const open = stringTime2Int(today.open[periodIndex]);
      const close = stringTime2Int(today.close[periodIndex]); //Se o periodo verificado passa para o próximo dia, ver somente se o horário de abertura é menor que o horário atual.
      //caso seja 00:00 no dia anterior, o horário de fechamento é o horário de abertura do dia atual.
      const tomorrow = horaries[tomorrowNum].open;

      if (open >= close && nowTime >= open && close <= tomorrow) {
        return true;
      } //Verificar se o horário atual está dentro do range

      if (nowTime >= open && nowTime <= close) {
        return true;
      }
    }

    return false;
  }

  static getWeekDays(locale = "pt-br") {
    var baseDate = new Date(Date.UTC(2017, 0, 1));
    return Array(7)
      .fill(0)
      .map(() => {
        let dayName = baseDate.toLocaleDateString(locale, {
          weekday: "long",
          timeZone: "UTC",
        });
        baseDate.setDate(baseDate.getDate() + 1);
        return dayName;
      });
  }

  static getTemplatePeriodWeek(periodName = null) {
    return new Period(
      periodName,
      Array(7)
        .fill(0)
        .map((item, index) => {
          return new Day(index + 1, null, null, true);
        })
    );
  }

  getBRLNowTime() {
    let now = new Date();
    now.setHours(now.getUTCHours() - 3);
    return now;
  }
}
