import styles from "styles/components/input.module.scss";

export const Textarea = ({
  placeholder = "",
  onBlur,
  onFocus,
  autoFocus,
  onChange,
  label,
  value,
  resizeVertical,
  floatLabel,
  className,
  ...props
}) => {
  const { name } = props;
  const id = props.id || name;

  return (
    <div className={styles.inputContainer} spellCheck="false">
      <textarea
        value={value}
        id={id}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autoFocus}
        style={{ resize: resizeVertical ? "vertical" : "none" }}
        className={className}
      />
    </div>
  );
};
