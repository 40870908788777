import { useEffect, useRef, useState } from "react";
import styles from "styles/components/tabs.module.scss";
import classNames from "classnames/bind";

export const Tabs = ({ tabs, onTabSelected, activeTab }) => {
  let cx = classNames.bind(styles);

  const lineRef = useRef();
  const firstTabRef = useRef();

  const [loadedTabs, setLoadedTabs] = useState(tabs);

  useEffect(() => {
    setLoadedTabs(tabs);
  }, [tabs]);

  useEffect(() => {
    if (firstTabRef.current) {
      lineRef.current.setAttribute(
        "style",
        `transform: translateX(${firstTabRef.current.offsetLeft}px);width: ${firstTabRef.current.offsetWidth}px`
      );
    }
  }, [firstTabRef, loadedTabs]);

  const scrollToTab = (e, index) => {
    e.target.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });

    requestAnimationFrame(() => {
      lineRef.current.setAttribute(
        "style",
        `transform: translateX(${e.target.offsetLeft}px);width: ${e.target.offsetWidth}px`
      );
    });

    onTabSelected(index);
  };

  return (
    <ul className={styles.tabs}>
      <div className={styles.line} ref={lineRef} />
      {loadedTabs.map((tab, _i) => {
        return (
          <li
            key={_i}
            onClick={(e) => scrollToTab(e, tab)}
            className={cx({
              tab: true,
              active: activeTab === tab,
              last: _i === tabs.length - 1,
            })}
            ref={(el) => (_i === 0 ? (firstTabRef.current = el) : null)}
          >
            {tab}
          </li>
        );
      })}
      <div className={styles.border} />
    </ul>
  );
};
