import styles from "styles/containers/product-details.module.scss";

export const PizzaQuantityFlavorsStep = ({ product, selectedQuantity, onSetMaxFlavors }) => {
    const maxAllowedFlavors = product?.sizes[0]?.max || 1;
    const flavorQuantities = new Array(maxAllowedFlavors).fill(1).map((_, flavorQuantity) => flavorQuantity + 1);

    if(maxAllowedFlavors == 1) {
        return null;
    }

    return (
        <>
            <div className={styles.sectionHeader}>
                <p className={styles.sectionTitle}>
                    Escolha a quantidade de sabores:
                </p>
                <span className={styles.requiredField}>Obrigatório</span>
            </div>

            <div className={styles.sizes}>
                {flavorQuantities.map((quantity) => (
                    <div
                        key={`max-flavors-${quantity}`}
                        className={`${styles.size} ${selectedQuantity === quantity ? styles.selected : ""}`}
                        onClick={() => onSetMaxFlavors(quantity)}
                    >
                        <p className={styles.name}>{quantity}</p>
                    </div>
                ))}
            </div>
        </>
    );
}