import { Request } from "@konv/http";
import { Brand } from "shared/core/Brand";
import { Holding } from "shared/core/Holding";

export class AddressAPI {
  static async request({ endpoint = "/", method = "GET", query }) {
    return await Request.request({
      endpoint: endpoint,
      method: method,
      service: "addresses",
      ignoreVersion: true,
      params: query,
    });
  }

  static async searchAddresses(q, token) {
    const environmentId = Brand.holdingId || Holding.id;
    return await AddressAPI.request({
      endpoint: `/search`,
      method: "GET",
      query: { q, token, environmentId },
    });
  }

  static async getAddressById(id, token) {
    const environmentId = Brand.holdingId || Holding.id;
    return await AddressAPI.request({
      endpoint: `/places/${id}`,
      method: "GET",
      query: { token, environmentId },
    });
  }
}
