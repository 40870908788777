import React from "react";

import styles from "@/styles/containers/benefits.module.scss";

import { Button, Drawer } from "@/shared/components";

import BenefitsList from "./BenefitsList";
import CashbackEvents from "./CashbackEvents";
import AddCoupon from "./AddCoupon";
import CashbackBalance from "./CashbackBalance";
import QRCode from "./QRCode";
import { BenefitScanPage } from "./BenefitScanPage";
import { AddOptions, AddTypes } from "./AddOptions";

import { BenefitStorage } from "storage/BenefitStorage";
import { OrderStorage } from "storage/OrderStorage";

import { ClubAPI } from "api/ClubAPI";

import { Customer } from "@/shared/core/Customer";
import { Benefit } from "@/shared/tools";

import classNames from "classnames/bind";
import { ClubLogin } from "./ClubLogin";
import { Brand } from "@/shared/core/Brand";
import { DataLayer } from "@/shared/tools/DataLayer";
const css = classNames.bind(styles);

const Steps = {
  BENEFITS: 1,
  ADD_COUPON: 2,
  EXTRACT: 3,
  USE_BALANCE: 4,
  QR_CODE: 5,
  CLUB_LOGIN: 6,
};

export default class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addOptionsVisible: false,
      prevStep: null,
      step: null,
      giftList: [],
      cashbackInfos: {},
      currentSelectedBenefit: null,
      limitHeight: false,
      codeUnlocked: false,
      openBenefitScan: false,
      codeUnlocked: false,
      loading: true,
      infos: {},
    };
  }

  async componentDidMount() {
    this.loadBenefits();
  }

  handleUseBalance() {
    const { infos } = this.state;
    const { cashback } = infos?.benefits || {};
    this.setState({
      currentSelectedBenefit: {
        type: "CASHBACK_CONSUME",
        amount: cashback.total,
      },
    });
  }

  getTitle() {
    const { step } = this.state;
    const titles = {
      [Steps.BENEFITS]: "Benefícios",
      [Steps.ADD_COUPON]: "Adicionar cupom",
      [Steps.EXTRACT]: "Meu extrato",
      [Steps.USE_BALANCE]: "Uso do saldo",
      [Steps.QR_CODE]: "QR Code",
      [Steps.CLUB_LOGIN]: "Identificação",
    };

    return titles[step];
  }

  componentDidUpdate() {
    const { open } = this.props;
    const { step, prevState } = this.state;

    const titles = {
      [Steps.BENEFITS]: "Benefícios | Listagem",
      [Steps.EXTRACT]: "Benefícios | Saldo de pontos",
      [Steps.USE_BALANCE]: "Benefícios | Uso do saldo",
    };

    if (open && prevState !== step && step !== Steps.ADD_COUPON) {
      DataLayer.push({
        event: "page_view",
        page_title: titles[step],
      });
    }
  }

  setStep(step) {
    this.setState({ step });
  }

  async loadBenefits() {
    const customerInfos = (await ClubAPI.getCustomerInfos()) || {};
    if (!customerInfos?.id) {
      this.setState({ loading: false });
      this.setStep(Steps.CLUB_LOGIN);
    } else {
      this.setState({
        giftList: [],
        cashbackInfos: [],
        infos: customerInfos,
        loading: false,
      });
    }
    return true;
  }

  handleSelectCoupon(coupon) {
    this.setState({
      currentSelectedBenefit: coupon,
    });
  }

  render() {
    const {
      open,
      onClose,
      alreadyDrawer,
      withoutTransition,
      withoutFade,
      showJumpButton,
      onJump,
    } = this.props;

    const {
      step,
      prevStep,
      infos,
      cashbackInfos,
      currentSelectedBenefit,
      openBenefitScan,
      addOptionsVisible,
      loading,
      initialAddCouponData,
    } = this.state;

    const benefits = infos?.benefits || {};

    const { cashback = { total: 0 }, coupons = [] } = benefits;

    return (
      <Drawer
        open={open}
        showBackButton
        alreadyDrawer={alreadyDrawer}
        showJumpButton={showJumpButton && step === Steps.BENEFITS}
        jumpButton={
          <Button design="default-without-underline" onClick={onJump}>
            Pular
          </Button>
        }
        title={this.getTitle()}
        onClose={() => {
          onClose();
          this.setState({ step: Steps.BENEFITS });
        }}
        withoutTransition={withoutTransition}
        withoutFade={withoutFade}
        backButton={() => {
          if (step === Steps.CLUB_LOGIN || step === Steps.BENEFITS) {
            return onClose();
          }

          this.setState({
            step: prevStep,
          });
        }}
      >
        {step === Steps.CLUB_LOGIN && (
          <ClubLogin
            cpfIsValid={(value) => this.setState({ codeUnlocked: value })}
          />
        )}

        {[Steps.BENEFITS, Steps.EXTRACT].includes(step) && (
          <div
            className={css({
              scrollContainer: true,
              limitHeight: true,
            })}
          >
            {[Steps.BENEFITS, Steps.EXTRACT].includes(step) && (
              <CashbackBalance
                balance={cashbackInfos.cashback_balance || 0}
                goToExtract={() => {
                  this.setState({
                    step: Steps.EXTRACT,
                    prevStep: step,
                  });
                }}
                showViewExtract={step === Steps.BENEFITS}
              />
            )}

            {step === Steps.BENEFITS && (
              <BenefitsList
                addCoupon={() => {
                  this.setState({ addOptionsVisible: true, prevStep: step });
                }}
                hasRadio
                benefitSelected={currentSelectedBenefit}
                selectCoupon={(value) => {
                  if (value) this.setState({ limitHeight: true });
                  if (
                    currentSelectedBenefit &&
                    currentSelectedBenefit.code === value.code &&
                    currentSelectedBenefit.pin === value.pin &&
                    currentSelectedBenefit.type === value.type
                  ) {
                    this.handleSelectCoupon(null);
                    BenefitStorage.setSelectedBenefit(null);
                    OrderStorage.setGift(null);
                    this.setState({ limitHeight: false });
                  } else {
                    this.handleSelectCoupon(value);
                  }
                }}
                useBalance={this.handleUseBalance.bind(this)}
                coupons={coupons}
                balance={cashback.total}
                applyBenefit={() => {
                  BenefitStorage.setSelectedBenefit(currentSelectedBenefit);
                  onClose(false);
                }}
                loading={loading}
              />
            )}

            {step === Steps.EXTRACT && <CashbackEvents />}
          </div>
        )}

        {step === Steps.ADD_COUPON && (
          <AddCoupon
            onClose={() => {
              this.setState({
                step: Steps.BENEFITS,
                initialAddCouponData: { code: null, pin: null },
              });
            }}
            code={initialAddCouponData.code}
            pin={initialAddCouponData.pin}
            onSelectedBenefit={async (coupon) => {
              this.setState({
                step: Steps.BENEFITS,
                initialAddCouponData: { code: null, pin: null },
              });
              await this.loadBenefits();
              Benefit.canBeUsed(coupon) && this.handleSelectCoupon(coupon);
            }}
          />
        )}

        {step === Steps.QR_CODE && (
          <QRCode
            closeQRCode={() =>
              selectedBenefit === "Balance" &&
              this.setState({ step: Steps.USE_BALANCE })
            }
            coupon={selectedBenefit === "Coupon" ? selectedCoupon : null}
            cpfIsValid={(value) => this.setState({ codeUnlocked: value })}
            codeUnlocked={codeUnlocked}
          />
        )}

        <BenefitScanPage
          open={openBenefitScan}
          onClose={(status) => this.setState({ openBenefitScan: status })}
          onError={(err) => console.log(err)}
          onScan={({ type, code, pin }) => {
            this.setState({
              step: Steps.ADD_COUPON,
              openBenefitScan: !openBenefitScan,
              initialAddCouponData: { code, pin },
            });
          }}
        />

        <AddOptions
          open={addOptionsVisible}
          onClose={() => this.setState({ addOptionsVisible: false })}
          onSelectOption={async (option) => {
            this.setState({ addOptionsVisible: false });
            if (option === AddTypes.MANUAL) {
              this.setState({
                step: Steps.ADD_COUPON,
                initialAddCouponData: { code: null, pin: null },
              });
            } else {
              const allow = await navigator.permissions.query({
                name: "camera",
              });
              if (allow.state) {
                this.setState({
                  openBenefitScan: true,
                  initialAddCouponData: { code: null, pin: null },
                });
              }
            }
          }}
        />
      </Drawer>
    );
  }
}
