export class PaymentGroup {
    name = null;
    alias = null;
    type = null;
    flags = [];

    constructor(name, alias, type, defaultMethods, activeFlags) {
        this.name = name;
        this.alias = alias;
        this.type = type;
        this.flags = this.initFlags(defaultMethods, activeFlags);
    }

    static typeIs(haystack, needle) {
        return (haystack & needle) == needle;
    }

    initFlags(defaultFlags, activeFlags) {
        const myTypeFlagsMap = {};
    
        const myTypeFlags = activeFlags.filter((a) => PaymentGroup.typeIs(a.type, this.type)).map((f) => {
            f.active = true;
            myTypeFlagsMap[f.alias] = f; 
            return f;
        });
        
        for (const flag of defaultFlags) {
            const flagExists = myTypeFlagsMap[flag.alias];
            if (!flagExists) {
                const newFlag = { ...flag, type: this.type, active: false };
                myTypeFlags.push(newFlag);
                myTypeFlagsMap[newFlag.alias] = newFlag; 
            } else {
                flagExists.index = flag.index;
            }
        }
        
        myTypeFlags.sort((a, b) => {
            if(typeof a.index == 'number' && typeof b.index != 'number') {
                return -1;
            } else if(typeof b.index == 'number' && typeof a.index != 'number') {
                return 1;
            }

            if(a.index < b.index) {
                return -1;
            } else if(a.index > b.index) {
                return 1;
            } else {
                return a.alias.localeCompare(b.alias);
            }
        })
        return myTypeFlags;
    }
}