import { Request } from "@konv/http";
import { Store } from "shared/core/Store";
import { CustomerAPI } from "./CustomerAPI";
export class OrderAPI {
  static async request({
    endpoint = "/",
    method = "GET",
    headers = {},
    query = {},
    data = {},
  }) {
    return await Request.request({
      endpoint: endpoint,
      cors: true,
      method: method || "GET",
      headers: headers || {},
      service: "orders",
      ignoreVersion: true,
      params: query || {},
      data: data || {},
    });
  }

  static async requestAuthenticated({
    endpoint = "/",
    method = "GET",
    query,
    data,
  }) {
    return await OrderAPI.request({
      endpoint,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      method,
      query,
      data,
    });
  }

  static async getOrders(page, limit = 10) {
    const customerId = CustomerAPI.getCustomerId();
    return await OrderAPI.requestAuthenticated({
      endpoint: `/customers/${customerId}`,
      query: {
        page,
        limit: limit || 10,
      },
    });
  }

  static async postOrders(data) {
    const unitId = Store.id;
    return await OrderAPI.requestAuthenticated({
      endpoint: `/${unitId}`,
      method: "POST",
      data,
    });
  }

  static async getOrderById(orderId) {
    const customerId = CustomerAPI.getCustomerId();
    return await OrderAPI.requestAuthenticated({
      endpoint: `/customers/${customerId}/orders/${orderId}`,
    });
  }

  static async getDeliveryPreview(unitId, address) {
    return await OrderAPI.requestAuthenticated({
      endpoint: `/environments/${unitId}/delivery/preview`,
      method: 'POST',
      data: address
    });
  }
}
