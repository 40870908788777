import Image from "next/image";

import styles from "styles/containers/cart-item.module.scss";

import {
  EcleticaCatalog,
  EcleticaProductTypes,
} from "@/shared/core/EcleticaCatalog";
import { Currency } from "shared/tools";

import { Button } from "shared/components";
import { NoImage } from "shared/components/Icons";

const EcleticaItem = ({ product, cartItemIndex }) => {
  const getSubItemsOverview = () => {
    return (
      <div className={styles.question}>
        <span>
          {product.sub_items
            .map((subItem, index) => `${subItem.qtde}x ${subItem.descr_item}`)
            .join(", ")}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.item}>
      <div className={styles.picture}>
        {product.image ? (
          <Image
            src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${product.image}`}
            alt=""
            layout="fill"
            sizes="100vw"
          />
        ) : (
          <div className={styles.noImage}>
            <NoImage />
          </div>
        )}
      </div>
      <div className={styles.details}>
        <div className="d-flex align-center">
          <p className={styles.qty}>{product.qtde}x</p>
          <p className={styles.itemName}>{product?.descr_item}</p>
        </div>

        {product.sub_items.length > 0 && (
          <div className={styles.questions}>{getSubItemsOverview()}</div>
        )}

        <div className={styles.total}>
          <p className={styles.value}>
            {Currency.formatCurrency(product.vlr_unit)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default function EcleticaCartItem({ items, onClose }) {
  const groupEcleticaProduct = (productItems) => {
    if (productItems.length === 1) {
      return {
        ...productItems[0],
        sub_items: [],
      };
    }

    const groupedItems = {
      cod_item: 0,
      cod_reg: 0,
      descr_item: "",
      qtde: 0,
      vlr_unit: 0,
      sub_items: [],
    };

    const getEcleticaProductTotalPrice = (mainItem, anotherItems) => {
      return (
        (mainItem.qtde * mainItem.vlr_unit) +
        anotherItems.reduce(
          (total, item) => total + item.vlr_unit * item.qtde,
          0
        )
      );
    };

    const formatEcleticaProductItems = (productItems) => {
      let mainItem = null;
      let anotherItems = null;

      const comboProducts = productItems.filter(
        (item) => item.type === EcleticaProductTypes.COMBINATION
      );
      const pizzaProducts = productItems.filter(
        (item) => item.type === EcleticaProductTypes.PIZZA
      );

      if (comboProducts?.length > 0) {
        anotherItems = productItems.filter(
          (item) => item.type !== EcleticaProductTypes.COMBINATION
        );
        if (comboProducts.length > 1) {
          comboProducts.sort((a, b) => a.vlr_unit - b.vlr_unit);
          for (let i = 0; i < comboProducts.length - 1; i++) {
            anotherItems.push(comboProducts.pop());
          }
        }
        mainItem = comboProducts[0];
      } else if (pizzaProducts?.length > 0) {
        mainItem = pizzaProducts[0];
        anotherItems = productItems.filter(
          (item) => item.cod_item !== mainItem.cod_item
        );
      } else {
        mainItem = productItems.slice(0, 1)[0];
        anotherItems = productItems.slice(1);
      }

      return { mainItem, anotherItems };
    };

    const { mainItem, anotherItems } = formatEcleticaProductItems(productItems);

    groupedItems.cod_item = mainItem.cod_item;
    groupedItems.cod_reg = mainItem.cod_reg;
    groupedItems.descr_item = mainItem.descr_item;
    groupedItems.qtde = mainItem.qtde;
    groupedItems.vlr_unit = getEcleticaProductTotalPrice(
      mainItem,
      anotherItems
    );
    groupedItems.sub_items = anotherItems;

    return groupedItems;
  };

  const groupProductsByCodReg = () => {
    const productsByCodReg = {};

    items.map((product) => {
      const codReg = product.cod_reg;
      if (!productsByCodReg[codReg]) {
        productsByCodReg[codReg] = [
          {
            ...product,
            type: EcleticaCatalog.getProductType(product.cod_item),
          },
        ];
      } else {
        productsByCodReg[codReg].push({
          ...product,
          type: EcleticaCatalog.getProductType(product.cod_item),
        });
      }
    });

    const groupedProducts = Object.keys(productsByCodReg).map((cod_reg) => {
      return groupEcleticaProduct(productsByCodReg[cod_reg]);
    });

    return groupedProducts;
  };

  return (
    <div className={styles.ecleticaItems}>
      {groupProductsByCodReg().map((item, index) => (
        <EcleticaItem
          key={`cart-item-${Math.random()}`}
          product={item}
          cartItemIndex={index}
        />
      ))}

      <div className={styles.addItems}>
        <Button onClick={() => onClose && onClose()}>
          Adicionar mais itens
        </Button>
      </div>
    </div>
  );
}
