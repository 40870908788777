import { useState } from "react";

import ReactInputMask from "react-input-mask";

import classNames from "classnames/bind";

import { EyeClosed, EyeOpen, Search } from "shared/components/Icons";
import styles from "styles/components/input.module.scss";

export const SimpleInput = ({
  type,
  placeholder = " ",
  onBlur,
  onFocus,
  onChange,
  autoFocus,
  label,
  mask,
  floatLabel,
  autoComplete,
  maxLength,
  className,
  search,
  design = "one",
  disabled,
  ...props
}) => {
  const cx = classNames.bind(styles);

  const { name } = props;
  const id = props.id || name;

  const [inputType, setInputType] = useState(type);

  return (
    <div
      className={cx({
        inputContainer: true,
        [className]: className,
      })}
      spellCheck="false"
    >
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      {search && (
        <span className={styles["search-icon"]}>
          <Search />
        </span>
      )}
      <ReactInputMask
        id={id}
        mask={mask || null}
        maskChar={null}
        type={inputType}
        name={name}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autoFocus}
        maxLength={maxLength}
        autoComplete="off"
        disabled={disabled}
        className={cx({
          password: type === "password",
          search: search,
          [`design-${design}`]: true,
        })}
      />
      {floatLabel && <label className={styles.float}>{floatLabel}</label>}
      {type === "password" && (
        <span
          className={styles.eye}
          onClick={() =>
            setInputType(inputType === "password" ? "text" : "password")
          }
        >
          {inputType === "password" ? <EyeClosed /> : <EyeOpen />}
        </span>
      )}
    </div>
  );
};
