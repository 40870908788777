import { Request } from "@konv/http";
import { Brand } from "shared/core/Brand";
import { Holding } from "shared/core/Holding";

export class OAuthAPI {
  static async request({
    endpoint = "/",
    method = "GET",
    headers = {},
    query = {},
    data = {},
  }) {
    return await Request.request({
      endpoint: endpoint,
      cors: true,
      method: method || "GET",
      headers: headers || {},
      service: "oauth",
      ignoreVersion: true,
      params: query || {},
      data: data || {},
    });
  }

  static async requestAuthenticated({
    endpoint = "/",
    method = "GET",
    query,
    data,
  }) {
    return await OAuthAPI.request({
      endpoint,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      method,
      query,
      data,
    });
  }

  static async authorize() {
    return await OAuthAPI.request({
      endpoint: "/authorize",
      method: "POST",
      data: {
        APPLICATION_ID: `${process.env.APPLICATION_ID}`,
        APPLICATION_SECRET: `${process.env.APPLICATION_SECRET}`,
      },
    });
  }

  static async authenticate(email, phone, password) {
    const authorizationResponse = await OAuthAPI.authorize();
    const authorizationToken = authorizationResponse.getData({
      access_token: null,
    }).access_token;

    if (!authorizationToken) {
      return null;
    }

    const environmentId = Brand.holdingId || Holding.id;

    return await OAuthAPI.request({
      endpoint: "/authenticate",
      method: "POST",
      headers: { Authorization: "Bearer " + authorizationToken },
      data: { email, phone, password, environmentId },
    });
  }

  static async refresh() {
    return await OAuthAPI.requestAuthenticated({
      endpoint: "/refresh-token",
      method: "POST",
    });
  }
}
