import React from "react";
import adapter from "webrtc-adapter";
import { Drawer } from "shared/components";
import { QrReader } from "react-qr-reader";

export class BenefitScanPage extends React.Component {
  state = {
    allowScan: false,
    facingMode: "environment",
    localStream: null,
    error: null,
  };

  onQrCodeScan(result, error) {
    const { onError, onScan } = this.props;

    if (result && result.text) {
      const uri = new URL(result.text);
      const code = uri.searchParams.get("code");
      const pin = uri.searchParams.get("pin");
      return onScan && onScan({ type: "COUPON", code, pin });
    }

    // if (error) {
    //   return onError && onError(error);
    // }
  }

  render() {
    const { open, onClose } = this.props;
    const { facingMode } = this.state;

    return (
      <Drawer
        open={open}
        onClose={(status) => onClose(status)}
        alreadyDrawer
        title="Escanear QR Code"
        showBackButton
        withoutPadding
      >
        <QrReader
          key={"qr-scanner"}
          scanDelay={800}
          onResult={this.onQrCodeScan.bind(this)}
          constraints={{
            facingMode: { ideal: facingMode },
          }}
          ViewFinder={ViewFinder}
          containerStyle={{ width: "100%", height: "100%" }}
          videoContainerStyle={{
            paddingTop: 0,
            width: "100%",
            height: "100%",
          }}
          videoStyle={{
            color: "red",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Drawer>
    );
  }
}

const ViewFinder = () => (
  <>
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 1, background: "rgba(0,0,0,0.7)" }}></div>
      <div
        style={{
          boxSizing: "border-box",
          border: "50px solid rgba(0,0,0,0.7)",
          width: "100%",
          aspectRatio: 1,
        }}
      ></div>
      <div style={{ flex: 1, background: "rgba(0,0,0,0.7)" }}></div>
    </div>
  </>
);
