import React, { useEffect, useRef, useState } from "react";

import styles from "styles/containers/add-coupon.module.scss";

import { Button, Input, Message } from "shared/components";
import { TicketB } from "shared/components/Icons";
import { ClubAPI } from "api/ClubAPI";
import { Benefit, Currency, DateFormat } from "shared/tools";
import { Form, Formik, useFormikContext } from "formik";
import { BenefitStorage } from "storage/BenefitStorage";
import { Customer } from "shared/core/Customer";
import { DataLayer } from "@/shared/tools/DataLayer";

export default function AddCoupon({ onSelectedBenefit, code, pin, onClose }) {
  const formRef = useRef();

  const [couponCode, setCouponCode] = useState(code);
  const [pinCode, setPinCode] = useState(pin);
  const [couponValid, setCouponValid] = useState(false);
  const [error, setError] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    DataLayer.push({
      event: "page_view",
      page_title: "Adicionar benefício",
    });
  }, []);

  const validateCoupon = async () => {
    setIsLoading(true);

    const response = await ClubAPI.getStandaloneCoupon(couponCode, pinCode);

    if (response.getErrors([]).length > 0) {
      setError(true);
      setCouponValid(false);
      setCoupon(null);
      setIsLoading(false);
      setErrorMessage(response.getErrors([])[0]);
    } else {
      setError(false);
      setCouponValid(true);
      setCoupon(response.getData({}));
      setIsLoading(false);
      setErrorMessage(null);
    }
  };

  const resetPage = () => {
    setError(true);
    setCouponValid(false);
    setPinCode(null);
    setCoupon(null);
    setIsLoading(false);
    setErrorMessage(null);
  };

  const onAddBenefit = async () => {
    setIsLoading(true);
    const response = await ClubAPI.postAddStandaloneCoupon(couponCode, pinCode);

    if (response.getErrors([]).length > 0) {
      setError(true);
      setErrorMessage(response.getErrors([])[0]);
      setIsLoading(false);
    } else {
      const customerInfos = (await ClubAPI.getCustomerInfos()) || {};
      const benefits = customerInfos?.benefits || {};
      const { coupons = [] } = benefits;

      const newBenefit = coupons.find(
        (g) => g.code == coupon.code && g.pin == coupon.pin
      );
      if (newBenefit) {
        if (Benefit.canBeUsed(newBenefit)) {
          BenefitStorage.setSelectedBenefit(newBenefit);
        }
        onSelectedBenefit && onSelectedBenefit(newBenefit);
      }
      resetPage();
    }
  };

  const initialValues = {
    code: code,
    pin: pin,
  };

  const FormObserver = () => {
    const { values } = useFormikContext();

    useEffect(() => {
      const { code, pin } = values;

      setCouponCode(code);
      setPinCode(pin);

      setError(!code || code.length < 1 || !pin || pin.length !== 3);
    }, [values]);

    return null;
  };

  const renderCouponDetails = () => {
    return (
      <>
        <div className={styles.scrollContainer}>
          <div className={styles.coupon}>
            <div className={styles.details}>
              <p className={styles.name}>{Benefit.getTitle(coupon)}</p>
              <div className="d-flex align-center justify-between">
                <div className={styles.code}>
                  {coupon.code} - {coupon.pin}
                </div>
                <p className={styles.exp}>
                  {coupon?.expiration?.gain?.days ||
                  coupon?.expiration_in_days ||
                  coupon?.expiration_days
                    ? `Expira em ${DateFormat.formatDateOffset(
                        coupon.created_at,
                        coupon?.expiration?.gain?.days ||
                          coupon.expiration_in_days ||
                          coupon?.expiration_days
                      )}`
                    : "Nunca expira"}
                </p>
              </div>
              <p className={styles.requirement}>
                {coupon.min_order
                  ? `Pedido mínimo ${Currency.formatCurrency(coupon.min_order)}`
                  : "Não há pedido mínimo"}
              </p>
            </div>
          </div>

          <div className="d-flex justify-center mt-15">
            <Button design="default" onClick={() => resetPage()}>
              Aplicar outro benefício
            </Button>
          </div>

          {errorMessage && (
            <Message type="error" text={errorMessage} className="mt-15" />
          )}
        </div>

        <div className={styles.footer}>
          <Button
            design="primary"
            className="w-100"
            onClick={() => onAddBenefit()}
            loading={isLoading}
            disabled={isLoading || !couponValid}
          >
            Concluir e adicionar benefício
          </Button>

          <Button design="secondary" className="mt-15" onClick={onClose}>
            Cancelar
          </Button>
        </div>
      </>
    );
  };

  if (couponValid) {
    return renderCouponDetails();
  }

  return (
    <>
      <div className={styles.scrollContainer}>
        <div className={styles.addCoupon}>
          <div className={styles.message}>
            <div className={styles.icon}>
              <TicketB />
            </div>
            <p>
              Insira o código e o pin do cupom que você deseja adicionar a sua
              lista de benefícios.
            </p>
          </div>

          <Formik initialValues={initialValues} innerRef={formRef}>
            <Form>
              <FormObserver />

              <div className="d-flex align-center">
                <Input
                  name="code"
                  label="Código de cupom"
                  className="w-100"
                  design="two"
                  maxLength={10}
                />
                <Input
                  name="pin"
                  label="PIN"
                  className="ml-25 w-100"
                  design="two"
                  maxLength={3}
                />
              </div>
            </Form>
          </Formik>
        </div>

        {errorMessage && (
          <Message type="error" text={errorMessage} className="mt-15" />
        )}
      </div>

      <div className={styles.footer}>
        <Button
          design="primary"
          className="w-100 mt-35"
          loading={isLoading}
          disabled={isLoading || error}
          onClick={() => validateCoupon()}
        >
          Buscar cupom
        </Button>

        <Button design="secondary" className="w-100 mt-15" onClick={onClose}>
          Cancelar
        </Button>
      </div>
    </>
  );
}
