import { useEffect, useRef, useState } from "react";

import styles from "styles/containers/product-details.module.scss";

import Image from "next/image";

import { Button, Checkbox, Textarea } from "shared/components";
import { Minus, Plus, Times } from "shared/components/Icons";

import { Currency, OlgaImageLoader } from "shared/tools";
import { CatalogHelpers } from "shared/tools/CatalogHelpers";
import { OMath } from "shared/tools/OMath";
import { number, object } from "yup";

import { Table } from "@/shared/core/Table";
import classNames from "classnames/bind";
const css = classNames.bind(styles);

const prepareProduct = (product) => {
  if ((product.sizes || []).filter((s) => s.selected).length == 0) {
    product.sizes[0].selected = true;
  }

  product.selected = true;

  return product;
};

export default function Drink({ product, onSave, onClose, isUpdate }) {
  const [localProduct, setLocalProduct] = useState(prepareProduct(product));
  const [valid, setValid] = useState(false);
  const [isDigitalMenu, setIsDigitalMenu] = useState(false);

  useEffect(() => {
    const externalId = Table.externalId;
    if (externalId === Table.isDigitalMenu) setIsDigitalMenu(true);
    else setIsDigitalMenu(false);

    validate();
  }, [localProduct]);

  const scrollContainer = useRef();

  useEffect(() => {
    validate();
  }, [localProduct]);

  useEffect(() => {
    validate();
  }, []);

  const validate = async () => {
    const productSchema = object().shape({ quantity: number().required() });

    setValid(await productSchema.isValid(localProduct));
  };

  const onProductChange = (data) => {
    setLocalProduct({ ...localProduct, ...data });
    validate();
  };

  const uncheckZeroQuantity = (obj) => {
    if (obj?.hasOwnProperty("selected") && obj?.hasOwnProperty("quantity")) {
      if (obj.quantity === 0) obj.selected = false;
    }

    for (const key in obj) {
      if (typeof obj[key] === "object") {
        uncheckZeroQuantity(obj[key]);
      }
    }
  };

  const save = () => {
    uncheckZeroQuantity(localProduct);

    onSave && onSave(localProduct);
  };

  const calculateItemPrice = (item) => {
    const props = ["items", "sizes", "extra_ingredients", "doughs", "edges"];

    let total =
      Math.round((item.price || 0) * 100) *
      (item.selected !== false && item.quantity > 0 ? 1 : 0);
    for (const key of props) {
      if (item[key] && item[key].length > 0) {
        for (const subItem of item[key]) {
          total += calculateItemPrice(subItem) * 100;
        }
      }
    }

    return +OMath.div(OMath.times(total, item.quantity), 100).toFixed(2);
  };

  const renderPictureSection = () => {
    if (!product.image) {
      return (
        <div
          className={styles.noPictureClose}
          onClick={() => onClose && onClose(true)}
        >
          <Times color={"#fff"} />
        </div>
      );
    }

    return (
      <div className={styles.picture}>
        <div className={styles.close} onClick={() => onClose && onClose(true)}>
          <Times color={"#fff"} />
        </div>
        {product.image && (
          <Image
            src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${product.image}`}
            alt={product.name}
            placeholder="blur"
            blurDataURL={OlgaImageLoader.BLURRED_IMG}
            loader={OlgaImageLoader.load}
            layout="fill"
            sizes="100vw"
          />
        )}
      </div>
    );
  };

  const renderExtrasSection = () => {
    if ((localProduct.extra_ingredients || []).length == 0) {
      return null;
    }

    return (
      <div className="d-flex flex-col">
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>Adicionais</p>
        </div>
        <div className={styles.optionItems}>
          {localProduct.extra_ingredients.map((e, eIndex) => {
            return (
              <div
                className={styles.optionItem}
                key={eIndex}
                onClick={() => {
                  localProduct.extra_ingredients[eIndex].selected =
                    !localProduct.extra_ingredients[eIndex].selected;
                  onProductChange({
                    extra_ingredients: localProduct.extra_ingredients,
                  });
                }}
              >
                <div className="d-flex flex-col">
                  <p className={styles.optionName}>{e.name}</p>
                  <span className={styles.optionPrice}>
                    {`${Currency.formatCurrency(e.price)}`}
                  </span>
                </div>
                <Checkbox
                  value={e.name}
                  checked={e.selected}
                  key={`extraingredient-${e.id}`}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderObservationSection = () => {
    return (
      <>
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>Alguma observação?</p>
        </div>

        <Textarea
          onChange={(e) => onProductChange({ observation: e.target.value })}
          value={localProduct.observation || ""}
          placeholder="Ex. Remover cenoura"
          className="mt-20"
          max={128}
        />

        <span className={styles.obsLength}>
          {localProduct?.observation?.length || 0}/128
        </span>
      </>
    );
  };

  const renderQuantitySection = () => {
    return (
      <div className={styles.quantityContainer}>
        <span
          className={`${styles.minus} ${
            localProduct.quantity <= 1 ? styles.disabled : styles.enabled
          }`}
          onClick={() =>
            localProduct.quantity > 1 &&
            onProductChange({ quantity: --localProduct.quantity })
          }
        >
          <Minus />
        </span>
        <p className={styles.qty}>{localProduct.quantity}</p>
        <span
          className={`${styles.plus} ${styles.enabled}`}
          onClick={() => onProductChange({ quantity: ++localProduct.quantity })}
        >
          <Plus />
        </span>
      </div>
    );
  };

  const renderSizesSection = () => {
    const selectedSize = localProduct.sizes.find((size) => size.selected);
    const isPromotionalPrice = selectedSize.promo_price;

    return (
      <>
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>Escolha o tamanho</p>
          <span className={styles.requiredField}>Obrigatório</span>
        </div>

        <div className={styles.sizes}>
          <span className={styles.sizePrice}>
            {isPromotionalPrice && (
              <>
                <strike className={styles.promoPrice}>
                  {Currency.formatCurrency(selectedSize.old_price)}
                </strike>
                &nbsp;&nbsp;
              </>
            )}
            {Currency.formatCurrency(selectedSize.price)}
          </span>

          {localProduct.sizes.map((size, sizeIndex) => (
            <div
              key={sizeIndex}
              className={`${styles.size} ${
                size.selected ? styles.selected : ""
              }`}
              onClick={() => {
                localProduct.sizes.forEach((s) => (s.selected = false));
                localProduct.sizes[sizeIndex].selected = true;
                onProductChange({ sizes: localProduct.sizes });
              }}
            >
              <p className={styles.name}>{size.name}</p>
            </div>
          ))}
        </div>
      </>
    );
  };

  const isPromotionalPrice = (product) => {
    if (!product?.sizes || product?.sizes?.length <= 0) return false;

    return !!product.sizes.find((size) => size.promo_price === true);
  };

  const renderProductStartPrice = () => {
    const oldPrice = CatalogHelpers.mountOldPrice(localProduct);
    const hasSizes =
      (localProduct.sizes || []).length > 1 ||
      (localProduct.items || []).length > 0;

    return isPromotionalPrice(localProduct) && oldPrice && !hasSizes ? (
      <p className={styles.fromPrice}>
        <strike>{oldPrice}</strike>&nbsp;&nbsp;
        {CatalogHelpers.mountPrice(localProduct)}
      </p>
    ) : (
      <p className={styles.fromPrice}>
        {hasSizes
          ? `A partir de ${CatalogHelpers.mountPrice(localProduct)}`
          : CatalogHelpers.mountPrice(localProduct)}
      </p>
    );
  };

  return (
    <>
      <div
        className={css({ scrollContainer: true, isDigitalMenu: isDigitalMenu })}
        ref={scrollContainer}
      >
        {renderPictureSection()}

        <div className={styles.productDetailsContainer}>
          <div className={styles.productDetails}>
            <p className={styles.productName}>{product.name}</p>
            <p className={styles.productDescription}>{product.description}</p>
            {renderProductStartPrice()}
          </div>

          {localProduct.sizes.length > 1 && renderSizesSection()}

          {localProduct.extra_ingredients.length > 0 && renderExtrasSection()}

          {!isDigitalMenu && renderObservationSection()}
        </div>
      </div>

      {!isDigitalMenu && (
        <div className={styles.footer}>
          {renderQuantitySection()}

          <Button
            design="primary"
            className="w-100"
            disabled={!valid}
            onClick={() => save()}
          >
            {isUpdate ? "Atualizar" : "Adicionar"} (
            {Currency.formatCurrency(calculateItemPrice(localProduct))})
          </Button>
        </div>
      )}
    </>
  );
}
