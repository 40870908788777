import { Request } from "@konv/http";
import { Builder } from "@olga-food/schemas/lib/classes/schemas/catalog/Builder";
import dayjs from "dayjs";

import { Catalog } from "@/shared/core/Catalog";
import { Store } from "@/shared/core/Store";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

export class CatalogAPI {
	static async request({
		endpoint = "/",
		method = "GET",
		headers = {},
		query,
		data,
	}) {
		return await Request.request({
			endpoint: endpoint,
			method: method,
			headers: headers,
			service: "catalog",
			ignoreVersion: true,
			params: query,
			data: data,
		});
	}

	static removeHiddensAndASized(catalog) {
		//Removendo do cardapio tudo o que está oculto
		Builder.executeOnSchema(catalog, (item) => {
			item.sizes = item.sizes?.filter((s) => s.show);
			item.items = item.items?.filter((i) => i.show);
			item.edges = item.edges?.filter((e) => e.show);
			item.doughs = item.doughs?.filter((d) => d.show);
			item.extra_ingredients = item.extra_ingredients?.filter((e) => e.show);
		})

		//Removendo do cardapio tudo que não tenha tamanho definido
		Builder.executeOnSchema(catalog, (item) => {
			if (['category', 'option'].includes(item.type)) {
				return;
			}

			if (item.sizes?.length == 0) {
				item.show = false;
			}
		});

		return catalog;
	}

	static removeUnavailableEcleticaCombinations(combinations, unavailableItems = []) {
		const unavailableSkus = unavailableItems.map(ui => ui.sku);
		return combinations.filter(
			(combination) => !unavailableSkus.includes(combination.cod_prod_vinc)
		);
	}

	static removeUnavailableEcleticaProducts(products, unavailableItems = []) {
		const unavailableSkus = unavailableItems.map(ui => ui.sku);
		return products.filter(
			(product) => !unavailableSkus.includes(product.cod_item)
		);
	}

	static hideUnavailable(item, unavailableSkus = []) {
		const props = ["items", "sizes", "extra_ingredients", "doughs", "edges"];

		for (const key of props) {
			if (item[key] && item[key].length > 0) {
				for (const subItemIndex in item[key]) {
					item[key][subItemIndex] = CatalogAPI.hideUnavailable(
						item[key][subItemIndex], unavailableSkus
					);
				}

				item[key] = item[key].filter((i) => !unavailableSkus.includes(i.sku));
			}
		}
		return item;
	}

	static removeUnavailableEcleticaItems(catalog, unavailableItems = []) {
		catalog.items = catalog.items.map((item) => {
			return CatalogAPI.hideUnavailable(item, unavailableItems.map(ui => ui.sku));
		})
		return CatalogAPI.removeHiddensAndASized(catalog);
	}

	static async getS3Catalog(environmentAlias, service) {
		try {
			const response = await fetch(`${process.env.NEXT_PUBLIC_CDN_ENVIRONMENT_CATALOG}${environmentAlias}/${service}.json?t=${Date.now()}`);
			const catalog = CatalogAPI.removeHiddensAndASized(await response.json());
			return CatalogAPI.prepareCatalog(catalog);
		} catch (err) {
			console.log(err);
			return { type: "catalog", items: [] };
		}
	}

	static async getEcleticaCatalog(environmentAlias) {
		try {
			const response = await fetch(`${process.env.NEXT_PUBLIC_CDN_ENVIRONMENT_CATALOG}${environmentAlias}/OPEN_TABLE.json?t=${Date.now()}`);
			const catalog = await response.json();
			const unavailableItems = await CatalogAPI.getUnavailableProducts();
			return CatalogAPI.prepareCatalog(CatalogAPI.removeUnavailableEcleticaItems(catalog, unavailableItems));
		} catch (err) {
			console.log(err);
			return { type: "catalog", items: [] };
		}
	}

	static async getEcleticaProducts(environmentAlias) {
		try {
			const response = await fetch(`${process.env.NEXT_PUBLIC_CDN_ENVIRONMENT_CATALOG}${environmentAlias}/ECLETICA/products.json?t=${Date.now()}`);
			const products = await response.json();
			const unavailableItems = await CatalogAPI.getUnavailableProducts();
			return CatalogAPI.removeUnavailableEcleticaProducts(products, unavailableItems);
		} catch (err) {
			console.log(err);
			return [];
		}
	}

	static async getEcleticaCombinations(environmentAlias) {
		try {
			const response = await fetch(`${process.env.NEXT_PUBLIC_CDN_ENVIRONMENT_CATALOG}${environmentAlias}/ECLETICA/combinations.json?t=${Date.now()}`);
			const combinations = await response.json();
			const unavailableItems = await CatalogAPI.getUnavailableProducts();
			return CatalogAPI.removeUnavailableEcleticaCombinations(combinations, unavailableItems);
		} catch (err) {
			console.log(err);
			return [];
		}
	}

	static prepareCatalog(catalog) {
		for (const category of catalog.items) {
			if (category.mode == 'step') {
				for (const steps of category.items) {
					const questions = steps?.items || [];
					steps.items = CatalogAPI.prepareQuestionOptions(questions);
				}
				continue;
			}

			if (category.mode == 'pizza') {
				const newItems = [];
				//Tamanho como produto
				for (const size of category.sizes) {
					newItems.push({
						id: size.id,
						type: 'pizza',
						name: size.name,
						ref_id: size.ref_id,
						sizes: [size],
						show: true,
						visible: true,
						quantity: 1,
						items: category.items.map((flavor) => {
							return {
								...flavor,
								sizes: flavor.sizes.filter((s) => s.ref_id == size.ref_id)
							};
						}),
						extra_ingredients: category.extra_ingredients.map((extra) => {
							return {
								...extra,
								quantity: 0,
								sizes: extra.sizes.filter((s) => s.ref_id == size.ref_id)
							};
						}),
						doughs: category.doughs.map((dough) => {
							return {
								...dough,
								sizes: dough.sizes.filter((s) => s.ref_id == size.ref_id)
							};
						}),
						edges: category.edges.map((edge) => {
							return {
								...edge,
								sizes: edge.sizes.filter((s) => s.ref_id == size.ref_id)
							};
						}),
					})
				}

				//Sabores como destaques
				for (const flavor of category.items) {

					if (!flavor.highlight) {
						continue;
					}

					newItems.push({
						type: 'pizza',
						id: flavor.id,
						name: flavor.name,
						fixedFlavor: flavor.id,
						selectedFlavors: [flavor.id],
						image: flavor.image || null,
						highlight: true,
						description: flavor.description,
						ref_id: null,
						sizes: [...flavor.sizes],
						show: true,
						visible: true,
						quantity: 1,
						items: category.items.map((f) => {
							return { ...f, selected: f.id == flavor.id }
						}),
						extra_ingredients: [...category.extra_ingredients.map(extra => ({ ...extra, quantity: 0 }))],
						doughs: [...category.doughs],
						edges: [...category.edges]
					})
				}

				category.items = newItems
			}
		}

		return catalog;
	}

	static prepareQuestionOptions(questions = []) {
		return questions = questions.map((question) => {
			const options = (question.items || []).map((option) => ({
				...option,
				quantity: 0,
				items: option?.items?.length === 0 ? [] : CatalogAPI.prepareQuestionOptions(option.items)
			}));

			return {
				...question,
				items: options
			}
		});
	}

	static async getS3Configurations(alias) {
		try {
			const config_url = `${process.env.REACT_APP_CDN_ENVIRONMENT_CONFIGURATIONS}/${alias}.json?t=${Date.now()}`;
			return await fetch(config_url).then((r) => {
				if (r === null) {
					return r;
				}
				r.json();
			});
		} catch (err) {
			return err;
		}
	}

	static async getUnavailableProducts() {
		const updateOffset = 1;
		try {
			const currentUnavailableItems = Catalog.getUnavailableItems();

			if (currentUnavailableItems?.updated) {
				const currentDate = dayjs().tz('America/Sao_Paulo');
				const nextUpdate =
					dayjs(currentUnavailableItems?.updated)
						.add(updateOffset, 'minute')
						.tz('America/Sao_Paulo');

				if (currentDate.isBefore(nextUpdate)) {
					return currentUnavailableItems.items;
				}
			}

			const newUnavailableItemsReq = await CatalogAPI.request({
				endpoint: `/${Store.id}/unavailable`,
				method: "GET"
			});
			const newUnavailableItems = newUnavailableItemsReq.getData([]);

			Catalog.setUnavailableItems(newUnavailableItems);

			return newUnavailableItems;
		} catch (err) {
			console.log(err);
			return [];
		}
	}
}
