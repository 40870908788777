export class Phone {
  static format(phoneString) {
    if (!phoneString) {
      return null;
    }
    return phoneString.replace(
      /^([0-9]{2})([0-9]{2})([0-9]{4,5})([0-9]{4})$/,
      "($2) $3-$4"
    );
  }

  static partialFormat(phoneString) {
    if (!phoneString) {
      return null;
    }
    return phoneString.replace(
      /^([0-9]{2})([0-9]{2})([0-9]{4,5})([0-9]{4})$/,
      "($2) *****-$4"
    );
  }

  static unmask(phone) {
    return phone.replace(/\D/g, "");
  }
  
  static mask(phone) {
    let phoneNumber = phone.replace(/\D/g, "");
    phoneNumber = phoneNumber.replace(/^0/, "");

    if (phoneNumber.length === 13) {
      phoneNumber = phoneNumber.replace(
        /^(\d\d)(\d\d)(\d{5})(\d{4}).*/,
        "($2) $3-$4"
      );
    } else if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.replace(
          /^(\d\d)(\d{5})(\d{4}).*/,
          "($1) $2-$3"
        );
    } else if (phoneNumber.length > 7 && phoneNumber.length <= 10) {
      phoneNumber = phoneNumber.replace(
        /^(\d\d)(\d{4})(\d{0,4}).*/,
        "($1) $2-$3"
      );
    } else if (phoneNumber.length > 2 && phoneNumber.length <= 7) {
      phoneNumber = phoneNumber.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else if (phone.trim() !== "") {
      phoneNumber = phoneNumber.replace(/^(\d*)/, "($1");
    }

    return phoneNumber;
  }

  static isMobilePhoneBR(phoneNumber) {
    return /^((\+?55\ ?[1-9]{2}\ ?)|(\+?55\ ?\([1-9]{2}\)\ ?)|(0[1-9]{2}\ ?)|(\([1-9]{2}\)\ ?)|([1-9]{2}\ ?))((\d{4}\-?\d{4})|(9[2-9]{1}\d{3}\-?\d{4}))$/.test(
      phoneNumber
    );
  }
}
