import { useState } from "react";
import { Button, Drawer, Radio } from "shared/components";

import styles from "styles/containers/scheduling.module.scss";

import classNames from "classnames/bind";
const css = classNames.bind(styles);

export default function Scheduling({ open, onClose, onSelectScheduling }) {
  const [days, setDays] = useState([
    {
      label: "Hoje",
      value: "05/06/23",
    },
    {
      label: "Amanhã",
      value: "06/06/23",
    },
    {
      label: "Qua",
      value: "07/06/23",
    },
  ]);
  const [selectedDay, setSelectedDay] = useState(days[0].value);
  const [times, setTimes] = useState([
    {
      value: "18:00 - 19:00",
    },
    {
      value: "19:00 - 20:00",
    },
    {
      value: "20:00 - 21:00",
    },
  ]);
  const [selectedTime, setSelectedTime] = useState(null);

  return (
    <Drawer
      open={open}
      alreadyDrawer
      onClose={() => onClose()}
      title="Entrega agendada"
      showCloseButton
      showBackButton
      withoutTransition
      withoutFade
    >
      <div className={styles.days}>
        {days.map((day) => {
          return (
            <div
              className={css({
                day: true,
                selected: selectedDay === day.value,
              })}
              onClick={() => {
                setSelectedDay(day.value);
                selectedDay !== day.value && setSelectedTime(null);
              }}
              key={day.value}
            >
              <p>{day.value}</p>
              <span>{day.label}</span>
            </div>
          );
        })}
      </div>

      <div className={styles.times}>
        {times.map((time) => {
          return (
            <div
              className={styles.time}
              key={time.value}
              onClick={() => setSelectedTime(time.value)}
            >
              <p>{time.value}</p>
              <Radio
                readOnly
                name="radio"
                value={time.value}
                family="time"
                checked={selectedTime}
              />
            </div>
          );
        })}
      </div>

      <div className={styles.footer}>
        <Button
          design="primary"
          onClick={() => onSelectScheduling()}
          disabled={!(selectedDay && selectedTime)}
        >
          Definir agendamento
        </Button>
      </div>
    </Drawer>
  );
}
