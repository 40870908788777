import { ClubAPI } from "api/ClubAPI";
import { Brand } from "./Brand";
import { Customer } from "./Customer";

const key = 'olga-customer';

export class OlgaCustomer {

    static async install(brandAlias = null, phone = null) {
        const customer = await ClubAPI.getCustomerInfosByPhoneAndAlias(
            phone || Customer.phone, 
            brandAlias || Brand.alias
        );

        if(customer.id) {
            localStorage.setItem(`${key}-${brandAlias || Brand.alias}`, JSON.stringify(customer));
        } else {
            localStorage.removeItem(`${key}-${brandAlias || Brand.alias}`);
        }
    }
    
    static getCustomer(brandAlias = null) {
        try {
            const customer = localStorage.getItem(`${key}-${brandAlias || Brand.alias}`);
            return customer ? JSON.parse(customer) : null;
        } catch (err) {
            return null;
        }
    }
    
    static get id() {
        const customer = OlgaCustomer.getCustomer();
        return customer?.id;
    }

    static get name() {
        const customer = OlgaCustomer.getCustomer();
        return customer?.name;
    }
    
    static get cashback() {
        const customer = OlgaCustomer.getCustomer();
        return customer?.cashback;
    }
    
    static get coupons() {
        const customer = OlgaCustomer.getCustomer();
        return customer?.coupons;
    }
}