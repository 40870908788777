import { useEffect, useRef, useState } from "react";

import styles from "@/styles/containers/card-payment.module.scss";

import Image from "next/image";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { Button, Drawer, Input } from "@/shared/components";
import {
  CheckCircle2,
  CreditCard,
  DebitCard,
  Voucher,
} from "@/shared/components/Icons";
import { Payment } from "@/shared/core/Payment";
import { Brand } from "@/shared/core/Brand";

const paymentMethods = {
  CREDIT: "Crédito",
  DEBIT: "Débito",
  VOUCHER: "Voucher",
};

export default function CardPayment({ open, onClose, onPaymentSuccess }) {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [openPaymentSuccess, setOpenPaymentSuccess] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);

  useEffect(() => {
    if (open) setSelectedMethod(null);
  }, [open]);

  const formRef = useRef(null);

  const initialValuesFullAddress = {
    number: "",
    exp: "",
    cvv: "",
    name: "",
    cpfCnpj: "",
  };

  const validationSchemaFullAddress = Yup.object({
    number: Yup.string().required("Campo obrigatório"),
    exp: Yup.string().required("Campo obrigatório"),
    cvv: Yup.string().required("Campo obrigatório").min(3, "CVV inválido"),
    name: Yup.string().required("Campo obrigatório"),
    cpfCnpj: Yup.string().required("Campo obrigatório"),
  });

  const formatHolderName = (holderName) => {
    const newHolderName = holderName.toUpperCase().replace(/[^A-Z\s]/g, "");
    return newHolderName;
  };

  const validateCvv = (event) => {
    const cvv =
      event.target.name === "cvv"
        ? event.target.value
        : formRef.current.values.cvv;
    const number =
      event.target.name === "number"
        ? event.target.value
        : formRef.current.values.number;

    if (cvv.length > 3) {
      if (!Payment.isAmexFromCardNumber(number)) {
        formRef.current.setFieldValue("cvv", String(cvv).substring(0, 3));
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);

    window.onpopstate = () => {
      window.onpopstate = undefined;
      if (!selectedMethod) {
        onClose();
      } else setSelectedMethod(null);
    };
  }, [onClose, selectedMethod]);

  const pay = async () => {
    setProcessingPayment(true);

    setTimeout(() => {
      setOpenPaymentSuccess(true);
    }, 5000);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={selectedMethod || "Pagar pelo cartão"}
      showBackButton
      backButton={selectedMethod ? () => setSelectedMethod(null) : onClose}
    >
      {!selectedMethod && (
        <>
          <div
            className={styles.paymentMethod}
            onClick={() => setSelectedMethod(paymentMethods.CREDIT)}
          >
            <div className={styles.icon}>
              <CreditCard />
            </div>
            <p className={styles.name}>Crédito</p>
          </div>

          <div
            className={styles.paymentMethod}
            onClick={() => setSelectedMethod(paymentMethods.DEBIT)}
          >
            <div className={styles.icon}>
              <DebitCard />
            </div>
            <p className={styles.name}>Débito</p>
          </div>
          <div
            className={styles.paymentMethod}
            onClick={() => setSelectedMethod(paymentMethods.VOUCHER)}
          >
            <div className={styles.icon}>
              <Voucher />
            </div>
            <p className={styles.name}>Voucher</p>
          </div>
        </>
      )}

      {selectedMethod && (
        <>
          <Formik
            innerRef={formRef}
            initialValues={initialValuesFullAddress}
            validationSchema={validationSchemaFullAddress}
            onSubmit={(values, actions) => pay(values, actions)}
          >
            {(props) => (
              <>
                <Form
                  autoComplete="off"
                  onChange={(e) => {
                    if (["cvv", "number"].includes(e.target.name)) {
                      validateCvv(e);
                    }
                  }}
                >
                  <Input
                    design="two"
                    className="mb-20"
                    name="number"
                    label="Número do cartão"
                    mask="9999 9999 9999 9999"
                    type="tel"
                    placeholder="0000 0000 0000 0000"
                  />

                  <div className="d-flex mb-20">
                    <Input
                      design="two"
                      className="w-100"
                      name="exp"
                      label="Data de validade"
                      mask="99/9999"
                      type="tel"
                      placeholder="MM/AAAA"
                    />
                    <Input
                      design="two"
                      className="ml-10 w-100"
                      name="cvv"
                      label="CVV"
                      mask="9999"
                      type="tel"
                    />
                  </div>

                  <Input
                    design="two"
                    className="mb-20"
                    name="name"
                    label="Nome do titular"
                    onChange={(e) => {
                      props.handleChange(e);
                      const name = formatHolderName(e.target.value);
                      props.setFieldValue("name", name);
                    }}
                  />
                  <Input
                    design="two"
                    className="mb-20"
                    name="cpfCnpj"
                    mask="999.999.999-99"
                    label="CPF do titular"
                    type="tel"
                  />

                  <div className={styles.footer}>
                    <Button design="primary" type="submit">
                      Pagar
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>

          <div className={styles.createAccount}>
            <p>Os dados preenchidos não serão salvos.</p>
            <span>Para salvar os dados dos seus cartões, crie sua conta.</span>

            <Button>Crie sua conta</Button>
          </div>
        </>
      )}

      <Drawer open={processingPayment} withoutPadding withoutTransition>
        <div className={styles.processingPayment}>
          <div className={styles.brandHeader}>
            {Brand.avatar && (
              <div className={styles.picture}>
                <Image src={Brand.avatar} alt={Brand.name} layout="fill" />
              </div>
            )}
          </div>

          <div className={styles.statusContainer}>
            <p className={styles.msgOne}>
              Estamos processando seu pagamento...
            </p>
            <p className={styles.msgTwo}>Aguarde um pouco, estamos quase lá!</p>
          </div>

          <span className={styles.loader} />
        </div>
      </Drawer>

      <Drawer open={openPaymentSuccess} alreadyDrawer direction="bottom-center">
        <div className="d-flex justify-center mb-20">
          <CheckCircle2 />
        </div>
        <p className={styles.alertTitle}>
          Sua senha é <span>#502</span>
        </p>
        <p className={styles.alertSubtitle}>
          Pedido realizado com sucesso. Obrigado!
        </p>
        <Button
          design="primary"
          className="w-100"
          onClick={() => {
            setOpenPaymentSuccess(false);
            onPaymentSuccess();
          }}
        >
          Voltar para o início
        </Button>
      </Drawer>
    </Drawer>
  );
}
