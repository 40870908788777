import { useEffect, useCallback } from "react";

import classNames from "classnames/bind";

import { ChevronLeft, Times } from "shared/components/Icons";

import styles from "styles/components/modal.module.scss";

let cx = classNames.bind(styles);

export const Modal = ({
  open,
  title,
  onClose,
  direction = "center",
  closeButton,
  backButton,
  children,
  alreadyModal,
}) => {
  const handleCloseModal = useCallback((status) => onClose(status), [onClose]);

  const handleBackButton = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  useEffect(() => {
    if (open) {
      window.history.pushState(null, null, document.URL);
      window.addEventListener("popstate", handleBackButton, false);
    } else {
      window.removeEventListener("popstate", handleBackButton, false);
    }

    return () => {
      window.removeEventListener("popstate", handleBackButton, false);
    };
  }, [open, handleBackButton]);

  return (
    <div
      className={cx({
        modalContainer: true,
        open: open,
      })}
    >
      <div
        className={cx({
          fade: true,
          withBackground: !alreadyModal,
        })}
        onClick={() => handleCloseModal(!open)}
      />
      <div
        className={cx({
          modal: true,
          [direction]: true,
        })}
      >
        <div
          className={cx({
            container: true,
          })}
        >
          {(closeButton || backButton) && (
            <div className={styles.header}>
              {backButton && (
                <div
                  className={styles.back}
                  onClick={() => handleCloseModal(!open)}
                >
                  <ChevronLeft />
                </div>
              )}
              {title && <p className={styles.title}>{title}</p>}
              {closeButton && (
                <div
                  className={styles.close}
                  onClick={() => handleCloseModal(!open)}
                >
                  <Times color="#BDBDBD" />
                </div>
              )}
            </div>
          )}
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};
