import { default as Axios } from "axios";
import { Customer } from "shared/core/Customer";
import { Brand } from "shared/core/Brand";
import { OlgaRequest } from "./OlgaRequest";
import { Response } from "@konv/http";
import { OlgaCustomer } from "shared/core/OlgaCustomer";
import { Phone } from "shared/tools";

export class ClubAPI {
    static async request({ endpoint = "/", method = "GET", headers = {}, query = {}, data = {}, }) {
        return await OlgaRequest.request({
            service: 'benefits/standard',
            endpoint: endpoint,
            headers,
            method: method || "GET",
            data,
            params: query,
            ignoreVersion: true
        });
    }

    static getCustomerPhone () {
        let phone = Customer.phone;

        if (phone?.length === 11) {
            phone = `55${phone}`;
        }

        return phone || null;
    }

    static async requestAuthenticated({ endpoint = "/", method = "GET", query, data, }) {
        const customerPhone = ClubAPI.getCustomerPhone();
        if (!customerPhone) {
            return new Response(null, 403, ['Usuário não definido!']);
        }

        const keys = Brand.keys;
        if (!(keys?.olga_api_key)) {
            return new Response(null, 403, ['Chave não definida!']);
        }

        const headers = {
            Authorization: "Bearer " + keys?.olga_api_key,
            'x-customer-phone': ClubAPI.getCustomerPhone(),
            'x-customer-id': OlgaCustomer.id
        };

        return await ClubAPI.request({ endpoint, headers, method, query, data });
    }

    static async hasBenefits(brandAlias, customerPhone) {
        const keys = Brand.keysByAlias(brandAlias);

        //Se não houver a chave configurada, retornar false
        const api_key = keys?.olga_api_key || null;
        if (!api_key) {
            return false;
        }

        //Se não houver identificação do cliente, retornar false
        if (!customerPhone) {
            return false;
        }

        const response = await ClubAPI.request({
            endpoint: '/customers/infos',
            headers: {
                Authorization: `Bearer ${api_key}`,
                'x-customer-phone': `55${Phone.unmask(customerPhone)}`
            }
        });

        const data = response.getData({});
        return data?.benefits.cashback.total || data?.benefits.coupons.length
    }

    static async getCustomerInfosByPhoneAndAlias(customerPhone, brandAlias) {
        const keys = Brand.keysByAlias(brandAlias);

        //Se não houver a chave configurada, retornar false
        const api_key = keys?.olga_api_key || null;
        if (!api_key) {
            return false;
        }

        //Se não houver identificação do cliente, retornar false
        if (!customerPhone) {
            return false;
        }

        const response = await ClubAPI.request({
            endpoint: '/customers/infos',
            headers: {
                Authorization: `Bearer ${api_key}`,
                'x-customer-phone': ClubAPI.getCustomerPhone()
            }
        });

        return response.getData({});
    }

    static async getCustomerInfos() {
        const response = await ClubAPI.requestAuthenticated({ endpoint: '/customers/infos' });
        return response.getData(null);
    }
    
    static async getCashbackHistory() {
        const response = await ClubAPI.requestAuthenticated({ endpoint: '/customers/cashback/history' });
        return response.getData([]);
    }

    static async getStandaloneCoupon(couponCode, pinCode) {
        return await ClubAPI.requestAuthenticated({
            endpoint: `/standalone-coupons/code/${couponCode}/pin/${pinCode}/validate`,
        });
    }

    static async postAddStandaloneCoupon(couponCode, pinCode) {
        return await ClubAPI.requestAuthenticated({
            endpoint: `/coupons`,
            method: 'POST',
            data: {
                code: couponCode,
                pin: pinCode
            }
        });
    }

    static async newQrcode(customerCouponId) {
        return await ClubAPI.requestAuthenticated({
            endpoint: `/environments/qrcode`,
            method: 'POST',
            data: {
                customerCouponId
            }
        });
    }

    static async confirmField(customer) {
        return await ClubAPI.requestAuthenticated({
            endpoint: `/customers/sync/confirm`,
            method: 'POST',
            data: {
                ...customer
            }
        });
    }

    static async updateField(fieldAlias, fieldValue, verificationCode) {
        return await ClubAPI.requestAuthenticated({
            endpoint: `/customers/sync`,
            method: 'PUT',
            data: {
                field: fieldAlias,
                value: fieldValue,
                verificationCode
            }
        });
    }

    static async requestVerificationCode(field) {
        return await ClubAPI.requestAuthenticated({
            endpoint: `/customers/verification-code`,
            method: 'POST',
            data: { field }
        });
    }

    static async validateVerificationCode(field, verificationCode) {
        return await ClubAPI.requestAuthenticated({
            endpoint: `/customers/verification-code/validate`,
            method: 'POST',
            data: {
                field,
                verificationCode
            }
        });
    }


}
