import styles from "styles/components/message.module.scss";

import {
  InfoIcon,
  CautionIcon,
  ErrorIcon,
  SuccessIcon,
} from "shared/components/Icons";

import classNames from "classnames/bind";

export const Message = ({ type = "info", text, className }) => {
  const cx = classNames.bind(styles);

  const Icons = {
    info: <InfoIcon />,
    error: <ErrorIcon />,
    caution: <CautionIcon />,
    success: <SuccessIcon />,
  };

  return (
    <div
      className={cx({
        messageContainer: true,
        [styles[type]]: true,
        [className]: true,
      })}
    >
      {Icons[type]}
      <span className={styles.text}>{text}</span>
    </div>
  );
};
