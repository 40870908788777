import { ChevronRight, Gift } from "shared/components/Icons";
import { Benefit } from "shared/tools";
import styles from "styles/containers/cart.module.scss";
import classNames from "classnames/bind";
import {  Currency } from "shared/tools";

const cx = classNames.bind(styles);

export const CartBenefit = ({ selectedBenefit, onClick,order }) => {
    return (
        <div
          className={styles.benefitContainer}
          onClick={onClick}
        >
          <p className={styles.title}>Benefícios</p>
  
          <div className="d-flex align-center justify-between">
            <div className="d-flex align-center">
              <div
                className={cx({
                  gift: true,
                  applied: selectedBenefit && selectedBenefit.type,
                })}
              >
                <div className={styles.icon}>
                  <Gift color="#555" />
                </div>
               
              </div>
               
              <p
                className={cx({
                  benefitApplied: true,
                  applied: selectedBenefit && selectedBenefit.type,
                })}
              >
                {!selectedBenefit || !selectedBenefit.type
                  ? "Aplique um benefício"
                  : `${Benefit.getTitle(selectedBenefit)} (${(Currency.formatCurrency(selectedBenefit.amount || 0))})`}
              </p>
            </div>
            {/* {Currency.formatCurrency(order.total || 0)} */}
            <ChevronRight color="#3D734A" />
          </div>
        </div>
      );
}