export class BrazilStates {
  static UF = [
    { name: "Acre", shortName: "AC" },
    { name: "Alagoas", shortName: "AL" },
    { name: "Amapá", shortName: "AP" },
    { name: "Amazonas", shortName: "AM" },
    { name: "Bahia", shortName: "BA" },
    { name: "Ceará", shortName: "CE" },
    { name: "Distrito Federal", shortName: "DF" },
    { name: "Espírito Santo", shortName: "ES" },
    { name: "Goiás", shortName: "GO" },
    { name: "Maranhão", shortName: "MA" },
    { name: "Mato Grosso", shortName: "MT" },
    { name: "Mato Grosso do Sul", shortName: "MS" },
    { name: "Minas Gerais", shortName: "MG" },
    { name: "Pará", shortName: "PA" },
    { name: "Paraíba", shortName: "PB" },
    { name: "Paraná", shortName: "PR" },
    { name: "Pernambuco", shortName: "PE" },
    { name: "Piauí", shortName: "PI" },
    { name: "Rio de Janeiro", shortName: "RJ" },
    { name: "Rio Grande do Norte", shortName: "RN" },
    { name: "Rio Grande do Sul", shortName: "RS" },
    { name: "Rondônia", shortName: "RO" },
    { name: "Roraima", shortName: "RR" },
    { name: "Santa Catarina", shortName: "SC" },
    { name: "São Paulo", shortName: "SP" },
    { name: "Sergipe", shortName: "SE" },
    { name: "Tocantins", shortName: "TO" },
  ];

  static getShortNameByName(name) {
    for (var x of BrazilStates.UF) {
      if (x.name == name) {
        return x.shortName;
      }
    }
    return null;
  }

  static getNameByShortName(shortName) {
    for (var x of BrazilStates.UF) {
      if (x.shortName == shortName) {
        return x.shortName;
      }
    }
    return null;
  }
}
