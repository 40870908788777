import { useState, useEffect } from "react";
import { useRouter } from "next/router";

import styles from "@/styles/containers/command.module.scss";

import { Gift, Money, Waiter, TableRegular } from "@/shared/components/Icons";

import { Currency } from "@/shared/tools";
import { Button, Drawer, Loading } from "@/shared/components";
import { Brand } from "@/shared/core/Brand";
import { Store } from "@/shared/core/Store";
import { Table as TableCore } from "@/shared/core/Table";

import { OpenTableAPI } from "@/api/OpenTableAPI";
import { PreCloseTable } from "./PreCloseTable";
import CloseTable from "./CloseTable";
import EcleticaCart from "../Cart/EcleticaCart";

export default function Table({ onClose }) {
  const [openConfirmClose, setOpenConfirmClose] = useState(false);
  const [openCloseTable, setOpenCloseTable] = useState(false);
  const [closeDesk, setCloseDesk] = useState(false);
  const [closeWaiter, setCloseWaiter] = useState(false);
  
  const [loading, setLoading] = useState(true);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);

  const [table, setTable] = useState(null);
  const [tableStatus, setTableStatus] = useState(null);
  const [tableItems, setTableItems] = useState([]);

  const router = useRouter();

  useEffect(() => {
    setSelectedEnvironment(Brand.alias ? Brand.alias : null);
  }, []);

  const hasServiceAmount =
    table?.total?.vlr_servico && +table?.total.vlr_servico > 0 ? true : false;

  const environmentId = Store.id;
  const tableId = TableCore.id;

  const loadTable = async () => {
    const tableStatus = await OpenTableAPI.getTableStatus(
      environmentId,
      tableId
    );

    
    let tableItems = [];

    if (tableStatus === "Fechando") {
      const table  = await OpenTableAPI.getTable(environmentId, tableId);
      tableItems = table?.produtos;

      setTable(table);
    } else {
      tableItems = await OpenTableAPI.getTableItems(
        environmentId,
        tableId
      );
    }

    setTableItems(tableItems || []);
    setTableStatus(tableStatus)
  };

  const onLoad = async () => {
    setLoading(true);
    await loadTable();
    setLoading(false);
  };

  const goToCatalog = () => {
    const newPath = selectedEnvironment
      ? `/${selectedEnvironment}/cardapio`
      : "/cardapio";
    router.push(newPath);
  };

  useEffect(() => {
    onLoad();
  }, []);

  let menuMobileHeight;
  let heightScrollContainer;

  if (window !== undefined) {
    const menuMobile = document.getElementById("menuMobile");

    if (menuMobile) {
      menuMobileHeight = menuMobile.offsetHeight;
      heightScrollContainer = menuMobileHeight + 170;
    }
  }

  return (
    <div
      className={styles.tableScrollContainer}
      style={{ height: `calc(100vh - ${heightScrollContainer}px)` }}
    >
      {loading && (
        <div className={styles.loading}>
          <Loading fullScreen />
        </div>
      )}

      {!loading && tableItems.length === 0 && (
        <div className={styles.empty}>
          <div className={styles.icon}>
            <TableRegular />
          </div>

          <p>Não há pedidos na sua mesa.</p>
          <span>
            O que acha de ver o nosso cardápio e experimentar uma das nossas
            opções?
          </span>

          <Button design="primary" onClick={() => goToCatalog()}>
            Ver cardápio
          </Button>
        </div>
      )}

      {!loading && tableItems.length > 0 && (
        <>
          {closeDesk && (
            <div className={styles.statusContainer}>
              <div className="d-flex flex-col flex-1">
                <p className={styles.title}>Se dirija ao balcão</p>
                <span className={styles.subtitle}>
                  Seu fechamento foi iniciado e o pagamento está sendo aguardado
                  no balcão
                </span>
              </div>
              <Money />
            </div>
          )}

          {closeWaiter && (
            <div className={styles.statusContainer}>
              <div className="d-flex flex-col">
                <div className="d-flex align-center justify-between">
                  <div className="d-flex flex-col flex-1">
                    <p className={styles.title}>Aguarde alguns instantes</p>
                    <span className={styles.subtitle}>
                      Seu fechamento foi iniciado e o garçom irá atender a sua
                      mesa em breve.
                    </span>
                  </div>
                  <Waiter />
                </div>

                {/* <div className={styles.openQrCode}>
                  <Button design="default-white">Abrir QR Code</Button>
                </div> */}
              </div>
            </div>
          )}

          {(tableStatus && tableStatus != "EmUso") && (
            <div className={styles.totalsContainer}>
              <ul>
                <li>
                  <p>Valor total inicial</p>
                  <span>
                    {Currency.formatCurrency(table.total.vlr_total_produtos)}
                  </span>
                </li>

                {hasServiceAmount === true && (
                  <li>
                    <p>
                      Valor serviço{" "}
                      {table.servico_obrigatorio != "S" ? "(Opcional)" : ""}{" "}
                    </p>
                    <span>
                      {Currency.formatCurrency(+table.total.vlr_servico)}
                    </span>
                  </li>
                )}

                {/* <li>
                  <p className={styles.benefit}>Benefício aplicado</p>
                  <span className={styles.benefit}>
                    {Currency.formatCurrency(133)}
                  </span>
                </li> */}
                <li>
                  <p>Total</p>
                  <span className={styles.total}>
                    {Currency.formatCurrency(table.total_conta)}
                  </span>
                </li>
              </ul>

              {/* <div className={styles.gift}>
                  <div className={styles.icon}>
                    <Gift />
                  </div>
                  <p>
                    Cupom de <strong>{Currency.formatCurrency(15)}</strong>{" "}
                    aplicado
                  </p>
                </div> */}
            </div>
          )}

          <EcleticaCart items={tableItems} onClose={() => onClose && onClose()} />

          <div
            className={styles.footer}
            style={{ bottom: `${menuMobileHeight}px` }}
          >
            {tableStatus !== "Fechando" && (
              <Button
                design="primary"
                disabled={loading}
                onClick={() => setOpenConfirmClose(true)}
              >
                Fechar Mesa
              </Button>
            )}
          </div>
        </>
      )}

      <PreCloseTable
        open={openConfirmClose}
        onPreClose={() => {
          setOpenConfirmClose(false);
          setOpenCloseTable(true);
        }}
        onClose={() => setOpenConfirmClose(false)}
      />
      <CloseTable
        open={openCloseTable}
        onClose={() => setOpenCloseTable(false)}
        onCloseDesk={() => {
          setOpenCloseTable(false);
          setCloseDesk(true);
          onLoad();
        }}
        onCloseWaiter={() => {
          setOpenCloseTable(false);
          setCloseWaiter(true);
          onLoad();
        }}
      />
    </div>
  );
}
