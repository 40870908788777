import { ErrorMessage } from "formik";
import classNames from "classnames/bind";
import CurrencyInput from "react-currency-input";
import styles from "styles/components/input.module.scss";


export const MoneyInput = ({
    type,
    placeholder = " ",
    onBlur,
    onFocus,
    onChange,
    autoFocus,
    label,
    mask,
    floatLabel,
    autoComplete,
    maxLength,
    className,
    search,
    design = "one",
    isInvalid,
    defaultValue,
    value,
    ...props
}) => {
    const cx = classNames.bind(styles);
    const { name } = props;
    const id = props.id || name;
    
    const additionalProps = {};
    if (onChange) {
        additionalProps.onChange = onChange;
    }

    additionalProps.prefix = props.prefix
    additionalProps.thousandSeparator = props.thousandSeparator
    additionalProps.decimalSeparator = props.decimalSeparator

    return (
        <div
            className={cx({
                inputContainer: true,
                [className]: className,
            })}
            spellCheck="false"
        >
            {label && (
                <label className={styles.label} htmlFor={name}>
                    {label}
                </label>
            )}
            <div className="relative">
                <CurrencyInput
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoFocus={autoFocus}
                    maxLength={maxLength}
                    defaultValue={defaultValue}
                    value={value}
                    autoComplete="off"
                    className={cx({
                        invalid: isInvalid,
                        [`design-${design}`]: true,
                    })}
                    {...additionalProps}
                />
                {floatLabel && (
                    <label className={`${styles.float} ${isInvalid ? styles.invalid : ""}`}>
                        {floatLabel}
                    </label>
                )}
            </div>
        </div>
    );
};
