import { OpenTableAPI } from "@/api/OpenTableAPI";
import { BaseStorage } from "@/storage/BaseStorage";
import { Holding } from "./Holding";
import { Brand } from "./Brand";
import { Store } from "./Store";

const tableKey = "of-table";

export class Table {
  static table = null;

  static async install({ id, code, externalId, name, brandAlias, storeAlias }) {
    const baseStorage = new BaseStorage({ key: tableKey });
    baseStorage.setData({
      id,
      code,
      externalId,
      name,
      brandAlias,
      storeAlias,
    });
  }

  static async loadQrCode(code) {
    const tableInfosReq = await OpenTableAPI.getTableByCode(code);
    const tableInfos = tableInfosReq.getData({});

    const {
      id,
      name,
      external_id: externalId,
      unitAlias,
      brandAlias,
      holdingAlias,
    } = tableInfos;

    if (
      Holding.alias &&
      Holding.alias !== "null" &&
      Holding.alias !== holdingAlias
    ) {
      throw new Error("A mesa lida não tem cadastro neste site");
    }

    if (Brand.alias && Brand.alias !== "null" && Brand.alias !== brandAlias) {
      throw new Error("A mesa lida não tem cadastro nesta marca");
    }

    await Table.install({
      id,
      code,
      externalId,
      name,
      brandAlias,
      storeAlias: unitAlias,
    });
  }

  static getTable() {
    try {
      const baseStorage = new BaseStorage({ key: tableKey });
      const table = baseStorage.getData();
      return table;
    } catch (err) {
      return null;
    }
  }

  static get id() {
    const table = Table.getTable() || {};
    return table?.id || null;
  }

  static get code() {
    const table = Table.getTable() || {};
    return table?.code || null;
  }

  static get externalId() {
    const table = Table.getTable() || {};
    return table?.externalId || null;
  }

  static get name() {
    const table = Table.getTable() || {};
    return table?.name || null;
  }

  static get brandAlias() {
    const table = Table.getTable() || {};
    return table?.brandAlias || null;
  }

  static get storeAlias() {
    const table = Table.getTable() || {};
    return table?.storeAlias || null;
  }

  static get isDigitalMenu() {
    const CARDAPIO_DIGITAL_CODE = "636172646170696F2D6469676974616C";
    return CARDAPIO_DIGITAL_CODE;
  }
}
