import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import styles from "styles/containers/home.module.scss";

import { Button, Drawer } from "@/shared/components";
import { ForkKnife, HomeLight, Table } from "@/shared/components/Icons";

import { Brand } from "@/shared/core/Brand";
import { OrderStorage } from "@/storage/OrderStorage";

import classNames from "classnames/bind";
import { DataLayer } from "@/shared/tools/DataLayer";
const css = classNames.bind(styles);

const ReceiveOnOptions = {
  ORDER_AHEAD: "ORDER_AHEAD",
  TAKE_AWAY: "TAKE_AWAY",
  OPEN_TABLE: "OPEN_TABLE",
};

const Steps = {
  RECEIVE_OPTION_CHOOSE: 1,
  BRAND_CHOOSE: 2,
  LOCATION: 3,
  SET_CITY: 4,
  UNIT_CHOOSE: 5,
  SCAN_QRCODE: 6,
};

const names = {
  [ReceiveOnOptions.ORDER_AHEAD]: "Comer no local",
  [ReceiveOnOptions.TAKE_AWAY]: "Levar para casa",
  [ReceiveOnOptions.OPEN_TABLE]: "Pedir na mesa",
};

export default function ReceiveOn({
  selectedReceiveOn,
  onSelectReceiveOn,
  onChangeStep,
  inCatalog,
}) {
  const brand = Brand.getBrand();
  const [orderFlow, setOrderFlow] = useState(null);

  const router = useRouter();

  useEffect(() => {
    setOrderFlow(OrderStorage.flow);
  }, []);

  const [openClearCart, setOpenClearCart] = useState(false);

  const ReceiveOnName = () => {
    return names[selectedReceiveOn];
  };

  useEffect(() => {
    DataLayer.push({
      event: "page_view",
      event_title: names[selectedReceiveOn],
    });
  }, [selectedReceiveOn]);

  const changeStep = () => {
    if (selectedReceiveOn === ReceiveOnOptions.OPEN_TABLE)
      return onChangeStep(Steps.SCAN_QRCODE);

    if (brand?.alias) return onChangeStep(Steps.LOCATION);

    if (
      [ReceiveOnOptions.ORDER_AHEAD, ReceiveOnOptions.TAKE_AWAY].includes(
        selectedReceiveOn
      )
    )
      return onChangeStep(Steps.BRAND_CHOOSE);
  };

  const clearCart = () => {
    OrderStorage.clearCartItems();
    OrderStorage.setFlow(null);
    setOrderFlow(null);
  };

  return (
    <div className={styles.receiveOnOptions}>
      {/* <div
        className={css({
          option: true,
          selected: selectedReceiveOn === ReceiveOnOptions.ORDER_AHEAD,
        })}
        onClick={() => onSelectReceiveOn(ReceiveOnOptions.ORDER_AHEAD)}
      >
        <div className={styles.icon}>
          <ForkKnife />
        </div>
        <p className={styles.name}>Comer no local</p>
        {orderFlow && orderFlow === ReceiveOnOptions.ORDER_AHEAD && (
          <div className={styles.inUse}>Em uso</div>
        )}
      </div>
      <div
        className={css({
          option: true,
          selected: selectedReceiveOn === ReceiveOnOptions.TAKE_AWAY,
        })}
        onClick={() => onSelectReceiveOn(ReceiveOnOptions.TAKE_AWAY)}
      >
        <div className={styles.icon}>
          <HomeLight />
        </div>
        <p className={styles.name}>Levar para casa</p>
        {orderFlow && orderFlow === ReceiveOnOptions.TAKE_AWAY && (
          <div className={styles.inUse}>Em uso</div>
        )}
      </div> */}
      <div
        className={css({
          option: true,
          selected: selectedReceiveOn === ReceiveOnOptions.OPEN_TABLE,
        })}
        onClick={() => onSelectReceiveOn(ReceiveOnOptions.OPEN_TABLE)}
      >
        <div className={styles.icon}>
          <Table />
        </div>
        <p className={styles.name}>Pedir na mesa</p>
        {orderFlow && orderFlow === ReceiveOnOptions.OPEN_TABLE && (
          <div className={styles.inUse}>Em uso</div>
        )}
      </div>

      {selectedReceiveOn ? (
        <div className={styles.footer}>
          <Button design="primary" onClick={changeStep}>
            Avançar
          </Button>
        </div>
      ) : null}

      {inCatalog && orderFlow ? (
        <div className={styles.footer}>
          <Button
            design="primary"
            onClick={() => setOpenClearCart(true)}
            disabled={!selectedReceiveOn || orderFlow === selectedReceiveOn}
          >
            Confirmar
          </Button>
          <div className="d-flex justify-center mt-20 mb-10">
            <Button
              onClick={() => {
                clearCart();
                router.push("/");
              }}
            >
              Ir para home
            </Button>
          </div>
        </div>
      ) : null}

      <Drawer
        open={openClearCart}
        alreadyDrawer
        onClose={(status) => setOpenClearCart(status)}
        direction="bottom"
      >
        <p className={styles.alertTitle}>
          Você tem certeza que deseja alterar para {`"${ReceiveOnName()}"`}?
        </p>
        <p className={styles.alertSubtitle}>O seu pedido será perdido.</p>
        <Button
          design="primary"
          className="w-100 mb-10"
          onClick={() => setOpenClearCart(false)}
        >
          Não
        </Button>
        <Button
          design="secondary"
          className="w-100"
          onClick={() => {
            clearCart();
            setOpenClearCart(false);
          }}
        >
          Cancelar pedido
        </Button>
      </Drawer>
    </div>
  );
}
