import { useEffect, useState } from "react";

import Image from "next/image";

import styles from "styles/containers/login-or-register.module.scss";

import { Button, Drawer } from "shared/components";

import Login from "shared/containers/Account/Login";
import Register from "shared/containers/Account/Register";
import RecoverPassword from "shared/containers/Account/RecoverPassword";

import { Times } from "shared/components/Icons";
import { Store } from "shared/core/Store";
import { Brand } from "shared/core/Brand";
import { Holding } from "shared/core/Holding";
import { DataLayer } from "@/shared/tools/DataLayer";

const Steps = {
  LOGIN_OR_REGISTER: "LOGIN_OR_REGISTER",
  LOGIN: "LOGIN",
  REGISTER_USER_DATA: "REGISTER_USER_DATA",
  REGISTER_LOSE_BENEFITS: "REGISTER_LOSE_BENEFITS",
  RECOVER_PASSWORD: "RECOVER_PASSWORD",
};

const titles = {
  [Steps.LOGIN_OR_REGISTER]: "Entrar ou cadastrar-se",
  [Steps.LOGIN]: "Entrar",
  [Steps.REGISTER_USER_DATA]: "Cadastre-se",
  [Steps.REGISTER_LOSE_BENEFITS]: "E-mails e SMS’s promocionais",
  [Steps.RECOVER_PASSWORD]: "Redefina sua senha",
};

export default function LoginOrRegister({
  open,
  onClose,
  setIsLogged,
  alreadyDrawer,
}) {
  const [step, setStep] = useState(Steps.LOGIN_OR_REGISTER);

  const getTitle = () => {
    if (step === Steps.LOGIN_OR_REGISTER) return;

    return titles[step];
  };

  useEffect(() => {
    if (open) {
      DataLayer.push({
        event: "page_view",
        page_title: titles[step],
      });
    }
  }, [open, step]);

  const handleBack = () => {
    switch (step) {
      case Steps.LOGIN:
        return setStep(Steps.LOGIN_OR_REGISTER);
      case Steps.RECOVER_PASSWORD:
        return setStep(Steps.LOGIN);
      case Steps.REGISTER_LOSE_BENEFITS:
        return setStep(Steps.REGISTER_USER_DATA);
      case Steps.REGISTER_USER_DATA:
        return setStep(Steps.LOGIN_OR_REGISTER);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
        setStep(Steps.LOGIN_OR_REGISTER);
      }}
      title={getTitle()}
      showBackButton={step !== Steps.LOGIN_OR_REGISTER}
      backButton={() => handleBack()}
      showCloseButton={step === Steps.LOGIN_OR_REGISTER}
      alreadyDrawer={alreadyDrawer}
    >
      {[Steps.LOGIN_OR_REGISTER].includes(step) && (
        <>
          <div className={styles.loginOrRegister}>
            {Brand.avatar && (
              <div className={styles.storeLogo}>
                <Image src={Brand.avatar} alt="" layout="fill" sizes="100vw" />
              </div>
            )}

            <p className={styles.storeName}>{Brand.name || Holding.name}</p>

            <Button
              design="primary"
              className="mb-10"
              onClick={() => setStep(Steps.REGISTER_USER_DATA)}
            >
              Cadastre-se
            </Button>
            <Button design="secondary" onClick={() => setStep(Steps.LOGIN)}>
              Entrar
            </Button>
          </div>
        </>
      )}

      {[Steps.LOGIN].includes(step) && (
        <div className={styles.scrollContainer}>
          <Login
            setStep={(step) => setStep(step)}
            setIsLogged={(status) => {
              setStep(Steps.LOGIN_OR_REGISTER);
              setIsLogged(status);
            }}
          />
        </div>
      )}

      {[Steps.REGISTER_USER_DATA, Steps.REGISTER_LOSE_BENEFITS].includes(
        step
      ) && (
        <div className={styles.scrollContainer}>
          <Register
            step={step}
            setStep={(step) => setStep(step)}
            onClose={(status) => onClose(status)}
            setIsLogged={(status) => {
              setStep(Steps.LOGIN_OR_REGISTER);
              setIsLogged(status);
            }}
          />
        </div>
      )}

      {[Steps.RECOVER_PASSWORD].includes(step) && (
        <div className={styles.scrollContainer}>
          <RecoverPassword onClose={() => setStep(Steps.LOGIN)} />
        </div>
      )}
    </Drawer>
  );
}
