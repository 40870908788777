import React, { Fragment } from "react";
import styles from "styles/containers/payment-methods.module.scss";
import classNames from "classnames/bind";
import { Store } from "shared/core/Store";
import { PaymentGroup } from "shared/tools/PaymentGroup";
import { PaymentMethod } from "@olga-food/schemas/lib/classes/schemas/order/payment/Payment";
import { PaymentStorage } from "storage/PaymentStorage";
import { Payment, PaymentOrigin } from "shared/core/Payment";
import { ChangeForDrawer } from "./OfflinePayment/ChangeForDrawer";

export class OfflinePayments extends React.Component {
	state = {
		visibleChangeFor: false,
		groups: []
	}

	componentDidMount() {
		const methods = Store.payment_methods;
		const flagsItemMethods = (Object.keys(methods)||[]).length>0 ? methods : []
		this.setState({
			groups: [
				new PaymentGroup("Dinheiro", "MONEY", PaymentMethod.MONEY | PaymentMethod.FISICO, [], flagsItemMethods),
				new PaymentGroup("Crédito", "CREDIT", PaymentMethod.CREDIT | PaymentMethod.FISICO, [], flagsItemMethods),
				new PaymentGroup("Débito", "DEBIT", PaymentMethod.DEBIT | PaymentMethod.FISICO, [], flagsItemMethods),
				new PaymentGroup("Voucher Refeição", "MEAL_VOUCHER", PaymentMethod.MEAL | PaymentMethod.VOUCHER | PaymentMethod.FISICO, [], flagsItemMethods),
				new PaymentGroup("Voucher Alimentação", "FOOD_VOUCHER", PaymentMethod.FOOD | PaymentMethod.VOUCHER | PaymentMethod.FISICO, [], flagsItemMethods),
			]
		})
	}

	onSetChangeFor(changeFor) {
		const { onClose } = this.props;
		PaymentStorage.unselectAllCards();
		Payment.currentOrigin = PaymentOrigin.OFFLINE;
		Payment.offlinePayment = {
			type: PaymentMethod.MONEY | PaymentMethod.FISICO,
			alias: 'DINHEIRO',
			change_for: changeFor
		}

		onClose && onClose();
	}

	onSelectMethod(groupType, methodAlias) {
		const { onClose } = this.props;
		// if(PaymentGroup.typeIs(PaymentMethod.MONEY & groupType) && PaymentGroup.typeIs(PaymentMethod.FISICO & groupType)) {
		if(false && PaymentGroup.typeIs(groupType, PaymentMethod.MONEY | PaymentMethod.FISICO)) {
			this.setState({ visibleChangeFor: true });
			return;
		}

		PaymentStorage.unselectAllCards();
		Payment.currentOrigin = PaymentOrigin.OFFLINE;
		Payment.offlinePayment = {
			type: groupType,
			alias: methodAlias,
			change_for: null
		}

		onClose && onClose();
	}

	render() {
		const { groups, visibleChangeFor } = this.state;
		return (
			<div className={styles.offlinePayments}>
				{groups.map((group, index) => {

					if (group.flags.length == 0) {
						return null;
					}

					return (
						<Fragment key={index}>
							<p className={styles.title}>{group.name}</p>
							{group.flags.map((flag, index) => {
								return (
									<div className={styles.paymentCard} key={index} onClick={() => this.onSelectMethod(group.type, flag.alias)}>
										<div className="d-flex align-center">
											<img src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/cards/${flag.alias}.png`}
												onError={({ currentTarget }) => {
													currentTarget.onerror = null; // prevents looping
													currentTarget.src = `${process.env.NEXT_PUBLIC_CDN_ASSETS}/cards/DEFAULT.png`
												}}
												className={styles.icon}
												alt={flag.alias}
											/>
											<p className={styles.alias}>{flag.name}</p>
										</div>
									</div>
								);
							})}
						</Fragment>
					);
				})}
				<ChangeForDrawer
					opened={visibleChangeFor}
					onChangeFor={(value) => this.onSetChangeFor(value)}
					onClose={(value) => this.onSetChangeFor(value)}
				/>
			</div>
		)
	}
}
