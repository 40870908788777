import JWT from "jsonwebtoken";

import { Request } from "@konv/http";
export class CustomerAPI {
  static async request({
    endpoint = "/",
    method = "GET",
    headers = {},
    query = {},
    data = {},
  }) {
    return await Request.request({
      endpoint: endpoint,
      cors: true,
      method: method || "GET",
      headers: headers || {},
      service: "customers",
      ignoreVersion: true,
      params: query || {},
      data: data || {},
    });
  }

  static async requestAuthenticated({
    endpoint = "/",
    method = "GET",
    query,
    data,
  }) {
    return await CustomerAPI.request({
      endpoint,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      method,
      query,
      data,
    });
  }

  static async createCustomer(customerData) {
    return await CustomerAPI.request({
      endpoint: "/",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("customerApiKey"),
      },
      method: "POST",
      data: customerData,
    });
  }

  static getCustomerId() {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const { user_id } = JWT.decode(accessToken);
      return user_id;
    } catch {
      return null;
    }
  }

  static async getMe() {
    const customerId = CustomerAPI.getCustomerId();
    const endpoint = `/${customerId}`;
    return await CustomerAPI.requestAuthenticated({
      endpoint,
      method: "GET",
    });
  }

  static async getAddresses() {
    const customerResponse = await CustomerAPI.getMe();

    if (customerResponse.getErrors([]).length > 0) {
      throw new Error(customerResponse.getErrors()[0]);
    }

    const customer = customerResponse.getData();
    const addresses =
      customer && customer.extras && customer.extras.addresses
        ? customer.extras.addresses
        : [];
    return addresses;
  }

  static async updateAddresses(addresses) {
    const customerResponse = await CustomerAPI.getMe();

    if (customerResponse.getErrors([]).length > 0) {
      throw new Error(customerResponse.getErrors()[0]);
    }

    const customer = customerResponse.getData();
    const customerId = customer.id;

    const extraFields = [
      "id",
      "environment_id",
      "password",
      "created_at",
      "created_by",
      "updated_at",
      "updated_by",
      "deleted_at",
      "deleted_by",
    ];
    for (let field of extraFields) {
      delete customer[field];
    }

    if (!customer.facebook_id) {
      customer.facebook_id = null;
    }

    if (!customer.google_id) {
      customer.google_id = null;
    }

    const newCustomer = {
      ...customer,
      extras: {
        ...customer.extras,
        addresses: addresses,
      },
    };

    const endpoint = `/${customerId}`;
    return await CustomerAPI.requestAuthenticated({
      endpoint,
      method: "PUT",
      data: newCustomer,
    });
  }

  static async mergeAddresses(newAddresses) {
    let currentAddresses = await CustomerAPI.getAddresses();
    currentAddresses = currentAddresses.map((address) => {
      address.isLastSelected = false;
      return address;
    });
    const currentAddressIds = currentAddresses.map((address) => address.id);

    const mergedAddresses = [...currentAddresses];

    for (let address of newAddresses) {
      if (!currentAddressIds.includes(address.id)) {
        mergedAddresses.unshift(address);
      }
    }

    if (mergedAddresses.length !== currentAddresses.length) {
      await CustomerAPI.updateAddresses(mergedAddresses);
    }

    return mergedAddresses;
  }

  static async updateCustomer(customerId, newCustomer) {
    const endpoint = `/${customerId}`;
    return await CustomerAPI.requestAuthenticated({
      endpoint,
      method: "PUT",
      data: newCustomer,
    });
  }

  static async forgotPassword(email, brandAlias) {
    return await CustomerAPI.request({
      endpoint: "/forgot-password",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("customerApiKey"),
      },
      method: "POST",
      data: { email, brandAlias },
    });
  }

  static async recoverPassword(email, code, password) {
    return await CustomerAPI.request({
      endpoint: "/recover-password",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("customerApiKey"),
      },
      method: "POST",
      data: { email, code, password },
    });
  }

  static async deleteCustomer(password) {
    const customerId = CustomerAPI.getCustomerId();
    return await CustomerAPI.requestAuthenticated({
      endpoint: `/${customerId}`,
      method: "DELETE",
      data: { password }
    });
  }

}
