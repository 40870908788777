export class BaseStorage {
  constructor({ key }) {
    this.key = key;
  }

  getKey() {
    return this.key;
  }

  getData() {
    const unparsedData = localStorage.getItem(this.getKey());
    let parsedData = null;
    try {
      parsedData = JSON.parse(unparsedData);
    } catch {
      parsedData = null;
    }

    return parsedData;
  }

  setData(data) {
    const unparsedData = JSON.stringify(data);
    localStorage.setItem(this.getKey(), unparsedData);
    if (window) {
      window.dispatchEvent(
        new CustomEvent("storageChanged", {
          detail: { key: this.getKey(), data },
        })
      );
    }
  }

  static getItem(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch {
      return null;
    }
  }
}
