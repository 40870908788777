import { createRef, useEffect, useState } from "react";
import { Button, Drawer, Input } from "shared/components"
import { MoneyInput } from "shared/components/SimpleForm/CurrencyInput";
import { Currency } from "shared/tools";
import { OrderStorage } from "storage/OrderStorage";
import styles from "styles/containers/cart.module.scss";

export const ChangeForDrawer = ({ opened, onChangeFor }) => {
    const order = OrderStorage.getOrder();
    const [isValid, setIsValid] = useState(true);
    const [changeFor, setChangeFor] = useState(order.total || 0);

    useEffect(() => {
        const fieldValue = +(changeFor) || 0;
        if (fieldValue < order.total) {
            isValid && setIsValid(false);
        } else {
            !isValid && setIsValid(true);
        }
    });


    const onSubmit = () => {
        if (isValid) {
            onChangeFor && onChangeFor(changeFor)
        }
    }

    const onCancel = () => {
        onChangeFor && onChangeFor(order.total)
    }

    return (
      <Drawer open={opened} title="Precisa de troco?" direction="bottom">
        <div className="d-flex flex-col flex-1">
          <span className={styles.alertSubtitle}>
            O valor total do seu pedido é R${" "}
            <strong>{Currency.formatCurrency(order.total || 0)}</strong>. Digite
            quanto vai pagar em dinheiro para que o entregador leve o seu troco:
          </span>
          <MoneyInput
            name="change_for"
            floatLabel="Troco para"
            prefix="R$ "
            thousandSeparator="."
            decimalSeparator=","
            currency={true}
            id={"money-input"}
            value={changeFor}
            onChange={(masked, raw) => {
              setChangeFor(raw);
            }}
          />
        </div>
        <div className="d-flex flex-col align-center">
          <Button
            design="primary"
            disabled={!isValid}
            className="w-100 flex-1 mt-20"
            onClick={onSubmit}
          >
            Salvar
          </Button>
          <Button
            design="default"
            className="flex-1 mt-20 center"
            onClick={onCancel}
          >
            Não, obrigado
          </Button>
        </div>
      </Drawer>
    )
}