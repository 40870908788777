import styles from "styles/components/input-search.module.scss";

import classNames from "classnames/bind";

import { Search } from "shared/components/Icons";

export const InputSearch = ({
  type,
  name,
  placeholder,
  onBlur,
  onFocus,
  onChange,
  autoFocus,
  className,
}) => {
  const cx = classNames.bind(styles);

  return (
    <div
      className={cx({
        inputSearchContainer: true,
        [className]: className,
      })}
      spellCheck="false"
    >
      <div className="relative">
        <span className={styles["search-icon"]}>
          <Search />
        </span>

        <input
          id={name}
          type={type}
          name={name}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={autoFocus}
          onChange={onChange}
          className={cx({
            search: true,
          })}
        />
      </div>
    </div>
  );
};
