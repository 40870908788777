import styles from "styles/components/checkbox.module.scss";

import classNames from "classnames/bind";

import { Check } from "shared/components/Icons";

export const Checkbox = ({
  checked,
  name,
  onChange,
  children,
  className,
  onClick,
}) => {
  const cx = classNames.bind(styles);

  return (
    <div
      className={cx({
        checkboxContainer: true,
        [className]: className,
        inactive: !onChange,
      })}
    >
      <label htmlFor={name}>
        <input
          id={name}
          type="checkbox"
          checked={checked}
          onClick={() => onClick && onClick(!checked)}
          onChange={() => onChange && onChange(!checked)}
        />
        <div
          className={cx({
            checkbox: true,
          })}
        >
          {checked && <Check color="#fff" size="14" />}
        </div>
        {children && <div className={styles.content}>{children}</div>}
      </label>
    </div>
  );
};
