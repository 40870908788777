import { DeliveryType } from "@olga-food/schemas/lib/classes/schemas/order/delivery/Delivery";
import { OrderStorage } from "storage/OrderStorage";
import { Currency } from "./Currency";

const GIFT_TYPE = {
	// ===== Valores adicioniais =====
	ADDITION: 1,

	// ===== Forma de cobrança =====
	PRICE: 2,
	PERCENT: 4,

	// ===== Cobrar com base em =====
	CART: 8,
	DELIVERY: 16,

	// ===== Tipo de uso do cupom =====
	FIRST_PURCHASE_EVER: 32,
	N_USE: 64,
	FREE_TO_USE: 128,
	NEXT_PURCHARSE: 256,

	CASHBACK_USE: 512,
	CASHBACK_EARN: 1024,
};

const USE_TYPES = {
	ONLINE: 1,
	OFFLINE: 2,
	TAKEAWAY: 4,
	FASTFILA: 8,
};

const isType = (haystack, needle) => (needle & haystack) >= needle;

export class Benefit {
	static getTitle(coupon) {
		switch (coupon.type) {
			case "PERCENTAGE_DISCOUNT":
				return `Cupom de ${coupon.value || 0}%`;
			case "CASH_DISCOUNT":
				return `Cupom de ${Currency.formatCurrency(coupon.value || 0)}`;
			case "DISCOUNT_ORDER":
				if (coupon.amount_type === "CASH")
					return `Cupom de ${Currency.formatCurrency(coupon.amount || 0)}`;

				if (coupon.amount_type === "PERCENT")
					return `Cupom de ${coupon.amount || 0}%`;
				break;
			case "CASHBACK_ACCUMULATE":
				if (coupon.cashback_cash_bonus)
					return `Cashback de ${Currency.formatCurrency(
						coupon.cashback_cash_bonus || 0
					)}`;
				if (coupon.cashback_percent_bonus)
					return `Cashback de ${coupon.cashback_percent_bonus || 0}%`;
				break;
			case "CASHBACK":
				return `Cashback de ${coupon.amount || coupon.value || 0}%`;
			case "FREE_DELIVERY":
				return `Cupom de Frete grátis`;
			case "CASHBACK_CONSUME":
				return `Usar saldo`;
			default:
				break;
		}

		return "Cupom não encontrado";
	}

	static getRules(coupon) {
		const rules = [];

		if (!coupon.expiration?.days) {
			rules.push("Nunca expira");
		}

		if (coupon.type == "FREE_DELIVERY") {
			rules.push("Aplicado somente ao valor dos produtos");
		}

		if (!!coupon.min_order) {
			rules.push(
				`Pedido mínimo R$ ${Currency.formatCurrency(
					coupon.min_order || 0
				)}`
			);
		}

		if (isType(coupon.type_use, USE_TYPES.FASTFILA)) {
			rules.push(`Aplicado a retirada (comer no local)`);
		}

		if (isType(coupon.type_use, USE_TYPES.TAKEAWAY)) {
			rules.push(`Aplicado a retirada (para levar)`);
		}

		if (isType(coupon.type_use, USE_TYPES.ONLINE)) {
			rules.push(`Aplicado a entrega`);
		}

		if (isType(coupon.type_use, USE_TYPES.OFFLINE)) {
			rules.push(`Aplicado ao salão`);
		}

		return rules;
	}

	static canBeUsed(coupon) {
		if (coupon?.type) {
			const { cart, delivery } = OrderStorage.getOrder();

			if(coupon.type == 'CASHBACK_CONSUME') {
				return true;
			}

			if (!isType(coupon.type_use, USE_TYPES.OFFLINE) && cart.total < coupon.min_order) {
				return false;
			}

			if (coupon.type === "FREE_DELIVERY" && delivery?.type !== DeliveryType.DELIVER_ON_ADDRESS) {
				return false;
			}

			const isFastFilaOk = isType(coupon.type_use, USE_TYPES.FASTFILA) && delivery?.type == DeliveryType.INDOOR;
			const isTakeAwayOk = isType(coupon.type_use, USE_TYPES.TAKEAWAY) && delivery?.type == DeliveryType.GET_ON_PLACE;
			const isDeliveryOk = isType(coupon.type_use, USE_TYPES.ONLINE) && delivery?.type == DeliveryType.DELIVER_ON_ADDRESS;

			if (!(isFastFilaOk || isTakeAwayOk || isDeliveryOk)) {
				return false;
			}

			return true;
		}
		
		return false;
	}

	static notAvailableText(coupon) {
		const { cart, delivery } = OrderStorage.getOrder();

		if (!isType(coupon.type_use, USE_TYPES.OFFLINE) && cart.total < coupon.min_order) {
			return "O pedido mínimo não foi atingido.";
		}

		if (coupon.type === "FREE_DELIVERY" && delivery?.type !== DeliveryType.DELIVER_ON_ADDRESS) {
			return "Este cupom é válido somente para entrega.";
		}

		const isDeliveryNotOk = isType(coupon.type_use, USE_TYPES.ONLINE) && delivery?.type !== DeliveryType.DELIVER_ON_ADDRESS;
		const isFastFilaNotOk = isType(coupon.type_use, USE_TYPES.FASTFILA) && delivery?.type !== DeliveryType.INDOOR;
		const isTakeAwayNotOk = isType(coupon.type_use, USE_TYPES.TAKEAWAY) && delivery?.type !== DeliveryType.GET_ON_PLACE;

		if (isDeliveryNotOk || isFastFilaNotOk || isTakeAwayNotOk) {
			const types = [];
			if (isType(coupon.type_use, USE_TYPES.ONLINE)) {
				types.push('entrega')
			}

			if (isType(coupon.type_use, USE_TYPES.TAKEAWAY)) {
				types.push('takeway (para levar)')
			}

			if (isType(coupon.type_use, USE_TYPES.FASTFILA)) {
				types.push('fastfila (comer no local)')
			}

			let typesStr = types[0];
			if (types.length > 1) {
				const lastItem = types.splice(types.length - 1, 1);
				typesStr = types.join(', ') + ` e ${lastItem}`;
			} 
			return `Este cupom é válido somente para ${typesStr}.`;
		}

	}

	static parseCouponType(coupon) {
		switch (coupon.type) {
			case "CASH_DISCOUNT":
				return GIFT_TYPE.PRICE | GIFT_TYPE.CART | GIFT_TYPE.FREE_TO_USE;
			case "PERCENTAGE_DISCOUNT":
				return GIFT_TYPE.PERCENT | GIFT_TYPE.CART | GIFT_TYPE.FREE_TO_USE;
			case "CASHBACK":
				return (
					GIFT_TYPE.CASHBACK_EARN |
					GIFT_TYPE.PERCENT |
					GIFT_TYPE.CART |
					GIFT_TYPE.FREE_TO_USE
				);
			case "CASHBACK_CONSUME":
				return (
					GIFT_TYPE.CASHBACK_USE |
					GIFT_TYPE.PRICE |
					GIFT_TYPE.CART |
					GIFT_TYPE.FREE_TO_USE
				);
			case "FREE_DELIVERY":
				return GIFT_TYPE.PRICE | GIFT_TYPE.DELIVERY | GIFT_TYPE.FREE_TO_USE;
			default:
				break;
		}

		return null;
	}
}
