import { Fragment, useState } from "react";
import { Currency } from "shared/tools";
import { Radio } from "shared/components";
import { PizzaQuantityFlavorsStep } from "./PizzaQuantityFlavorsStep";
import styles from "styles/containers/product-details.module.scss";

function loopTimes(max, callback) {
    const x = new Array(max).fill(undefined)
    return x.map((_, index) => callback(index));
}

const FlavorItem = ({ flavor, selected, onSelect }) => {
    const size = flavor.sizes?.length > 0 ? flavor.sizes[0] : null;
    const price = size ? size.price : flavor.price;

    return (
        <div className={styles.optionItem} onClick={() => onSelect && onSelect()}>
            <div className="d-flex flex-col">
                <p className={styles.optionName}>{flavor.name}</p>
                {Number(price > 0) ? (
                    <p className={styles.optionPrice}>
                        {`${Currency.formatCurrency(price || 0)}`}
                    </p>
                ) : null}
            </div>

            <Radio
                key={Math.random()}
                value={flavor.id}
                checked={selected && flavor.id}
                readOnly
            />
        </div>
    );
};


export const PizzaFlavorStep = ({ product, fixed, selectedFlavors, onFlavorsUpdated }) => {
    const [maxFlavors, setMaxFlavors] = useState(selectedFlavors.length || 1);
    const maxAllowedFlavors = product?.sizes?.find(s => s.ref_id == product.ref_id)?.max || 1;

    const onSelectFlavor = (flavorId, flavorPosition) => {
        const flavors = [...(selectedFlavors || [])];
        flavors[flavorPosition] = flavorId;
        if (onFlavorsUpdated) {
            onFlavorsUpdated(flavors)
        };
    }

    const onSetMaxFlavors = (max) => {
        setMaxFlavors(max);
        if (onFlavorsUpdated) {
            onFlavorsUpdated(
                //Reduz ou expande a array ao tamanho do length
                loopTimes(max, (i) => selectedFlavors[i] || null)
            )
        };
    }

    return (
        <div className={`${styles.flavors}`}>
            <PizzaQuantityFlavorsStep
                product={product}
                selectedQuantity={maxFlavors}
                onSetMaxFlavors={(q) => onSetMaxFlavors(q)}
            />
            {(maxFlavors || maxAllowedFlavors === 1) && loopTimes(maxFlavors, (position) => {
                const sectionTitle = maxFlavors === 1 ? "Escolha o sabor" : `Escolha o ${position + 1}º sabor`;
                const items = [];

                if (position == 0 && fixed) {
                    items.push(...product.items.filter((f) => f.id == fixed));
                } else {
                    items.push(...(product.items || []));
                }

                return (
                    <>
                        <div className={styles.sectionHeader}>
                            <div className="d-flex flex-col">
                                <p className={styles.sectionTitle}>{sectionTitle}</p>
                            </div>
                            <span className={styles.requiredField}>Obrigatório</span>
                        </div>

                        <div className={styles.optionItems}>
                            {items.map((flavor, flavorIndex) => (
                                <Fragment key={flavorIndex}>
                                    <FlavorItem
                                        flavor={flavor}
                                        onSelect={() => onSelectFlavor(flavor.id, position)}
                                        selected={selectedFlavors[position] == flavor.id}
                                    />
                                </Fragment>
                            ))}
                        </div>
                    </>
                )
            })}
        </div>
    );
}