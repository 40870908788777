import { useEffect, useRef, useState } from "react";

import { Form, Formik, useFormikContext } from "formik";

import { Button, Drawer, Input } from "shared/components";
import { Clock2, Pix } from "shared/components/Icons";
import { PaymentHubAPI } from "api/PaymentHub";

import styles from "styles/containers/cart.module.scss";

const getCharge = async (orderId) => {
  const charge = await PaymentHubAPI.PagarMeGetCharge(orderId);
  return charge.getData({});
};

export const PixPayment = ({ open, onClose, onSuccess, orderId }) => {
  const formRef = useRef();

  const [openConfirmClosePix, setOpenConfirmClosePix] = useState(false);
  const [countDown, setCountDown] = useState(null);
  const [runTimer, setRunTimer] = useState(true);

  const loadPix = async () => {
    const charge = await getCharge(orderId);
    formRef.current.setFieldValue("code", charge.extra.qrCode.code);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(formRef.current.values.code);
  };

  useEffect(() => {
    let timerId;
    let pollingId;

    if (open === true) {
      const startTimer = () => {
        setCountDown(60 * 5);
        timerId = setInterval(() => {
          setCountDown((countDown) => countDown - 1);
        }, 1000);
      };

      const startPolling = () => {
        pollingId = setInterval(async () => {
          const charge = await getCharge(orderId);
          if (charge.status === "PAYED") {
            onSuccess(orderId);
          }
        }, 10000);
      };

      if (runTimer) {
        startTimer();
      } else {
        clearInterval(timerId);
      }

      loadPix();

      startPolling();
    }

    return () => {
      clearInterval(timerId);
      clearInterval(pollingId);
    };
  }, [open, orderId, runTimer]);

  useEffect(() => {
    if (countDown < 0) {
      onClose();
      setRunTimer(false);
    }
  }, [countDown]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  const initialValues = {
    code: "Carregando...",
  };

  return (
    <Drawer
      open={open}
      onClose={() => setOpenConfirmClosePix(true)}
      title="Pagamento PIX"
      alreadyDrawer
      showCloseButton
      showBackButton
    >
      <div className={styles.pixCode}>
        <div className="d-flex align-center">
          <div className={styles.pix}>
            <Pix />
          </div>
          <p className={styles.status}>Pedido aguardando pagamento</p>
        </div>

        <div className={styles.hairline} />

        <div className="d-flex align-center">
          <div className={styles.clock}>
            <Clock2 />
          </div>
          <p className={styles.time}>
            ({minutes}:{seconds})
          </p>
        </div>

        <ul className={styles.instructions}>
          <li>Acesse seu app de pagamentos</li>
          <li>Escolha pagar via PIX</li>
          <li>Cole o código abaixo</li>
        </ul>

        <div className={styles.hairline} />

        <Formik initialValues={initialValues} innerRef={formRef}>
          <Form>
            <Input name="code" floatLabel="Código PIX" disabled />
          </Form>
        </Formik>
      </div>

      <div className={styles.footer}>
        <Button design="primary" onClick={() => copyToClipboard()}>
          Copiar código PIX
        </Button>
      </div>

      <Drawer
        open={openConfirmClosePix}
        onClose={(status) => setOpenConfirmClosePix(status)}
        direction="bottom"
        alreadyDrawer
      >
        <div className={styles.cancelPix}>
          <p className={styles.alertTitle}>Atenção</p>
          <p className={styles.alertSubtitle}>
            Ao confirmar esta ação, o seu pedido será cancelado permanentemente
          </p>

          <Button
            design="primary"
            className="mb-15"
            onClick={() => setOpenConfirmClosePix(false)}
          >
            Voltar para PIX
          </Button>
          <Button
            design="secondary"
            onClick={() => {
              onClose && onClose();
              setOpenConfirmClosePix(false);
            }}
          >
            Cancelar pedido
          </Button>
        </div>
      </Drawer>
    </Drawer>
  );
};
