import { useState } from "react";
import { Field, ErrorMessage, useField, getIn } from "formik";
import ReactInputMask from "react-input-mask";
import classNames from "classnames/bind";
import CurrencyInput from "react-currency-input";

import { EyeClosed, EyeOpen, Search } from "shared/components/Icons";
import styles from "styles/components/input.module.scss";

const MaskedInput = (props) => <ReactInputMask {...props} />;
const MoneyInput = (props) => {
  const additionalProps = { ...props };
  return <CurrencyInput {...additionalProps} />;
};

export const Input = ({
  type,
  placeholder = " ",
  onBlur,
  onFocus,
  onChange,
  autoFocus,
  label,
  mask,
  floatLabel,
  autoComplete,
  maxLength,
  className,
  search,
  design = "one",
  disabled,
  ...props
}) => {
  const cx = classNames.bind(styles);
  const [inputType, setInputType] = useState(type);

  const { name } = props;
  const id = props.id || name;

  const [field, meta] = useField(props);

  const additionalProps = {};
  if (onChange) {
    additionalProps.onChange = onChange;
  }

  if (props.currency) {
    additionalProps.prefix = props.prefix;
    additionalProps.thousandSeparator = props.thousandSeparator;
    additionalProps.decimalSeparator = props.decimalSeparator;
  }

  const getInputType = () => {
    if (props.currency) {
      return MoneyInput;
    } else if (mask) {
      return MaskedInput;
    } else {
      return "input";
    }
  };

  const typeInput = getInputType();

  return (
    <div
      className={cx({
        inputContainer: true,
        [className]: className,
      })}
      spellCheck="false"
    >
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      {search && (
        <span className={styles["search-icon"]}>
          <Search />
        </span>
      )}
      <div className="relative">
        <Field
          id={id}
          as={typeInput}
          mask={mask || null}
          maskChar={null}
          type={inputType}
          name={name}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={autoFocus}
          maxLength={maxLength}
          autoComplete="off"
          disabled={disabled}
          className={cx({
            invalid: meta.touched && meta.error,
            password: type === "password",
            search: search,
            [`design-${design}`]: true,
          })}
          {...additionalProps}
        />
        {floatLabel && (
          <label
            className={`${styles.float} ${
              meta.touched && meta.error ? styles.invalid : ""
            }`}
          >
            {floatLabel}
          </label>
        )}
        {type === "password" && (
          <span
            className={styles.eye}
            onClick={() =>
              setInputType(inputType === "password" ? "text" : "password")
            }
          >
            {inputType === "password" ? <EyeClosed /> : <EyeOpen />}
          </span>
        )}
      </div>
      <ErrorMessage name={name} component="span" className={styles.error} />
    </div>
  );
};
