import { useEffect, useState } from "react";

import Image from "next/image";

import { Address } from "@olga-food/schemas/lib/classes/schemas/address/Address";
import { Geolocation } from "@olga-food/schemas/lib/classes/schemas/address/Geolocation";

import styles from "styles/containers/take-out.module.scss";

import UnitLocation from "shared/containers/Address/UnitLocation";

import { BucketsAPI } from "api/BucketAPI";

import { Location } from "shared/components/Icons";
import { Environment } from "@/shared/core/Environment";
import { Button, InputSearch, Loading } from "@/shared/components";

export default function TakeOutByGeolocation({ brandAlias, onSelectLocation }) {
  const DISTANCE_LIMIT_IN_KM = 100;

  const [locations, setLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState([]);
  const [message, setMessage] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [geolocation, setGeolocation] = useState(null);
  const [units, setUnits] = useState([]);
  const [term, setTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingUnit, setLoadingUnit] = useState(false);

  useEffect(() => {
    const loadUnits = async () => {
      const units = await BucketsAPI.getUnits(brandAlias);

      setUnits(units);
    };

    loadUnits();
  }, [brandAlias]);

  useEffect(() => {
    getGeolocation();
  }, [units]);

  useEffect(() => {
    const findByGeolocation = () => {
      const customerLocation = Geolocation.fromObject(geolocation);

      const filteredUnits = units.filter((unit) => {
        const { address } = unit;

        if (!address) {
          return false;
        }

        const unitAddress = Address.fromObject(address);
        const distanceInMeters =
          unitAddress.geolocation.distanceTo(customerLocation);
        const distanceInKm = distanceInMeters / 1000;

        return distanceInKm >= 0 && distanceInKm < DISTANCE_LIMIT_IN_KM;
      });

      setLoading(false);

      return BucketsAPI.getUnitWithTakeout(filteredUnits, customerLocation);
    };

    const getLocations = () => {
      const { latitude, longitude } = geolocation;

      if (latitude && longitude) {
        const locations = findByGeolocation();
        setLocations(locations);

        if (locations?.length > 0) {
          getLocationName(locations);
          setMessage(null);
        } else {
          setMessage(
            "Não encontramos nenhuma loja próxima da sua localização."
          );
        }
      } else {
        setMessage("Não foi possível obter sua localização.");
      }

      setLoading(false);
    };

    if (geolocation?.latitude && geolocation?.longitude) {
      getLocations();
    }
  }, [geolocation, units]);

  const handleSearch = () => {};

  const getGeolocation = async () => {
    const promise = new Promise((resolve, reject) => {
      const handleSuccess = (geolocation) => {
        resolve(geolocation && geolocation.coords ? geolocation.coords : {});
      };

      const handleError = (err) => {
        reject(err);
      };

      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    });

    try {
      const { latitude, longitude } = await promise;
      if (latitude && longitude) {
        setGeolocation({
          latitude,
          longitude,
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const getLocationName = (locations) => {
    const firstLocation = locations && locations.length > 0 ? locations[0] : {};
    setCurrentLocation(firstLocation);
    setLocations(locations);
  };

  const handleSelectedLocation = (location) => {
    setSelectedLocation(location);
  };

  const selectUnit = () => {
    setLoadingUnit(true);
    if (selectedLocation) {
      onSelectLocation({
        unit: {
          id: selectedLocation.id,
          alias: selectedLocation.alias,
          name: selectedLocation.name,
          fastfila: selectedLocation.allowFastfila,
        },
        delivery: selectedLocation.delivery,
      });
    } else {
      onSelectLocation(null);
    }
  };

  const formatAddress = () => {
    const { address } = currentLocation.delivery;

    return (
      address &&
      `${address.address}, ${address.number}, ${address.region} - ${address.city} - ${address.state}`
    );
  };

  return (
    <div className={styles.geolocationContainer}>
      {loading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : message ? (
        <div className={styles.emptyUnits}>
          <div className={styles.picture}>
            <Image src="/map-marker.png" alt="" layout="fill" />
          </div>
          <p>{message}</p>
        </div>
      ) : (
        <>
          <div className={styles.currentLocation}>
            <div className={styles.address}>
              <Location color="#3D734A" />
              {currentLocation?.delivery?.address && (
                <span>{formatAddress()}</span>
              )}
            </div>
          </div>
          <InputSearch
            placeholder="Buscar por nome..."
            value={term}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <p className={styles.nearUnits}>
            Selecione uma unidade próxima à <strong>você</strong>
          </p>
          <div className={styles.units}>
            {locations.map((location) => (
              <UnitLocation
                key={location.id}
                id={location.id}
                name={location.name}
                alias={location.alias}
                delivery={location.delivery}
                selected={selectedLocation?.id}
                active={location.active}
                onSelect={() => handleSelectedLocation(location)}
              />
            ))}
          </div>

          {selectedLocation && selectedLocation?.id && (
            <div className={styles.footer}>
              <Button
                design="primary"
                onClick={selectUnit}
                loading={loadingUnit}
                disabled={loadingUnit}
              >
                Selecionar unidade
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
