import { useCallback, useEffect, useState } from "react";

import slugify from "slugify";

import styles from "styles/containers/take-out.module.scss";

import { BucketsAPI } from "api/BucketAPI";

import { InputSearch } from "shared/components";

import UnitLocation from "./UnitLocation";

import { OrderStorage } from "storage/OrderStorage";
import { Environment } from "@/shared/core/Environment";

import { PinLocation, PinLocationLight } from "@/shared/components/Icons";
import { Button } from "@/shared/components";

export default function TakeOutByCity({ brandAlias, onSelectLocation }) {
  const [locations, setLocations] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [units, setUnits] = useState([]);
  const [term, setTerm] = useState("");
  const [citiesResult, setCitiesResult] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loadingUnit, setLoadingUnit] = useState(false);

  useEffect(() => {
    const loadUnits = async () => {
      const units = await BucketsAPI.getUnits(brandAlias);

      setUnits(units);
    };

    loadUnits();
  }, [brandAlias]);

  const findByCity = useCallback(
    (normalizedCity) => {
      const filteredUnits = units.filter((unit) => {
        const { address } = unit;

        if (!address) {
          return false;
        }

        const unitCity = slugify(address.city, {
          lower: true,
          trim: true,
        });

        return normalizedCity === unitCity;
      });

      return BucketsAPI.getUnitWithTakeout(filteredUnits);
    },
    [units]
  );

  const getCities = useCallback(() => {
    const cities = new Map();

    units.forEach((unit) => {
      if (unit.address && unit.deliveryTime) {
        const normalizedCity = slugify(unit.address.city, {
          lower: true,
          trim: true,
        });

        if (!cities.get(normalizedCity)) {
          cities.set(normalizedCity, unit.address.city, 10);
        }
      }
    });

    return cities;
  }, [units]);

  useEffect(() => {
    const loadCities = () => {
      clearLocations();

      const cities = [];
      const allCities = getCities();

      for (let [normalizedCity, cityName] of allCities.entries()) {
        const locations = findByCity(normalizedCity);

        cities.push({
          slug: normalizedCity,
          name: cityName,
          units: locations?.length,
        });
      }

      setCitiesResult(cities);
    };

    loadCities();
  }, [units, findByCity, getCities]);

  const handleSearch = (term) => {
    setTerm(term);
    clearLocations();

    const normalizedTerm = slugify(term, {
      lower: true,
      trim: true,
    });

    const cities = [];
    const allCities = getCities();

    for (let [normalizedCity, cityName] of allCities.entries()) {
      const locations = findByCity(normalizedCity);

      if (normalizedCity.startsWith(normalizedTerm)) {
        cities.push({
          slug: normalizedCity,
          name: cityName,
          units: locations?.length,
        });
      }
    }

    setCitiesResult(cities);
  };

  const handleSelectCity = (city = null) => {
    if (city) {
      setLocationName(city.name);

      const locations = findByCity(city.slug);

      setLocations(locations);

      const location = locations.filter((location) => {
        return (
          JSON.stringify(location.delivery?.address?.geolocation) ==
          JSON.stringify(OrderStorage.getDelivery()?.address?.geolocation)
        );
      })[0]?.id;

      setSelectedLocation(location);
    } else {
      setLocationName(null);
    }
  };

  const clearLocations = () => {
    setLocationName("");
    setSelectedLocation(null);
  };

  const handleSelectedLocation = (location) => {
    setSelectedLocation(location);
  };

  const selectUnit = () => {
    setLoadingUnit(true);
    if (selectedLocation) {
      onSelectLocation({
        unit: {
          id: selectedLocation.id,
          alias: selectedLocation.alias,
          name: selectedLocation.name,
          fastfila: selectedLocation.allowFastfila,
        },
        delivery: selectedLocation.delivery,
      });
    } else {
      onSelectLocation(null);
    }
  };

  return (
    <div className={styles.citiesContainer}>
      {!locationName && (
        <>
          <InputSearch
            placeholder="Buscar por cidade..."
            value={term}
            onChange={(e) => handleSearch(e.target.value)}
          />

          <div className={styles.cities}>
            {citiesResult.map((city) => (
              <div
                key={city.slug}
                className={styles.city}
                onClick={() => handleSelectCity(city)}
              >
                <div className={styles.icon}>
                  <PinLocationLight />
                </div>
                <div className="d-flex flex-col">
                  <p className={styles.cityName}>{city.name}</p>
                  <p className={styles.unitsCity}>
                    {city.units > 1
                      ? `${city.units} unidades`
                      : `${city.units} unidade`}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {locationName && (
        <>
          <div className={styles.unitsContainer}>
            <div className={styles.selectedCity}>
              <div className={styles.city}>
                <PinLocation color="#3D734A" size={12} />
                <p>{locationName}</p>
              </div>
            </div>

            <p className={styles.nearUnits}>
              Unidades em <strong>{locationName}</strong>.
            </p>
            <InputSearch
              placeholder="Buscar por nome..."
              value={term}
              onChange={(e) => {}}
            />
            <div className={styles.units}>
              {locations.map((location) => (
                <UnitLocation
                  key={location.id}
                  id={location.id}
                  name={location.name}
                  alias={location.alias}
                  delivery={location.delivery}
                  selected={selectedLocation?.id}
                  active={location.active}
                  onSelect={() => handleSelectedLocation(location)}
                />
              ))}
            </div>
          </div>
        </>
      )}

      {selectedLocation && selectedLocation?.id && (
        <div className={styles.footer}>
          <Button
            design="primary"
            onClick={selectUnit}
            loading={loadingUnit}
            disabled={loadingUnit}
          >
            Selecionar unidade
          </Button>
        </div>
      )}
    </div>
  );
}
