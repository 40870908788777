import { isDesktop } from "react-device-detect";
import { Button, Drawer } from "shared/components";

import styles from "styles/containers/benefits.module.scss";

export const AddTypes = {
  SCAN: "scan",
  MANUAL: "manual",
};

export const AddOptions = ({ open, onSelectOption, onClose, isClub }) => {
  return (
    <Drawer
      open={open}
      alreadyDrawer
      onClose={onClose}
      direction={isClub && isDesktop ? "bottom-center" : "bottom"}
    >
      <p className={styles.alertTitle}>Adicionar benefício</p>
      <p className={styles.alertSubtitle}>O que deseja fazer?</p>
      <Button
        design="primary"
        className="w-100 mb-10"
        onClick={() => onSelectOption && onSelectOption(AddTypes.SCAN)}
      >
        Escanear QR Code
      </Button>
      <Button
        design="secondary"
        className="w-100"
        onClick={() => onSelectOption && onSelectOption(AddTypes.MANUAL)}
      >
        Digitar Código
      </Button>
    </Drawer>
  );
};
