import { useEffect, useState } from "react";

import Image from "next/image";

import { BrazilStates } from "shared/tools/BrazilStates";

import classNames from "classnames/bind";

import styles from "styles/containers/address-list.module.scss";

import { CustomerAPI } from "api/CustomerAPI";
import { BucketsAPI } from "api/BucketAPI";
import { AddressStorage } from "storage/AddressStorage";

import { Button, Drawer, Loading } from "shared/components";

import AddAddress from "shared/containers/Address/AddAddress";
import { OlgaFoodSession } from "shared/tools/OlgaFoodSession";
import { AddressCard } from "./AddressCard";
import { Customer } from "shared/core/Customer";

import { DataLayer } from "shared/tools/DataLayer";

const Steps = {
  ADDRESS_LIST: 1,
  SEARCH_ADDRESS: 2,
  ADD_ADDRESS: 3,
};

export default function AddressList({
  onSelectAddress,
  onLoading,
  changeStep,
  currentStep,
  onOpenDeliveryType,
  onSelectAddressWithoutUnit,
}) {
  const cx = classNames.bind(styles);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAddressess, setLoadingAddressess] = useState(true);
  const [step, setStep] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [newAddress, setNewAddress] = useState(null);
  const [openRemoveAddress, setOpenRemoveAddress] = useState(false);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    loadAddresses();
  }, []);
  useEffect(() => {
    setStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    if (newAddress || newAddress === 0) {
      selectAddress(newAddress, addresses);
    }
  }, [newAddress]);

  const loadUnits = (addresses) => {
    return addresses.map(async (addr) => {
      const units = await BucketsAPI.getNearbyUnits(addr.geolocation);

      if (units.length > 0) {
        const { unit } = units[0];

        return { addressId: addr.id, unit };
      }
    });
  };

  const updateUnits = async (addresses) => {
    const units = loadUnits(addresses);

    const resultUnits = await Promise.all(units);

    setUnits(resultUnits);
  };

  const loadAddresses = () => {
    const addressess = AddressStorage.findAll();
    updateUnits(addressess);
    setAddresses(addressess);
    setTimeout(() => {
      setLoadingAddressess(false);
    }, 500);
  };

  const getAddressNearUnit = async (addresses) => {
    const promises = addresses.map(async (addr, index) => {
      const units = await BucketsAPI.getNearbyUnits(addr.geolocation);
      const [nearUnit] = units || [];
      return {
        index,
        address: addr,
        unit: nearUnit,
      };
    });

    const unitByAddresses = await Promise.all(promises);

    const minDistance = unitByAddresses.reduce((minDistance, unitByAddress) => {
      const unit = unitByAddress.unit;
      const distance = unit ? unit.delivery.distance : null;

      if (distance === null) {
        return minDistance;
      }

      return !minDistance || distance < minDistance ? distance : minDistance;
    }, null);

    const address = unitByAddresses.find((unitByAddress) => {
      return (
        unitByAddress &&
        unitByAddress.unit &&
        unitByAddress.unit.delivery.distance === minDistance
      );
    });

    if (address) {
      return {
        index: address.index,
        address: address.address,
      };
    } else {
      return null;
    }
  };

  const deleteAddress = async (addressId) => {
    setLoading(true);

    const newAddresses = addresses.filter(
      (address) => address.id !== addressId
    );
    if (Customer.id) {
      await updateAddress(newAddresses);
    }

    AddressStorage.load(newAddresses);
    setAddresses(newAddress);

    const nearAddress = await getAddressNearUnit(newAddresses);
    if (nearAddress) {
      await selectAddress(nearAddress.address, newAddresses);
    }

    setOpenRemoveAddress(false);
    setDeleteIndex(null);
    setLoading(false);
  };

  const updateAddress = async (newAddressess) => {
    try {
      if (OlgaFoodSession.hasSession()) {
        const responseAddress = await CustomerAPI.updateAddresses(
          newAddressess
        );
        if (responseAddress.getErrors([]).length > 0) {
          throw new Error(responseAddress.getErrors()[0]);
        }
      }
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const saveAddress = async (address) => {
    const newAddressess = [...addresses, address];
    setLoading(true);

    if (OlgaFoodSession.hasSession()) {
      const updated = await updateAddress(newAddressess);

      if (!updated) {
        setStep(Steps.ADDRESS_LIST);
        setLoading(false);
        return false;
      }
    }

    AddressStorage.add(address);

    setStep(Steps.ADDRESS_LIST);
    changeStep(Steps.ADDRESS_LIST);
    setLoading(false);

    if (newAddressess.length > 0) {
      const lastAddress = newAddressess.length - 1;
      setAddresses(newAddressess);
      loadAddresses(newAddressess);
      setNewAddress(lastAddress);
    }

    DataLayer.push({ event: "address_complete" });
  };

  const selectAddress = async (address, addresses) => {
    const currentAddresses = addresses.map((address) => {
      return {
        ...address,
        isLastSelected: false,
      };
    });

    const units = await BucketsAPI.getNearbyUnits(address.geolocation);

    let selectedAddress = null;
    let hasNearUnit = true;
    if (units.length > 0) {
      currentAddresses.forEach((currentAddress) => {
        if (currentAddress.id === address.id) {
          currentAddress.isLastSelected = true;
          selectedAddress = currentAddress;
        }
      });
    } else {
      hasNearUnit = false;
      const nearAddress = await getAddressNearUnit(currentAddresses);
      if (nearAddress) {
        currentAddresses[nearAddress.index].isLastSelected = true;
        selectedAddress = currentAddresses[nearAddress.index];
      }
    }

    if (!hasNearUnit && onSelectAddressWithoutUnit) {
      onSelectAddressWithoutUnit();
    } else onLoading(true);

    AddressStorage.load(currentAddresses);
    setAddresses(currentAddresses);

    onLoading(false);

    if (onSelectAddress && units.length > 0) {
      onSelectAddress(selectedAddress);
    }
  };

  return (
    <>
      <AddAddress
        step={step}
        setStep={(step) => {
          setStep(step);
          changeStep(step);
        }}
        loading={loading}
        onSave={(address) => saveAddress(address)}
      />

      {[Steps.ADDRESS_LIST].includes(step) && (
        <>
          {loadingAddressess && (
            <div className={styles.loading}>
              <Loading />
            </div>
          )}

          {!loadingAddressess && addresses?.length === 0 && (
            <div className={styles.emptyAddressess}>
              <Image
                src="/map-marker.png"
                alt=""
                width="86"
                height="65"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <p>Você não cadastrou nenhum endereço ainda</p>
            </div>
          )}

          {!loadingAddressess && addresses?.length > 0 && (
            <>
              <p className={styles.title}>Endereços salvos</p>
              <div className={styles.scrollContainer}>
                {addresses.map((address, index) => (
                  <AddressCard
                    nearUnit={units[index]}
                    address={address}
                    unitIndex={index}
                    disabledRemove={addresses?.length < 2}
                    onRemoveAddress={() => setOpenRemoveAddress(address.id)}
                    onSelectAddress={(unitIndex, address) =>
                      selectAddress(address, addresses)
                    }
                    onOpenDeliveryType={onOpenDeliveryType}
                    key={index}
                  />
                ))}

                <Drawer
                  open={openRemoveAddress}
                  onClose={(status) => setOpenRemoveAddress(status)}
                  alreadyDrawer
                  direction="bottom"
                >
                  <div className={styles.deleteAddress}>
                    <p>Excluir</p>
                    <span>Deseja excluir este endereço?</span>

                    <Button
                      design="primary"
                      className="mb-10"
                      loading={loading}
                      disabled={loading}
                      onClick={() => deleteAddress(openRemoveAddress)}
                    >
                      Sim
                    </Button>
                    <Button
                      design="secondary"
                      onClick={() => setOpenRemoveAddress(false)}
                      disabled={loading}
                    >
                      Cancelar
                    </Button>
                  </div>
                </Drawer>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
