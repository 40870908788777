export class CPF {
  static validate(cpf) {
    if (!cpf || cpf.length < 11) {
      return false;
    }

    let cpfArray = `${cpf}`.split("");

    if (cpfArray.every((char) => char === cpfArray[0])) {
      return false;
    }

    let primeiroDigito =
      (cpfArray.slice(0, 9).reduce((carry, digito, idx) => {
        return carry + digito * (10 - idx);
      }, 0) *
        10) %
      11;
    let segundoDigito =
      (cpfArray.slice(0, 10).reduce((carry, digito, idx) => {
        return carry + digito * (11 - idx);
      }, 0) *
        10) %
      11;

    if (primeiroDigito > 9 && primeiroDigito < 12) {
      primeiroDigito = 0;
    }

    if (segundoDigito > 9 && segundoDigito < 12) {
      segundoDigito = 0;
    }

    let digitoVerificador = cpfArray.slice(9);

    if (
      digitoVerificador[0] != primeiroDigito ||
      digitoVerificador[1] != segundoDigito
    ) {
      return false;
    }

    return true;
  }
}
