import Axios from 'axios';
import Qs from 'qs';
import { Exception, ExceptionCode } from '@konv/exceptions';
import { Response } from '@konv/http';

Axios.interceptors.request.use(config => {
    config.paramsSerializer = params => {
        // Qs is already included in the Axios package
        return Qs.stringify(params, {
            arrayFormat: "brackets",
            encode: true
        });
    };
    return config;
});


export class OlgaRequest {
    static httpClient = null;
    static generalHeaders = [];

    static async request(data) {
        if (!OlgaRequest.httpClient) {
            OlgaRequest.httpClient = Axios;
        }

        const serviceAddress = await OlgaRequest.loadServiceAddress(data.service || 'storage', !data.ignoreVersion ? (data.version || 'v1') : false);
        const endpoint = data.endpoint || '/';
        
        delete data.endpoint;
        delete data.ignoreVersion;

        const headers = data['headers'] || { ...OlgaRequest.generalHeaders };

        data = Object.assign({
            crossdomain: true,
            url: `${serviceAddress}${endpoint}`,
            headers
        }, data);

        data.headers['Content-Type'] = 'application/json';

        return OlgaRequest.httpClient(data).then((response) => {
            const result = response?.data?.response;
            return new Response(
                result?.data || null,
                result?.code || 200,
                result?.errors || [],
                result?.pagination || null
            );
        });
    }

    static get(data) {
        data['method'] = 'get'
        return this.request(data)
    }

    static put(data) {
        data['method'] = 'put'
        return this.request(data)
    }

    static post(data) {
        data['method'] = 'post'
        return this.request(data)
    }

    static patch(data) {
        data['method'] = 'patch'
        return this.request(data)
    }

    static delete(data) {
        data['method'] = 'delete'
        return this.request(data)
    }

    static setHeader(header, value) {
        this.generalHeaders[header] = value
    }

    static setHeaders(headersArray = []) {
        Object.keys(headersArray).map((key) => {
            this.setHeader(key, headersArray[key]);
        });
    }

    static async loadServiceAddress(serviceName, version) {
        const servicesEndpoint = process.env.OLGA_URL;
        if (servicesEndpoint) {
            return `${servicesEndpoint.replace('{SERVICE}', serviceName).replace('{STAGE}', process.env.STAGE).toLowerCase()}${version ? '/' + version.toLowerCase() : ''}`
        } else {
            throw new Exception(ExceptionCode.ENVIRONMENT_ENDPOINT_UNDEFINED);
        }
    }
}