import styles from "styles/components/button.module.scss";

import classNames from "classnames/bind";

export const Button = ({
  design = "default",
  type = "button",
  children,
  disabled,
  onClick,
  className,
  loading,
}) => {
  const cx = classNames.bind(styles);

  const handleClick = (e) => {
    e.stopPropagation();
    onClick && onClick();
  };

  return (
    <button
      type={type}
      onClick={handleClick}
      className={cx({
        button: true,
        loading: loading,
        [design]: true,
        [className]: className,
      })}
      disabled={disabled}
    >
      {loading ? (
        <div className={styles.loading}>
          <div className={styles.loader}></div>
        </div>
      ) : (
        children
      )}
    </button>
  );
};
