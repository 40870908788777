import React from "react";

import styles from "styles/components/loading.module.scss";

import classNames from "classnames/bind";

export const Loading = ({
  size = 14,
  color = "#3d734a",
  fullContent = false,
  fullScreen = false,
}) => {
  const cx = classNames.bind(styles);

  const dotStyle = {
    height: size,
    width: size,
    background: color,
  };

  if (fullContent) {
    return (
      <div
        className={cx({
          "content-load": true,
          "full-loading": fullScreen,
        })}
      >
        <div
          className={styles["lds-ellipsis"]}
          style={{ height: size, width: size * 6 }}
        >
          <div style={dotStyle}></div>
          <div style={dotStyle}></div>
          <div style={dotStyle}></div>
          <div style={dotStyle}></div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={styles["lds-ellipsis"]}
      style={{ height: size, width: size * 6 }}
    >
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
    </div>
  );
};
