import { createRef, useEffect, useState } from "react";

import { Form, Formik } from "formik";

import * as Yup from "yup";

import styles from "styles/containers/login-or-register.module.scss";

import {
  Button,
  Input,
  Message,
  Modal,
  VerificationCode,
} from "shared/components";
import { CustomerAPI } from "api/CustomerAPI";
import { Brand } from "shared/core/Brand";
import { DataLayer } from "shared/tools/DataLayer";

const Steps = {
  ENTER_EMAIL: 1,
  VERIFICATION_CODE: 2,
  NEW_PASSWORD: 3,
};

export default function RecoverPassword({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(Steps.ENTER_EMAIL);
  const [codeNumber, setCodeNumber] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const [savedPassword, setSavedPassword] = useState(false);

  const emailFormRef = createRef();
  const passwordFormRef = createRef();

  useEffect(() => {
    setStep(Steps.ENTER_EMAIL);
  }, []);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => {
        setResendTimer((oldTimer) => oldTimer - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);

  const initialValues = {
    email: customerEmail,
    password: "",
    passwordConfirm: "",
  };

  const emailValidationSchema = Yup.object({
    email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
  });

  const passwordValidationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Informe ao menos 8 caracteres")
      .required("Campo obrigatório"),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "As senhas não conferem")
      .min(8, "Informe ao menos 8 caracteres")
      .required("Campo obrigatório"),
  });

  const onEmailSubmit = async (e) => {
    setCustomerEmail(e.email);
    await sendForgotPasswordEmail(e.email);
  };

  const onRetryEmail = async () => {
    if (resendTimer > 0) return;

    await sendForgotPasswordEmail(customerEmail);
    setResendTimer(60);
  };

  const sendForgotPasswordEmail = async (email) => {
    setLoading(true);
    const sentEmail = await CustomerAPI.forgotPassword(email, Brand.alias);

    if (!sentEmail.getErrors([]).length) {
      setStep(Steps.VERIFICATION_CODE);

      DataLayer.push({
        event: "reset_senha",
        method: "email",
      });
    } else {
      setErrorPassword(true);
      setErrorMessage(sentEmail.getErrors([])[0]);
    }

    setLoading(false);
  };

  const onRecoverSubmit = async (e) => {
    setLoading(true);
    const changedPassword = await CustomerAPI.recoverPassword(
      e.email,
      codeNumber,
      e.password
    );

    if (
      !changedPassword.getErrors([]).length &&
      changedPassword.getData({}).success
    ) {
      setSavedPassword(true);
    } else {
      setErrorPassword(true);
      setErrorMessage(changedPassword.getErrors()[0]);
    }

    setLoading(false);
  };

  const prevStep = () => {
    if (step > Steps.ENTER_EMAIL) {
      setStep(step - 1);
    }

    if (step === Steps.ENTER_EMAIL) setClose(true);
  };

  const nextStep = () => {
    switch (step) {
      case Steps.ENTER_EMAIL: {
        if (emailFormRef.current) emailFormRef.current.handleSubmit();
        return null;
      }
      case Steps.VERIFICATION_CODE: {
        return setStep(Steps.NEW_PASSWORD);
      }
      case Steps.NEW_PASSWORD: {
        if (passwordFormRef.current) passwordFormRef.current.handleSubmit();
        return null;
      }
      default:
        return null;
    }
  };

  return (
    <div className={styles.recoverPassword}>
      {step === Steps.ENTER_EMAIL && (
        <>
          <p className="mb-20">
            Insira o email associado à sua conta e enviaremos código de
            verificação para redefinir a sua senha.
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={emailValidationSchema}
            onSubmit={(e) => onEmailSubmit(e)}
            innerRef={emailFormRef}
          >
            <Form autoComplete="off">
              <Input name="email" floatLabel="Email" />
            </Form>
          </Formik>
        </>
      )}

      {step === Steps.VERIFICATION_CODE && (
        <>
          <p className="mb-20">
            Um código foi enviado para o email {customerEmail}.
          </p>

          <VerificationCode
            label="Insira o código recebido"
            onChange={(code) => setCodeNumber(code)}
          />

          <div className="d-flex align-center justify-center mt-20">
            <p>
              <strong>Não recebeu o código?</strong>
            </p>
            &nbsp;
            <Button
              onClick={(e) => onRetryEmail()}
              design="underline-grey"
              disabled={resendTimer > 0}
            >
              Reenviar {resendTimer > 0 ? `(em ${resendTimer} segundos)` : ""}
            </Button>
          </div>
        </>
      )}

      {step === Steps.NEW_PASSWORD && (
        <>
          <p className="mb-20">
            Cadastre uma nova senha para acessar sua conta.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={passwordValidationSchema}
            onSubmit={(e) => onRecoverSubmit(e)}
            innerRef={passwordFormRef}
          >
            <Form>
              <Input
                className="mb-20"
                name="password"
                floatLabel="Crie uma senha"
                type="password"
              />
              <Input
                name="passwordConfirm"
                floatLabel="Confirme sua senha"
                type="password"
              />
            </Form>
          </Formik>
        </>
      )}

      <Modal
        open={errorPassword}
        alreadyModal
        onClose={(status) => setErrorPassword(status)}
      >
        <p className="text-2-bold-lg">{errorMessage}</p>
        <Button
          design="primary"
          onClick={() => setErrorPassword(false)}
          className="w-100 mt-30"
        >
          Tentar novamente
        </Button>
      </Modal>

      <Modal open={savedPassword} alreadyModal onClose={() => onClose()}>
        <p className="text-2-bold-lg">Senha alterada com sucesso!</p>
        <Button
          design="primary"
          onClick={() => onClose()}
          className="w-100 mt-30"
        >
          Ok
        </Button>
      </Modal>

      <div className={styles.footer}>
        <Button
          type="submit"
          design="primary"
          className="w-100"
          onClick={nextStep}
          loading={loading}
          disabled={
            loading ||
            (step === Steps.VERIFICATION_CODE && codeNumber.length < 6)
          }
        >
          Continuar
        </Button>
      </div>
    </div>
  );
}
