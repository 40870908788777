import { useState } from "react";

import Image from "next/image";

import styles from "styles/containers/help.module.scss";

import { EnvelopeGreen, PhoneGreen } from "shared/components/Icons";

import { Phone } from "shared/tools";
import useEventListener from "shared/hooks/useEventListener";
import { Store, StoreEvents } from "shared/core/Store";
import { Brand } from "shared/core/Brand";

export default function Help({ unit }) {
  const [helpdesk_contact, setHelpDeskContact] = useState(
    unit?.helpdesk_contact || Store.helpdesk_contact
  );

  useEventListener(StoreEvents.STORE_CHANGED, () => {
    setHelpDeskContact(unit?.helpdesk_contact || Store.helpdesk_contact);
  });

  const brand = Brand.getBrandByAlias(unit?.parent_alias);

  return (
    <div className={styles.helpContainer}>
      {brand?.avatar ? (
        <div className={styles.storeLogo}>
          <Image
            src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${brand.avatar}`}
            alt=""
            layout="fill"
            sizes="100vw"
          />
        </div>
      ) : (
        Brand.avatar && (
          <div className={styles.storeLogo}>
            <Image src={Brand.avatar} alt="" layout="fill" sizes="100vw" />
          </div>
        )
      )}
      <p className={styles.storeName}>{brand?.name || Brand.name}</p>
      {(unit?.name || Store.name) && (
        <p className={styles.unitName}>{unit?.name || Store.name}</p>
      )}
      <p className={styles.helpDescription}>
        Se precisar de ajuda, entre em contato conosco pelos seguintes meios:
      </p>

      <div className={styles.contacts}>
        <div className="d-flex flex-col">
          {!!helpdesk_contact?.phone && (
            <div className="d-flex align-center mb-20">
              <PhoneGreen />
              <p className={styles.phone}>
                {Phone.mask(helpdesk_contact?.phone)}
              </p>
            </div>
          )}
          {!!helpdesk_contact?.email && (
            <div className="d-flex align-center">
              <EnvelopeGreen />
              <p className={styles.email}>{helpdesk_contact?.email}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
