import { useEffect, useRef, useState } from "react";

import { Form, Formik } from "formik";

import * as Yup from "yup";

import dayjs from "dayjs";

import styles from "styles/containers/login-or-register.module.scss";

import { CustomerAPI } from "api/CustomerAPI";

import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  Button,
  Checkbox,
  Drawer,
  Input,
  Loading,
  Message,
} from "shared/components";
import { Customer } from "shared/core/Customer";
import { DeleteModal } from "./DeleteModal";
import { OlgaFoodSession } from "shared/tools/OlgaFoodSession";
import { CPF } from "shared/tools";
import { DataLayer } from "@/shared/tools/DataLayer";

dayjs.extend(customParseFormat);

const Steps = {
  CUSTOMER_DATA: "CUSTOMER_DATA",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
};

const validationSchemaPassword = Yup.object({
  currentPassword: Yup.string()
    .min(8, "Informe ao menos 8 caracteres")
    .required("Campo obrigatório"),
  password: Yup.string()
    .min(8, "Informe ao menos 8 caracteres")
    .required("Campo obrigatório"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "As senhas não conferem")
    .min(8, "Informe ao menos 8 caracteres")
    .required("Campo obrigatório"),
});

export default function CustomerData({ open, onClose, setLogout }) {
  const formRef = useRef();

  const [checkedSMS, setCheckedSMS] = useState(false);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [step, setStep] = useState(Steps.CUSTOMER_DATA);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [error, setError] = useState(null);

  const cpfIsValid = CPF.validate(
    (userData?.extras?.doc || "").replace(/[^0-9]/g, "")
  );

  useEffect(() => {
    const titles = {
      [Steps.CUSTOMER_DATA]: "Usuário - Ver informações pessoais",
      [Steps.CHANGE_PASSWORD]: "Usuário - Alterar senha",
    };

    if (open) {
      DataLayer.push({
        event: "page_view",
        page_title: titles[step],
      });
    }
  }, [step, open]);

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Campo obrigatório"),
    birthdate: Yup.string()
      .optional()
      .matches(
        /([0-9]{2}\/[0-9]{2}\/[0-9]{4})/,
        "O formato deve ser DD/MM/AAAA"
      ),
    phone: Yup.string()
      .transform((value) => value.replace(/[^0-9]/g, ""))
      .min(11, "Informe o telefone completo")
      .required("Campo obrigatório"),
    email: Yup.string().email("Email inválido").required("Campo obrigatório"),
    cpf: Yup.string()
      .optional()
      .nullable()
      .test("cpf-validation", "CPF inválido", (value) =>
        CPF.validate((value || "").replace(/[^0-9]/g, ""))
      ),
  });

  const onCustomerChanged = async () => {
    const data = await CustomerAPI.getMe();
    const user = data.getData({});

    setUserData(user);
    setCheckedSMS(user?.extras?.marketing);
  };

  useEffect(() => {
    onCustomerChanged();
    Customer.onChange(onCustomerChanged);
    return () => Customer.offChange(onCustomerChanged);
  }, []);

  useEffect(() => {
    if (open) {
      const loadCustomer = async () => {
        const data = await CustomerAPI.getMe();
        const user = data.getData({});
        setUserData(user);
        setCheckedSMS(user?.extras?.marketing);
      };
      loadCustomer();
      setError(null);
    }
  }, [open]);

  const initialValues = {
    fullName: userData?.name || "",
    birthdate: dayjs(userData?.birthdate).format("DD/MM/YYYY") || "",
    phone: userData?.phone || "",
    email: userData?.email || "",
    cpf: userData?.extras?.doc || "",
  };

  const initialValuesPassword = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  };

  const onSubmit = async (e) => {
    let customerPayload;
    const doc = String(e.cpf).replace(/[^0-9]/g, "");

    if (e.password && e.confirmPassword) {
      customerPayload = {
        password: e.password || null,
      };
    } else {
      customerPayload = {
        name: e.fullName,
        birthdate: dayjs(e.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        email: e.email,
        phone: String(e.phone).replace(/[^0-9]/g, ""),
        extras: {
          ...(userData?.extras || {}),
          doc,
          marketing: Boolean(checkedSMS),
        },
      };
    }

    setUpdating(true);
    setError(null);
    const updated = await CustomerAPI.updateCustomer(
      userData.id,
      customerPayload
    );

    if (updated.getErrors([])?.length > 0) {
      setUpdating(false);
      setError(updated.getErrors()[0]);
    } else {
      Customer.setCustomer({ ...customerPayload, id: userData.id });
      setUpdating(false);
      onClose(false);
      setStep(Steps.CUSTOMER_DATA);
    }
  };

  const onDeleteAccount = () => {
    OlgaFoodSession.resetSession();
    setLogout(true);
  };

  const getTitle = () => {
    const titles = {
      [Steps.CUSTOMER_DATA]: "Meus dados",
      [Steps.CHANGE_PASSWORD]: "Alterar senha",
    };

    return titles[step];
  };

  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
        setStep(Steps.CUSTOMER_DATA);
      }}
      title={getTitle()}
      showBackButton={step === Steps.CHANGE_PASSWORD}
      backButton={() => setStep(Steps.CUSTOMER_DATA)}
      showCloseButton
    >
      {loading && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}

      {step === Steps.CUSTOMER_DATA && (
        <div className={styles.customerDataContainer}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formRef}
            onSubmit={(e) => onSubmit(e)}
          >
            <Form autoComplete="off">
              <Input
                className="mb-20"
                name="fullName"
                floatLabel="Nome completo"
              />

              <Input
                className="mb-20"
                name="email"
                floatLabel="Email"
                autoComplete
              />
              <div className={styles.phoneAndBirthdate}>
                <div className={styles.phone}>
                  <Input
                    name="phone"
                    className="w-100"
                    floatLabel="Número de celular"
                    placeholder="(00) 0 0000-0000"
                    mask="(99) 9 9999-9999"
                    type="tel"
                  />
                </div>
                <Input
                  className="w-100"
                  name="birthdate"
                  floatLabel="Data de nascimento"
                  mask="99/99/9999"
                  placeholder="DD/MM/AAAA"
                  type="tel"
                />
              </div>

              {cpfIsValid === false && (
                <Input
                  className="mb-20"
                  name="cpf"
                  floatLabel="Informe o CPF"
                  mask="999.999.999-99"
                  placeholder="000.000.000-00"
                  type="tel"
                />
              )}

              <Checkbox
                className="mb-15"
                onChange={(e) => setCheckedSMS(e)}
                checked={checkedSMS}
              >
                Aceito receber e-mails e SMS&apos;s promocionais
              </Checkbox>

              {error && <Message type="error" text={error} />}

              <div className="d-flex justify-center mt-25">
                <Button
                  design="default"
                  onClick={() => setStep(Steps.CHANGE_PASSWORD)}
                >
                  Alterar senha
                </Button>
              </div>
              <div className="d-flex justify-center mt-25">
                <Button
                  design="default"
                  onClick={() => setShowDeleteAccount(true)}
                >
                  Excluir conta
                </Button>
              </div>

              <div className={styles.footer}>
                <Button
                  type="submit"
                  design="primary"
                  className="w-100 flex-1 mt-20"
                  disabled={updating}
                  loading={updating}
                >
                  Salvar
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      )}

      {step === Steps.CHANGE_PASSWORD && (
        <div
          className={`${styles.customerDataContainer} ${
            loading ? styles.hide : styles.show
          }`}
        >
          <Formik
            initialValues={initialValuesPassword}
            validationSchema={validationSchemaPassword}
            onSubmit={(e) => onSubmit(e)}
          >
            <Form autoComplete="off">
              <Input
                className="mb-20"
                name="currentPassword"
                floatLabel="Senha atual"
                type="password"
              />
              <Input
                className="mb-20"
                name="password"
                floatLabel="Nova senha"
                type="password"
              />
              <Input
                name="confirmPassword"
                floatLabel="Confirme a nova senha"
                type="password"
              />

              <div className={styles.footer}>
                <Button
                  type="submit"
                  design="primary"
                  className="w-100 flex-1 mt-20"
                  disabled={updating}
                  loading={updating}
                >
                  Alterar
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      )}
      <DeleteModal
        visible={showDeleteAccount}
        onClose={() => setShowDeleteAccount(false)}
        onConfirm={(password) => onDeleteAccount({ password })}
      />
    </Drawer>
  );
}
