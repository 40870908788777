const benefitKey = "super-benefit";

export class BenefitStorage {
  static attachOnBenefitChanged(eventHandler) {
    window.addEventListener("benefitChanged", eventHandler);
  }

  static detachOnBenefitChanged(eventHandler) {
    window.removeEventListener("benefitChanged", eventHandler);
  }

  static getSelectedBenefit() {
    try {
      const benefitRaw = localStorage.getItem(benefitKey);
      return benefitRaw ? JSON.parse(benefitRaw) : null;
    } catch (err) {
      return null;
    }
  }

  static setSelectedBenefit(data) {
    if (!data || !data?.type) {
      localStorage.setItem(benefitKey, null);
    } else {
      const unparsedData = JSON.stringify(data);
      localStorage.setItem(benefitKey, unparsedData);
    }

    if (window) {
      window.dispatchEvent(new CustomEvent("benefitChanged", { detail: data }));
    }
  }
}
