import { Minus, Plus } from "shared/components/Icons";
import styles from "styles/containers/minus-plus-input.module.scss";

import classNames from "classnames/bind";
const css = classNames.bind(styles);

export const MinusPlusInput = ({
  value,
  min = 0,
  disabledMinus,
  disabledMax,
  onChange,
  className,
  hasQuantity,
}) => {
  const change = (event, value) => {
    event.stopPropagation();
    onChange(value);
  };

  return (
    <div
      className={css({
        quantityContainer: true,
        [className]: className,
        hasQuantity: hasQuantity,
      })}
    >
      <div
        className={css({
          minus: true,
          enabled: !(value == min || disabledMinus),
        })}
        onClick={(event) =>
          !disabledMinus && value > min && change(event, --value)
        }
      >
        <Minus />
      </div>
      <p className={styles.qty}>{value}</p>
      <div
        className={css({
          plus: true,
          disabled: disabledMax,
          enabled: !disabledMax,
        })}
        onClick={(event) => !disabledMax && change(event, ++value)}
      >
        <Plus />
      </div>
    </div>
  );
};
