const unitKey = "super-unit";

export class UnitStorage {
  static getUnitConfigurations() {
    try {
      const unit = UnitStorage.getUnit();
      if (!unit) {
        return {};
      }

      const configurationsRaw = localStorage.getItem(unit.alias);
      if (!configurationsRaw) {
        return {};
      }

      return JSON.parse(configurationsRaw);
    } catch (err) {
      return null;
    }
  }

  static getUnit() {
    try {
      const unitRaw = localStorage.getItem(unitKey);
      // if (!unitRaw) {
      //     return UnitStorage.initOrder();
      // }

      return JSON.parse(unitRaw);
    } catch (err) {
      return null;
    }
  }

  static setUnit(data) {
    const unparsedData = JSON.stringify(data);
    localStorage.setItem(unitKey, unparsedData);
    if (window) {
      window.dispatchEvent(new CustomEvent("unitChanged", { detail: data }));
    }
  }
}
