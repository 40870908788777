import { useEffect, useState } from "react";

import { Button, Drawer, Modal } from "shared/components";

import styles from "styles/containers/take-out.module.scss";

import AddressList from "shared/containers/Address/AddressList";

import { OrderStorage } from "storage/OrderStorage";
import { BucketsAPI } from "api/BucketAPI";

import { Address } from "@olga-food/schemas/lib/classes/schemas/address/Address";

import { AddressStorage } from "storage/AddressStorage";
import { Store } from "shared/core/Store";
import { DeliveryType } from "@olga-food/schemas/lib/classes/schemas/order/delivery/Delivery";
import { DataLayer } from "shared/tools/DataLayer";

import Scheduling from "./Scheduling";
import { CalendarAlt, Check, Motorcycle } from "@/shared/components/Icons";

import classNames from "classnames/bind";
const css = classNames.bind(styles);

const Steps = {
  ADDRESS_LIST: 1,
  SEARCH_ADDRESS: 2,
  ADD_ADDRESS: 3,
};

const deliveryTypes = {
  DEFAULT: 1,
  SCHEDULED: 2,
};

export default function Addressess({ open, onClose, alreadyDrawer }) {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(Steps.ADDRESS_LIST);
  const [openClearCart, setOpenClearCart] = useState(false);
  const [openSelectWithdrawalType, setOpenSelectWithdrawalType] =
    useState(false);
  const [openDeliveryType, setOpenDeliveryType] = useState(false);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState(null);
  const [openScheduled, setOpenScheduled] = useState(false);
  const [hasDeliveryType, setHasDeliveryType] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    loadSelectedAddress();
  }, []);

  const loadSelectedAddress = async () => {
    const currentAddress = AddressStorage.findSelected();
    setSelectedAddress(currentAddress);
  };

  const selectAddress = async (address = null) => {
    if (address) {
      setSelectedAddress(Address.fromObject(address));
    } else {
      setSelectedAddress(null);
    }
  };

  const verifyCart = () => {
    const { cart } = OrderStorage.getOrder();

    if ((cart.catalog.items || []).length > 0) {
      return true;
    }
    return false;
  };

  const clearCart = () => {
    OrderStorage.clearCartItems();
    onClose(false);
    setOpenClearCart(false);
    setOpenSelectWithdrawalType(false);
    handleConfirmedAddress();

    const order = OrderStorage.getOrder();

    if (order.cart?.catalog?.items?.length > 0) {
      const cartDataLayer = {
        event: "remove_from_cart",
        ecommerce: {
          currency: "BRL",
          value: order.total,
          items: DataLayer.getOrderItems(order.cart?.catalog?.items),
        },
      };

      DataLayer.push({ ecommerce: null });
      DataLayer.push(cartDataLayer);
    }
  };

  const handleConfirmedAddress = async () => {
    window.dispatchEvent(new CustomEvent("updateAddress"));
    try {
      // await Store.setStoreByAddress({
      //   delivery: selectedAddress?.delivery || { address: selectedAddress },
      //   unit: selectedAddress?.unit || null,
      // });

      onClose(false);
      setTimeout(() => {
        setOpenSelectWithdrawalType(false);
      }, 0);
      setSelectedAddress(null);
    } catch (err) {
      console.log(err);
      setErrorMessage(err.message);
      setShowError(true);
    }
  };

  const isSameUnit = async () => {
    if (selectedAddress.unit) {
      return Store.id == selectedAddress.unit?.id;
    }

    const [newUnit] =
      (selectedAddress &&
        (await BucketsAPI.getNearbyUnits(selectedAddress?.geolocation))) ||
      [];
    return Store.id == newUnit?.unit?.id;
  };

  const selectDeliveryType = async () => {
    setOpenDeliveryType(false);

    if (selectedDeliveryType === deliveryTypes.DEFAULT) {
      setHasDeliveryType(true);
      localStorage.setItem("selected-delivery-type", deliveryTypes.DEFAULT);
    }

    if (selectedDeliveryType === deliveryTypes.SCHEDULED) {
      setOpenScheduled(true);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
        setSelectedAddress(null);
        setOpenSelectWithdrawalType(false);
      }}
      showBackButton={step !== Steps.ADDRESS_LIST}
      backButton={() => setStep(Steps.ADDRESS_LIST)}
      title="Meus endereços"
      showCloseButton
      alreadyDrawer={alreadyDrawer}
    >
      <AddressList
        onLoading={(isLoading) => setLoading(isLoading)}
        onSelectAddress={(address) => selectAddress(address)}
        changeStep={(step) => setStep(step)}
        currentStep={step}
        onOpenDeliveryType={() => setOpenDeliveryType(true)}
      />

      <div className={styles.footer}>
        {[Steps.ADDRESS_LIST].includes(step) && (
          <>
            {!hasDeliveryType && selectedAddress ? (
              <Button
                onClick={() => setOpenDeliveryType(true)}
                design="primary"
                className="w-100"
              >
                Selecionar tipo de entrega
              </Button>
            ) : (
              <Button
                loading={loading}
                onClick={async () =>
                  !(await isSameUnit()) && verifyCart()
                    ? setOpenClearCart(true)
                    : handleConfirmedAddress()
                }
                design="primary"
                className="w-100"
                disabled={loading || !selectedAddress}
              >
                Salvar
              </Button>
            )}

            {/* <Button
              loading={loading}
              onClick={async () =>
                !(await isSameUnit()) && verifyCart()
                  ? setOpenClearCart(true)
                  : handleConfirmedAddress()
              }
              design="primary"
              className="w-100"
              disabled={loading || !selectedAddress}
            >
              Salvar
            </Button> */}
          </>
        )}
      </div>

      <Drawer
        open={openDeliveryType}
        alreadyDrawer
        onClose={(status) => setOpenDeliveryType(status)}
        direction="bottom"
      >
        <p className={styles.alertTitle}>Defina o tipo de entrega</p>

        <div className={styles.deliveryTypes}>
          <div
            onClick={() =>
              selectedDeliveryType === deliveryTypes.DEFAULT
                ? setSelectedDeliveryType(null)
                : setSelectedDeliveryType(deliveryTypes.DEFAULT)
            }
            className={css({
              type: true,
              selected: selectedDeliveryType === deliveryTypes.DEFAULT,
            })}
          >
            <div className={styles.icon}>
              <Motorcycle />
            </div>
            <p className={styles.name}>Entrega padrão</p>
            <div className={styles.checked}>
              <Check color="#fff" size={8} />
            </div>
          </div>
          <div
            onClick={() =>
              selectedDeliveryType === deliveryTypes.SCHEDULED
                ? setSelectedDeliveryType(null)
                : setSelectedDeliveryType(deliveryTypes.SCHEDULED)
            }
            className={css({
              type: true,
              selected: selectedDeliveryType === deliveryTypes.SCHEDULED,
            })}
          >
            <div className={styles.icon}>
              <CalendarAlt />
            </div>
            <p className={styles.name}>Entrega agendada</p>
            <div className={styles.checked}>
              <Check color="#fff" size={8} />
            </div>
          </div>
        </div>
        <Button
          design="primary"
          disabled={!selectedDeliveryType}
          onClick={selectDeliveryType}
        >
          Confirmar
        </Button>
      </Drawer>

      <Scheduling
        open={openScheduled}
        onClose={() => setOpenScheduled(false)}
        onSelectScheduling={() => {
          localStorage.setItem(
            "selected-delivery-type",
            deliveryTypes.SCHEDULED
          );
          setHasDeliveryType(true);
          setOpenScheduled(false);
        }}
      />

      <Modal open={showError} alreadyModal onClose={() => setShowError(false)}>
        <p className={styles.errorMessage}>{errorMessage}</p>
        <Button
          design="primary"
          className="w-100 mt-30"
          onClick={() => setShowError(false)}
        >
          <span className="d-flex align-center">Ok</span>
        </Button>
      </Modal>

      <Drawer
        open={openClearCart}
        alreadyDrawer
        onClose={(status) => setOpenClearCart(status)}
        direction="bottom"
      >
        <p className={styles.alertTitle}>Atenção</p>
        <p className={styles.alertSubtitle}>
          Ao realizar uma alteração de endereço, seu carrinho será limpo. Deseja
          continuar?
        </p>
        <Button
          design="primary"
          className="w-100 mb-10"
          onClick={() => clearCart()}
        >
          Sim
        </Button>
        <Button
          design="secondary"
          className="w-100"
          onClick={() => setOpenClearCart(false)}
        >
          Não
        </Button>
      </Drawer>
    </Drawer>
  );
}
