import Image from "next/image";
import { useEffect, useState, useRef } from "react";
import { Builder } from "@olga-food/schemas/lib/classes/schemas/catalog/Builder";

import { Button, Textarea, Steps } from "shared/components";
import { Currency, OlgaImageLoader } from "shared/tools";
import {
	ChevronLeft,
	ChevronRight,
	Times,
} from "shared/components/Icons";

import { PizzaSizeStep } from "./Pizza/PizzaSizeStep";

import { PizzaFlavorStep } from "./Pizza/PizzaFlavorStep";
import { PizzaDoughStep } from "./Pizza/PizzaDoughStep";
import { MinusPlusInput } from "./Pizza/MinusPlusInput";
import { PizzaEdgeStep } from "./Pizza/PizzaEdgeStep";
import { PizzaExtrasStep } from "./Pizza/PizzaExtrasStep";
import { PizzaOverviewStep } from "./Pizza/PizzaOverviewStep";

import styles from "styles/containers/product-details.module.scss";

const StepsName = {
	SIZES: "Tamanho",
	DOUGHS: "Massa",
	FLAVORS: "Sabores",
	EDGES: "Borda",
	EXTRAS: "Adicionais",
	OVERVIEW: "Resumo",
};

const clone = (obj) => JSON.parse(JSON.stringify(obj));

const fixPizzaProduct = (productBase, selectedFlavors) => {
	//Deep Clone do produto
	const product = clone(productBase);

	//Corrigir sabores com base nos sabores selecionados
	product.items.forEach(flavor => {
		flavor.quantity = selectedFlavors.filter((fId) => fId == flavor.id).length;
		flavor.selected = flavor.quantity > 0;
	});

	product.edges.forEach(edge => {
		edge.quantity = +(edge.selected);
	});

	product.extra_ingredients.forEach(extra => {
		extra.selected = extra.quantity > 0;
	});

	product.doughs.forEach(dough => {
		dough.quantity = +(dough.selected);
	});

	const pizza = Builder.mountSchema(product);
	if (pizza.ref_id) {
		const selectedSize = pizza.sizes.find(s => s.ref_id == product.ref_id);
		Builder.executeOnSchema(pizza, (item) => {
			if (item.type == 'size' && item.ref_id == selectedSize.ref_id) {
				item.selected = item.parent_ref.selected;
			}
		});
	}

	//É realizado clone para evitar o toJSON da classe pizza
	const finalPizza = clone(pizza);

	finalPizza.fixedFlavor = product.fixedFlavor;
	finalPizza.selectedFlavors = selectedFlavors;

	return finalPizza;
}

const getPizzaPrice = (product) => {
	const pizza = product && Builder.mountSchema(
		JSON.parse(
			JSON.stringify(product)
		)
	);

	return pizza?.getPrice() || 0;
}

export default function Pizza({ product, onSave, onClose, isUpdate }) {
	const [productData, setProductData] = useState(product);

	const [steps, setSteps] = useState([]);
	const [activeStep, setActiveStep] = useState(null);
	const [quantity, setQuantity] = useState(product.quantity);
	const [observation, setObservation] = useState(product?.observation || "");
	const [flavorsBySection, setFlavorsBySection] = useState(product.selectedFlavors || []);

	const [mountedProduct, setMountedProduct] = useState(fixPizzaProduct(productData, flavorsBySection));

	const scrollContainer = useRef();

	useEffect(() => {
		setMountedProduct(
			fixPizzaProduct(productData, flavorsBySection)
		)
	}, [productData, flavorsBySection])

	useEffect(() => {
		const initialSteps = [];
		if (product.sizes?.length > 1) {
			initialSteps.push(StepsName.SIZES);
		}

		if (product.doughs?.length > 1) {
			initialSteps.push(StepsName.DOUGHS);
		}

		initialSteps.push(StepsName.FLAVORS);

		if (product.edges?.length > 0) {
			initialSteps.push(StepsName.EDGES);
		}

		if (product.extra_ingredients?.length > 0) {
			initialSteps.push(StepsName.EXTRAS);
		}

		initialSteps.push(StepsName.OVERVIEW);

		setActiveStep(initialSteps[0]);
		setSteps(initialSteps);

		if(product.ref_id) {
			const selectedSize = product.sizes.find((s) => s.ref_id == product.ref_id)
			if(selectedSize) {
				onSelectSize(selectedSize)
			}
		}
	}, [product]);


	useEffect(() => {
		scrollContainer.current?.scrollTo({ y: 0, animated: true });
	}, [activeStep]);


	const onChangeStep = (step) => {
		setActiveStep(step);
	};

	const prevStep = () => {
		if (activeStep === StepsName.FLAVORS) {
			if (steps.includes(StepsName.SIZES)) {
				return setActiveStep(StepsName.SIZES);
			}
		}

		if (activeStep === StepsName.EXTRAS) {
			if (steps.includes(StepsName.FLAVORS)) {
				return setActiveStep(StepsName.FLAVORS);
			}
		}

		if (activeStep === StepsName.OVERVIEW) {
			if (steps.includes(StepsName.EXTRAS)) {
				return setActiveStep(StepsName.EXTRAS);
			}
		}
	};

	const nextStep = () => {
		const currentStepIndex = Object.values(StepsName).findIndex(
			(stepName) => stepName === activeStep
		);

		const nextStepIndex = Object.values(StepsName)[currentStepIndex + 1];

		setActiveStep(nextStepIndex);
	};

	const isInvalidStep = () => {
		if (activeStep === StepsName.DOUGHS) {
			return productData.doughs.some((dough) => dough.selected === true) === false;
		}

		if (activeStep === StepsName.FLAVORS) {
			if (flavorsBySection.length == 0) {
				return true;
			}

			return flavorsBySection.filter((f) => !!f).length != flavorsBySection.length;
		}

		return false;
	};

	const save = () => {
		onSave && onSave(mountedProduct);
	};

	const renderPictureSection = () => {
		if (!product.image) {
			return (
				<div
					className={styles.noPictureClose}
					onClick={() => onClose && onClose(true)}
				>
					<Times color={"#fff"} />
				</div>
			);
		}

		return (
			<div className={styles.picture}>
				<div className={styles.close} onClick={() => onClose && onClose(true)}>
					<Times color={"#fff"} />
				</div>
				{product.image && (
					<Image
						src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${product.image}`}
						alt={product.name}
						placeholder="blur"
            blurDataURL={OlgaImageLoader.BLURRED_IMG}
            loader={OlgaImageLoader.load}
						layout="fill"
						sizes="100vw"
					/>
				)}
			</div>
		);
	};

	const renderObservationSection = () => {
		return (
			<>
				<div className={styles.sectionHeader}>
					<p className={styles.sectionTitle}>Alguma observação?</p>
				</div>

				<Textarea
					onChange={(e) => onSetObservation(e.target.value)}
					value={productData?.observation}
					placeholder="Ex. Remover cenoura"
					className="mt-20"
				/>
			</>
		);
	};

	const onSelectDough = (dough) => {
		const { doughs } = productData;
		for (const d of doughs) {
			d.selected = d.id == dough.id;
		}
		setProductData({ ...productData, doughs });
	}

	const onSelectEdge = (edge) => {
		const { edges } = productData;
		for (const e of edges) {
			e.selected = edge?.id && (e.id == edge.id);
		}
		setProductData({ ...productData, edges });
	}

	const onExtrasUpdated = (extras) => {
		setProductData({
			...productData,
			extra_ingredients: extras
		});
	}

	const onSetQuantity = (quantity) => {
		setProductData({ ...productData, quantity });
	}
	
	const onSetObservation = (observation) => {
		setProductData({ ...productData, observation });
	}

	const onSelectSize = (size) => {
		const selectedSize = { ...size, selected: true };
		setProductData({
			...productData,
			ref_id: size.ref_id,
			sizes: [selectedSize],
			items: product.items.map((flavor) => {
				const filteredfSize = flavor.sizes.filter((s) => s.ref_id == size.ref_id);
				if (product.fixedFlavor && flavor.id == product.fixedFlavor) {
					filteredfSize[0].selected = true;
				}
				return {
					...flavor,
					sizes: filteredfSize
				};
			}),
			extra_ingredients: product.extra_ingredients.map((extra) => {
				return {
					...extra,
					sizes: extra.sizes.filter((s) => s.ref_id == size.ref_id)
				};
			}),
			doughs: product.doughs.map((dough) => {
				return {
					...dough,
					sizes: dough.sizes.filter((s) => s.ref_id == size.ref_id)
				};
			}),
			edges: product.edges.map((edge) => {
				return {
					...edge,
					sizes: edge.sizes.filter((s) => s.ref_id == size.ref_id)
				};
			})
		})
	}

	return (
		<>
			<div className={styles.scrollContainer} ref={scrollContainer}>
				{activeStep !== StepsName.OVERVIEW ? (
					renderPictureSection()
				) : (
					<div className={styles.productOverview}>
						<p>Resumo do produto</p>
						<div
							className={styles.close}
							onClick={() => onClose && onClose(true)}
						>
							<Times />
						</div>
					</div>
				)}

				<div className={styles.productDetailsContainer}>
					{activeStep !== StepsName.OVERVIEW && (
						<div className={`${styles.productDetails} mb-20`}>
							<p className={styles.productName}>{product.name}</p>
							<p className={styles.productDescription}>{product.description}</p>
						</div>
					)}

					{steps.length > 0 && (
						<Steps
							steps={steps}
							onChangeStep={(step) => onChangeStep(step)}
							activeStep={activeStep}
						/>
					)}

					{activeStep === StepsName.SIZES && (
						<PizzaSizeStep
							selectedSize={productData.sizes.find(s => s.selected)}
							sizes={product.sizes}
							onSelectSize={onSelectSize}
						/>
					)}

					{activeStep === StepsName.DOUGHS && (
						<PizzaDoughStep
							doughs={productData?.doughs}
							onSelectDough={onSelectDough}
						/>
					)}

					{activeStep === StepsName.FLAVORS && (
						<PizzaFlavorStep
							product={productData}
							selectedFlavors={flavorsBySection || []}
							fixed={product.fixedFlavor}
							onFlavorsUpdated={(flavorIds) => setFlavorsBySection(flavorIds)}
						/>
					)}

					{activeStep === StepsName.EDGES && (
						<PizzaEdgeStep
							edges={productData?.edges}
							onSelectEdge={onSelectEdge}
						/>
					)}

					{activeStep === StepsName.EXTRAS && (
						<PizzaExtrasStep
							extras={productData?.extra_ingredients || []}
							onExtrasUpdated={onExtrasUpdated}
						/>
					)}

					{activeStep === StepsName.OVERVIEW && (
						<PizzaOverviewStep
							product={productData}
							selectedFlavors={flavorsBySection}
							steps={steps}
							onActiveStep={() => true}
						/>
					)}

					{(activeStep === StepsName.OVERVIEW || steps.length === 0) && renderObservationSection()}
				</div>
			</div>
			<div className={styles.footer}>
				{[StepsName.SIZES, StepsName.EXTRAS].includes(steps) &&
					activeStep === StepsName.EXTRAS && (
						<Button
							design="secondary"
							className="flex-1 mr-20"
							onClick={() => prevStep()}
						>
							<ChevronLeft color="#3D734A" />
						</Button>
					)}

				{activeStep !== StepsName.OVERVIEW && steps.length !== 0 && (
					<Button
						design="primary"
						onClick={() => nextStep()}
						disabled={isInvalidStep()}
					>
						Continuar ({Currency.formatCurrency(getPizzaPrice(mountedProduct) || 0)})
						<div className={styles.continueChevron}>
							<ChevronRight color="#fff" />
						</div>
					</Button>
				)}

				{activeStep === StepsName.OVERVIEW && (
					<>
						<MinusPlusInput
							onChange={(quantity) => onSetQuantity(quantity)}
							min={1}
							value={productData?.quantity || 1}
						/>

						<Button
							design="primary"
							className="w-100"
							disabled={quantity <= 0}
							onClick={() => save()}
						>
							{isUpdate ? "Atualizar" : "Adicionar"} ({Currency.formatCurrency(getPizzaPrice(mountedProduct) || 0)})
						</Button>
					</>
				)}
			</div>
		</>
	);
}
