import { useCallback, useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";

import { Button, Drawer, Loading } from "@/shared/components";

import styles from "styles/containers/home.module.scss";

import Brands from "./Brands";
import Location from "./Location";
import ReceiveOn from "./ReceiveOn";
import Image from "next/image";
import TakeOutByCity from "../Address/TakeOutByCity";
import TakeOutByGeolocation from "../Address/TakeOutByGeolocation";

import { OpenTableAPI } from "@/api/OpenTableAPI";

import { Holding } from "@/shared/core/Holding";
import { Brand } from "@/shared/core/Brand";
import { Store } from "@/shared/core/Store";
import { Table } from "@/shared/core/Table";
import { EcleticaCatalog } from "@/shared/core/EcleticaCatalog";

import { toast } from "react-hot-toast";

import { useRouter } from "next/router";

import slugify from "slugify";
import { OrderStorage } from "@/storage/OrderStorage";
import { Flows } from "@/shared/core/Enum";
import { DataLayer } from "@/shared/tools/DataLayer";

const Steps = {
  RECEIVE_OPTION_CHOOSE: 1,
  BRAND_CHOOSE: 2,
  LOCATION: 3,
  SET_CITY: 4,
  UNIT_CHOOSE: 5,
  SCAN_QRCODE: 6,
};

export default function ReceiveOnLocal({
  open,
  onClose,
  holding,
  alreadyDrawer,
  onlyBrands,
  inCatalog,
}) {
  const [step, setStep] = useState(null);
  const [selectedReceiveOn, setSelectedReceiveOn] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allowedCamera, setAllowedCamera] = useState(false);

  useEffect(() => {
    if (open) {
      setStep(onlyBrands ? Steps.BRAND_CHOOSE : Steps.SCAN_QRCODE);
    }
  }, [onlyBrands, open]);

  const router = useRouter();

  const brand = Brand.getBrand();

  const getTitle = () => {
    const titles = {
      [Steps.RECEIVE_OPTION_CHOOSE]: "Receber no local",
      [Steps.BRAND_CHOOSE]: "Defina a marca",
      [Steps.LOCATION]: "Onde você está?",
      [Steps.SET_CITY]: selectedBrand?.name || brand?.name,
      [Steps.UNIT_CHOOSE]: selectedBrand?.name || brand?.name,
      [Steps.SCAN_QRCODE]: "Pedir na mesa",
    };

    return titles[step];
  };

  useEffect(() => {
    if (open && step === Steps.SCAN_QRCODE) {
      DataLayer.push({
        event: "page_view",
        event_title: "Ler QR Code da mesa",
      });
    }
  }, [open, step]);

  const prevStep = useCallback(() => {
    setAllowedCamera(false);

    switch (step) {
      case Steps.RECEIVE_OPTION_CHOOSE:
        return onClose();
      case Steps.BRAND_CHOOSE:
      case Steps.SCAN_QRCODE:
        return onlyBrands ? onClose() : onClose();
      case Steps.LOCATION:
        return setStep(
          brand?.alias ? Steps.RECEIVE_OPTION_CHOOSE : Steps.BRAND_CHOOSE
        );
      case Steps.SET_CITY:
        return setStep(Steps.LOCATION);
      case Steps.UNIT_CHOOSE:
        return setStep(Steps.LOCATION);
    }
  }, [onClose, step, brand, onlyBrands]);

  const handleConfirmedAddress = async (address) => {
    window.dispatchEvent(new CustomEvent("updateAddress"));

    try {
      // await Store.setStoreByAddress({
      //   delivery: address?.delivery || { address: address },
      //   unit: address?.unit || null,
      // });

      const brandAlias = slugify(selectedBrand?.alias || brand?.alias);

      OrderStorage.setFlow(selectedReceiveOn);

      Brand.alias = brandAlias;
      onClose();
      router.push(`/${brandAlias}/cardapio`);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);

    window.onpopstate = () => {
      window.onpopstate = undefined;
      prevStep();
    };
  }, [prevStep]);

  const onQrCodeScan = async (result) => {
    if (result && !loading) {
      setLoading(true);

      const { text } = result;
      const code = text.split("/").pop();

      try {
        await Table.loadQrCode(code);

        await Store.install(Table.storeAlias);
        await Store.setStore(Table.storeAlias);
        await EcleticaCatalog.install(Table.storeAlias);

        OrderStorage.setFlow(Flows.OPEN_TABLE);

        await router.push(`/${Table.brandAlias}/cardapio`);
        onClose();
      } catch (err) {
        console.error(err);
        setLoading(false);
        return null;
      }
    }
  };

  const ViewFinder = () => (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        position: "relative",
        top: 0,
        left: 0,
        zIndex: 1,
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 1, background: "rgba(0,0,0,0.7)" }}></div>
      <div
        style={{
          boxSizing: "border-box",
          border: "25px solid rgba(0,0,0,0.7)",
          width: "100%",
          aspectRatio: 1,
        }}
      ></div>
      <div style={{ flex: 1, background: "rgba(0,0,0,0.7)" }}></div>
    </div>
  );

  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
        setAllowedCamera(false);
        setSelectedReceiveOn(null);
      }}
      title={getTitle()}
      showBackButton
      withoutPadding
      backButton={prevStep}
      alreadyDrawer={alreadyDrawer}
    >
      {loading && (
        <div className={styles.loading}>
          <Loading fullScreen />
        </div>
      )}
      {!loading && step === Steps.RECEIVE_OPTION_CHOOSE ? (
        <ReceiveOn
          selectedReceiveOn={selectedReceiveOn}
          onSelectReceiveOn={(option) => setSelectedReceiveOn(option)}
          onChangeStep={(step) => setStep(step)}
          inCatalog={inCatalog}
        />
      ) : null}

      {!loading && step === Steps.BRAND_CHOOSE ? (
        <Brands
          holding={holding || Holding.getHolding()}
          onlyBrands={onlyBrands}
          onSelectBrand={(brand) => {
            setSelectedBrand(brand);
            setStep(Steps.LOCATION);
          }}
        />
      ) : null}

      {!loading && step === Steps.LOCATION ? (
        <Location
          selectedLocation={selectedLocation}
          onSelectLocation={(location) => setSelectedLocation(location)}
          onChangeStep={(step) => setStep(step)}
        />
      ) : null}

      {!loading && step === Steps.SET_CITY ? (
        <TakeOutByCity
          brandAlias={selectedBrand?.alias || brand?.alias}
          onSelectLocation={(address) => handleConfirmedAddress(address)}
        />
      ) : null}

      {!loading && step === Steps.UNIT_CHOOSE ? (
        <TakeOutByGeolocation
          brandAlias={selectedBrand?.alias || brand?.alias}
          onSelectLocation={(address) => handleConfirmedAddress(address)}
        />
      ) : null}

      {!loading && step === Steps.SCAN_QRCODE ? (
        <>
          {!allowedCamera ? (
            <div className={styles.scanQRcodeContainer}>
              <div className={styles.scanQRcode}>
                <div className={styles.logo}>
                  <div className={styles.picture}>
                    <Image src="/logo.png" alt="" layout="fill" />
                  </div>
                </div>
                <div className={styles.mobilePicture}>
                  <Image src="/qrcodescan.png" alt="" layout="fill" />
                </div>
                <p>
                  Por favor, leia o <span>QR Code</span> da mesa com sua câmera.
                </p>
              </div>
            </div>
          ) : null}

          {!allowedCamera && (
            <div className={styles.footer}>
              <Button design="primary" onClick={() => setAllowedCamera(true)}>
                Abrir Câmera
              </Button>
            </div>
          )}

          {allowedCamera ? (
            <>
              <QrReader
                key={"qr-scanner"}
                scanDelay={800}
                onResult={(result) => onQrCodeScan(result)}
                constraints={{
                  facingMode: { ideal: "environment" },
                }}
                ViewFinder={ViewFinder}
                containerStyle={{ width: "100%", height: "100%" }}
                videoContainerStyle={{
                  paddingTop: 0,
                  width: "100%",
                  height: "100%",
                }}
                videoStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />

              <div className={styles.footer}>
                <Button
                  design="primary"
                  onClick={() => setAllowedCamera(false)}
                >
                  Fechar câmera
                </Button>
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </Drawer>
  );
}
