import { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { Button, Input, Checkbox } from "shared/components";
import { AddressStorage } from "storage/AddressStorage";
import { PaymentStorage } from "storage/PaymentStorage";
import { CustomerAPI } from "api/CustomerAPI";
import { PaymentHubAPI } from "api/PaymentHub";
import { OAuthAPI } from "api/OAuthAPI";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Info } from "shared/components/Icons";
import { Message } from "shared/components/Message";
import { Customer } from "shared/core/Customer";
import styles from "styles/containers/register.module.scss";
import { ClubAPI } from "api/ClubAPI";
import { OlgaCustomer } from "shared/core/OlgaCustomer";

import customParseFormat from "dayjs/plugin/customParseFormat";

import { DataLayer } from "@/shared/tools/DataLayer";
import { CPF } from "shared/tools";

dayjs.extend(customParseFormat);

const Steps = {
  REGISTER_USER_DATA: "REGISTER_USER_DATA",
  REGISTER_LOSE_BENEFITS: "REGISTER_LOSE_BENEFITS",
};

export default function Register({
  customerInfo,
  setStep,
  step,
  onClose,
  setIsLogged,
}) {
  const formRef = useRef();

  const [checkedSMS, setCheckedSMS] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const titles = {
      [Steps.REGISTER_USER_DATA]: "Cadastro | Cadastrar e-mail ou telefone",
      [Steps.REGISTER_LOSE_BENEFITS]: "Cadastro | Benefícios",
    };

    DataLayer.push({
      event: "page_view",
      event_title: titles[step],
    });
  }, [step]);

  const initialValues = {
    fullName: "",
    birthday: "",
    phone: "",
    email: "",
    cpf: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Campo obrigatório"),
    birthday: Yup.string()
      .optional()
      .matches(
        /([0-9]{2}\/[0-9]{2}\/[0-9]{4})/,
        "O formato deve ser DD/MM/AAAA"
      ),
    phone: Yup.lazy(() => {
      if (!customerInfo?.phone)
        return Yup.string()
          .transform((value) => value.replace(/[^0-9]/g, ""))
          .min(11, "Informe o telefone completo")
          .required("Campo obrigatório");
      else return Yup.string().optional();
    }),
    email: Yup.lazy(() => {
      if (!customerInfo?.email)
        return Yup.string()
          .required("Campo obrigatório")
          .email("Email inválido");
      else return Yup.string().optional();
    }),
    cpf: Yup.string()
      .required("Campo obrigatório")
      .test("cpf-validation", "CPF inválido", (value) =>
        CPF.validate((value || "").replace(/[^0-9]/g, ""))
      ),
    password: Yup.string()
      .min(8, "Informe ao menos 8 caracteres")
      .required("Campo obrigatório"),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "As senhas não conferem")
      .min(8, "Informe ao menos 8 caracteres")
      .required("Campo obrigatório"),
  });

  const mergeAddresses = async () => {
    const localAddresses = AddressStorage.findAll();
    try {
      const mergeAddresses = await CustomerAPI.mergeAddresses(localAddresses);
      return AddressStorage.load(mergeAddresses);
    } catch (err) {
      console.error(err);
    }
  };

  const clearPhoneFromUrl = () => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);

    if (searchParams.get("q"))
      window.history.replaceState(null, "", window.location.origin);
  };

  const loginCustomer = async (email, phone, password) => {
    try {
      const authRequest = await OAuthAPI.authenticate(email, phone, password);
      const accessToken = authRequest.getData({
        access_token: "",
      }).access_token;

      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
        sessionStorage.clear("customerPhone");
        const meResponse = await CustomerAPI.getMe();
        Customer.setCustomer(meResponse.getData({}));
        await mergeAddresses();

        clearPhoneFromUrl();
        setIsLogged(true);
      }
    } catch (err) {}
  };

  const saveRegister = async () => {
    if (!checkedTerms && [Steps.REGISTER_USER_DATA].includes(step)) {
      setError(
        "O aceite dos termos de uso e política de privacidade é obrigatório."
      );
      return false;
    }

    const payload = formRef.current.values;

    setError(null);
    setLoading(true);

    const customerData = {
      name: payload.fullName,
      birthdate: dayjs(payload.birthday, "DD/MM/YYYY").format("YYYY-MM-DD"),
      email: payload.email,
      phone: String(payload.phone).replace(/[^0-9]/g, ""),
      password: payload.password,
      extras: {
        doc: String(payload.cpf).replace(/[^0-9]/g, ""),
        marketing: Boolean(checkedSMS),
      },
    };

    if (!checkedSMS && [Steps.REGISTER_USER_DATA].includes(step)) {
      setStep(Steps.REGISTER_LOSE_BENEFITS);
      setLoading(false);
      return false;
    }

    const result = await CustomerAPI.createCustomer(customerData);
    const createErrors = result.getErrors([]);

    if (createErrors.length === 0) {
      await loginCustomer(
        customerData.email,
        customerData.phone,
        customerData.password
      );
      setLoading(false);
      onClose(false);
      setError(null);
      await OlgaCustomer.install(null, customerData?.phone);
      if (!OlgaCustomer.id) {
        await ClubAPI.confirmField({ doc: customerData?.extras?.doc });
        await OlgaCustomer.install(null, customerData?.phone);
      }

      DataLayer.push({ event: "sign_up" });

      return true;
    } else {
      setLoading(false);
      setStep(Steps.REGISTER_USER_DATA);
      setError(createErrors[0]);
      return false;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
      onSubmit={() => saveRegister()}
    >
      <Form>
        {[Steps.REGISTER_USER_DATA].includes(step) && (
          <>
            <Input
              className="mb-20"
              name="fullName"
              floatLabel="Nome e Sobrenome"
            />
            <Input className="mb-20" name="email" floatLabel="Email" />
            <div className={styles.phoneAndBirthdate}>
              <div className={styles.phone}>
                <Input
                  name="phone"
                  className="w-100"
                  floatLabel="Seu número de celular"
                  placeholder="(00) 0 0000-0000"
                  mask="(99) 9 9999-9999"
                  type="tel"
                />
              </div>
              <Input
                className="w-100"
                name="birthday"
                floatLabel="Data de nascimento"
                mask="99/99/9999"
                placeholder="DD/MM/AAAA"
                type="tel"
              />
            </div>

            <Input
              className="mb-20"
              name="cpf"
              floatLabel="Informe o CPF"
              mask="999.999.999-99"
              placeholder="000.000.000-00"
              type="tel"
            />

            <Input
              className="mb-20"
              name="password"
              floatLabel="Senha"
              type="password"
            />
            <Input
              className="mb-20"
              name="passwordConfirm"
              floatLabel="Confirme sua senha"
              type="password"
            />

            <Checkbox
              className="mb-15"
              onChange={(e) => setCheckedSMS(e)}
              checked={checkedSMS}
            >
              Aceito receber e-mails e SMS&apos;s promocionais
            </Checkbox>
            <Checkbox
              onChange={(e) => setCheckedTerms(e)}
              checked={checkedTerms}
              className="mb-20"
            >
              Declaro que li e concordo com os <strong>termos de uso</strong> e{" "}
              <strong>política de privacidade</strong>
            </Checkbox>

            {error && <Message type="error" text={error} />}

            <div className={styles.footer}>
              <Button
                type="submit"
                design="primary"
                className="w-100"
                loading={loading}
                disabled={loading}
              >
                Cadastre-se
              </Button>
            </div>
          </>
        )}

        {[Steps.REGISTER_LOSE_BENEFITS].includes(step) && (
          <div className={styles.loseBenefits}>
            <div className={styles.info}>
              <Info />
            </div>

            <p className={styles.text}>
              Você tem certeza que deseja desativar os benefícios?
            </p>
            <p className={styles.text}>
              <strong>Ao desativá-los, você perde:</strong>
            </p>

            <ul className={styles.benefits}>
              <li>Cupons e promoções exclusivas</li>
              <li>Programa de fidelidade</li>
              <li>Acúmulo de cashback</li>
            </ul>

            <Button
              design="primary"
              type="submit"
              onClick={() => setCheckedSMS(true)}
              className="w-100 mt-20"
              loading={loading}
              disabled={loading}
            >
              Manter benefícios e concluir
            </Button>

            <div className="d-flex flex-col align-center">
              <Button
                design="underline"
                type="submit"
                className="mt-25"
                disabled={loading}
              >
                Desejo perder os benefícios e concluir
              </Button>
            </div>
          </div>
        )}
      </Form>
    </Formik>
  );
}
