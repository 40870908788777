import { useRef, useState } from "react";

import { Form, Formik } from "formik";

import * as Yup from "yup";

import styles from "styles/containers/login-or-register.module.scss";

import { OrderStorage } from "storage/OrderStorage";
import { OAuthAPI } from "api/OAuthAPI";
import { CustomerAPI } from "api/CustomerAPI";
import { PaymentHubAPI } from "api/PaymentHub";

import { AddressStorage } from "storage/AddressStorage";
import { PaymentStorage } from "storage/PaymentStorage";

import { Button, Input, Message } from "shared/components";

import { Email, Phone } from "shared/tools";
import { OlgaFoodSession } from "shared/tools/OlgaFoodSession";
import { Store } from "shared/core/Store";
import { Customer } from "shared/core/Customer";
import { ClubAPI } from "api/ClubAPI";
import { OlgaCustomer } from "shared/core/OlgaCustomer";

import { DataLayer } from "shared/tools/DataLayer";

export default function Login({ setIsLogged, setStep }) {
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const Steps = {
    LOGIN: "LOGIN",
    RECOVER_PASSWORD: "RECOVER_PASSWORD",
  };

  const initialValues = {
    emailOrPhone: "",
    password: "",
  };

  const validationSchema = Yup.object({
    emailOrPhone: Yup.lazy((input) => {
      if (Phone.isMobilePhoneBR(input || ""))
        return Yup.string().required("Campo obrigatório");
      else if (Email.looksLikeMail(input || ""))
        return Yup.string()
          .required("Campo obrigatório")
          .email("Email inválido");
      else return Yup.mixed().oneOf([""], "Login inválido");
    }),
    password: Yup.string()
      .min(8, "Informe ao menos 8 caracteres")
      .required("Campo obrigatório"),
  });

  const formatPhone = (phone) => {
    let phoneNumber = phone.replace(/\D/g, "");
    phoneNumber = phoneNumber.replace(/^0/, "");

    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.replace(
        /^(\d\d)(\d{5})(\d{4}).*/,
        "($1) $2-$3"
      );
    } else if (phoneNumber.length > 7 && phoneNumber.length <= 10) {
      phoneNumber = phoneNumber.replace(
        /^(\d\d)(\d{4})(\d{0,4}).*/,
        "($1) $2-$3"
      );
    } else if (phoneNumber.length > 2 && phoneNumber.length <= 7) {
      phoneNumber = phoneNumber.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else if (phone.trim() !== "") {
      phoneNumber = phoneNumber.replace(/^(\d*)/, "($1");
    }

    return phoneNumber;
  };

  const hasAlphaChar = (me) => {
    return me.match(/[a-zA-Z]+/);
  };

  const unformatString = (string) => string.replace(/([^0-9A-z@\.\_])/g, "");

  const clearPhoneFromUrl = () => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);

    if (searchParams.get("q"))
      window.history.replaceState(null, "", window.location.origin);
  };

  const onSubmit = async (e) => {
    setLoading(true);
    setError(false);

    try {
      let email = null;
      let phone = null;

      if (Phone.isMobilePhoneBR(e.emailOrPhone || "")) {
        phone = String(e.emailOrPhone).replace(/[^0-9]/g, "");
      } else {
        email = e.emailOrPhone;
      }

      const authRequest = await OAuthAPI.authenticate(email, phone, e.password);
      const accessToken = authRequest.getData({
        access_token: "",
      }).access_token;
      if (accessToken) {
        OlgaFoodSession.session = accessToken;
        sessionStorage.clear("customerPhone");

        const meResponse = await CustomerAPI.getMe();
        const mergedCustomer = await mergeAddresses(meResponse.getData({}));

        Customer.setCustomer(mergedCustomer);
        if (mergedCustomer.extras?.addresses) {
          const { addresses } = mergedCustomer.extras;

          const lastSelectedAddress = addresses.find((a) => a.isLastSelected);
          if (lastSelectedAddress) {
            try {
              // await Store.setStoreByAddress();
              OrderStorage.setDelivery({
                ...delivery,
                address: lastSelectedAddress,
              });
            } catch (err) {
              console.log('Não foi possível selecionar o endereço', err);
            }
          }
        }

        const cardsResponse = await PaymentHubAPI.PagarMeGetCards(
          mergedCustomer.id
        );
        PaymentStorage.loadCards(cardsResponse.getData([]));

        setIsLogged(true);
        setLoading(false);
        clearPhoneFromUrl();
        await OlgaCustomer.install(null, mergedCustomer?.phone);
        if (!OlgaCustomer.id) {
          await ClubAPI.confirmField({ doc: mergedCustomer?.extras?.doc });
          await OlgaCustomer.install(null, mergedCustomer?.phone);
        }

        const method = email ? "email" : "phone";
        DataLayer.push({ event: "login", method: method });

        const customer = Customer.getCustomer();
        DataLayer.push({
          event: "visitorStatus",
          visitorLoginState: true,
          userId: customer?.id,
        });
      }

      if (!accessToken) {
        setError(true);
      }
    } catch (err) {}

    setLoading(false);
  };

  const mergeAddresses = async (customer) => {
    const localAddresses = AddressStorage.findAll();
    try {
      const mergeAddresses = await CustomerAPI.mergeAddresses(localAddresses);
      customer.extras.addresses = mergeAddresses;
      AddressStorage.load(mergeAddresses);
      return customer;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(e) => onSubmit(e)}
    >
      <Form
        autoComplete="off"
        onChange={(e) => {
          if (e.target.name == "emailOrPhone") {
            let t = e.target.value;
            const { emailOrPhone } = formRef.current.values;
            if (
              emailOrPhone &&
              !hasAlphaChar(emailOrPhone) &&
              hasAlphaChar(t)
            ) {
              t = unformatString(t);
            }
            formRef.current.setFieldValue(
              "emailOrPhone",
              hasAlphaChar(t) ? t : formatPhone(t),
              true
            );
          }
        }}
      >
        <Input
          className="mb-20"
          name="emailOrPhone"
          floatLabel="Email ou número de telefone"
        />
        <Input name="password" floatLabel="Senha" type="password" />
        {error && (
          <Message
            type="error"
            className="mt-10"
            text="Email ou senha incorrretos. Tente novamente."
          />
        )}
        <div className="d-flex justify-end mt-20">
          <Button
            design="underline"
            onClick={() => !loading && setStep(Steps.RECOVER_PASSWORD)}
          >
            Esqueceu a sua senha?
          </Button>
        </div>
        <div className={styles.footer}>
          <Button
            type="submit"
            design="primary"
            className="w-100 mt-20"
            loading={loading}
            disabled={loading}
          >
            Entrar
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
