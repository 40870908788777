import styles from "styles/components/verification-code.module.scss";

export const VerificationCode = ({ onChange, label }) => {
  const inputArr = document.getElementsByClassName("codeInput");

  const acceptOnlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  const onInputDigit = (e) => {
    let code = "";
    const curIndex = e.target.id;

    if ("0123456789".indexOf(e.key) === -1 && e.keyCode !== 8) return false;

    for (let j = 0; j < 6; j++) {
      code += inputArr[j].value;
      onChange(code);
    }

    if (e.keyCode !== 8 && e.keyCode !== 9) {
      for (let i = 0; i <= curIndex; i++) {
        const nextIndex = Number(curIndex) + 1;
        if (nextIndex < 6) inputArr[nextIndex].focus();
      }
      onChange(code);
    }

    if (e.keyCode === 8) {
      const prevIndex = Number(curIndex) - 1;
      if (prevIndex >= 0) inputArr[prevIndex].focus();
    }
  };

  return (
    <div className={styles.verificationCodeContainer}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.grid}>
        {[0, 1, 2, 3, 4, 5].map((i) => {
          return (
            <input
              className="codeInput"
              key={i}
              id={i}
              type="tel"
              maxLength="1"
              onKeyUp={(e) => onInputDigit(e)}
              onInput={(e) => acceptOnlyNumbers(e)}
              onFocus={(e) => e.target.setSelectionRange(0, 1)}
              autoFocus={i === 0}
            />
          );
        })}
      </div>
    </div>
  );
};
