import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { Button, Input, Drawer } from "shared/components";

import { CPF } from "shared/tools";
import { ClubAPI } from "api/ClubAPI";

import styles from "styles/containers/benefits.module.scss";

export default class ConfirmCPF extends React.Component {
  formRef = null;

  constructor(props) {
    super(props);
    this.state = {
      open: true,
      cpf: "",
      errorCpf: false,
      cpfNeedsUpdate: false,
      loading: false,
    };
  }

  async save(values, actions) {
    const { generateQRCode, cpfIsValid } = this.props;
    const formattedCpf = String(values.cpf).replace(/[^0-9]/g, "");

    const confirmFieldReq = await ClubAPI.confirmField({ doc: formattedCpf });
    const confirmedFieldData = confirmFieldReq.getData({});
    const confirmedFieldErrors = confirmFieldReq.getErrors({});

    if (!confirmedFieldData?.success) {
      if (confirmedFieldErrors[0] === "cpfDiff") {
        this.setState({ cpfNeedsUpdate: true });
        await this.formRef.setFieldError(
          "cpf",
          "O CPF informado não coincide com o cadastrado.*"
        );
      }
    } else {
      cpfIsValid && cpfIsValid(true);
      setTimeout(() => {
        this.onClose(false);
      }, 500);
      generateQRCode && generateQRCode(values.cpf);
    }
    this.setLoading(false);
  }
  onClose(value) {
    const { closeQRCode, codeUnlocked } = this.props;
    if (value === false && !codeUnlocked) closeQRCode && closeQRCode();
    this.setState({ open: value });
  }

  setLoading(value) {
    this.setState({ loading: value });
  }

  render() {
    const { errorCpf, cpfNeedsUpdate, open } = this.state;

    const { alreadyDrawer, showAdjustCPF } = this.props;

    const validationSchema = Yup.object({
      cpf: Yup.lazy(() => {
        if (errorCpf)
          return Yup.string()
            .required("Campo obrigatório.*")
            .length(0, "CPF inválido.*");
        else return Yup.string().required("Campo obrigatório.*");
      }),
    });
    const initialValues = {
      cpf: "",
    };

    return (
      <Drawer
        open={open}
        alreadyDrawer={alreadyDrawer}
        onClose={(status) => this.onClose(status)}
        direction="bottom"
      >
        <p className={styles.title}>Informe seu CPF</p>
        <p className={styles.subtitle}>
          Desta forma conseguiremos validar o uso do benefício.
        </p>
        <Formik
          innerRef={(ref) => (this.formRef = ref)}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => this.save(values, actions)}
        >
          {(props) => (
            <Form className="mt-20">
              <Input
                className="mb-20"
                name="cpf"
                label="CPF"
                onChange={(e) => props.handleChange(e)}
                mask="999.999.999-99"
                placeholder="000.000.000-00"
                type="tel"
              />

              <Button type="submit" design="primary" className="w-100 mt-35">
                Liberar QR Code
              </Button>
            </Form>
          )}
        </Formik>

        {cpfNeedsUpdate && (
          <div className="d-flex flex-col align-center">
            <Button
              onClick={() => showAdjustCPF && showAdjustCPF()}
              design="underline"
              className="mt-20"
            >
              Ajustar CPF
            </Button>
          </div>
        )}
      </Drawer>
    );
  }
}
