import { CatalogHelpers } from "shared/tools/CatalogHelpers";
import { OMath } from "shared/tools/OMath";

const orderKey = "super-order";
const GIFT_TYPE = {
  // ===== Valores adicioniais =====
  ADDITION: 1,

  // ===== Forma de cobrança =====
  PRICE: 2,
  PERCENT: 4,

  // ===== Cobrar com base em =====
  CART: 8,
  DELIVERY: 16,

  // ===== Tipo de uso do cupom =====
  FIRST_PURCHASE_EVER: 32,
  N_USE: 64,
  FREE_TO_USE: 128,
  NEXT_PURCHARSE: 256,
  CASHBACK_USE: 512,
  CASHBACK_EARN: 1024,
};
export class OrderStorage {
  static initOrder() {
    const orderTemplate = {
      cart: {
        catalog: {
          service: null,
          items: [],
          type: "catalog",
          quantity: 1,
          selected: true,
        },
        total: 0,
      },
      payment: { payers: [], total: 0 },
      delivery: { type: 1, address: null, total: null },
      gift: null,
      total: 0,
      flow: null,
    };

    const unparsedData = JSON.stringify(orderTemplate);
    localStorage.setItem(orderKey, unparsedData);
    return orderTemplate;
  }

  static addItemToCart(cartItem) {
    const order = OrderStorage.getOrder();
    order.cart.catalog.items.push(cartItem);
    order.cart.total = CatalogHelpers.calculateSelectedItemPrice(
      order.cart.catalog
    );
    OrderStorage.setOrder(OrderStorage.adjustTotal(order));
  }

  static updateCartItem(cartItemIndex, cartItem) {
    const order = OrderStorage.getOrder();
    order.cart.catalog.items[cartItemIndex] = cartItem;
    order.cart.total = CatalogHelpers.calculateSelectedItemPrice(
      order.cart.catalog
    );
    OrderStorage.setOrder(OrderStorage.adjustTotal(order));
  }

  static removerCartItem(cartItemIndex) {
    const order = OrderStorage.getOrder();
    order.cart.catalog.items = order.cart.catalog.items.filter(
      (_, cIndex) => cIndex != cartItemIndex
    );
    order.cart.total = CatalogHelpers.calculateSelectedItemPrice(
      order.cart.catalog
    );
    OrderStorage.setOrder(OrderStorage.adjustTotal(order));
  }

  static updateCartCatalogService(service) {
    const order = OrderStorage.getOrder();
    order.cart.catalog.service = service;
    OrderStorage.setOrder(order);
  }

  static clearCartItems() {
    const order = OrderStorage.getOrder();
    order.cart.catalog.items = [];
    order.cart.total = 0;
    OrderStorage.setOrder(OrderStorage.adjustTotal(order));
  }

  static get paymentMethod() {
    const order = OrderStorage.getOrder();
    return order.payment.payers[0];
  }

  static getDelivery() {
    const order = OrderStorage.getOrder();
    return order.delivery;
  }

  static setDelivery(delivery) {
    const order = OrderStorage.getOrder();
    order.delivery = delivery;
    OrderStorage.setOrder(OrderStorage.adjustTotal(order));
  }

  static resetDelivery() {
    const order = OrderStorage.getOrder();
    order.delivery = { type: 1, address: null, total: null };
    OrderStorage.setOrder(OrderStorage.adjustTotal(order));
  }

  static setDeliveryType(type) {
    const order = OrderStorage.getOrder();
    order.delivery.type = type;
    OrderStorage.setOrder(order);
  }

  static setPaymentPayers(payers) {
    const order = OrderStorage.getOrder();
    order.payment.payers = [payers];
    OrderStorage.setOrder(order);
  }

  static setDeliveryAddress(address) {
    const order = OrderStorage.getOrder();
    order.delivery.address = address;
    OrderStorage.setOrder(order);
  }

  static setTotal(total) {
    const order = OrderStorage.getOrder();
    order.total = total;
    OrderStorage.setOrder(order);
  }

  static setFlow(type) {
    const order = OrderStorage.getOrder();
    order.flow = type;
    OrderStorage.setOrder(order);
  }

  static get flow() {
    const order = OrderStorage.getOrder();
    return order.flow;
  }

  static getOrder() {
    try {
      const orderRaw = localStorage.getItem(orderKey);
      if (!orderRaw) {
        return OrderStorage.initOrder();
      }

      return JSON.parse(orderRaw);
    } catch (err) {
      return null;
    }
  }

  static setOrder(data) {
    const unparsedData = JSON.stringify(data);

    localStorage.setItem(orderKey, unparsedData);
    if (window) {
      window.dispatchEvent(new CustomEvent("orderUpdated", { detail: data }));
    }
  }

  static typeIs(gift, type) {
    return (gift.type & type) == type;
  }

  static getGift() {
    const order = OrderStorage.getOrder();
    return order.gift;
  }

  static setGift(gift) {
    const order = OrderStorage.getOrder();
    order.gift = gift;
    OrderStorage.setOrder(OrderStorage.adjustTotal(order));
  }

  static getGiftValue(gift, cartValue, deliveryValue = 0) {
    let baseValue = OrderStorage.typeIs(gift, GIFT_TYPE.DELIVERY)
      ? deliveryValue
      : OrderStorage.typeIs(gift, GIFT_TYPE.CART)
      ? cartValue
      : 0;
    let v = !this.typeIs(gift, GIFT_TYPE.CASHBACK_USE)
      ? gift.value || 0
      : gift.amount || 0;
    let finalGiftValue = baseValue;

    if (OrderStorage.typeIs(gift, GIFT_TYPE.PERCENT)) {
      finalGiftValue = (baseValue * Math.min(v, 100)) / 100;
    } else if (OrderStorage.typeIs(gift, GIFT_TYPE.PRICE)) {
      finalGiftValue = !OrderStorage.typeIs(gift, GIFT_TYPE.ADDITION)
        ? !OrderStorage.typeIs(gift, GIFT_TYPE.CASHBACK_USE)
          ? Math.min(baseValue, v)
          : v >= baseValue
          ? baseValue > 0
            ? OMath.sum(baseValue, -0.01)
            : baseValue
          : v
        : v;
    }

    return +OMath.times(
      Math.abs(finalGiftValue),
      OrderStorage.typeIs(gift, GIFT_TYPE.ADDITION) ? 1 : -1
    ).toFixed(2);
  }

  static adjustTotal(order) {
    const { gift } = order;
    if (gift) {
      if (OrderStorage.typeIs(gift, GIFT_TYPE.DELIVERY)) {
        gift.value = order.delivery?.total;
      }

      gift.total = OrderStorage.getGiftValue(
        gift,
        order.cart.total,
        order.delivery?.total
      );
      if (this.typeIs(gift, GIFT_TYPE.CASHBACK_USE)) {
        gift.value = OMath.times(gift.total, -1);
      }
      order.gift = gift;
    }

    order.total = +OMath.sum(
      order.cart.total || 0,
      order.delivery?.total || 0,
      order.gift?.total || 0
    ).toFixed(2);
    return order;
  }
}
