import dayjs from "dayjs";

import { Address } from "@olga-food/schemas/lib/classes/schemas/address/Address";
import { Geolocation } from "@olga-food/schemas/lib/classes/schemas/address/Geolocation";
import {
  Delivery,
  DeliveryType,
} from "@olga-food/schemas/lib/classes/schemas/order/delivery/Delivery";
import { DeliveryStrip } from "@olga-food/schemas/lib/classes/schemas/order/delivery/DeliveryStrip";

import { EnvironmentAPI } from "./EnvironmentAPI";
import { BaseStorage } from "storage/BaseStorage";
import { Environment } from "shared/core/Environment";
import { Brand } from "shared/core/Brand";

import { DeliveryChecker } from "shared/core/DeliveryChecker";

export class BucketsAPI {
  static async getEnvironmentConfigurationsByAlias(alias) {
    try {
      return EnvironmentAPI.getS3Configurations(alias);
    } catch (err) {
      return {};
    }
  }

  static async getUnits(brandAlias) {
    const units = [];

    const brandStorage = new BaseStorage({
      key: brandAlias,
    });

    const brandConfigurations = brandStorage.getData() || { unit: [] };
    if (brandConfigurations.unit) {
      await Promise.all(
        brandConfigurations.unit.map(async (u) => {
          const unitStorage = new BaseStorage({ key: u.alias });
          const unitConfigurations = unitStorage.getData();
          units.push(unitConfigurations);
        })
      );
    }

    const computedUnits = (units || []).map((unit) => {
      const {
        id,
        name,
        alias,
        address,
        delivery_time,
        delivery_limit,
        delivery_regions,
        delivery_polygons,
        working_time,
        payment_methods,
        active,
      } = unit || {};
      let computedUnit = {
        ...unit,
        id,
        name,
        alias,
        active,
        address: address || null,
        deliveryTime: delivery_time || null,
        deliveryLimit: delivery_limit || null,
        workingTime: { days: working_time?.days || [] },
        deliveryRegions: delivery_regions || [],
        deliveryPolygons: delivery_polygons || [],
        payment_methods: payment_methods || [],
      };

      return computedUnit;
    });

    return computedUnits;
  }

  static async getNearbyUnits(geolocation, brandAlias = null) {
    const customerLocation = Geolocation.fromObject(geolocation);
    const units = (await BucketsAPI.getUnits(brandAlias || Brand.alias)) || [];

    const filteredUnits = [];
    units.forEach((unit) => {
      const { active, address, deliveryRegions, deliveryPolygons, workingTime } = unit;

      if (!active || !address || !deliveryRegions || !workingTime) {
        return false;
      }

      const checker = new DeliveryChecker({ 
        address,
        deliveryRegions,
        deliveryPolygons: deliveryPolygons || [] 
      });

      const isInsideLimits = checker.isInsideLimits(customerLocation)
      if(!isInsideLimits) {
        return false;
      }

      const currentDate = dayjs();
      const days = workingTime.days || [];
      const isOpen = BucketsAPI.isOpen(
        currentDate.day(),
        currentDate.format("HHmm"),
        days
      );

      // if (!isOpen) {
      //   return false;
      // }

      const unitAddress = Address.fromObject(address);
      if (!unitAddress.geolocation) {
        return false;
      }

      const nearbyRegion = checker.isInsideDeliveryStrips(customerLocation);
      if (!nearbyRegion) {
        return false;
      }

      const deliveryStrip = DeliveryStrip.fromObject(nearbyRegion);
      deliveryStrip.min_order = nearbyRegion.minOrder;

      if(deliveryPolygons?.length > 0) {
        const poly = checker.getInsidePolygon(customerLocation);
        deliveryStrip.price = poly?.price || deliveryStrip?.price;
      }

      const delivery = Delivery.fromObject({
        address: checker.getAddress(),
        distance: checker.getDistance(customerLocation) / 1000.0,
        type: DeliveryType.DELIVER_ON_ADDRESS,
        total: deliveryStrip.price,
        deliveryStrip,
      });

      filteredUnits.push({
        unit: {
          id: unit.id,
          name: unit.name,
          alias: unit.alias,
          city: unitAddress.city,
          isOpen
        },
        delivery,
      });
    });

    return filteredUnits.sort((prev, next) => {
      if (prev.unit.isOpen === next.unit.isOpen) {
        return prev.delivery.distance - next.delivery.distance;
      }        
      return prev.unit.isOpen ? -1 : 1;
    });
  }

  static getUnitWithTakeout(units, customerLocation = null) {
    const currentDate = dayjs();
    const filteredUnits = units.map((unit) => {
      const { address, deliveryTime, workingTime } = unit;
      const filteredUnit = {
        id: unit.id,
        name: unit.name,
        alias: unit.alias,
        delivery: null,
        active: 0,
      };

      const unitAddress = Address.fromObject(address);
      const delivery = Delivery.fromObject({
        address: unitAddress,
        distance: null,
        type: DeliveryType.GET_ON_PLACE,
        total: 0,
        deliveryStrip: null,
      });

      let distanceInKm = null;
      if (customerLocation) {
        const distanceInMeters =
          unitAddress.geolocation.distanceTo(customerLocation);
        distanceInKm = distanceInMeters / 1000;
      }

      if (
        !deliveryTime || (deliveryTime?.allowLocation !== true) || workingTime.days.length === 0 || unit.active !== 1
      ) {
        filteredUnit.delivery = delivery;
        filteredUnit.delivery.distance = distanceInKm;
        return filteredUnit;
      }

      filteredUnit.delivery = delivery;
      filteredUnit.delivery.distance = distanceInKm;
      filteredUnit.delivery.deliveryStrip = DeliveryStrip.fromObject({
        price: 0,
        free_fee: true,
        minDesk: deliveryTime.minDesk,
        maxDesk: deliveryTime.maxDesk,
      });

      filteredUnit.active = +BucketsAPI.isOpen(
        currentDate.day(),
        currentDate.format("HHmm"),
        workingTime.days
      );

      filteredUnit.allowFastfila = deliveryTime?.allowFastfila;
      return filteredUnit;
    });

    return filteredUnits.sort((prev, next) => {
      const prevDistance = prev?.delivery?.distance || null;
      const nextDistance = next?.delivery?.distance || null;

      if (prevDistance === nextDistance) {
        const prevMaxDesk = prev?.delivery?.deliveryStrip?.maxDesk || 999;
        const nextMaxDesk = next?.delivery?.deliveryStrip?.maxDesk || 999;
        return prevMaxDesk - nextMaxDesk;
      }

      if (!prevDistance) {
        return 1;
      }

      if (!nextDistance) {
        return -1;
      }

      return prev.delivery.distance - next.delivery.distance;
    });
  }

   static isOpen(weekDay, hour, workingTime) {
		let available = false;
		let currentTimeInt = parseInt(hour);
	
		const adjustedWeekDay = weekDay === 0 ? 7 : weekDay;
	
		if (!workingTime[adjustedWeekDay-1]?.active || adjustedWeekDay < 1 || adjustedWeekDay > 7 || currentTimeInt < 0 || currentTimeInt >= 2400 || !workingTime || workingTime.length === 0) {
			return available;
		}
	
		let yesterdayNum = adjustedWeekDay - 1 === 0 ? 7 : adjustedWeekDay - 1;
		
		const yesterday = workingTime.find((day) => day.active && day.nextDay && +day.weeknum === yesterdayNum);
	
		const today = workingTime.find((day) => day.active && +day.weeknum === adjustedWeekDay);
		if (!today) {
			return available;
		}
	
		if (yesterday) {
			const yesterdayClose = parseInt(yesterday.close[yesterday.close.length - 1]);
			if (currentTimeInt < yesterdayClose) {
				available = true;
				return available;
			}
		}
	
		for (let openTimeIndex = 0; openTimeIndex < today.open.length; openTimeIndex++) {
			const open = parseInt(today.open[openTimeIndex]);
			let close = parseInt(today.close[openTimeIndex]);
	
			if (close < open) {
				close = 2400;
			}
	
			if (currentTimeInt >= open && currentTimeInt < close) {
				available = true;
				return available;
			}
		}
	
		return available;
  }
}