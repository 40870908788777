import { useEffect } from "react";
import { useRouter } from "next/router";

export default function ScrollToTop() {
  const router = useRouter();
  const { pathname } = router;

  useEffect(() => {
    document.querySelector("#layout").scrollTo(0, 0);
  }, [pathname]);

  return null;
}
