import { CatalogAPI } from "@/api/CatalogAPI";
import { BaseStorage } from "@/storage/BaseStorage";
import { Builder } from "@olga-food/schemas/lib/classes/schemas/catalog/Builder";
import { v4 as uuid } from 'uuid';

const ecleticaCatalogKey = "of-ecletica-catalog";

export const EcleticaProductTypes = {
  PIZZA: 'PIZZA',
  COMBINATION: 'COMBINATION',
  COMMON: 'COMMON',
};

export class EcleticaCatalog {
  static async install(environmentAlias) {
    const baseStorage = new BaseStorage({ key: ecleticaCatalogKey });

    if (environmentAlias) {
      const products = await CatalogAPI.getEcleticaProducts(environmentAlias);
      const combinations = await CatalogAPI.getEcleticaCombinations(environmentAlias);
      baseStorage.setData({
        combinations,
        products,
      });
    } else {
      baseStorage.setData({});
    }
  }

  static getCatalog() {
    try {
      const baseStorage = new BaseStorage({ key: ecleticaCatalogKey });
      const catalog = baseStorage.getData();
      return catalog || {};
    } catch (err) {
      return {};
    }
  }

  static get products() {
    const catalog = EcleticaCatalog.getCatalog();
    return catalog?.products || [];
  }

  static get combinations() {
    const catalog = EcleticaCatalog.getCatalog();
    return catalog?.combinations || [];
  }

  static isCombination(catalogItem = {}) {
    const [size] = catalogItem?.sizes || [];
    const sku = size?.sku || catalogItem?.sku;
    return EcleticaCatalog.products.some(
      (product) => product.cod_item === sku && product.combo === "S"
    );
  }

  static getFirstStep(combinationId) {
    const options = EcleticaCatalog.combinations.filter(
      (option) => option.cod_prod_princ === combinationId
    );
    const convertedStep = EcleticaCatalog.buildQuestion(options);
    return Builder.mountSchema(convertedStep);
  }

  static getNextStep(combinationId, productId) {
    const options = EcleticaCatalog.combinations.filter(
      (option) =>
        option.combo_princ === combinationId &&
        option.cod_prod_princ === productId
    );

    if (options.length === 0) {
      return null;
    }

    const convertedStep = EcleticaCatalog.buildQuestion(options);
    return Builder.mountSchema(convertedStep);
  }

  static buildQuestion(options = []) {
    const [stepConfiguration] = options;
    const nome_nivel = stepConfiguration?.nome_nivel
    const qtde_max = stepConfiguration?.qtde_max
    const qtde_obrig = stepConfiguration?.qtde_obrig

    let minOptions = 0;
    let maxOptions = null;
    let mode = "option";
    

    const hasMaxOptions = qtde_max === "S";
    const hasMinOptions = qtde_max === "N";

    if (hasMinOptions) {
      minOptions = qtde_obrig;
      maxOptions = qtde_obrig;
    }

    if (hasMaxOptions || qtde_obrig) {
      maxOptions = qtde_obrig || 1;
    }

    if (minOptions > 1 || maxOptions > 1) {
      mode = "quantity";
    }

    const convertedOptions = options.map((option) =>
      EcleticaCatalog.buildOption(option)
    );

    return {
      id: uuid(),
      type: "question",
      sku: null,
      mode: mode,
      name: nome_nivel,
      description: "",
      show: true,
      min: Number(minOptions).toFixed(),
      max: maxOptions ? Number(maxOptions).toFixed() : null,
      items: convertedOptions,
      quantity: 1
    };
  }

  static buildOption(option) {
    const price =
      option.preco_diferenciado === "S"
        ? option.vlr_unit_combo
        : option.vlr_unit_prod;

    const extrasFromOption = EcleticaCatalog.combinations.filter((link) => link.cod_prod_princ === option.cod_prod_vinc && link.combo_princ === '0');

    let convertedExtras = [];
    if (extrasFromOption.length > 0) {
      convertedExtras = [EcleticaCatalog.buildQuestion(extrasFromOption)];
    }
    
    return {
      id: uuid(),
      type: "option",
      sku: option.cod_prod_vinc,
      name: option.descr_item,
      description: "",
      show: true,
      price: Number(price),
      position: 0,
      quantity: 0,
      selected: false,
      items: convertedExtras,
    };
  }
  
  static getProductType(codItem) {
    const product = EcleticaCatalog.products.find((product) => String(product.cod_item) === String(codItem));

    let type = EcleticaProductTypes.COMMON;

    if (product?.combo === 'S') {
      type = EcleticaProductTypes.COMBINATION;
    }

    if (product?.produto_pizza === 'S') {
      type = EcleticaProductTypes.PIZZA;
    }

    return type;
  }
}
