import { Checkbox, Radio } from "shared/components";
import { Currency } from "shared/tools";

import styles from "styles/containers/product-details.module.scss";
import { MinusPlusInput } from "./MinusPlusInput";

export const OptionItem = ({
    option,
    onClick,
    singleOption,
    quantityOption = false,
}) => {
    const price = option.sizes[0]?.price || option.price || 0;
    return (
        <div
            className={styles.optionItem}
            onClick={() => !quantityOption && onClick && onClick(option)}
        >
            <div className="d-flex flex-col">
                <p className={styles.optionName}>{option.name}</p>
                {Number(price > 0) ? (
                    <p className={styles.optionPrice}>
                        {`${Currency.formatCurrency(price || 0)}`}
                    </p>
                ) : null}
            </div>

            {singleOption ? (
                <Radio
                    key={Math.random()}
                    value={option.id}
                    checked={option.selected && option.id}
                    readOnly
                />
            ) : quantityOption === false ? (
                <Checkbox
                    key={Math.random()}
                    value={option.id}
                    checked={option.selected}
                    readOnly
                />
            ) : (
                <MinusPlusInput
                    value={option.quantity || 0}
                    onChange={(newValue) => onClick && onClick(newValue)}
                />
            )}
        </div>
    );
};