import React from "react";

import styles from "styles/containers/coupon-details.module.scss";

import { Button } from "shared/components";

import { Currency, DateFormat, Benefit } from "shared/tools";

import { OrderStorage } from "storage/OrderStorage";
import { BenefitStorage } from "storage/BenefitStorage";
import { DeliveryType } from "@olga-food/schemas/lib/classes/schemas/order/delivery/Delivery";

const USE_TYPES = {
  ONLINE: 1,
  OFFLINE: 2,
  TAKEAWAY: 4,
  FASTFILA: 8,
};

const isType = (haystack, needle) => (needle & haystack) >= needle;


export default class CouponDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generatingQrCode: false,
    };
  }

  async setAsyncState(data) {
    return new Promise((resolve) => this.setState(data, resolve));
  }

  canUseWithTakeAway(type_use) {
    const delivery = OrderStorage.getDelivery();
    return (
      isType(type_use, USE_TYPES.TAKEAWAY) && delivery?.type == DeliveryType.GET_ON_PLACE
    );
  }

  canUseWithFastFila(type_use) {
    const delivery = OrderStorage.getDelivery();
    return isType(type_use, USE_TYPES.FASTFILA) && delivery?.type == DeliveryType.INDOOR;
  }

  setSelectedBenefit(coupon) {
    const { goToBenefits } = this.props;

    const gift = {
      id: coupon.coupon,
      code: `${coupon.code}-${coupon.pin}`,
      type: Benefit.parseCouponType(coupon),
      value: coupon.value,
      min_order: coupon.min_order,
      expiration: coupon.expiration?.days,
      created_at: coupon.created_at,
    };

    OrderStorage.setGift(gift);
    BenefitStorage.setSelectedBenefit(coupon);
    goToBenefits && goToBenefits();
  }

  async useOnStore() {
    const { goToQRCode } = this.props;
    await this.setAsyncState({ generatingQrCode: true });
    await this.setAsyncState({ generatingQrCode: false });
    goToQRCode && goToQRCode();
  }

  render() {
    const { coupon, goToBenefits } = this.props;
    const { generatingQrCode } = this.state;
    const sessionPhone = sessionStorage.getItem("customerPhone");
    const disabled = !Benefit.canBeUsed(coupon) && !!sessionPhone;

    return (
      <>
        <div className={styles.coupon}>
          <div className={styles.details}>
            <p className={styles.name}>{Benefit.getTitle(coupon)}</p>
            <div className={styles.code}>
              {coupon.code}-{coupon.pin}
            </div>
            <p className={styles.requirement}>
              {coupon.min_order
                ? `Para pedidos de no mínimo ${Currency.formatCurrency(
                    coupon.min_order
                  )}`
                : "Não há pedido mínimo"}
            </p>
            <p className={styles.exp}>
              {coupon?.expiration?.gain?.days || coupon?.expiration?.days || coupon?.expiration_days
                ? `Expira em ${DateFormat.formatDateOffset(
                    coupon.created_at,
                    coupon?.expiration?.gain?.days || coupon.expiration?.days || coupon?.expiration_days
                  )}`
                : "Nunca expira"}
            </p>
            {(isType(coupon.type_use, USE_TYPES.ONLINE) || this.canUseWithTakeAway(coupon.type_use) || this.canUseWithFastFila(coupon.type_use)) &&
            Benefit.canBeUsed(coupon) ? (
              <Button
                design="primary"
                className="w-100"
                onClick={() => this.setSelectedBenefit(coupon)}
                disabled={disabled}
              >
                Usar benefício no app
              </Button>
            ) : (
              <Button design="primary" className="w-100" disabled>
                Cupom indisponível perante as regras
              </Button>
            )}
            {isType(coupon.type_use, USE_TYPES.OFFLINE) && (
              <Button
                design="secondary"
                className="w-100 mt-10"
                loading={generatingQrCode}
                disabled={generatingQrCode}
                onClick={this.useOnStore.bind(this)}
              >
                Usar benefício na loja
              </Button>
            )}
          </div>
        </div>

        <div className="d-flex justify-center mt-20">
          <Button design="default" onClick={goToBenefits}>
            Ver meus benefícios
          </Button>
        </div>
      </>
    );
  }
}
