import { useState, useEffect } from "react";
import { Currency, DateFormat } from "shared/tools";
import { Loading } from "shared/components";
import { ClubAPI } from "api/ClubAPI";

import styles from "styles/containers/extract.module.scss";

import classNames from "classnames/bind";
const css = classNames.bind(styles);

const CashbackEvents = ({ open }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadEvents = async () => {
      setLoading(true);

      const cashbackHistory = await ClubAPI.getCashbackHistory();

      const groupedCashbackkHistory = cashbackHistory.reduce((acc, item) => {
        const itemIndex = acc.findIndex(
          (i) =>
            DateFormat.dateParse(i.created_at) ===
              DateFormat.dateParse(item.created_at) && i.type === item.type
        );
        if (itemIndex !== -1) {
          acc[itemIndex].value += item.value;
        } else {
          acc.push(item);
        }
        return acc;
      }, []);

      const sortedCashbackHistory = groupedCashbackkHistory.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      setEvents(sortedCashbackHistory || []);
      setLoading(false);
    };

    loadEvents();
  }, [open]);

  const CashbackEventTypes = {
    INPUT: "CREDIT",
    USE_OF_BALANCE: "DEBIT",
  };

  const formatDate = (created, expirate) => {
    const createdDate = new Date(created);
    const expirateDate = new Date(expirate);
    const diff = expirateDate.getTime() - createdDate.getTime();
    var Difference_In_Days = diff / (1000 * 3600 * 24);
    return (
      parseInt(Difference_In_Days) > 0 &&
      `Expira em ${parseInt(Difference_In_Days)} dias`
    );
  };

  return (
    <>
      {loading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : events.length > 0 ? (
        <div className={styles.extractContainer}>
          {events.map((event, index) => {
            return (
              <div className={styles.event} key={index}>
                <div className={styles.details}>
                  <p className={styles.type}>{event.description}</p>
                  <div className="d-flex">
                    <span className={styles.date}>
                      {DateFormat.dateParse(event.created_at)}
                    </span>
                    {event.expiration && (
                      <span className={styles.exp}>
                        {event.type === CashbackEventTypes.INPUT &&
                          formatDate(event.created_at, event.expiration)}
                      </span>
                    )}
                  </div>
                </div>

                <span
                  className={css({
                    value: true,
                    input: event.type === CashbackEventTypes.INPUT,
                    output: event.type !== CashbackEventTypes.INPUT,
                  })}
                >
                  {event.type === CashbackEventTypes.INPUT ? "+ " : "- "}
                  {Currency.formatCurrency(event.value)}
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.emptyEvents}>
          <p>Não há nenhum registro de cashback</p>
        </div>
      )}
    </>
  );
};

export default CashbackEvents;
