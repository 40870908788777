import { useState } from "react";
import { BucketsAPI } from "api/BucketAPI";

import dayjs from "dayjs";

import Image from "next/image";

import styles from "styles/containers/store-status.module.scss";
import useEventListener from "shared/hooks/useEventListener";
import { Store, StoreEvents } from "shared/core/Store";
import { Brand, BrandEvents } from "shared/core/Brand";

export const BrandStatus = () => {
  const [_, setTimestamp] = useState(Date.now());
  useEventListener(BrandEvents.BRAND_CHANGED, () => {
    setTimestamp(Date.now());
  });

  return (
    <div className={styles.storeStatusContainer}>
      {Brand.avatar && (
        <div className={styles.storeLogo}>
          <Image src={Brand.avatar} alt="" layout="fill" sizes="100px" />
        </div>
      )}
      <p className={styles.storeName}>{Brand.name}</p>
    </div>
  );
};

export const StoreStatus = () => {
  const [store, setStore] = useState(Store.getStore());
  useEventListener(StoreEvents.STORE_CHANGED, (e) => {
    setStore(Store.getStore());
  });

  const checkStoreStatus = () => {
    const currentDate = dayjs();
    const days = Store?.working_time?.days || [];
    const isOpen = BucketsAPI.isOpen(
      currentDate.day(),
      currentDate.format("HHmm"),
      days
    );
    return isOpen;
  };

  const isOpen = checkStoreStatus();

  return (
    <div className={styles.storeStatusContainer}>
      {Brand.avatar && (
        <div className={styles.storeLogo}>
          <Image src={Brand.avatar} alt="" layout="fill" sizes="100px" />
        </div>
      )}

      <p className={styles.storeName}>{Brand.name}</p>
      {Store.name && <p className={styles.unitName}>{Store.name}</p>}
      <div
        className={`${styles.storeStatus} ${
          isOpen ? styles.opened : styles.closed
        }`}
      >
        <span className={styles.dot} />
        <p>{isOpen ? "Aberto" : "Fechado"} agora</p>
      </div>
    </div>
  );
};
