import { useEffect, useState } from "react";

import styles from "styles/containers/profile.module.scss";

import { Button, Drawer } from "@/shared/components";
import {
  Exit,
  User,
  SavesLifes,
  UserTwo,
  MapMarker,
  UserLight,
} from "@/shared/components/Icons";

import Addressess from "../Address/Addressess";
import CustomerData from "./CustomerData";
import Help from "../Help";
import LoginOrRegister from "./LoginOrRegister";

import { Customer } from "@/shared/core/Customer";
import { OlgaFoodSession } from "@/shared/tools/OlgaFoodSession";
import { DataLayer } from "@/shared/tools/DataLayer";

export default function Profile({ open, onClose, alreadyDrawer }) {
  const [customer, setCustomer] = useState(null);
  const [openLogout, setOpenLogout] = useState(false);
  const [showCardModal, setShowCardModal] = useState(false);
  const [openUserData, setOpenUserData] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [openAddressList, setOpenAddressList] = useState(false);
  const [openLoginOrRegister, setOpenLoginOrRegister] = useState(false);

  const logout = () => {
    OlgaFoodSession.resetSession();
    setCustomer(null);
    setOpenLogout(false);

    DataLayer.push({ event: "logout" });
    DataLayer.push({
      event: "visitorStatus",
      visitorLoginState: false,
      userId: null,
    });
  };

  const onCustomerChanged = async () => {
    setCustomer(Customer.getCustomer());
  };

  useEffect(() => {
    onCustomerChanged();

    Customer.onChange(onCustomerChanged);
    return () => Customer.offChange(onCustomerChanged);
  }, []);

  useEffect(() => {
    if (open) {
      DataLayer.push({
        event: "page_view",
        page_title: "Perfil",
      });
    }
  }, [open])

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Minha conta"
      showBackButton
      backButton={onClose}
      alreadyDrawer={alreadyDrawer}
    >
      <div className={styles.scrollContainer}>
        {!customer?.name && (
          <>
            <Button
              design="primary"
              onClick={() => setOpenLoginOrRegister(true)}
            >
              Entre ou cadastre-se
            </Button>
            <ul>
              <li onClick={() => setOpenHelp(true)}>
                <div className={styles.icon}>
                  <SavesLifes />
                </div>
                <span>Ajuda</span>
              </li>
            </ul>
          </>
        )}

        {customer?.name && (
          <>
            <div className={styles.greetings}>
              <UserTwo />
              <p>Olá{customer?.name ? `, ${customer?.name}` : ""}!</p>
            </div>

            <ul>
              <li onClick={() => setOpenUserData(true)}>
                <div className={styles.icon}>
                  <UserLight color="#737373" />
                </div>
                <span>Editar meus dados</span>
              </li>
              {/* <li onClick={() => setOpenAddressList(true)}>
                <div className={styles.icon}>
                  <MapMarker size={12} />
                </div>
                <span>Meus endereços</span>
              </li> */}
              <li onClick={() => setOpenHelp(true)}>
                <div className={styles.icon}>
                  <SavesLifes />
                </div>
                <span>Ajuda</span>
              </li>
              <li onClick={() => setOpenLogout(true)}>
                <div className={styles.icon}>
                  <Exit size={18} />
                </div>
                <span>Sair</span>
              </li>
            </ul>
          </>
        )}
      </div>

      <LoginOrRegister
        open={openLoginOrRegister}
        onClose={() => setOpenLoginOrRegister(false)}
        setIsLogged={() => {
          setCustomer(Customer.getCustomer());
          setOpenLoginOrRegister(false);
        }}
      />

      <CustomerData
        open={openUserData}
        onClose={(status) => setOpenUserData(status)}
        setLogout={logout}
      />

      <Drawer
        open={openHelp}
        onClose={(status) => setOpenHelp(status)}
        title="Ajuda"
        showCloseButton
      >
        <Help />
      </Drawer>

      <Addressess
        open={openAddressList}
        onClose={(status) => setOpenAddressList(status)}
      />

      <Drawer
        open={showCardModal}
        onClose={(status) => setShowCardModal(status)}
        direction="bottom-center"
      >
        <div className={styles.logout}>
          <p>Selecione um endereço</p>

          <Button
            design="primary"
            className="mb-10"
            onClick={() => {
              setShowCardModal(false);
              setOpenAddressList(true);
            }}
          >
            Adicionar endereço
          </Button>
          <Button
            design="secondary"
            className="mb-10"
            onClick={() => setShowCardModal(false)}
          >
            Cancelar
          </Button>
        </div>
      </Drawer>
      <Drawer
        open={openLogout}
        onClose={(status) => setOpenLogout(status)}
        direction="bottom"
      >
        <div className={styles.logout}>
          <p>Deseja sair?</p>

          <Button
            design="primary"
            className="mb-10"
            onClick={() => setOpenLogout(false)}
          >
            Cancelar
          </Button>
          <Button design="secondary" onClick={() => logout()}>
            Sair
          </Button>
        </div>
      </Drawer>
    </Drawer>
  );
}
