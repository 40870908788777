import { useEffect, useState } from "react";

import { Button, Drawer, Input, Radio } from "@/shared/components";

import styles from "@/styles/containers/close-table.module.scss";

import {
  Blocked,
  CashRegister,
  CheckCircle2,
  GiftLight,
  PhoneLight,
  WaiterLight,
} from "@/shared/components/Icons";

import Club from "../Benefits/Club";

import classNames from "classnames/bind";
import PaymentMethodsReceiveOn from "../Payment/PaymentMethodsReceiveOn";
import Benefits from "../Benefits/Benefits";
import { OpenTableAPI } from "@/api/OpenTableAPI";
import { Store } from "@/shared/core/Store";
import { Table } from "@/shared/core/Table";
const css = classNames.bind(styles);

const ApplyOptions = {
  APPLY: "APPLY",
  NO_APPLY: "NO_APPLY",
};

const FinishOptions = {
  WAITER: "WAITER",
  DESK: "DESK",
  APP: "APP",
};

const Steps = {
  APPLY_BENEFIT: 1,
  FINISH_TABLE: 2,
};

export default function CloseTable({
  open,
  onClose,
  onCloseWaiter,
  onCloseDesk,
}) {
  const [loadingClose, setLoadingClose] = useState(false);
  const [error, setError] = useState("");
  const [step, setStep] = useState(Steps.APPLY_BENEFIT);
  const [applyBenefit, setApplyBenefit] = useState(null);
  const [selectedFinishOption, setSelectedFinishOption] = useState(null);
  const [openClub, setOpenClub] = useState(false);
  const [openWaiterSuccess, setOpenWaiterSuccess] = useState(false);
  const [openDeskSuccess, setOpenDeskSuccess] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);

  useEffect(() => {
    if (open) {
      setApplyBenefit(null);
      setSelectedFinishOption(null);
      setLoadingClose(false);
      setStep(Steps.FINISH_TABLE);
    }
  }, [open]);

  const prevStep = () => {
    switch (step) {
      case Steps.APPLY_BENEFIT:
        return onClose();
      case Steps.FINISH_TABLE:
        return onClose();
    }
  };

  const finishTable = async () => {
    if (selectedFinishOption === FinishOptions.WAITER) {
      await callWaiter();
    }

    if (selectedFinishOption === FinishOptions.DESK) {
      return setOpenDeskSuccess(true);
    }

    // if (selectedFinishOption === FinishOptions.APP) return setOpenPayment(true);
  };

  const callWaiter = async () => {
    setLoadingClose(true);

    const waiterResponse = await OpenTableAPI.callWaiter(Store.id, Table.id);

    if (waiterResponse.getErrors([]).length > 0) {
      setLoadingClose(false);
      return setError(waiterResponse.getErrors()[0]);
    }

    setLoadingClose(false);
    setOpenWaiterSuccess(true);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      showBackButton={step === Steps.APPLY_BENEFIT}
      backButton={prevStep}
      title={step === Steps.APPLY_BENEFIT ? "Aplicar benefício" : ""}
    >
      {step === Steps.APPLY_BENEFIT && (
        <>
          <div className={styles.applyBenefitOptions}>
            <p className={styles.title}>
              Você gostaria de aplicar um <span>benefício</span>?
            </p>

            <div className={styles.options}>
              <div
                className={css({
                  option: true,
                  selected: applyBenefit === ApplyOptions.APPLY,
                })}
                onClick={() => setApplyBenefit(ApplyOptions.APPLY)}
              >
                <div className={styles.icon}>
                  <GiftLight />
                </div>
                <p className={styles.name}>Aplicar benefício</p>
              </div>
              <div
                className={css({
                  option: true,
                  selected: applyBenefit === ApplyOptions.NO_APPLY,
                })}
                onClick={() => setApplyBenefit(ApplyOptions.NO_APPLY)}
              >
                <div className={styles.icon}>
                  <Blocked />
                </div>
                <p className={styles.name}>Não, obrigado</p>
              </div>
            </div>
          </div>

          {applyBenefit && (
            <div className={styles.footer}>
              <Button
                design="primary"
                onClick={() =>
                  applyBenefit === ApplyOptions.APPLY
                    ? setOpenClub(true)
                    : setStep(Steps.FINISH_TABLE)
                }
              >
                Próximo
              </Button>
            </div>
          )}
        </>
      )}

      <Benefits
        open={openClub}
        onClose={() => setOpenClub(false)}
        withoutTransition
        withoutFade
        showJumpButton
        onJump={() => {
          setOpenClub(false);
          setStep(Steps.FINISH_TABLE);
        }}
      />

      {step === Steps.FINISH_TABLE && (
        <div className={styles.finishTableOptions}>
          <p className={styles.title}>Como deseja fechar a mesa?</p>
          <p className={styles.subtitle}>
            Selecione uma das opções abaixo para finalizar a sua mesa.
          </p>

          <div className={styles.options}>
            {Store.tables?.payment?.waiter && (
              <div
                className={css({
                  option: true,
                  selected: selectedFinishOption === FinishOptions.WAITER,
                })}
                onClick={() => setSelectedFinishOption(FinishOptions.WAITER)}
              >
                <div className="d-flex align-center">
                  <div className={styles.icon}>
                    <WaiterLight />
                  </div>
                  <p>Chamar garçom</p>
                </div>
                <Radio
                  readOnly
                  family="option"
                  value={FinishOptions.WAITER}
                  checked={selectedFinishOption}
                />
              </div>
            )}

            {Store.tables?.payment?.desk && (
              <div
                className={css({
                  option: true,
                  selected: selectedFinishOption === FinishOptions.DESK,
                })}
                onClick={() => setSelectedFinishOption(FinishOptions.DESK)}
              >
                <div className="d-flex align-center">
                  <div className={styles.icon}>
                    <CashRegister />
                  </div>
                  <p>Pagar pelo balcão</p>
                </div>
                <Radio
                  readOnly
                  family="option"
                  value={FinishOptions.DESK}
                  checked={selectedFinishOption}
                />
              </div>
            )}

            {/* <div
              className={css({
                option: true,
                selected: selectedFinishOption === FinishOptions.APP,
              })}
              onClick={() => setSelectedFinishOption(FinishOptions.APP)}
            >
              <div className="d-flex align-center">
                <div className={styles.icon}>
                  <PhoneLight />
                </div>
                <p>Pagar pelo app</p>
              </div>
              <Radio
                readOnly
                family="option"
                value={FinishOptions.APP}
                checked={selectedFinishOption}
              />
            </div> */}
          </div>

          <div className={styles.footer}>
            <Button
              design="primary"
              className="mb-15"
              disabled={loadingClose || !selectedFinishOption}
              onClick={finishTable}
              loading={loadingClose}
            >
              Confirmar
            </Button>
            <Button design="secondary" onClick={() => onClose()}>
              Voltar
            </Button>
          </div>
        </div>
      )}

      <Drawer open={openWaiterSuccess} alreadyDrawer direction="bottom-center">
        <div className="d-flex justify-center mb-20">
          <CheckCircle2 />
        </div>
        <p className={styles.alertTitle}>O garçom foi chamado</p>
        <p className={styles.alertSubtitle}>
          Espere alguns instantes e um garçom irá atender a sua mesa em breve.
        </p>
        <Button
          design="primary"
          className="w-100"
          onClick={() => {
            setOpenWaiterSuccess(false);
            onCloseWaiter();
          }}
        >
          Voltar para mesa
        </Button>
      </Drawer>

      <Drawer open={openDeskSuccess} alreadyDrawer direction="bottom-center">
        <div className="d-flex justify-center mb-20">
          <CheckCircle2 />
        </div>
        <p className={styles.alertTitle}>A mesa foi fechada</p>
        <p className={styles.alertSubtitle}>
          Você pode ir ao balcão realizar o seu pagamento.
        </p>
        <Button
          design="primary"
          className="w-100"
          onClick={() => {
            setOpenDeskSuccess(false);
            onCloseDesk();
          }}
        >
          Voltar para mesa
        </Button>
      </Drawer>

      <Drawer open={openPayment}>
        <PaymentMethodsReceiveOn
          onClose={() => {
            setOpenPayment(false);
            onClose();
          }}
        />
      </Drawer>

      <Drawer open={error?.length > 0} alreadyDrawer direction="bottom-center">
        <p className={styles.alertTitle}>Tente novamente!</p>
        <p className={styles.alertSubtitle}>{error}</p>
        <Button
          design="primary"
          className="w-100"
          onClick={() => {
            setError("");
          }}
        >
          Ok
        </Button>
      </Drawer>
    </Drawer>
  );
}
