import { useEffect, useRef, useState } from "react";

import styles from "styles/containers/product-details.module.scss";

import Image from "next/image";

import { Button, Checkbox, Textarea } from "shared/components";
import { Minus, Plus, Times } from "shared/components/Icons";

import { number, object } from "yup";

import { CatalogHelpers, Currency, OlgaImageLoader } from "shared/tools";
import { OMath } from "shared/tools/OMath";
import { QuestionItem } from "./Step/QuestionItem";

import { Table } from "@/shared/core/Table";

import classNames from "classnames/bind";
const css = classNames.bind(styles)

const prepareProduct = (product) => {
  if ((product.sizes || []).filter((s) => s.selected).length == 0) {
    product.sizes[0].selected = true;
  }

  product.selected = true;

  return product;
};

export default function Step({ product, onSave, onClose, isUpdate }) {
  const [localProduct, setLocalProduct] = useState(prepareProduct(product));
  const [valid, setValid] = useState(false);

  const [isDigitalMenu, setIsDigitalMenu] = useState(false);

  const scrollContainer = useRef();

  useEffect(() => {
    const externalId = Table.externalId;
    if (externalId === Table.isDigitalMenu) setIsDigitalMenu(true);
    else setIsDigitalMenu(false);

    validate();
  }, [localProduct]);

  const validateExtrasSelected = () => {
    // Checa se há questões obrigatórias
    if (localProduct.items?.length > 0) {
      const selectedExtras =
        localProduct.items.filter((q) => {
          //Se não tem minimo, não está incompleto
          if (q.min == 0) {
            return false;
          }

          // Situação onde a etapa é obrigatória e a etapa não tem nenhum item disponível.
          if (q.min > 0 && q.items.length == 0) {
            return true;
          }

          //Se o não tem opções listadas, passa.
          if (q.items?.length == 0) {
            return false;
          }

          const seletedOptions = q.items.filter((o) => o.selected) || [];
          if (seletedOptions[0]?.quantity >= q.min) {
            return false;
          }

          const seletedOptionsQuantity = seletedOptions.reduce(
            (acc, selectedOption) => acc + selectedOption.quantity,
            0
          );

          if (
            seletedOptionsQuantity < q.min ||
            seletedOptionsQuantity > q.max
          ) {
            return true;
          }

          // Se tem ao menos um item selecionado, não está incompleto
          return !q.items.find((o) => o.selected);
        }).length > 0;

      return selectedExtras;
    }
  };

  useEffect(() => {
    validate();
  }, []);

  const validate = async () => {
    const productSchema = object().shape({ quantity: number().required() });

    setValid(
      (await productSchema.isValid(localProduct)) && !validateExtrasSelected()
    );
  };

  const onProductChange = (data) => {
    setLocalProduct({ ...localProduct, ...data });
    validate();
  };

  const uncheckZeroQuantity = (obj) => {
    if (obj?.hasOwnProperty("selected") && obj?.hasOwnProperty("quantity")) {
      if (obj.quantity === 0) obj.selected = false;
    }

    for (const key in obj) {
      if (typeof obj[key] === "object") {
        uncheckZeroQuantity(obj[key]);
      }
    }
  };

  const save = () => {
    uncheckZeroQuantity(localProduct);

    onSave && onSave(localProduct);
  };

  const calculateItemPrice = (item) => {
    const props = ["items", "sizes", "extra_ingredients", "doughs", "edges"];

    let total =
      Math.round((item.price || 0) * 100) *
      (item.selected !== false && item.quantity > 0 ? 1 : 0);
    for (const key of props) {
      if (item[key] && item[key].length > 0) {
        for (const subItem of item[key]) {
          total += calculateItemPrice(subItem) * 100;
        }
      }
    }

    return +OMath.div(OMath.times(total, item.quantity), 100).toFixed(2);
  };

  const renderPictureSection = () => {
    if (!product.image) {
      return (
        <div
          className={styles.noPictureClose}
          onClick={() => onClose && onClose(true)}
        >
          <Times color={"#fff"} />
        </div>
      );
    }

    return (
      <div className={styles.picture}>
        <div className={styles.close} onClick={() => onClose && onClose(true)}>
          <Times color={"#fff"} />
        </div>
        {product.image && (
          <Image
            src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${product.image}`}
            alt={product.name}
            placeholder="blur"
            blurDataURL={OlgaImageLoader.BLURRED_IMG}
            loader={OlgaImageLoader.load}
            layout="fill"
            sizes="100vw"
          />
        )}
      </div>
    );
  };

  const isPromotionalPrice = (product) => {
    if (!product?.sizes || product?.sizes?.length <= 0) return false;

    return !!product.sizes.find((size) => size.promo_price === true);
  };

  const renderProductStartPrice = () => {
    const oldPrice = CatalogHelpers.mountOldPrice(localProduct);
    const hasSizes =
      (localProduct.sizes || []).length > 1 ||
      (localProduct.items || []).length > 0;

    return isPromotionalPrice(localProduct) && oldPrice && !hasSizes ? (
      <p className={styles.fromPrice}>
        <strike>{oldPrice}</strike>&nbsp;&nbsp;
        <span className={styles.price}>
          {CatalogHelpers.mountPrice(localProduct)}
        </span>
      </p>
    ) : isPromotionalPrice(localProduct) && hasSizes ? (
      <p className={styles.fromPrice}>
        A partir de&nbsp;
        <span className={styles.price}>
          {CatalogHelpers.mountPrice(localProduct)}
        </span>
        &nbsp;&nbsp;
        <strike>{oldPrice}</strike>
      </p>
    ) : hasSizes ? (
      <p className={styles.fromPrice}>
        A partir de{" "}
        <span className={styles.price}>
          {CatalogHelpers.mountPrice(localProduct)}
        </span>
      </p>
    ) : (
      <p className={styles.fromPrice}>
        {CatalogHelpers.mountPrice(localProduct)}
      </p>
    )
  };

  const renderQuantitySection = () => {
    return (
      <div className={styles.quantityContainer}>
        <span
          className={`${styles.minus} ${
            localProduct.quantity <= 1 ? styles.disabled : styles.enabled
          }`}
          onClick={() =>
            localProduct.quantity > 1 &&
            onProductChange({ quantity: --localProduct.quantity })
          }
        >
          <Minus />
        </span>
        <p className={styles.qty}>{localProduct.quantity}</p>
        <span
          className={`${styles.plus} ${styles.enabled}`}
          onClick={() => onProductChange({ quantity: ++localProduct.quantity })}
        >
          <Plus />
        </span>
      </div>
    );
  };

  const renderSubQuestionSection = (option, questionIndexBase, onChange) => {
    return option?.items?.map((subQuestion, subQuestionIndex) => {
      return (
        subQuestion?.items?.length > 0 && (
          <QuestionItem
            key={subQuestionIndex}
            questionIndex={questionIndexBase.index++}
            question={subQuestion}
            onChange={(q) => onChange(q, subQuestionIndex)}
          />
        )
      );
    });
  };

  const renderQuestionSection = () => {
    const qObj = { index: 0 };

    return localProduct.items?.map((question, questionIndex) => {
      //Encontra as opções selecionadas
      const selectedOptions = Object.entries(question?.items || {}).filter(
        ([_, option]) => option.selected === true
      );
      return (
        <>
          {question?.items?.length > 0 && (
            <QuestionItem
              key={questionIndex}
              questionIndex={qObj.index++}
              question={question}
              onChange={(question) => {
                localProduct.items[questionIndex] = question;
                onProductChange({ items: localProduct.items });
              }}
            />
          )}
          {
            //Para cada opção selecionada, renderiza suas questões
            selectedOptions.map(([oIndex, option]) =>
              renderSubQuestionSection(
                option,
                qObj,
                (subQuestion, subQuestionIndex) => {
                  //Atualiza a questão da opção renderizada
                  option.items[subQuestionIndex] = subQuestion;
                  //Atualiza a opção da questão renderizada
                  question.items[oIndex] = option;
                  //Atualiza o produto com a questão corrente
                  localProduct.items[questionIndex] = question;
                  onProductChange({ items: localProduct.items });
                }
              )
            )
          }
        </>
      );
    });
  };

  const renderSizesSection = () => {
    const selectedSize = localProduct.sizes.find((size) => size.selected);
    const isPromotionalPrice = selectedSize.promo_price;

    return (
      <>
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>Escolha o tamanho</p>
          <span className={styles.requiredField}>Obrigatório</span>
        </div>

        <div className={styles.sizes}>
          <span className={styles.sizePrice}>
            {isPromotionalPrice && (
              <>
                <strike className={styles.promoPrice}>
                  {Currency.formatCurrency(selectedSize.old_price)}
                </strike>
                &nbsp;&nbsp;
              </>
            )}
            {Currency.formatCurrency(selectedSize.price)}
          </span>

          {localProduct.sizes.map((size, sizeIndex) => (
            <div
              key={sizeIndex}
              className={`${styles.size} ${
                size.selected ? styles.selected : ""
              }`}
              onClick={() => {
                localProduct.sizes.forEach((s) => (s.selected = false));
                localProduct.sizes[sizeIndex].selected = true;
                onProductChange({ sizes: localProduct.sizes });
              }}
            >
              <p className={styles.name}>{size.name}</p>
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderExtrasSection = () => {
    const currentSize = localProduct.sizes.find((s) => s.selected);

    const extras = localProduct.extra_ingredients.map((extra) => {
      const extraSize = extra.sizes.find(
        (size) => size.ref_id === currentSize.ref_id
      );

      return {
        ...extra,
        price: extraSize?.price || extra.price,
      };
    });

    return (
      <div className="d-flex flex-col">
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>Adicionais</p>
        </div>
        <div className={styles.optionItems}>
          {extras.map((e, eIndex) => {
            return (
              <div
                className={styles.optionItem}
                key={eIndex}
                onClick={() => {
                  extras[eIndex].selected = !extras[eIndex].selected;
                  onProductChange({
                    extra_ingredients: extras,
                  });
                }}
              >
                <div className="d-flex flex-col">
                  <p className={styles.optionName}>{e.name}</p>
                  <div className="d-flex align-center mt-10">
                    <span className={styles.optionPrice}>
                      {`${Currency.formatCurrency(e.price)}`}
                    </span>
                  </div>
                </div>
                <Checkbox
                  value={e.name}
                  checked={e.selected}
                  key={`extraingredient-${e.id}`}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderObservationSection = () => {
    return (
      <>
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>Alguma observação?</p>
        </div>

        <Textarea
          onChange={(e) => onProductChange({ observation: e.target.value })}
          value={localProduct.observation || ""}
          placeholder="Ex. Remover cenoura"
          className="mt-20"
          max={128}
        />

        <span className={styles.obsLength}>
          {localProduct?.observation?.length || 0}/128
        </span>
      </>
    );
  };

  return (
    <>
       <div className={css({ scrollContainer: true, isDigitalMenu: isDigitalMenu })} ref={scrollContainer}>
        {renderPictureSection()}

        <div className={styles.productDetailsContainer}>
          <div className={styles.productDetails}>
            <p className={styles.productName}>{product.name}</p>
            <p className={styles.productDescription}>{product.description}</p>
            {renderProductStartPrice()}
          </div>

          {localProduct.sizes.length > 1 && renderSizesSection()}

          {localProduct.items.length > 0 && renderQuestionSection()}

          {localProduct.extra_ingredients.length > 0 && renderExtrasSection()}

          {!isDigitalMenu && renderObservationSection()}
        </div>
      </div>
      {!isDigitalMenu && (
        <div className={styles.footer}>
          {renderQuantitySection()}

          <Button
            design="primary"
            className="w-100"
            disabled={!valid}
            onClick={() => save()}
          >
            {isUpdate ? "Atualizar" : "Adicionar"} (
            {Currency.formatCurrency(calculateItemPrice(localProduct))})
          </Button>
        </div>
      )}
    </>
  );
}
