import { useEffect, useRef, useState } from "react";

import styles from "styles/components/steps.module.scss";

import classNames from "classnames/bind";

export const Steps = ({ steps, onChangeStep, activeStep }) => {
  let cx = classNames.bind(styles);

  const lineRef = useRef();
  const firstStepRef = useRef();

  const [completedSteps, setCompletedSteps] = useState([activeStep]);

  useEffect(() => {
    lineRef.current.setAttribute(
      "style",
      `width: ${firstStepRef.current.offsetWidth}px`
    );
  }, [lineRef]);

  const handleScroll = (e) => {
    const allSteps = document.querySelectorAll(
      `.${styles.steps} .${styles.completed}`
    );

    const lastCompletedStep = allSteps[allSteps.length - 1];

    const el =
      lastCompletedStep.offsetLeft > e.offsetLeft ? lastCompletedStep : e;

    el.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });

    lineRef.current.setAttribute(
      "style",
      `width: ${el.offsetLeft + el.offsetWidth}px`
    );
  };

  const scrollToStep = (e, step) => {
    handleScroll(e.target);
    onChangeStep(step);
  };

  useEffect(() => {
    if (!completedSteps.includes(activeStep)) {
      setCompletedSteps([...completedSteps, activeStep]);
    }

    const allSteps = document.querySelectorAll(
      `.${styles.steps} .${styles.completed}`
    );

    const lastCompletedStep = allSteps[allSteps.length - 1];

    handleScroll(lastCompletedStep);
  }, [activeStep, completedSteps]);

  return (
    <div className={styles.stepsContainer}>
      <ul className={styles.steps}>
        <div className={styles.line} ref={lineRef} />
        {steps.map((step, _i) => {
          return (
            <li
              key={_i}
              onClick={(e) =>
                completedSteps.includes(step) && scrollToStep(e, step)
              }
              className={cx({
                [`step-${_i + 1}`]: true,
                completed: completedSteps.includes(step),
              })}
              ref={(el) => (_i === 0 ? (firstStepRef.current = el) : null)}
            >
              <span
                className={`${styles.dot} ${
                  activeStep === step ? styles.show : styles.hide
                }`}
              />
              {step}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
