import React from "react";

import styles from "styles/containers/qrcode.module.scss";

import { ClubAPI } from "api/ClubAPI";
import QRCodeComponent from "react-qr-code";
import { Copy } from "shared/components/Icons";
import { Phone } from "shared/tools";
import AdjustCPF from "./AdjustCPF";
import ConfirmCPF from "./ConfirmCPF";
import { Customer } from "shared/core/Customer";
import { OlgaCustomer } from "shared/core/OlgaCustomer";

const Steps = {
  CONFIRM_CPF: "1",
  ADJUST_CPF: "2",
  QR_CODE_SHOW: "3",
};
export default class QRCode extends React.Component {
  state = {
    copiedText: false,
    step: null,
    customerPhone: null,
    generatedQrcode: null,
    cpfIsValid: false,
  };

  componentDidMount() {
    const { codeUnlocked } = this.props;
    const olgaCustomerData = OlgaCustomer.getCustomer();
    const customerConfirmedDoc = sessionStorage.getItem('customerDoc');

    if (Customer.phone) {
      console.log('Customer.phone: ', Customer.phone);
      console.log('customerConfirmedDoc: ', customerConfirmedDoc);
      if (customerConfirmedDoc) {
        this.setState({
          customerPhone: Customer.phone,
          step: Steps.QR_CODE_SHOW,
         });
        this.generateQRCode();
      } else {
        this.setState({
          customerPhone: Customer.phone,
          step: Steps.CONFIRM_CPF,
        });
      }
      
    } else if (olgaCustomerData) {
      console.log('olgaCustomerData: ', olgaCustomerData);
      console.log('olgaCustomerData && codeUnlocked: ', olgaCustomerData, ' && ', codeUnlocked);
      if (codeUnlocked) {
        this.setState({
          customerPhone: olgaCustomerData.phone,
          step: Steps.QR_CODE_SHOW,
        });
        this.generateQRCode();
      } else {
        this.setState({
          customerPhone: olgaCustomerData.phone,
          step: Steps.CONFIRM_CPF,
        });
      }
    }
  }

  copyToClipboard() {
    const { generatedQrcode } = this.state;
    navigator.clipboard.writeText(generatedQrcode.qrcode);
    this.setState({ copiedText: true });
  }

  async generateQRCode(cpf) {
    const { coupon } = this.props;

    const giftId = coupon?.gift_id || coupon?.id || null;
    const qrCodeReq = await ClubAPI.newQrcode(giftId);
    const generatedQrcode = qrCodeReq.getData({});

    if (generatedQrcode?.qrcode) {
      this.showQRCode(generatedQrcode);
      if (cpf) sessionStorage.setItem("customerDoc", cpf);
    }
  }

  showQRCode(generatedQrcode) {
    const { codeUnlocked } = this.props;
    this.setState({
      generatedQrcode,
      step: !codeUnlocked ? Steps.CONFIRM_CPF : Steps.QR_CODE_SHOW,
    });
  }

  render() {
    const { copiedText, customerPhone, generatedQrcode, step } = this.state;
    const { closeQRCode, codeUnlocked, cpfIsValid } = this.props;

    console.log(generatedQrcode?.qrcode);
    return (
      <>
        {step === Steps.CONFIRM_CPF && (!codeUnlocked || !generatedQrcode) && (
          <ConfirmCPF
            generateQRCode={(cpf) => this.generateQRCode(cpf)}
            showAdjustCPF={() => this.setState({ step: Steps.ADJUST_CPF })}
            cpfIsValid={(value) => cpfIsValid && cpfIsValid(value)}
            closeQRCode={closeQRCode}
            codeUnlocked={codeUnlocked}
            alreadyDrawer
          />
        )}

        {step === Steps.ADJUST_CPF && (
          <AdjustCPF
            finishCpfUpdate={(cpf) => this.generateQRCode(cpf)}
            customerPhone={customerPhone}
            cpfIsValid={(value) => cpfIsValid && cpfIsValid(value)}
          />
        )}

        {step !== Steps.ADJUST_CPF && (
          <>
            <p className={styles.title}>QR Code</p>
            <p className={styles.subtitle}>
              Apresente este QR Code na loja física e aproveite o seu benefício.
            </p>
            <div
              className={`${styles.QRCode} ${
                step !== Steps.QR_CODE_SHOW ? styles.blurred : ""
              }`}
            >
              <div className={styles.QRCodeContainer}>
                <QRCodeComponent
                  value={generatedQrcode?.qrcode ? String(generatedQrcode?.qrcode) : ""}
                  size={150}
                  fgColor="#3D734A"
                />
              </div>
              <div className={styles.item}>
                <p>Código do QR</p>
                <div className={styles.code}>
                  <span>{generatedQrcode?.qrcode || ""}</span>
                  <div
                    onClick={this.copyToClipboard.bind(this)}
                    className={styles.copy}
                  >
                    <Copy />
                  </div>
                </div>
              </div>
              <div className={styles.item}>
                <p>Telefone do cliente</p>
                <span>
                  {customerPhone && Phone.mask(customerPhone || "")}
                </span>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
