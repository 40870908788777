import { useEffect, useState } from "react";

import Image from "next/image";

import styles from "styles/containers/cart.module.scss";

import {
  ChevronRight,
  CreditCardB,
  Gift,
  PenCircle,
} from "shared/components/Icons";
import { Button, Drawer, Modal } from "shared/components";

import CartItem from "shared/containers/Cart/CartItem";

import { OrderStorage } from "storage/OrderStorage";
import { UnitStorage } from "storage/UnitStorage";
import { BenefitStorage } from "storage/BenefitStorage";
import { PaymentStorage } from "storage/PaymentStorage";

import { Benefit } from "shared/tools/Benefit";
import { CatalogHelpers, Currency } from "shared/tools";
import { OrderAPI } from "api/OrderAPI";

import {
  Method,
  PaymentMethod,
} from "@olga-food/schemas/lib/classes/schemas/order/payment/Payment";

import { BucketsAPI } from "api/BucketAPI";

import dayjs from "dayjs";

import PaymentMethods from "../Payment/PaymentMethods";
import ProductDetails from "../Product/ProductDetails";
import Benefits from "../Benefits/Benefits";

import classNames from "classnames/bind";
import { Store, StoreEvents } from "shared/core/Store";
import { CartTotals } from "./CartTotals";
import { CartItemsResume } from "./CartItemsResume";
import { CartBenefit } from "./CartBenefit";
import { CartDelivery } from "./CartDelivery";
import { Customer, CustomerEvents } from "shared/core/Customer";
import useEventListener from "shared/hooks/useEventListener";
import { CardTypes } from "../Payment/Card";
import { Catalog } from "shared/core/Catalog";
import { PaymentSection } from "./PaymentSection";
import { Payment, PaymentEvents, PaymentOrigin } from "shared/core/Payment";
import { PixPayment } from "./PixPayment";
import { DataLayer } from "@/shared/tools/DataLayer";
import { isMobile } from "react-device-detect";

const Containers = {
  CHECKOUT: "CHECKOUT",
  CART_ITEMS: "CART_ITEMS",
  PAYMENT_METHODS: "PAYMENT_METHODS",
};

const deliveryTypes = {
  DEFAULT: 1,
  SCHEDULED: 2,
};

export default function Cart({
  onLoginOrRegister,
  selectDeliveryAddress,
  selectPaymentMethod,
  onClose,
  onCheckout,
  open,
}) {
  const [isCheckout, setIsCheckout] = useState(false);
  const [order, setOrder] = useState(OrderStorage.getOrder());
  const [customer, setCustomer] = useState(Customer.getCustomer());
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [openPaymentMethods, setOpenPaymentMethods] = useState(false);
  const [openProductDetails, setOpenProductDetails] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [cartItemIndex, setCartItemIndex] = useState(null);
  const [visibleContainer, setVisibleContainer] = useState(Containers.CHECKOUT);
  const [openBenefits, setOpenBenefits] = useState(false);
  const [openConfirmOrder, setOpenConfirmOrder] = useState(false);
  const [hasClubAccess, setHasClubAccess] = useState(false);
  const [cardSelected, setCardSelected] = useState(null);
  const [store, setStore] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [openPix, setOpenPix] = useState(false);
  const [pixOrderId, setPixOrderId] = useState(null);

  const cartItemsCount = order?.cart?.catalog?.items.reduce(
    (t, i) => t + (i.quantity || 1),
    0
  );

  const DeliveryType = {
    DELIVER_ON_ADDRESS: 1,
    GET_ON_PLACE: 2,
    INDOOR: 3,
  };

  const onEditProduct = (product, cartItemIndex) => {
    let productToUpdate = null;
    if (product.type === "pizza") {
      productToUpdate = Catalog.preparePizzaProductToUpdate(product);
    } else {
      productToUpdate = product;
    }

    setSelectedProduct(JSON.parse(JSON.stringify(productToUpdate)));
    setCartItemIndex(cartItemIndex);
    setOpenProductDetails(true);
  };

  const onOrderChanged = async () => {
    const order = OrderStorage.getOrder();
    setOrder(order);

    if (Payment.currentOrigin == PaymentOrigin.OFFLINE) {
      const isMoney =
        Payment.offlinePayment.type &
        (PaymentMethod.MONEY == PaymentMethod.MONEY);
      const change_for = +Payment.offlinePayment.change_for;
      if (isMoney && change_for < order.total) {
        Payment.offlinePayment = {};
      }
    }

    if (selectedBenefit && !Benefit.canBeUsed(selectedBenefit)) {
      BenefitStorage.setSelectedBenefit(null);
    }
  };

  const onCustomerChanged = () => {
    setCustomer(Customer.getCustomer());
    setHasClubAccess(!!Customer.phone);
  };

  useEventListener(CustomerEvents.CUSTOMER_CHANGED, onCustomerChanged);

  const onStoreChanged = () => {
    const store = Store.getStore();
    setStore(store);
    setShowPayment(store ? true : false);
  };

  useEventListener(StoreEvents.STORE_CHANGED, onStoreChanged);
  useEventListener(PaymentEvents.PAYMENT_CHANGED, () => onCardChanged());

  const onBenefitChanged = ({ detail: coupon }) => {
    setSelectedBenefit(coupon);

    if (!coupon?.type) {
      OrderStorage.setGift(null);
      return;
    }

    const getCode = (coupon) => {
      if (coupon.code) {
        return `${coupon.code}-${coupon.pin}`;
      } else {
        return null;
      }
    };

    OrderStorage.setGift({
      id: coupon.coupon,
      code: getCode(coupon),
      type: Benefit.parseCouponType(coupon),
      value: coupon.value || 0,
      ...(coupon.type == "CASHBACK_CONSUME" ? { amount: coupon.amount } : {}),
      min_order: coupon.min_order_amount,
      expiration: null,
      created_at: coupon.created_at,
    });
  };

  const onCardChanged = () => {
    const selectedCard = Payment.selectedCard;
    if (selectedCard) {
      const cardSelected = PaymentStorage.getCard().filter(
        (item) => item.id == selectedCard
      );
      setCardSelected(cardSelected[0]);
    }
  };

  useEffect(() => {
    setCustomer(Customer.getCustomer());

    const selectedBenefit = BenefitStorage.getSelectedBenefit();
    setSelectedBenefit(selectedBenefit);
    onBenefitChanged({ detail: selectedBenefit || {} });

    window.addEventListener("orderUpdated", onOrderChanged);

    onCardChanged();
    BenefitStorage.attachOnBenefitChanged(onBenefitChanged);

    onStoreChanged();

    return () => {
      window.removeEventListener("orderUpdated", onOrderChanged);
      BenefitStorage.detachOnBenefitChanged(onBenefitChanged);
    };
  }, []);

  useEffect(() => {
    const selectedBenefit = BenefitStorage.getSelectedBenefit();

    if (selectedBenefit && !Benefit.canBeUsed(selectedBenefit)) {
      BenefitStorage.setSelectedBenefit(null);
    }

    setSelectedBenefit(selectedBenefit);
  }, [order]);

  const handleConfirmOrder = () => {
    const order = OrderStorage.getOrder();

    const orderDataLayer = {
      event: "review_cart",
      currency: "BRL",
      value: order.total,
      items: DataLayer.getOrderItems(order.cart?.catalog?.items),
      coupon: order.gift?.code || "",
    };

    DataLayer.push(orderDataLayer);

    setOpenConfirmOrder(true);
  };

  useEffect(() => {
    if (openConfirmOrder) {
        DataLayer.push({
            event: "page_view",
            page_title: "Confirmação de pedido",
        });
    }
}, [openConfirmOrder])

  const RenderDeliveryConfirmOrder = () => {
    const { delivery } = order;

    if (!delivery) {
      return null;
    }

    const { address } = delivery;

    const selectedDeliveryType = localStorage.getItem("selected-delivery-type");

    return (
      <div className={styles.deliveryContainer}>
        <div className={styles.address}>
          <div className="d-flex align-start">
            <div className={styles.icon}>
              {delivery.type === DeliveryType.GET_ON_PLACE ? (
                <Image
                  src="/get-on-place-map.png"
                  className={styles.mapAddress}
                  alt=""
                  layout="fill"
                  sizes="100vw"
                />
              ) : (
                <Image
                  src="/deliver-on-address-map.png"
                  className={styles.mapAddress}
                  alt=""
                  layout="fill"
                  sizes="100vw"
                />
              )}
            </div>
            {!address && (
              <div className="d-flex align-center justify-between w-100">
                <div className={styles.selectAddress}>
                  Selecione um endereço de entrega
                </div>
                <ChevronRight />
              </div>
            )}
            {address && (
              <div className="d-flex flex-col flex-1">
                <div className={styles.deliveryIn}>
                  {[DeliveryType.GET_ON_PLACE, DeliveryType.INDOOR].includes(
                    order.delivery?.type
                  ) ? (
                    <>
                      <div className="d-flex align-center justify-between">
                        <span>Retirar em</span>
                        <>
                          {order.delivery?.type ===
                          DeliveryType.GET_ON_PLACE ? (
                            <p className={styles.withdrawalType}>Para levar</p>
                          ) : (
                            <p className={styles.withdrawalType}>
                              Comer no local
                            </p>
                          )}
                        </>
                      </div>
                      <p className={styles.address}>{store.name}</p>
                    </>
                  ) : (
                    <>
                      <span>Receber em</span>
                      {address?.alias && (
                        <p className={styles.address}>{address.alias}</p>
                      )}
                    </>
                  )}
                  <p className={styles.referencePoint}>
                    {address?.observation}
                  </p>
                </div>

                <div className={styles.deliveryType}>
                  {selectedDeliveryType == deliveryTypes.DEFAULT && (
                    <>
                      <p className={styles.name}>Entrega padrão</p>
                      <div className="d-flex align-center">
                        <div className={styles.deliveryEstimate}>20-30 min</div>
                      </div>
                    </>
                  )}

                  {selectedDeliveryType == deliveryTypes.SCHEDULED && (
                    <>
                      <div className="d-flex flex-col align-start w-100">
                        <p className={styles.name}>Entrega agendada</p>
                        <div className="mt-10">
                          <div className={styles.deliveryEstimate}>
                            06/06/23 | 19:00 - 20:00
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const RenderPaymentConfirmOrder = () => {
    const selectedPix = Payment.selectedPayment === "PIX";
    const selectedCard = Payment.selectedCard;

    return (
      <div className={styles.paymentContainer}>
        {selectedCard && (
          <>
            <div className="d-flex align-center">
              <div className={styles.iconCard}>
                <div className={styles.card}>
                  <CreditCardB />
                </div>
              </div>
              <div className="d-flex flex-col w-100">
                <div className={styles.paymentType}>
                  {CardTypes[cardSelected?.type]}
                </div>
                <div className="d-flex align-center justify-between ">
                  <p className={styles.paymentName}>
                    {cardSelected?.number} {cardSelected?.name}
                  </p>
                  <p className={styles.total}>
                    {Currency.formatCurrency(order.total || 0)}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedPix && (
          <>
            <div className="d-flex align-center">
              <div className={styles.iconPix}>
                <div className={styles.pix}>
                  <Image src="/pix.png" alt="" layout="fill" />
                </div>
              </div>
              <div className="d-flex flex-col w-100">
                <p className={styles.paymentType}>Pagamento online</p>
                <div className="d-flex align-center justify-between">
                  <p className={styles.paymentName}>PIX</p>
                  <p className={styles.total}>
                    {Currency.formatCurrency(order.total || 0)}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderCartItems = () => {
    return (
      <CartItem
        order={order}
        onEditProduct={onEditProduct}
        onRemoveProduct={(_, productIndex) => {
          OrderStorage.removerCartItem(productIndex);
        }}
        onClose={() => onClose && onClose()}
      />
    );
  };

  const renderCheckoutButton = () => {
    const { delivery } = order;

    const hasItems = order?.cart?.catalog?.items?.length > 0;
    const hasPayment = order?.total > 0;

    const isOnlinePayment = cardSelected || Payment.selectedPayment === "PIX";

    const allowPayment =
      hasItems &&
      (!hasPayment ||
        (hasPayment > 0 && isOnlinePayment && order?.total >= 1) ||
        Payment.offlinePayment?.type);

    if (!customer) {
      return (
        <div className={styles.footer}>
          <Button
            design="primary"
            className="w-100"
            onClick={() => onLoginOrRegister && onLoginOrRegister()}
          >
            Entre ou cadastre-se
          </Button>
        </div>
      );
    }

    if (!Boolean(Store.active)) {
      return (
        <div className={styles.footer}>
          <Button
            design="primary"
            className="w-100"
            onClick={() => null}
            disabled={true}
          >
            Loja indisponível
          </Button>
        </div>
      );
    }

    if (!delivery || !delivery.address) {
      return (
        <div className={styles.footer}>
          <Button
            design="primary"
            className="w-100"
            onClick={() => selectDeliveryAddress()}
          >
            Definir endereço
          </Button>
        </div>
      );
    }

    if (isOnlinePayment && order?.total < 1) {
      return (
        <div className={styles.footer}>
          <Button design="primary" className="w-100" disabled>
            Valor abaixo do pedido mínimo (R$ 1,00)
          </Button>
        </div>
      );
    }

    if (
      !!delivery.deliveryStrip &&
      !!delivery.deliveryStrip.min_order &&
      delivery.deliveryStrip.min_order > order.cart.total
    ) {
      return (
        <div className={styles.footer}>
          <Button design="primary" className="w-100" disabled>
            Valor abaixo do pedido mínimo (
            {Currency.formatCurrency(delivery.deliveryStrip.min_order)})
          </Button>
        </div>
      );
    }

    if (!Store.working_time) {
      return (
        <div className={styles.footer}>
          <Button design="primary" className="w-100" disabled>
            Loja fechada
          </Button>
        </div>
      );
    } else {
      const currentDate = dayjs();
      const days = Store.working_time.days || [];
      const isOpen = BucketsAPI.isOpen(
        currentDate.day(),
        currentDate.format("HHmm"),
        days
      );

      if (!isOpen) {
        return (
          <div className={styles.footer}>
            <Button design="primary" className="w-100" disabled>
              Loja fechada
            </Button>
          </div>
        );
      }
    }

    return (
      <div className={styles.footer}>
        <Button
          design="primary"
          className="w-100"
          onClick={() => handleConfirmOrder()}
          loading={isCheckout}
          disabled={isCheckout || !allowPayment}
        >
          {Payment.currentOrigin == PaymentOrigin.OFFLINE
            ? "Finalizar pedido"
            : "Efetuar pagamento"}
        </Button>
      </div>
    );
  };

  const preparePayloadOrder = () => {
    const payloadOrder = OrderStorage.getOrder();
    const customer = Customer.getCustomer();
    delete customer.password;

    if (customer.extras) {
      delete customer.extras.addresses;
    }

    payloadOrder.cart.catalog = CatalogHelpers.clearUnselectedItems(
      payloadOrder.cart.catalog
    );

    return payloadOrder;
  };

  const preparePayer = (payloadOrder) => {
    const customer = Customer.getCustomer();

    const payer = {
      amount: payloadOrder.total,
      user: customer,
    };

    const setOfflinePayer = () => {
      const offlinePayment = Payment.offlinePayment;
      payer.method = offlinePayment.alias;
      payer.paymentMethod = offlinePayment.type;
      payer.changeFor = offlinePayment.change_for;
    };

    const setOnlinePayer = () => {
      if (Payment.selectedPayment === "PIX") {
        payer.method = Method.PIX.alias;
        payer.paymentMethod = PaymentMethod.ONLINE | PaymentMethod.MONEY;
      } else {
        const onlinePayment = Payment.onlinePayment;
        payer.method = onlinePayment.alias;
        payer.paymentMethod = onlinePayment.type;
        payer.card = onlinePayment.gatewayReference;
        payer.accountPagarmeId = process.env.APPLICATION_ID;
      }
    };

    if (Payment.currentOrigin == PaymentOrigin.OFFLINE) {
      setOfflinePayer();
    } else {
      setOnlinePayer();
    }

    return payer;
  };

  const successOrder = async (orderId) => {
    OrderStorage.clearCartItems();
    BenefitStorage.setSelectedBenefit(null);
    OrderStorage.setGift(null);
    Payment.selectedCard = null;

    const createdOrderResponse = await OrderAPI.getOrderById(orderId);
    const createdOrder = createdOrderResponse.getData({});
    onCheckout && onCheckout(createdOrder);

    const orderDataLayer = {
      event: "purchase",
      ecommerce: {
        transaction_id: createdOrder.id.toString(),
        value: createdOrder.total,
        currency: "BRL",
        items: DataLayer.getOrderItems(createdOrder.cart?.catalog?.items),
        coupon: createdOrder.gift?.code || "",
        shipping: createdOrder.delivery?.total || 0,
      },
    };

    DataLayer.push({ ecommerce: null });
    DataLayer.push(orderDataLayer);
  };

  const checkout = async () => {
    setOpenConfirmOrder(false);

    try {
      setIsCheckout(true);

      const payloadOrder = preparePayloadOrder();
      const payer = preparePayer(payloadOrder);

      payloadOrder.payment = {
        total: payloadOrder.total,
        payers: [payer],
      };

      if (payloadOrder.gift && !payloadOrder.gift.type) {
        payloadOrder.gift = null;
      }

      const order = await OrderAPI.postOrders(payloadOrder);
      const orderNew = order.getData({});
      setIsCheckout(false);

      if (order.getCode() !== 200) {
        setShowError(true);
        setError(order.getErrors()[0]);
        return;
      }

      if (Payment.selectedPayment === "PIX") {
        setPixOrderId(orderNew.id);
        setOpenPix(true);
      } else {
        successOrder(orderNew.id);
      }
    } catch (err) {
      console.log(err);
      setIsCheckout(false);
    }
  };

  useEffect(() => {
    if (order?.cart?.catalog?.items.length === 0)
      setVisibleContainer(Containers.CHECKOUT);
  }, [order?.cart?.catalog?.items.length]);

  if (!order) {
    return null;
  }

  return (
    <Drawer
      open={open}
      onClose={() => {
        setVisibleContainer(Containers.CHECKOUT);
        onClose();
      }}
      title={`Seu pedido (${cartItemsCount})`}
      showBackButton={visibleContainer !== Containers.CHECKOUT}
      backButton={() => setVisibleContainer(Containers.CHECKOUT)}
      showCloseButton
      withoutPadding
      direction={isMobile ? "top-full" : "right"}
    >
      <div className={styles.cart}>
        {visibleContainer === Containers.CHECKOUT && (
          <div className={styles.scrollContainer}>
            <CartDelivery order={order} onClick={selectDeliveryAddress} />

            {order?.cart?.catalog?.items.length > 0 && renderCartItems()}

            <CartBenefit
              selectedBenefit={selectedBenefit}
              onClick={() => setOpenBenefits(true)}
              order={order}
            />
            {customer && showPayment && (
              <PaymentSection onClick={() => setOpenPaymentMethods(true)} />
            )}
            <CartTotals order={order} selectedBenefit={selectedBenefit} />
          </div>
        )}

        {visibleContainer === Containers.CART_ITEMS && renderCartItems()}
        {visibleContainer === Containers.CHECKOUT && renderCheckoutButton()}
      </div>

      <Modal open={showError} alreadyModal onClose={() => setShowError(false)}>
        <p className={styles.errorMessage}>{error}</p>
        <Button
          design="primary"
          className="w-100 mt-30"
          onClick={() => setShowError(false)}
        >
          <span className="d-flex align-center">Ok</span>
        </Button>
      </Modal>

      <Benefits
        open={openBenefits}
        onClose={() => setOpenBenefits(false)}
        alreadyDrawer
      />

      <PaymentMethods
        open={openPaymentMethods}
        onClose={() => setOpenPaymentMethods(false)}
        alreadyDrawer
      />

      <Drawer
        open={openProductDetails}
        onClose={() => setOpenProductDetails(false)}
        withoutPadding
        alreadyDrawer
      >
        <ProductDetails
          cartItemIndex={cartItemIndex}
          product={selectedProduct}
          onClose={() => setOpenProductDetails(false)}
        />
      </Drawer>

      <Drawer
        open={openConfirmOrder}
        onClose={() => setOpenConfirmOrder(false)}
        title="Confirme sua entrega"
        direction="bottom"
        alreadyDrawer
      >
        <div className={styles.confirmOrder}>
          <RenderDeliveryConfirmOrder />
          <RenderPaymentConfirmOrder />

          <Button design="primary" className="mb-15" onClick={() => checkout()}>
            Confirmar e fazer pedido
          </Button>
          <Button design="secondary" onClick={() => setOpenConfirmOrder(false)}>
            Alterar dados
          </Button>
        </div>
      </Drawer>

      {pixOrderId && (
        <PixPayment
          open={openPix}
          onClose={() => setOpenPix(false)}
          onSuccess={(orderId) => {
            setOpenPix(false);
            successOrder(orderId);
          }}
          orderId={pixOrderId}
        />
      )}
    </Drawer>
  );
}
