import { Request, Response } from "@konv/http";
import { Store } from "shared/core/Store";

const UnavailabilityMessage = "Pagamento online indisponível";
export class PaymentHubAPI {
  static async requestAuthenticated({
    endpoint = "/",
    method = "GET",
    headers = {},
    query,
    data,
  }) {
    return await Request.request({
      endpoint: endpoint,
      cors: true,
      method: method || "GET",
      headers: headers || {},
      service: "payment-hub",
      ignoreVersion: true,
      params: query || {},
      data: data || {},
    });
  }

  static async PagarMeCreateAccount(customerReferenceId, data) {
    const paymentAccount = Store.getPaymentConfigs();
    if (paymentAccount) {
      const { accountId, publicKey } = paymentAccount;
      return await PaymentHubAPI.requestAuthenticated({
        endpoint: `/${accountId}/pagarme/account/${customerReferenceId}`,
        headers: {
          Authorization: "Bearer " + publicKey,
        },
        method: "POST",
        data,
      });
    } else {
      return new Response([], 403, [UnavailabilityMessage]);
    }
  }

  static async PagarMeGetCharge(customerReferenceId) {
    const paymentAccount = Store.getPaymentConfigs();
    if (paymentAccount) {
      const { accountId, publicKey } = paymentAccount;
      return await PaymentHubAPI.requestAuthenticated({
        endpoint: `/${accountId}/pagarme/charge/${customerReferenceId}`,
        headers: {
          Authorization: "Bearer " + publicKey,
        },
        method: "GET",
      });
    } else {
      return new Response([], 403, [UnavailabilityMessage]);
    }
  }

  static async PagarmeGetMethodsPayment(data) {
    const paymentAccount = Store.getPaymentConfigs();
    if (paymentAccount) { 
      const { accountId, publicKey } = paymentAccount;
      return await PaymentHubAPI.requestAuthenticated({
        endpoint: `/${accountId}/pagarme/methods-payment`,
        headers: {
          Authorization: "Bearer " + publicKey,
        },
        method: "POST",
        data,
      });
    } else {
      return new Response([], 403, [UnavailabilityMessage]);
    }
  }

  static async PagarMeCreateCard(customerReferenceId, data) {
    const paymentAccount = Store.getPaymentConfigs();
    if (paymentAccount) {
      const { accountId, publicKey } = paymentAccount;
      return await PaymentHubAPI.requestAuthenticated({
        endpoint: `/${accountId}/pagarme/cards`,
        headers: {
          Authorization: "Bearer " + publicKey,
        },
        method: "POST",
        data: {
          customerReferenceId: String(customerReferenceId),
          ...data
        },
      });
    } else {
      return new Response([], 403, [UnavailabilityMessage]);
    }
  }

  static async PagarMeGetCards(customerReferenceId) {
    const paymentAccount = Store.getPaymentConfigs();
    if (paymentAccount) {
      const { accountId, publicKey } = paymentAccount;
      return await PaymentHubAPI.requestAuthenticated({
        endpoint: `/${accountId}/pagarme/cards/${customerReferenceId}`,
        headers: {
          Authorization: "Bearer " + publicKey,
        },
        method: "GET",
      });
    } else {
      return new Response([], 403, [UnavailabilityMessage]);
    }
  }

  static async PagarMeRemoveCard(customerReferenceId, cardId) {
    const paymentAccount = Store.getPaymentConfigs();
    if (paymentAccount) {
      const { accountId, publicKey } = paymentAccount;
      return await PaymentHubAPI.requestAuthenticated({
        endpoint: `/${accountId}/pagarme/cards/${cardId}?customerReferenceId=${customerReferenceId}`,
        headers: {
          Authorization: "Bearer " + publicKey,
        },
        method: "DELETE",
      });
    } else {
      return new Response([], 403, [UnavailabilityMessage]);
    }
  }

  static async AdyenSession(data, readOnly) {
    const paymentAccount = Store.getPaymentConfigs();
    if (paymentAccount) {
      const { accountId, publicKey } = paymentAccount;
      return await PaymentHubAPI.requestAuthenticated({
        endpoint: `/${accountId}/adyen/dropin/session${
          readOnly ? `?readonly=${readOnly}` : ``
        }`,
        headers: {
          Authorization: "Bearer " + publicKey,
        },
        method: "POST",
        data,
      });
    } else {
      return new Response([], 403, [UnavailabilityMessage]);
    }
  }
}
