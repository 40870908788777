
import { Radio } from "shared/components";
import { OptionItem } from "./OptionItem";
import styles from "styles/containers/product-details.module.scss";

export const PizzaExtrasStep = ({ extras, onExtrasUpdated }) => {
    const hasSelectedExtras = extras.some((extra) => extra.selected === true);
    const onSelectedExtra = (extra, quantity) => {
        
        onExtrasUpdated(
            extras.map((existingExtra) => {
                const newQuantity = !!extra ? (existingExtra.id == extra.id ? quantity : existingExtra.quantity) : 0;
                return {
                    ...existingExtra,
                    quantity: newQuantity,
                    selected: newQuantity > 0,
                };
            })
        )
    }

    return (
        <>
            <div className={styles.sectionHeader}>
                <div className="d-flex flex-col">
                    <p className={styles.sectionTitle}>Adicionais</p>
                </div>
                <span className={styles.requiredField}>Opcional</span>
            </div>

            <div className={styles.iDontWant}>
                <p>Não quero, obrigado</p>
                <Radio
                    id="without-extra"
                    value={true}
                    checked={!hasSelectedExtras}
                    onChange={() => onSelectedExtra(null, 0)}
                />
            </div>

            <div className={styles.optionItems}>
                {extras.map((extra) => (
                    <OptionItem
                        key={`extra-${extra.id}`}
                        option={extra}
                        quantityOption={true}
                        onClick={(quantity) => onSelectedExtra(extra, quantity)}
                    />
                ))}
            </div>
        </>
    );
};