import { useEffect, useState, useRef } from "react";

import styles from "styles/containers/product-details.module.scss";

import Image from "next/image";

import { Button, Checkbox, Textarea } from "shared/components";
import { Minus, Plus, Times } from "shared/components/Icons";

import { object, number } from "yup";

import { Currency, OlgaImageLoader } from "shared/tools";
import { QuestionItem } from "./Step/QuestionItem";
import { OMath } from "shared/tools/OMath";
import { EcleticaCatalog } from "@/shared/core/EcleticaCatalog";
import { DeepCopy } from "@/shared/tools/DeepCopy";
import { Table } from "@/shared/core/Table";

import classNames from "classnames/bind";
const css = classNames.bind(styles)

export default function EcleticaStep({ product, onSave, onClose, isUpdate }) {
  const combinationId =
    (product?.sizes || [{ sku: null }])[0].sku || product.sku;

  const [localProduct, setLocalProduct] = useState(
    DeepCopy.from({
      ...product,
      quantity: 1,
      selected: true,
    })
  );

  const [stepIds, setStepsIds] = useState([]);

  const [valid, setValid] = useState(false);

  const scrollContainer = useRef();

  const [isDigitalMenu, setIsDigitalMenu] = useState(false);

  useEffect(() => {
    const externalId = Table.externalId;
    if (externalId === Table.isDigitalMenu) setIsDigitalMenu(true);
    else setIsDigitalMenu(false);

    validate();
  }, [localProduct]);

  useEffect(() => {
    loadFirstStep();
  }, [product]);

  const loadFirstStep = () => {
    const firstQuestion = DeepCopy.from(
      EcleticaCatalog.getFirstStep(combinationId)
    );
    setLocalProduct({
      ...localProduct,
      items: [firstQuestion],
    });
  };

  const loadNextStep = () => {
    const optionsByQuestion = [];
    const questions = localProduct.items;

    for (let questionIndex in localProduct.items) {
      const question = localProduct.items[questionIndex];
      const selectedOptions = question.items.filter(
        (option) => option.selected === true
      );
      optionsByQuestion.push(selectedOptions.map((option) => option.sku));
    }

    const selectedQuestions = optionsByQuestion.filter(
      (options) => options.length > 0
    );
    if (questions.length === selectedQuestions.length) {
      const lastQuestion = questions[questions.length - 1];
      const firstSelectedOption = lastQuestion.items.find(
        (option) => option.selected === true
      );

      const nextQuestion = EcleticaCatalog.getNextStep(
        combinationId,
        firstSelectedOption.sku
      );

      if (nextQuestion) {
        setLocalProduct({
          ...localProduct,
          items: [...localProduct.items, DeepCopy.from(nextQuestion)],
        });
      }
    }
  };

  const validateExtrasSelected = () => {
    // Checa se há questões obrigatórias
    if (localProduct.items?.length > 0) {
      const selectedExtras =
        localProduct.items.filter((q) => {
          //Se não tem minimo, não está incompleto
          if (q.min == 0) {
            return false;
          }

          //Se o não tem opções listadas, passa.
          if (q.items?.length == 0) {
            return false;
          }

          const seletedOptions = q.items.filter((o) => o.selected) || [];
          if (seletedOptions[0]?.quantity >= q.min) {
            return false;
          }

          const seletedOptionsQuantity = seletedOptions.reduce(
            (acc, selectedOption) => acc + selectedOption.quantity,
            0
          );

          if (
            seletedOptionsQuantity < q.min ||
            seletedOptionsQuantity > q.max
          ) {
            return true;
          }

          // Se tem ao menos um item selecionado, não está incompleto
          return !q.items.find((o) => o.selected);
        }).length > 0;

      return selectedExtras;
    }
  };

  useEffect(() => {
    if (product.sizes && product.sizes.length > 0) {
      if (localProduct.sizes.filter((s) => s.selected).length == 0) {
        localProduct.sizes[0].selected = true;
      }
    }

    validate();
  }, []);

  const validate = async () => {
    const productSchema = object().shape({ quantity: number().required() });

    setValid(
      (await productSchema.isValid(localProduct)) && !validateExtrasSelected()
    );
  };

  const mountPrice = (item) => {
    if (item.price > 0) {
      return Currency.formatCurrency(Number(item.price));
    }

    if (item.price >= 0 && item.sizes.length === 0) {
      return Currency.formatCurrency(Number(item.price));
    }

    if (item?.sizes?.length > 0) {
      const minValue = (item.sizes || []).reduce(
        (prev, current) => (prev.price < current.price ? prev : current),
        0
      );

      if (minValue.price === 0) {
        if (item.items.length > 0) {
          let totalPrice = 0;
          (item.items || []).forEach((subitem) => {
            if (subitem.min > 0) {
              const minValue = (subitem.items || []).reduce(
                (prev, current) =>
                  prev.price < current.price ? prev : current,
                0
              );
              totalPrice = totalPrice + minValue.price;
            }
          });

          return Currency.formatCurrency(Number(totalPrice));
        }
      }

      return Currency.formatCurrency(Number(minValue.price));
    }
  };

  const onProductChange = (data) => {
    setLocalProduct({ ...localProduct, ...data });
    validate();
  };

  const save = () => {
    onSave && onSave(localProduct);
  };

  const calculateItemPrice = (item) => {
    const props = ["items", "sizes", "extra_ingredients", "doughs", "edges"];

    let total =
      Math.round((item.price || 0) * 100) *
      (item.selected !== false && item.quantity > 0 ? 1 : 0);
    for (const key of props) {
      if (item[key] && item[key].length > 0) {
        for (const subItem of item[key]) {
          total += calculateItemPrice(subItem) * 100;
        }
      }
    }

    return +OMath.div(OMath.times(total, item.quantity), 100).toFixed(2);
  };

  const renderPictureSection = () => {
    if (!product.image) {
      return (
        <div
          className={styles.noPictureClose}
          onClick={() => onClose && onClose(true)}
        >
          <Times color={"#fff"} />
        </div>
      );
    }

    return (
      <div className={styles.picture}>
        <div className={styles.close} onClick={() => onClose && onClose(true)}>
          <Times color={"#fff"} />
        </div>
        {product.image && (
          <Image
            src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${product.image}`}
            alt={product.name}
            placeholder="blur"
            blurDataURL={OlgaImageLoader.BLURRED_IMG}
            loader={OlgaImageLoader.load}
            layout="fill"
            sizes="100vw"
          />
        )}
      </div>
    );
  };

  const renderProductStartPrice = () => {
    return (
      <p className={styles.fromPrice}>
        {localProduct.sizes.length > 1 || localProduct.items.length > 0
          ? `A partir de ${mountPrice(localProduct)}`
          : mountPrice(localProduct)}
      </p>
    );
  };

  const renderQuantitySection = () => {
    return (
      <div className={styles.quantityContainer}>
        <span
          className={`${styles.minus} ${
            localProduct.quantity <= 1 ? styles.disabled : styles.enabled
          }`}
          onClick={() =>
            localProduct.quantity > 1 &&
            onProductChange({ quantity: --localProduct.quantity })
          }
        >
          <Minus />
        </span>
        <p className={styles.qty}>{localProduct.quantity}</p>
        <span
          className={`${styles.plus} ${styles.enabled}`}
          onClick={() => onProductChange({ quantity: ++localProduct.quantity })}
        >
          <Plus />
        </span>
      </div>
    );
  };

  const renderSubQuestionSection = (option, questionIndexBase, onChange) => {
    return option?.items?.map((subQuestion, subQuestionIndex) => {
      return (
        subQuestion?.items?.length > 0 && (
          <QuestionItem
            key={subQuestionIndex}
            questionIndex={questionIndexBase.index++}
            question={subQuestion}
            onChange={(q) => onChange(q, subQuestionIndex)}
            ecleticaMenu
          />
        )
      );
    });
  };

  const renderQuestionSection = () => {
    const qObj = { index: 0 };

    return localProduct.items?.map((question, questionIndex) => {
      //Encontra as opções selecionadas
      const selectedOptions = Object.entries(question?.items || {}).filter(
        ([_, option]) => option.selected === true
      );
      return (
        <>
          {question?.items?.length > 0 && (
            <QuestionItem
              key={questionIndex}
              questionIndex={qObj.index++}
              question={question}
              onChange={(question) => {
                localProduct.items[questionIndex] = question;
                onProductChange({ items: localProduct.items });
                loadNextStep();
              }}
              ecleticaMenu
            />
          )}
          {
            //Para cada opção selecionada, renderiza suas questões
            selectedOptions.map(([oIndex, option]) =>
              renderSubQuestionSection(
                option,
                qObj,
                (subQuestion, subQuestionIndex) => {
                  //Atualiza a questão da opção renderizada
                  option.items[subQuestionIndex] = subQuestion;
                  //Atualiza a opção da questão renderizada
                  question.items[oIndex] = option;
                  //Atualiza o produto com a questão corrente
                  localProduct.items[questionIndex] = question;
                  onProductChange({ items: localProduct.items });
                }
              )
            )
          }
        </>
      );
    });
  };

  const renderSizesSection = () => {
    const selectedSize = localProduct.sizes.find((size) => size.selected);
    const externalId = Table.externalId;
    const isDigitalMenu = externalId === Table.isDigitalMenu;

    return (
      <>
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>Escolha o tamanho</p>
          {!isDigitalMenu && <span className={styles.requiredField}>Obrigatório</span>}
        </div>

        <div className={styles.sizes}>
          <span className={styles.sizePrice}>
            {Currency.formatCurrency(selectedSize.price)}
          </span>

          {localProduct.sizes.map((size, sizeIndex) => (
            <div
              key={sizeIndex}
              className={`${styles.size} ${
                size.selected ? styles.selected : ""
              }`}
              onClick={() => {
                localProduct.sizes.forEach((s) => (s.selected = false));
                localProduct.sizes[sizeIndex].selected = true;
                onProductChange({ sizes: localProduct.sizes });
              }}
            >
              <p className={styles.name}>{size.name}</p>
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderExtrasSection = () => {
    const currentSize = localProduct.sizes.find((s) => s.selected);

    const extras = localProduct.extra_ingredients.map((extra) => {
      const extraSize = extra.sizes.find(
        (size) => size.ref_id === currentSize.ref_id
      );

      return {
        ...extra,
        price: extraSize?.price || extra.price,
      };
    });

    return (
      <div className="d-flex flex-col">
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>Adicionais</p>
        </div>
        <div className={styles.optionItems}>
          {extras.map((e, eIndex) => {
            return (
              <div
                className={styles.optionItem}
                key={eIndex}
                onClick={() => {
                  extras[eIndex].selected = !extras[eIndex].selected;
                  onProductChange({
                    extra_ingredients: extras,
                  });
                }}
              >
                <div className="d-flex flex-col">
                  <p className={styles.optionName}>{e.name}</p>
                  <span className={styles.optionPrice}>
                    {`${Currency.formatCurrency(e.price)}`}
                  </span>
                </div>
                <Checkbox
                  value={e.name}
                  checked={e.selected}
                  key={`extraingredient-${e.id}`}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderObservationSection = () => {
    return (
      <>
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>Alguma observação?</p>
        </div>

        <Textarea
          onChange={(e) => onProductChange({ observation: e.target.value })}
          value={localProduct.observation || ""}
          placeholder="Ex. Remover cenoura"
          className="mt-20"
        />
      </>
    );
  };

  return (
    <>
      <div className={css({ scrollContainer: true, isDigitalMenu: isDigitalMenu })} ref={scrollContainer}>
        {renderPictureSection()}

        <div className={styles.productDetailsContainer}>
          <div className={styles.productDetails}>
            <p className={styles.productName}>{product.name}</p>
            <p className={styles.productDescription}>{product.description}</p>
            {renderProductStartPrice()}
          </div>

          {localProduct.sizes.length > 1 && renderSizesSection()}

          {localProduct.items.length > 0 && renderQuestionSection()}

          {localProduct.extra_ingredients.length > 0 && renderExtrasSection()}

          {!isDigitalMenu && renderObservationSection()}
        </div>
      </div>
      {!isDigitalMenu && (
        <div className={styles.footer}>
          {renderQuantitySection()}
          
          <Button
            design="primary"
            className="w-100"
            disabled={!valid}
            onClick={() => save()}
          >
            {isUpdate ? "Atualizar" : "Adicionar"} (
            {Currency.formatCurrency(calculateItemPrice(localProduct))})
          </Button>
        </div>
      )}
    </>
  );
}
