import React from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import styles from "styles/containers/clubLogin.module.scss";

import AdjustCPF from "./AdjustCPF";

import { Button } from "shared/components";
import { Input } from "shared/components";
import { Checkbox } from "shared/components";
import { Modal } from "shared/components";

import { CPF } from "shared/tools";

import { Customer } from "shared/core/Customer";

import { ClubAPI } from "api/ClubAPI";
import { OlgaCustomer } from "shared/core/OlgaCustomer";
import { Brand } from "shared/core/Brand";
import { BrandStatus } from "../StoreStatus";

import { ChevronLeft, UserLight } from "@/shared/components/Icons";

import classNames from "classnames/bind";
const css = classNames.bind(styles);

const Steps = {
  LOGIN: 1,
  ADJUST_CPF: 2,
};

export class ClubLogin extends React.Component {
  formRef = null;
  constructor(props) {
    super(props);
    this.state = {
      step: Steps.LOGIN,
      checked: false,
      phoneNumber: "",
      cpf: "",
      errorCpf: false,
      loading: false,
      showLoginError: false,
      loginErrorMessage: "",
    };
  }

  componentDidUpdate() {
    const { cpf, phoneNumber } = this.state;
    if (phoneNumber && this.formRef)
      this.formRef.setFieldValue("phoneNumber", phoneNumber);
    if (cpf && this.formRef) this.formRef.setFieldValue("cpf", cpf);
  }

  setLoading(value) {
    this.setState({ loading: value });
  }

  onClose(status) {
    const { onClose } = this.props;
    if (status === false) onClose && onClose();
  }

  finishCpfUpdate(cpf) {
    sessionStorage.setItem("customerDoc", cpf);
    setTimeout(() => {
      this.onClose(false);
    }, 500);
  }

  async handleBackStep() {
    const { step } = this.state;
    if (step === Steps.ADJUST_CPF) {
      return this.setState({ step: Steps.LOGIN });
    }
  }

  setShowLoginError(status) {
    this.setState({ showLoginError: status });
    if (status === false) this.setState({ loginErrorMessage: "" });
  }

  render() {
    const {
      step,
      phoneNumber,
      errorCpf,
      checked,
      loading,
      showLoginError,
      loginErrorMessage,
    } = this.state;

    const { cpfIsValid, isClub } = this.props;

    const initialValues = {
      phoneNumber: "",
      cpf: "",
    };

    const validationSchema = Yup.object({
      cpf: Yup.lazy(() => {
        if (errorCpf)
          return Yup.string()
            .required("Campo obrigatório.*")
            .length(0, "CPF inválido.*");
        else return Yup.string().required("Campo obrigatório.*");
      }),
      phoneNumber: Yup.string()
        .required("Campo obrigatório.*")
        .matches(/^\(\d{2}\) \d{5}\-\d{4}/, "Telefone inválido"),
    });

    const setChecked = (status) => {
      this.setState({ checked: status });
    };

    const formatPhone = (phone) => {
      let phoneNumber = phone.replace(/\D/g, "");
      phoneNumber = phoneNumber.replace(/^0/, "");

      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.replace(
          /^(\d\d)(\d{5})(\d{4}).*/,
          "($1) $2-$3"
        );
      } else if (phoneNumber.length > 7 && phoneNumber.length <= 10) {
        phoneNumber = phoneNumber.replace(
          /^(\d\d)(\d{4})(\d{0,4}).*/,
          "($1) $2-$3"
        );
      } else if (phoneNumber.length > 2 && phoneNumber.length <= 7) {
        phoneNumber = phoneNumber.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else if (phone.trim() !== "") {
        phoneNumber = phoneNumber.replace(/^(\d*)/, "($1");
      }

      return phoneNumber;
    };

    const onClubLogin = async (values, actions) => {
      this.setLoading(true);
      const formattedPhone = `55${String(values.phoneNumber).replace(
        /[^0-9]/g,
        ""
      )}`;
      Customer.setCustomer({ phone: formattedPhone });
      await OlgaCustomer.install(null, formattedPhone);

      const confirmFieldReq = await ClubAPI.confirmField({ doc: values.cpf });
      const confirmedFieldData = confirmFieldReq.getData({});
      const confirmedFieldErrors = confirmFieldReq.getErrors({});
      if (!confirmedFieldData?.success) {
        if (confirmedFieldErrors[0] === "cpfDiff") {
          this.setState({ step: Steps.ADJUST_CPF });
        }
      } else {
        if (!OlgaCustomer.id) {
          await OlgaCustomer.install();
        }
        cpfIsValid(true);
        this.finishCpfUpdate(values.cpf);
      }
      this.setLoading(false);
    };

    const setCpf = (value) => {
      this.setState({ cpf: value });
    };

    const onChange = (str) => {
      const formattedCpf = String(str).replace(/[^0-9]/g, "");
      const isValidCpf = CPF.validate(formattedCpf);
      setCpf(formattedCpf);
      this.formRef.setFieldValue("cpf", formattedCpf);
      this.setState({ errorCpf: !isValidCpf });
      this.setState({ cpfNeedsUpdate: false });
    };

    return (
      <div className={styles.clubLoginContainer}>
        {step === Steps.LOGIN && (
          <>
            <div className={styles.icon}>
              <UserLight />
            </div>
            <p className={styles.title}>Precisamos que você se identifique</p>
            <span className={styles.subtitle}>
              Insira o seu número de celular e o seu CPF.
            </span>

            <div className={styles.hairline} />

            <Formik
              innerRef={(ref) => (this.formRef = ref)}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => onClubLogin(values, actions)}
            >
              {(props) => (
                <Form
                  onChange={(e) => {
                    if (e.target.name === "phoneNumber") {
                      let t = e.target.value;
                      const formattedPhone = formatPhone(t);
                      this.formRef.setFieldValue("phoneNumber", formattedPhone);
                      this.setState({ phoneNumber: formattedPhone });
                    }
                  }}
                >
                  <Input
                    className="mb-20"
                    name="phoneNumber"
                    floatLabel="Número de celular"
                    placeholder="(DDD) + Número"
                    type="tel"
                  />
                  <Input
                    name="cpf"
                    floatLabel="CPF"
                    mask={"999.999.999-99"}
                    placeholder="000.000.000-00"
                    onChange={(e) => {
                      props.handleChange(e);
                      onChange(e.target.value);
                    }}
                    type="tel"
                  />

                  <Checkbox
                    className="mt-15"
                    onChange={(e) => setChecked(e)}
                    checked={checked}
                  >
                    Aceito receber ótimos <strong>benefícios</strong> via SMS e
                    Email.
                  </Checkbox>

                  {isClub ? (
                    <div className={styles.footer}>
                      <Button
                        type="submit"
                        design="primary"
                        className="w-100"
                        loading={loading}
                        disabled={loading || !checked}
                      >
                        Continuar
                      </Button>
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      design="primary"
                      className="w-100 mt-20"
                      loading={loading}
                      disabled={loading || !checked}
                    >
                      Continuar
                    </Button>
                  )}
                </Form>
              )}
            </Formik>
          </>
        )}

        {step === Steps.ADJUST_CPF && (
          <>
            <div
              className={styles.back}
              onClick={this.handleBackStep.bind(this)}
            >
              <ChevronLeft />
            </div>

            <AdjustCPF
              finishCpfUpdate={(cpf) => this.finishCpfUpdate(cpf)}
              customerPhone={phoneNumber}
              cpfIsValid={(value) => cpfIsValid && cpfIsValid(value)}
            />
          </>
        )}

        <Modal
          open={showLoginError}
          alreadyModal
          onClose={(status) => this.setShowLoginError(status)}
        >
          <p className="text-2-bold-lg">Ocorreu um erro!</p>
          <p>{loginErrorMessage}</p>
          <Button
            design="primary"
            className="w-100 mt-30"
            onClick={() => this.setShowLoginError(false)}
          >
            <span className="d-flex align-center">Ok</span>
          </Button>
        </Modal>
      </div>
    );
  }
}
