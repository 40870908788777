import styles from "styles/containers/home.module.scss";

import { Button } from "@/shared/components";
import { PinLocation, TargetLocation } from "@/shared/components/Icons";

import classNames from "classnames/bind";
const css = classNames.bind(styles);

const LocationOptions = {
  SET_CITY: "SET_CITY",
  MY_LOCATION: "MY_LOCATION",
};

const Steps = {
  RECEIVE_OPTION_CHOOSE: 1,
  BRAND_CHOOSE: 2,
  LOCATION: 3,
  SET_CITY: 4,
  UNIT_CHOOSE: 5,
  SCAN_QRCODE: 6,
};

export default function Location({
  selectedLocation,
  onSelectLocation,
  onChangeStep,
}) {
  const changeStep = () => {
    if (selectedLocation === LocationOptions.SET_CITY)
      return onChangeStep(Steps.SET_CITY);

    if (selectedLocation === LocationOptions.MY_LOCATION)
      return onChangeStep(Steps.UNIT_CHOOSE);
  };

  return (
    <div className={styles.locationOptions}>
      <div
        className={css({
          option: true,
          selected: selectedLocation === LocationOptions.SET_CITY,
        })}
        onClick={() => onSelectLocation(LocationOptions.SET_CITY)}
      >
        <div className={styles.icon}>
          <PinLocation />
        </div>
        <p className={styles.name}>Definir cidade</p>
      </div>
      <div
        className={css({
          option: true,
          selected: selectedLocation === LocationOptions.MY_LOCATION,
        })}
        onClick={() => onSelectLocation(LocationOptions.MY_LOCATION)}
      >
        <div className={styles.icon}>
          <TargetLocation />
        </div>
        <p className={styles.name}>Minha localização</p>
      </div>

      {selectedLocation ? (
        <div className={styles.footer}>
          <Button design="primary" onClick={changeStep}>
            Avançar
          </Button>
        </div>
      ) : null}
    </div>
  );
}
