import React from "react";

import { isDesktop } from "react-device-detect";

import styles from "@/styles/containers/benefits.module.scss";

import { Button, Drawer, Loading, Radio } from "@/shared/components";
import { Plus } from "@/shared/components/Icons";

import { Currency } from "@/shared/tools/Currency";

import Coupon from "./Coupon";

import { OrderStorage } from "@/storage/OrderStorage";
import { BenefitStorage } from "@/storage/BenefitStorage";

import { Benefit } from "@/shared/tools";
import { Brand } from "@/shared/core/Brand";

import classNames from "classnames/bind";
const css = classNames.bind(styles);

export default class BenefitsList extends React.Component {
  state = {
    cartTotal: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedBenefit: BenefitStorage.getSelectedBenefit() || null,
      gifts: [],
      coupons: [],
      openZeroBalance: false,
    };
  }

  componentDidMount() {
    const order = OrderStorage.getOrder();

    const { coupons, benefitSelected } = this.props;
    const { selectedBenefit } = this.state;
    const selectedCoupon = benefitSelected || selectedBenefit;

    const sortedCoupons = coupons.sort((a, b) => {
      if (b.code === selectedCoupon?.code) {
        return 1;
      } else if (Benefit.canBeUsed(a)) {
        return -1;
      }

      return a;
    });

    this.setState({
      coupons: sortedCoupons,
      cartTotal: order?.cart?.total || 0,
    });

    BenefitStorage.attachOnBenefitChanged(this.onBenefitSelected.bind(this));
  }

  componentWillUnmount() {
    BenefitStorage.detachOnBenefitChanged(this.onBenefitSelected.bind(this));
  }

  onBenefitSelected({ detail: coupon }) {
    this.setState({ selectedBenefit: coupon });
  }

  render() {
    const {
      addCoupon,
      hasRadio,
      selectCoupon,
      useBalance,
      applyBenefit,
      balance,
      benefitSelected,
      loading,
      isClub,
      coupons,
      allowSelectCoupon,
    } = this.props;
    const { selectedBenefit, cartTotal, openZeroBalance } = this.state;

    const selectedCoupon = benefitSelected || selectedBenefit;

    const checkSelected = (coupon) => {
      if (benefitSelected) {
        if (!Benefit.canBeUsed(benefitSelected)) return false;
        return coupon.code === benefitSelected.code;
      }

      if (selectedBenefit) {
        if (!Benefit.canBeUsed(selectedBenefit)) return false;
        return coupon.code == selectedCoupon.code;
      }

      return false;
    };

    const brand = Brand.getBrand();
    const isGuaco = brand?.id == "849" && brand?.alias == "gua.co849";

    return (
      <>
        <div className={styles.benefitsList}>
          <div
            className={css({
              useBalance: true,
              selected:
                selectedCoupon && selectedCoupon.type == "CASHBACK_CONSUME",
              disabled: isGuaco && hasRadio,
            })}
            onClick={
              isGuaco && hasRadio
                ? null
                : () => {
                    if (selectedCoupon?.type == "CASHBACK_CONSUME") {
                      return selectCoupon({
                        type: "CASHBACK_CONSUME",
                      });
                    }

                    if (balance === 0 || !balance) {
                      return this.setState({ openZeroBalance: true });
                    }

                    if (useBalance) {
                      return useBalance();
                    }

                    BenefitStorage.setSelectedBenefit({
                      type: "CASHBACK_CONSUME",
                      amount: balance,
                    });
                  }
            }
          >
            <div className="d-flex align-center">
              {hasRadio && (
                <Radio
                  value="CASHBACK_CONSUME"
                  checked={selectedCoupon && selectedCoupon.type}
                  readOnly
                  className="ml-20"
                />
              )}
              <div className={styles.details}>
                <p className={styles.text}>Utilizar saldo</p>
                <span className={styles.value}>
                  {Currency.formatCurrency(balance || 0)}
                </span>
              </div>
            </div>
            {isGuaco && hasRadio && (
              <div className={styles.notAvailable}>
                Disponível apenas no salão
              </div>
            )}
          </div>

          <p className={styles.selectBenefit}>
            Selecione o benefício{" "}
            {(coupons || []).length > 0 && (
              <strong>({(coupons || []).length})</strong>
            )}
          </p>

          <Button
            design="secondary"
            className="mb-20 w-100"
            onClick={addCoupon}
          >
            <Plus color="#3D734A" />
            <span className="ml-15">Adicionar cupom</span>
          </Button>

          {loading && (
            <div className={styles.loading}>
              <Loading />
            </div>
          )}

          {!loading &&
            (coupons.length > 0 ? (
              <div className={styles.coupons}>
                {coupons.map((coupon, index) => {
                  return (
                    <Coupon
                      key={index}
                      coupon={coupon}
                      hasRadio={hasRadio}
                      currentCartTotal={cartTotal}
                      selected={checkSelected(coupon)}
                      onClick={() => selectCoupon && selectCoupon(coupon)}
                      allowSelectCoupon={allowSelectCoupon}
                    />
                  );
                })}
              </div>
            ) : (
              <div className={styles.emptyCoupons}>
                <p>Não há benefícios no momento.</p>
              </div>
            ))}

          {hasRadio && (
            <div className={styles.footer}>
              <Button
                design="primary"
                className="w-100"
                onClick={applyBenefit}
                disabled={!Benefit.canBeUsed(benefitSelected)}
              >
                Selecionar benefício
              </Button>
            </div>
          )}
        </div>

        <Drawer
          open={openZeroBalance}
          alreadyDrawer
          onClose={(status) => this.setState({ openZeroBalance: status })}
          direction={isClub && isDesktop ? "bottom-center" : "bottom"}
        >
          <p className={styles.alertTitle}>Atenção</p>
          <p className={styles.alertSubtitle}>
            Infelizmente não há saldo de cashback disponível para uso.
          </p>
          <Button
            design="primary"
            className="w-100"
            onClick={() => this.setState({ openZeroBalance: false })}
          >
            Ok
          </Button>
        </Drawer>
      </>
    );
  }
}
