import { Currency } from "shared/tools";
import styles from "styles/containers/product-details.module.scss";

import { useEffect, useState } from "react";

import { Checkbox, Radio } from "@/shared/components";
import { Table } from "@/shared/core/Table";
import { OlgaImageLoader } from "@/shared/tools";
import classNames from "classnames/bind";
import Image from "next/image";
import { MinusPlusInput } from "../Pizza/MinusPlusInput";
const css = classNames.bind(styles);

export const OptionItem = ({
  option,
  onClick,
  sameOptions,
  singleOption,
  disabled,
  ecleticaMenu,
}) => {
  const [isDigitalMenu, setIsDigitalMenu] = useState(false);

  useEffect(() => {
    const externalId = Table.externalId;
    if (externalId === Table.isDigitalMenu) setIsDigitalMenu(true);
    else setIsDigitalMenu(false);
  }, []);

  return (
    <div
      className={css({ optionItem: true, isDigitalMenu: isDigitalMenu })}
      onClick={() =>
        (!disabled || (!sameOptions && option.selected) || singleOption) &&
        onClick &&
        (!isDigitalMenu || !!ecleticaMenu) &&
        onClick(option)
      }
    >
      <div
        className={css({
          "d-flex": true,
          "flex-col": true,
          "justify-between w-100": isDigitalMenu,
        })}
      >
        <p className={styles.optionName}>{option.name}</p>
        {option?.description && (
          <p className={styles.description}>{option?.description}</p>
        )}
        {option.old_price || option.price ? (
          <div className="d-flex align-center mt-10">
            {option.promo_price ? (
              <p className={styles.optionOldPrice}>
                {Currency.formatCurrency(option.old_price || 0)}
              </p>
            ) : null}
            {Number(option.price > 0) ? (
              <p className={styles.optionPrice}>
                {Currency.formatCurrency(option.price || 0)}
              </p>
            ) : null}
          </div>
        ) : null}
      </div>

      <div className={styles.optionSelect}>
        {option.image && (
          <div className={styles.image}>
            <Image
              src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${option.image}`}
              alt={option.name}
              placeholder="blur"
              blurDataURL={OlgaImageLoader.BLURRED_IMG}
              loader={OlgaImageLoader.load}
              width={55}
              height={55}
              objectFit="cover"
            />
          </div>
        )}

        {sameOptions && !isDigitalMenu && (
          <MinusPlusInput
            value={option.quantity || 0}
            disabledMax={disabled}
            onChange={(newValue) => {
              onClick && onClick({ ...option, quantity: newValue });
            }}
            hasQuantity={option?.quantity > 0}
          />
        )}
        {!sameOptions && singleOption && (!isDigitalMenu || !!ecleticaMenu) && (
          <Radio
            key={Math.random()}
            value={option.id}
            checked={option.selected && option.id}
            readOnly
          />
        )}
        {!sameOptions && !singleOption && !isDigitalMenu && (
          <Checkbox
            key={Math.random()}
            value={option.id}
            checked={option.selected}
            readOnly
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};
