import styles from "styles/components/radio.module.scss";

import classNames from "classnames/bind";

export const Radio = ({
  id,
  checked,
  onChange,
  family,
  value,
  disabled,
  className,
  children,
  readOnly,
}) => {
  const cx = classNames.bind(styles);

  return (
    <div
      className={cx({
        radioContainer: true,
        disabled: disabled,
        [className]: className,
      })}
    >
      <label htmlFor={id} className={cx({ readOnly: readOnly })}>
        <input
          id={id}
          type="radio"
          family={family}
          value={value}
          checked={checked === value}
          onChange={onChange}
          readOnly={readOnly}
        />
        <div className={styles.radio}></div>
        <span>{children}</span>
      </label>
    </div>
  );
};
