import { Currency } from "shared/tools";
import styles from "styles/containers/product-details.module.scss";

export const PizzaSizeStep = ({ selectedSize, sizes, onSelectSize }) => {
    return (
        <>
            <div className={styles.sectionHeader}>
                <p className={styles.sectionTitle}>Escolha o tamanho</p>
                <span className={styles.requiredField}>Obrigatório</span>
            </div>

            <div className={styles.sizes}>
                {selectedSize && (
                    <span className={styles.sizePrice}>
                        {Currency.formatCurrency(selectedSize.price)}
                    </span>
                )}

                {sizes.map((size) => (
                    <div
                        key={`size-${size.id}`}
                        className={`${styles.size} ${size.id == selectedSize?.id ? styles.selected : ""}`}
                        onClick={() => onSelectSize(size)}
                    >
                        <p className={styles.name}>{size.name}</p>
                    </div>
                ))}
            </div>
        </>
    );
}