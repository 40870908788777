import { Address } from "@olga-food/schemas/lib/classes/schemas/address/Address";
import { BucketsAPI } from "api/BucketAPI";
import { EnvironmentAPI } from "api/EnvironmentAPI";
import { BaseStorage } from "storage/BaseStorage";
import { OrderStorage } from "storage/OrderStorage";
import { Customer } from "./Customer";

export class Environment {
  static setCurrentAlias(alias) {
    localStorage.setItem("selected-environment", alias);
    window.dispatchEvent(
      new CustomEvent("selectedEnvironmentChanged", { detail: alias })
    );
  }

  static getCurrentAlias() {
    return localStorage.getItem("selected-environment");
  }

  static getCurrentEnvironment() {
    try {
      const environmentRaw = localStorage.getItem(
        Environment.getCurrentAlias()
      );
      return environmentRaw ? JSON.parse(environmentRaw) : null;
    } catch (err) {
      return null;
    }
  }

  static getCurrentConfigurationByAlias(alias) {
    const environment = Environment.getCurrentEnvironment();
    return environment ? environment[alias] : null;
  }

  static getEnvironment(environmentAlias) {
    try {
      const environmentRaw = localStorage.getItem(environmentAlias);
      return environmentRaw ? JSON.parse(environmentRaw) : null;
    } catch (err) {
      return null;
    }
  }

  static async install(environmentAlias, configurations) {
    const baseStorage = new BaseStorage({ key: environmentAlias });
    if (configurations.keys?.customer_api_key) {
      localStorage.setItem(
        "customerApiKey",
        configurations.keys.customer_api_key
      );
    }

    baseStorage.setData(configurations);
    Environment.setCurrentAlias(environmentAlias);
    if (configurations.unit) {
      await Promise.all(
        configurations.unit.map(async (u) => {
          const unitConfigurations = await EnvironmentAPI.getS3Configurations(
            u.alias
          );
          const baseStorage = new BaseStorage({ key: u.alias });
          baseStorage.setData(unitConfigurations);
        })
      );
    }
  }

  static async load(environmentAlias) {
    const envConfigurations = await EnvironmentAPI.getS3Configurations(
      environmentAlias
    );
    const baseStorage = new BaseStorage({ key: environmentAlias });
    if (envConfigurations.keys?.customer_api_key) {
      localStorage.setItem(
        "customerApiKey",
        envConfigurations.keys.customer_api_key
      );
    }

    baseStorage.setData(envConfigurations);
    Environment.setCurrentAlias(envConfigurations.alias);
    if (envConfigurations.unit) {
      await Promise.all(
        envConfigurations.unit.map(async (u) => {
          const unitConfigurations = await EnvironmentAPI.getS3Configurations(
            u.alias
          );
          const baseStorage = new BaseStorage({ key: u.alias });
          baseStorage.setData(unitConfigurations);
        })
      );
    }
  }

  static getCurrentThemeConfigs() {
    const environment = Environment.getCurrentEnvironment();
    let brand = environment;

    if (environment && environment.type == "UNIT") {
      //Se for uma unidade selecionada, devemos buscar pela marca
      brand = Environment.getEnvironment(environment.parent_alias);
    }

    return brand?.front_theme;
  }

  static getCurrentId() {
    const environment = Environment.getCurrentEnvironment();
    return environment.id;
  }

  static getCurrentBrandId() {
    const environment = Environment.getCurrentEnvironment();

    if (environment.type == "UNIT") {
      //Se for uma unidade selecionada, devemos buscar pela marca
      const brand = Environment.getEnvironment(environment.parent_alias);
      return brand.id;
    } else {
      //Se for uma marca, retornar o avatar da marca
      return environment.id;
    }
  }

  static getCurrentBrandAlias() {
    const environment = Environment.getCurrentEnvironment();

    if (!environment) {
      return null;
    }

    if (environment.type == "UNIT") {
      //Se for uma unidade selecionada, devemos buscar pela marca
      const brand = Environment.getEnvironment(environment.parent_alias);
      return brand.alias;
    } else {
      //Se for uma marca, retornar o avatar da marca
      return environment.alias;
    }
  }

  static getCurrentAvatar() {
    const environment = Environment.getCurrentEnvironment();
    let avatar = "";

    if (environment.type == "UNIT") {
      //Se for uma unidade selecionada, devemos buscar pela marca
      const brand = Environment.getEnvironment(environment.parent_alias);
      avatar = brand?.avatar || "";
    } else {
      //Se for uma marca, retornar o avatar da marca
      avatar = environment.avatar || "";
    }

    if (avatar.startsWith("http")) {
      return avatar;
    } else if (!avatar) {
      return null;
    }

    return `${process.env.NEXT_PUBLIC_CDN_ASSETS}/${avatar}`;
  }

  static getCurrentName() {
    const environment = Environment.getCurrentEnvironment();

    if (environment.type == "UNIT") {
      //Se for uma unidade selecionada, devemos buscar pela marca
      const brand = Environment.getEnvironment(environment.parent_alias);

      //E retornar o nome da marca
      return `${brand?.name} / ${environment.name}`;
    } else {
      //Se for uma marca, retornar o nome da marca
      return environment.name || "";
    }
  }

  static getCurrentDescription() {
    const environment = Environment.getCurrentEnvironment();

    if (environment.type == "UNIT") {
      //Se for uma unidade selecionada, devemos buscar pela marca
      const brand = Environment.getEnvironment(environment.parent_alias);

      //E retornar o nome da marca
      return brand?.metadata?.description || "";
    } else {
      //Se for uma marca, retornar o nome da marca
      return environment.metadata?.description || "";
    }
  }

  static getCurrentWorkingTime() {
    const environment = Environment.getCurrentEnvironment();
    if (environment.type == "UNIT") {
      return environment.working_time;
    } else {
      return { days: [] };
    }
  }

  static async getNearByUnitsFromAddress(brandAlias, geolocation) {
    const units = await BucketsAPI.getNearbyUnits(geolocation, brandAlias);

    if (units.length > 0) {
      return units[0];
    }

    return null;
  }

  static async updateEnvironmentByAddress() {
    const environment = Environment.getCurrentEnvironment();
    if (!environment || environment?.type == "HOLDING") {
      return true;
    }

    //Carregar o endereço selecionado do cliente
    const address = Customer.getCustomerAddress();

    //Se não houver endereço selecionado, selecionar marca
    if (!address) {
      return await Environment.load(Environment.getCurrentBrandAlias());
    }

    //Carregar unidade mais próxima do endereço
    const { unit, delivery } = await Environment.getNearByUnitsFromAddress(
      Environment.getCurrentBrandAlias(),
      address.geolocation
    );

    //Se não houver unidade mais próxima, selecionar marca
    if (!unit) {
      await Environment.load(Environment.getCurrentBrandAlias());
    }

    delivery.address = Address.fromObject(address);

    //Carregar unidade
    await Environment.load(unit.alias);

    //Atualizar o pedido com a região de entrega selecionada
    OrderStorage.setDelivery(delivery);

    return true;
  }
}
