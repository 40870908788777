import { BrazilStates } from "shared/tools";
import { AddressStorage } from "storage/AddressStorage";

export class Address {

    static get current() {
        return AddressStorage.findSelected() || null
    }

    static generateAddressString(address) {
        let stringAddress = `${address.address ? address.address + ", " : ""}`;

        if (address.number) {
            stringAddress += `${address.number}`;
        }

        if (address.complement) {
            stringAddress += ` - ${address.complement}`;
        }

        const shortNameState = BrazilStates.getShortNameByName(address.state);
        
        if (address.region) {
            stringAddress += ` - ${address.region}`;
        }

        return `${stringAddress} - ${address.city}/${shortNameState}`;
    }
}