import React from "react";

import styles from "styles/containers/use-balance.module.scss";

import { Button } from "shared/components";
import { Interrogation } from "shared/components/Icons";

import { Currency } from "shared/tools/Currency";
import { BenefitStorage } from "storage/BenefitStorage";

import { ClubAPI } from "api/ClubAPI";
import { Brand } from "shared/core/Brand";

export default class UseBalance extends React.Component {
  state = {
    generatingQrCode: false,
    showHelp: false,
  };

  constructor(props) {
    super(props);
  }

  async setAsyncState(data) {
    return new Promise((resolve) => this.setState(data, resolve));
  }

  setSelectedBenefit() {
    const { goToBenefits, balance } = this.props;
    BenefitStorage.setSelectedBenefit({
      type: "CASHBACK_CONSUME",
      amount: balance,
    });
    goToBenefits && goToBenefits();
  }

  async useOnStore() {
    const { goToQRCode } = this.props;
    await this.setAsyncState({ generatingQrCode: true });
    const qrCodeReq = await ClubAPI.newQrcode(null);
    const generatedQrcode = qrCodeReq.getData({});
    if (!generatedQrcode?.qrcode) {
      await this.setAsyncState({ generatingQrCode: false });
    } else {
      await this.setAsyncState({ generatingQrCode: false });
      goToQRCode && goToQRCode(generatedQrcode);
    }
  }

  render() {
    const { goToBenefits, balance } = this.props;
    const { showHelp, generatingQrCode } = this.state;

    const brand = Brand.getBrand();
    const isGuaco = brand?.id == "849" && brand?.alias == "gua.co849";
    const isTasty = brand?.id == "846" && brand?.alias == "tasty-salad-shop846"

    return (
      <>
        {!showHelp && (
          <>
            <div className={styles.useBalance}>
              <div className={styles.details}>
                <p className={styles.name}>
                  {Currency.formatCurrency(balance || 0)}
                </p>
                <div className="relative">
                  <p className={styles.requirement}>Saldo de cashback</p>
                  <div
                    className={styles.help}
                    onClick={() => this.setState({ showHelp: true })}
                  >
                    <Interrogation />
                  </div>
                </div>
                {!isGuaco && !isTasty ? (
                  <Button
                    design="primary"
                    className="w-100"
                    onClick={this.setSelectedBenefit.bind(this)}
                  >
                    Usar saldo no delivery
                  </Button>
                ) : null}

                <Button
                  design="secondary"
                  className="w-100 mt-10"
                  disabled={generatingQrCode}
                  loading={generatingQrCode}
                  onClick={this.useOnStore.bind(this)}
                >
                  Usar saldo na loja
                </Button>
              </div>
            </div>

            <div className="d-flex justify-center mt-20">
              <Button design="default" onClick={goToBenefits}>
                Ver meus benefícios
              </Button>
            </div>
          </>
        )}

        {showHelp && (
          <>
            <p className={styles.message}>
              Se o valor do seu pedido é maior que o saldo do cashback, todo o
              saldo de cashback é debitado. Se o saldo de cashback for maior que
              o valor do pedido, é debitado o valor do pedido.
            </p>
          </>
        )}
      </>
    );
  }
}
