import { useRef, useState } from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import styles from "styles/containers/address-list.module.scss";

import Image from "next/image";

import { v4 as uuid } from "uuid";

import { Button, Input, InputSearch, Loading } from "shared/components";

import { AddressAPI } from "api/AddressAPI";

import { MapMarker } from "shared/components/Icons";

import mapAddress from "/public/map-address.png";

import { DataLayer } from "shared/tools/DataLayer";
import { useRouter } from "next/router";

export default function AddAddress({ step, setStep, onSave, loading }) {
  const formikRef = useRef();

  const [shimmer, setShimmer] = useState(false);
  const [term, setTerm] = useState("");
  const [address, setAddress] = useState({});
  const [zipcodeNotFound, setZipcodeNotFound] = useState(false);
  const [fullAddress, setFullAddress] = useState(null);
  const [addressess, setAddressess] = useState([]);
  const [searchDelay, setSearchDelay] = useState(null);
  const [token] = useState(uuid());
  const [loadingAddressess, setLoadingAddressess] = useState(false);

  const router = useRouter();

  const Steps = {
    ADDRESS_LIST: 1,
    SEARCH_ADDRESS: 2,
    ADD_ADDRESS: 3,
  };

  const formatAddress = (address, formattedAddress) => {
    if (!address.region && formattedAddress) {
      const regionMatches = formattedAddress.match(/-\s([^,]+)/, "");
      const region = regionMatches?.length > 0 ? regionMatches[1] : null;
      address.region = region;
    }

    return (
      address &&
      `${address?.address}, ${address?.number ? `${address?.number}, ` : ""}${
        address?.region
      } - ${address?.city} - ${address?.state}`
    );
  };

  const initialValuesFullAddress = {
    complement: "",
    referencePoint: "",
    alias: "",
    number: "",
  };

  const validationSchemaFullAddress = Yup.object({
    complement: Yup.string(),
    referencePoint: Yup.string(),
    alias: Yup.string().required("Campo obrigatório"),
    number: Yup.string()
      .required("Campo obrigatório")
      .min(1, "Campo Obrigatório"),
    zipcode: Yup.string().optional().nullable().length(9, "CEP Inválido"),
  });

  const save = async (values, actions) => {
    let newAddress = {
      ...address,
      id: uuid(),
      alias: values.alias || "Minha casa",
      number: address.number || values.number,
      zipcode: address.zipcode || values.zipcode,
      complement: values.complement,
      observation: values.referencePoint,
    };

    if (!newAddress.zipcode) {
      const addressesResponse = await AddressAPI.searchAddresses(
        formatAddress(newAddress),
        token
      );
      const addressess = addressesResponse.getData([]);
      const addressId = addressess[0]?.id || null;

      if (addressId) {
        const detail = await AddressAPI.getAddressById(addressess[0].id, token);
        const { address: updatedAddress } = detail.getData({ address: null });

        if (updatedAddress.zipcode) {
          newAddress = {
            ...updatedAddress,
            id: uuid(),
            alias: values.alias || "Minha casa",
            number: updatedAddress.number || values.number,
            complement: values.complement,
            observation: values.referencePoint,
          };
          onSave(newAddress);
        } else {
          setZipcodeNotFound(true);
          actions.setFieldError("zipcode", "Campo obrigatório");
        }
      } else {
        console.error("Endereço não encontrado");
      }
    } else {
      onSave(newAddress);
    }
  };

  const handleSearchDelay = (searchHandler) => {
    const delayInSeconds = 1;
    const searchDelay = setTimeout(searchHandler, delayInSeconds * 1000);

    setSearchDelay(searchDelay);
  };

  const searchAddresses = (address) => {
    setShimmer(false);
    setAddressess([]);
    setTerm(address);
    setStep(Steps.ADDRESS_LIST);
    setZipcodeNotFound(false);

    if (searchDelay) {
      clearTimeout(searchDelay);
    }

    if (address && address.length > 3) {
      setShimmer(true);
      setStep(Steps.SEARCH_ADDRESS);

      handleSearchDelay(async () => {
        try {
          const addressesResponse = await AddressAPI.searchAddresses(
            address,
            token
          );
          const addressess = addressesResponse.getData([]);
          setAddressess(addressess);
          setShimmer(false);

          DataLayer.push({
            event: "search_address",
            search_address_term: address,
          });
        } catch (err) {
          console.error(err);
        }
      });
    }
  };

  const setSelectedAddress = async (addressId) => {
    setLoadingAddressess(true);
    const addressResponse = await AddressAPI.getAddressById(addressId, token);
    const { address, formattedAddress } = addressResponse.getData({
      address: null,
      formattedAddress: null,
    });

    if (address) {
      setAddress(address);

      if (Object.keys(address).length > 0) {
        setAddressess([]);
        setStep(Steps.ADD_ADDRESS);
        setFullAddress(formatAddress(address, formattedAddress));
      }

      const type = router.pathname.endsWith("/perfil") ? "profile" : "initial";
      DataLayer.push({ event: "address_begin", type: type });
      DataLayer.push({
        event: "select_content",
        content_type: "customer_address",
        content_id: addressId,
        content_properties: address,
      });

      setTimeout(() => {
        const form = formikRef?.current;

        form?.setFieldValue("number", address.number || "");
        form?.setFieldValue("zipcode", address?.zipcode || "");
      }, 100);
    }

    setLoadingAddressess(false);
  };

  return (
    <>
      {[Steps.ADDRESS_LIST, Steps.SEARCH_ADDRESS].includes(step) && (
        <>
          <InputSearch
            placeholder="Adicionar endereço com número"
            design="two"
            className="w-100"
            value={term}
            onChange={(e) => {
              searchAddresses(e.target.value);
            }}
          />

          {[Steps.SEARCH_ADDRESS].includes(step) &&
            (shimmer ? (
              <div className={styles.addressList}>
                <div className={styles.shimmerAddress}>
                  {Array.from({ length: 5 }).map((_, _i) => (
                    <div className={styles.container} key={_i}>
                      <MapMarker />
                      <div className={`${styles.item} ${styles.shimmer}`} />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              addressess.length > 0 &&
              !loadingAddressess && (
                <div className={styles.addressList}>
                  {}
                  {addressess.map((address) => (
                    <div
                      key={address.id}
                      className={styles.address}
                      onClick={() => setSelectedAddress(address.id)}
                    >
                      <MapMarker />
                      <p>{address.description}</p>
                    </div>
                  ))}
                </div>
              )
            ))}

          {[Steps.SEARCH_ADDRESS].includes(step) &&
            loadingAddressess &&
            !shimmer && (
              <div className={styles.loadingAddressess}>
                <Loading />
              </div>
            )}
        </>
      )}

      {[Steps.ADD_ADDRESS].includes(step) && (
        <div className={styles.addAddressContainer}>
          <p className={styles.addressName}>{address?.address}</p>
          <p className={styles.fullAddress}>{fullAddress}</p>

          <Formik
            innerRef={formikRef}
            initialValues={initialValuesFullAddress}
            validationSchema={validationSchemaFullAddress}
            onSubmit={(values, actions) => save(values, actions)}
          >
            <Form autoComplete="off">
              <div className="d-flex mb-20">
                <Input
                  design="two"
                  className="w-100"
                  name="number"
                  label="Número"
                />
                <Input
                  design="two"
                  className="ml-10 w-100"
                  name="complement"
                  label="Complemento"
                  placeholder="Ex. Casa, apartamento"
                />
              </div>
              {address?.address && zipcodeNotFound && (
                <Input
                  design="two"
                  className="mb-20"
                  name="zipcode"
                  label="Opa! Faltou CEP do endereço"
                  mask="99999-999"
                />
              )}
              <Input
                design="two"
                className="mb-20"
                name="referencePoint"
                label="Ponto de referência"
                placeholder="Ex. Perto da padaria"
              />
              <Input
                design="two"
                name="alias"
                label="Favoritar como"
                placeholder="Ex. Minha casa"
              />

              <div className={styles.footer}>
                <Button
                  design="primary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Concluir
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      )}
    </>
  );
}
