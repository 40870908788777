import React, { useEffect, useState } from "react";

import styles from "styles/pages/club.module.scss";

import BenefitsList from "@/shared/containers/Benefits/BenefitsList";
import CashbackEvents from "@/shared/containers/Benefits/CashbackEvents";
import AddCoupon from "@/shared/containers/Benefits/AddCoupon";
import CashbackBalance from "@/shared/containers/Benefits/CashbackBalance";
import CouponDetails from "@/shared/containers/Benefits/CouponDetails";
import QRCode from "@/shared/containers/Benefits/QRCode";
import UseBalance from "@/shared/containers/Benefits/UseBalance";

import { ClubAPI } from "api/ClubAPI";

import { BenefitScanPage } from "@/shared/containers/Benefits/BenefitScanPage";
import { AddOptions, AddTypes } from "@/shared/containers/Benefits/AddOptions";
import { BenefitStorage } from "storage/BenefitStorage";
import { OrderStorage } from "storage/OrderStorage";
import { ClubLogin } from "./ClubLogin";
import { Brand } from "@/shared/core/Brand";
import { Holding } from "@/shared/core/Holding";
import { Loading } from "@/shared/components";
import { Drawer } from "@/shared/components";

import classNames from "classnames/bind";
import { BrandStatus } from "../StoreStatus";
import { DataLayer } from "@/shared/tools/DataLayer";
import { Customer } from "@/shared/core/Customer";
const css = classNames.bind(styles);

const Steps = {
  BENEFITS: 1,
  ADD_COUPON: 2,
  EXTRACT: 3,
  COUPON_DETAILS: 4,
  USE_BALANCE: 5,
  QR_CODE: 6,
  CLUB_LOGIN: 7,
};

export default function Club({ open, onClose, alreadyDrawer }) {
  const [step, setStep] = useState(Steps.BENEFITS);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [giftList, setGiftList] = useState([]);
  const [addOptionsVisible, setAddOptionsVisible] = useState(false);
  const [initialAddCouponData, setInitialAddCouponData] = useState({
    code: null,
    pin: null,
  });
  const [openBenefitScan, setOpenBenefitsScan] = useState(false);
  const [codeUnlocked, setCodeUnlocked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [infos, setInfos] = useState({});

  const handleSelectCoupon = (coupon) => {
    setSelectedCoupon(coupon);
    BenefitStorage.setSelectedBenefit(coupon);
    setStep(Steps.COUPON_DETAILS);
  };

  const handleUseBalance = () => {
    setStep(Steps.USE_BALANCE);
  };

  useEffect(() => {
    if (Brand.alias) loadBenefits();

    onCustomerChanged();
    Customer.onChange(onCustomerChanged);
    return () => Customer.offChange(onCustomerChanged);
  }, []);

  const onCustomerChanged = async () => {
    if (Brand.alias) await loadBenefits();
  };

  const handleBackStep = async () => {
    let selectedEnvironment = null;
    if (typeof window !== "undefined") {
      selectedEnvironment =
        Holding.alias && Holding.alias != "null" ? Brand.alias : "";
    }

    if ([Steps.CLUB_LOGIN, Steps.BENEFITS].includes(step)) onClose();

    if ([Steps.EXTRACT, Steps.COUPON_DETAILS, Steps.USE_BALANCE].includes(step))
      return setStep(Steps.BENEFITS);

    if (step == Steps.ADD_COUPON) return setStep(Steps.BENEFITS);

    if (step === Steps.QR_CODE) {
      selectedBenefit &&
        selectedBenefit !== "Balance" &&
        setStep(Steps.COUPON_DETAILS);
      selectedBenefit &&
        selectedBenefit === "Balance" &&
        setStep(Steps.USE_BALANCE);
    }
  };

  const getTitle = () => {
    const titles = {
      [Steps.BENEFITS]: "Clube",
      [Steps.ADD_COUPON]: "Adicionar benefício",
      [Steps.EXTRACT]: "Saldo de cashback",
      [Steps.COUPON_DETAILS]: "Detalhes do benefício",
      [Steps.USE_BALANCE]: "Uso do saldo",
      [Steps.QR_CODE]: "QR Code",
      [Steps.CLUB_LOGIN]: "Clube",
    };

    return titles[step];
  };

  useEffect(() => {
    const titles = {
      [Steps.BENEFITS]: "Club | Listagem de benefícios",
      [Steps.ADD_COUPON]: "Club | Adicionar benefício",
      [Steps.EXTRACT]: "Club | Saldo de cashback",
      [Steps.COUPON_DETAILS]: "Club | Detalhes do benefício",
      [Steps.USE_BALANCE]: "Club | Uso do saldo",
      [Steps.CLUB_LOGIN]: "Club | Login",
    };

    if (open && step !== Steps.ADD_COUPON) {
      DataLayer.push({
        event: "page_view",
        page_title: titles[step],
      });
    }

    const clubContainerScroll = document.querySelector("#clubContainerScroll");

    if (clubContainerScroll) clubContainerScroll.scrollTo(0, 0);
  }, [open, step]);

  const loadBenefits = async () => {
    const customerInfos = (await ClubAPI.getCustomerInfos()) || {};
    if (!customerInfos?.id) {
      setLoading(false);
      setStep(Steps.CLUB_LOGIN);
    } else {
      setGiftList([]);
      setInfos(customerInfos);
      setLoading(false);
    }
    return true;
  };

  const benefits = infos?.benefits || {};
  const { cashback = { total: 0 }, coupons = [] } = benefits;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      alreadyDrawer={alreadyDrawer}
      title={getTitle()}
      showBackButton
      backButton={handleBackStep}
    >
      {loading && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}

      {!loading && (
        <>
          {step === Steps.BENEFITS && <BrandStatus />}

          <div className={css({ benefits: true })} id="clubContainerScroll">
            {step === Steps.CLUB_LOGIN && (
              <ClubLogin
                cpfIsValid={(value) => setCodeUnlocked(value)}
                onClose={() => {
                  loadBenefits();
                  setStep(Steps.BENEFITS);
                }}
              />
            )}

            {[Steps.BENEFITS, Steps.EXTRACT].includes(step) && (
              <CashbackBalance
                balance={cashback.total}
                goToExtract={() => setStep(Steps.EXTRACT)}
                showViewExtract={step === Steps.BENEFITS}
              />
            )}

            {step === Steps.BENEFITS && (
              <BenefitsList
                hasRadio={false}
                addCoupon={() => setAddOptionsVisible(true)}
                selectCoupon={(value) => {
                  const coupon = BenefitStorage.getSelectedBenefit();

                  if (
                    coupon &&
                    coupon.code === value.code &&
                    coupon.pin === value.pin &&
                    coupon.type === value.type
                  ) {
                    setSelectedCoupon(null);
                    BenefitStorage.setSelectedBenefit(null);
                    OrderStorage.setGift(null);
                  } else {
                    setSelectedCoupon(value);
                    setStep(Steps.COUPON_DETAILS);
                  }
                }}
                useBalance={() => {
                  const benefit = BenefitStorage.getSelectedBenefit();
                  if (benefit && benefit.type === "CASHBACK_CONSUME") {
                    setSelectedCoupon(null);
                    BenefitStorage.setSelectedBenefit(null);
                    OrderStorage.setGift(null);
                  } else {
                    handleUseBalance();
                  }
                }}
                coupons={coupons}
                allowSelectCoupon
                balance={cashback.total}
                giftList={giftList}
                loading={loading}
              />
            )}

            {step === Steps.ADD_COUPON && (
              <AddCoupon
                onClose={() => {
                  setStep(Steps.BENEFITS);
                  setInitialAddCouponData({ code: null, pin: null });
                }}
                code={initialAddCouponData.code}
                pin={initialAddCouponData.pin}
                onSelectedBenefit={async (coupon) => {
                  setInitialAddCouponData({ code: null, pin: null });
                  await loadBenefits();
                  handleSelectCoupon(coupon);
                }}
              />
            )}

            {step === Steps.USE_BALANCE && (
              <UseBalance
                goToQRCode={() => {
                  setStep(Steps.QR_CODE);
                  setSelectedBenefit("Balance");
                }}
                goToBenefits={() => setStep(Steps.BENEFITS)}
                balance={cashback.total}
              />
            )}

            {step === Steps.COUPON_DETAILS && (
              <CouponDetails
                goToQRCode={() => {
                  setStep(Steps.QR_CODE);
                  setSelectedBenefit("Coupon");
                }}
                goToBenefits={() => setStep(Steps.BENEFITS)}
                coupon={selectedCoupon}
              />
            )}

            {step === Steps.QR_CODE && (
              <QRCode
                closeQRCode={() => {
                  selectedBenefit === "Coupon" && setStep(Steps.COUPON_DETAILS);
                  selectedBenefit === "Balance" && setStep(Steps.USE_BALANCE);
                }}
                coupon={selectedBenefit === "Coupon" ? selectedCoupon : null}
                cpfIsValid={(value) => setState({ codeUnlocked: value })}
                codeUnlocked={codeUnlocked}
              />
            )}

            {step === Steps.EXTRACT && <CashbackEvents />}

            <BenefitScanPage
              open={openBenefitScan}
              onClose={() => setOpenBenefitsScan(false)}
              onError={(err) => console.log(err)}
              onScan={({ type, code, pin }) => {
                setStep(Steps.ADD_COUPON);
                setOpenBenefitsScan(!openBenefitScan);
                setInitialAddCouponData({ code, pin });
              }}
            />

            <AddOptions
              open={addOptionsVisible}
              onClose={() => setAddOptionsVisible(false)}
              onSelectOption={async (option) => {
                setAddOptionsVisible(false);
                if (option === AddTypes.MANUAL) {
                  setStep(Steps.ADD_COUPON);
                  setInitialAddCouponData({ code: null, pin: null });
                } else {
                  const allow = await navigator.permissions.query({
                    name: "camera",
                  });
                  if (allow.state) {
                    setOpenBenefitsScan(true);
                    setInitialAddCouponData({ code: null, pin: null });
                  }
                }
              }}
            />
          </div>
        </>
      )}
    </Drawer>
  );
}
