import { Fragment } from "react";
import { Radio } from "shared/components";
import { OptionItem } from "./OptionItem";
import styles from "styles/containers/product-details.module.scss";

export const PizzaEdgeStep = ({ edges, onSelectEdge }) => {
    const hasSelectedEdge = edges.some((edge) => edge.selected === true);

    return (
        <>
            <div className={styles.sectionHeader}>
                <div className="d-flex flex-col">
                    <p className={styles.sectionTitle}>Escolha uma borda</p>
                </div>
                <span className={styles.requiredField}>Opcional</span>
            </div>

            <div className={styles.iDontWant}>
                <p>Não quero, obrigado</p>
                <Radio
                    id="without-edge"
                    value={true}
                    checked={!hasSelectedEdge}
                    onChange={() => onSelectEdge(null)}
                />
            </div>

            <div className={styles.optionItems}>
                {edges.map((edge) => (
                    <Fragment key={`edge-${edge.id}`}>
                        <OptionItem
                            option={edge}
                            singleOption={true}
                            onClick={() => onSelectEdge(edge)}
                        />
                    </Fragment>
                ))}
            </div>
        </>
    );
};