export class DateFormat {
  /* Converte timestamp para data no formato DD/MM/AAAA */
  static ts2Date(iTimestamp, time = true) {
    let options;
    if (time) {
      options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
    } else {
      options = { year: "numeric", month: "2-digit", day: "2-digit" };
    }
    let date = new Date(iTimestamp * 1000);
    return date.toLocaleDateString("pt-BR", options);
  }

  /* Converte formato  AAAA-MM-DD para DD/MM/AAAA */
  static db2date(sDate) {
    return sDate.split("-").reverse().join("/");
  }

  /* Converte formato  2019-08-30T16:51:10.000Z para DD/MM/AAAA */
  static dateParse(date, timestamp = false) {
    let ms = Date.parse(date) / 1000;
    return timestamp ? ms : this.ts2Date(ms);
  }

  static formatDateOffset(createdAt, expirationDays) {
    return `${expirationDays} dias`;
  }
}
