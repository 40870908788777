import { useState, useEffect } from "react";
import { useRouter } from "next/router";

import styles from "@/styles/containers/cart-receive-on.module.scss";

import EcleticaCartItem from "@/shared/containers/Cart/EcleticaCartItem";
import { Brand } from "@/shared/core/Brand";
import { isDesktop } from "react-device-detect";

export default function EcleticaCart({ items = [], onClose }) {
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);

  const router = useRouter();

  useEffect(() => {
    setSelectedEnvironment(Brand.alias ? Brand.alias : null);
  }, []);

  const renderCartItems = () => {
    return <EcleticaCartItem onClose={() => close()} items={items} />;
  };

  if (!items) {
    return null;
  }

  const close = () => {
    if (isDesktop) return onClose && onClose()

    const newPath = selectedEnvironment
      ? `/${selectedEnvironment}/cardapio`
      : "/cardapio";
    router.push(newPath);
  };

  return items.length > 0 && renderCartItems();
}
