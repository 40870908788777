import React, { useEffect, useState } from "react";

import styles from "styles/containers/cart-resume.module.scss";

import { Bag } from "shared/components/Icons";

import { OrderStorage } from "storage/OrderStorage";

import { CatalogHelpers, Currency } from "shared/tools";

export default function CartResume({ openCart }) {
  const [cartCatalog, setCartCatalog] = useState({ selected: true, items: [] });

  const onOrderChanged = () => {
    const order = OrderStorage.getOrder();

    if (order && order.cart && order.cart.catalog) {
      setCartCatalog(order.cart.catalog);
    }
  };

  useEffect(() => {
    onOrderChanged();

    window.addEventListener("orderUpdated", onOrderChanged);

    return () => window.removeEventListener("orderUpdated", onOrderChanged);
  }, []);

  if (cartCatalog.items.length === 0) {
    return null;
  }

  const selectedCatalog = { ...cartCatalog, quantity: 1, selected: true };
  const cartItemsCount = cartCatalog.items.reduce(
    (t, i) => t + (i.quantity || 1),
    0
  );

  return (
    <div className={styles.cartResumeContainer} onClick={openCart}>
      <div className={styles.numberOfItems}>{cartItemsCount}</div>

      <p className={styles.seeCart}>Ver sacola</p>

      <p className={styles.totalCart}>
        {Currency.formatCurrency(
          CatalogHelpers.calculateSelectedItemPrice(selectedCatalog)
        )}
      </p>
    </div>
  );
}
