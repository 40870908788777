import classifyPoint from "robust-point-in-polygon";
import { Address } from "@olga-food/schemas/lib/classes/schemas/address/Address";
import { Geolocation } from "@olga-food/schemas/lib/classes/schemas/address/Geolocation";
import { DeliveryStrip } from "@olga-food/schemas/lib/classes/schemas/order/delivery/DeliveryStrip";

export class DeliveryChecker {
    address = null;
    deliveryPolygons = [];
    deliveryRegions = [];

    constructor({ address, deliveryPolygons, deliveryRegions }) {
        this.address = address;
        this.deliveryPolygons = deliveryPolygons;
        this.deliveryRegions = deliveryRegions;
    }

    /**
     * 
     * @returns {Address|Boolean}
     */
    getAddress() {
        const unitAddress = Address.fromObject(this.address);
        if (!unitAddress.geolocation) {
            return false;
        }
        return unitAddress;
    }

    /**
     * 
     * @param {Geolocation} geolocation 
     * @returns {Number|Boolean}
     */
    getDistance(geolocation) {
        const addr = this.getAddress();
        if (addr === false) {
            return false;
        }
        return addr.geolocation.distanceTo(geolocation);
    }

    /**
     * 
     * @param {Geolocation} geolocation 
     * @returns {Object}
     */
    getInsidePolygon(geolocation) {
        const { lat, lon } = geolocation;
        const insidePolys = [];
        for (const poly of this.deliveryPolygons) {
            if (classifyPoint(poly.points, [lat, lon]) < 1) {
                //Resultados com -1 e 0 indicam que estão dentro do poligono
                insidePolys.push(poly);
            }
        }
        insidePolys.sort((a, b) => a.price - b.price);
        return insidePolys[0] || null;
    }
    /**
     * 
     * @param {Geolocation} geolocation 
     * @returns {Boolean}
     */
    isInsideLimits(geolocation) {
        const { lat, lon } = geolocation;
        if (Array.isArray(this.deliveryPolygons) && this.deliveryPolygons.length > 0) {
            for (const poly of this.deliveryPolygons) {
                if (classifyPoint(poly.points, [lat, lon]) < 1) {
                    //Resultados com -1 e 0 indicam que estão dentro do poligono
                    return true;
                }
            }
            //Se estiver fora de qualquer poligono
            return false;
        } else {
            return true;
        }
    }

    /**
     * 
     * @param {Geolocation} geolocation 
     * @returns {DeliveryStrip | Boolean}
     */
    isInsideDeliveryStrips(geolocation) {
        const distance = this.getDistance(geolocation);
        if (distance === false) {
            return false;
        }

        return this.deliveryRegions.find(
            (region) => (region.upTo * 1000) >= distance
        );
    }
}